import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';

import { Location } from '../../../shared/models/location/location.model';
import { IShipComponent } from '../../models/ship-component.interface';
import { ICountry } from '../../../shared/models/country.interface';
import { Carriers } from '../../../shared/enum/general-enum';
import { Subscription } from 'rxjs';
import { StorageService } from '../../../core/services/storage/storage.service';
import { ShipConfigService } from '../../services/ship-config.service';
import { ShipmentStateService } from '../../services/shipment-state.service';
import { ShipmentService } from '../../services/shipment.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormService } from '../../../shared/services/form/form.service';
import { UserService } from 'app/shared/services/user/user.service';
import { User } from '../../../shared/services/user/models/user.model';

@Component({
    selector: 'upsc-ship-to-static',
    templateUrl: './ship-to-static.component.html',
    styleUrls: ['./ship-to-static.component.scss'],
})
export class ShipToStaticComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
    @Input() location: Location;
    @Input() carrier: Carriers;
    @Input() user: User;
    @Input() shipToCountryCode: string;
    @Input() shouldDisplayEUFields = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    public formGroup: UntypedFormGroup;
    public countries: ICountry[];
    public isFRITUser: boolean;
    private getShipToCountriesSubscription: Subscription;

    constructor(private storageService: StorageService,
                private shipConfigService: ShipConfigService,
                private notificationService: NotificationService,
                private utilityService: UtilityService,
                private errorHandlerService: ErrorHandlerService,
                private shipmentService: ShipmentService,
                private formBuild: UntypedFormBuilder,
                private formService: FormService,
                private userService: UserService,
                private readonly shipState: ShipmentStateService,
    ) {
        this.formGroup = this.formBuild.group({
            vat: [''],
            taxId: [''],
            eori: [''],
        });

        this.isValid.emit(true);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onLocationChanged(changes.location);
        this.onCarrierChanged(changes['carrier']);
        this.onShipToCountryCodeChanged(changes['shipToCountryCode']);
    }

    private onLocationChanged(change: SimpleChange): void {
        if (!change?.currentValue) {
            return;
        }

        const countryCode = (change.currentValue as Location)?.Country?.toUpperCase();
        this.shipState.shipToCountryCode.set(countryCode);
    }

    private onShipToCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.shipState.shipToCountryCode.set(change.currentValue);

        if (this.countries) {
            const selectedCountry = this.countries.find(country => country.CountryCode === change.currentValue);
            this.formGroup?.patchValue({
                vat: '',
                taxId: '',
                eori: '',
            });
            this.shipConfigService.setPostalCodeAware(selectedCountry);
        }
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.carrier = this.utilityService.getEnumValue(Carriers, change.currentValue);
        this.loadCarrierCountries(this.carrier);
    }

    private loadCarrierCountries(carrier: Carriers) {
        if (!carrier) {
            return;
        }

        if (this.getShipToCountriesSubscription) {
            this.getShipToCountriesSubscription.unsubscribe();
            this.getShipToCountriesSubscription = null;
        }

        this.getShipToCountriesSubscription = this.shipmentService.getShipToCountries(carrier)
            .subscribe(
                countries => this.handleGetShipToCountriesSuccess(countries),
                err => this.handleGetShipToCountriesFailure(err),
            );
    }

    private handleGetShipToCountriesSuccess(countries) {
        this.countries = countries;
    }

    private handleGetShipToCountriesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed loading country list',
            NotificationType.ERROR);
        // this.utilityService.delay(() => {
        //   this.isCountriesLoading = false;
        // });
    }

    public ngOnDestroy() {
        this.utilityService.clearSubscriptions([this.getShipToCountriesSubscription])
    }

    public ngOnInit() {        
        this.isFRITUser =  this.userService.isFRITUser(this.user);
        this.monitorValueChanges();
        this.setFormValues();
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges
            .subscribe(
                (form) => {
                    this.isValid.emit(this.formGroup.valid);
                    this.shipmentService.saveShipToStatic(form);
                    this.formValueChanged.emit(form);
                });
    }

    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        this.formGroup.patchValue({
            vat: this.shipmentService.Quote.ShipToVAT,
            taxId: this.shipmentService.Quote.ShipToTaxID,
            eori: this.shipmentService.Quote.ShipToEORI,
        });

        this.formService.markAllAsTouchedAndDirty(this.formGroup);
        this.isValid.emit(this.formGroup.valid);
    }

    public resetForm() {
    }

    public forceValidation() {
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import SafeUrlAssembler from 'safe-url-assembler';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';
import { PickupLocationConfig } from './config/pickup-location-config';
import { IPickupLocationRequest } from './models/pickup-location-request.interface';
import { IPickupLocation } from './models/pickup-location.interface';
import { IUsedPickupLocation } from './models/used-pickup-location.interface';

@Injectable({
    providedIn: 'root',
})
export class PickupLocationService {
    constructor(private http: HttpClient,
                private apiEndpointService: ApiEndpointService,
    ) {
    }

    public getPickupLocations(carrierCode: string, data: IPickupLocationRequest): Observable<IPickupLocation[]> {
        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('getPickupLocations'))
            .param('carrierCode', carrierCode)
            .toString();

        return this.http.post<IPickupLocation[]>(endpoint, data)
                   .pipe(shareReplay(1));
    }

    public getUsedPickupLocations(carrierCode: string, searchRangeInDays = 30): Observable<IUsedPickupLocation[]> {
        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('getUsedPickupLocations'))
            .param('carrierCode', carrierCode)
            .query({
                searchRangeInDays,
            })
            .toString();

        return this.http.get<IUsedPickupLocation[]>(endpoint)
                   .pipe(shareReplay(1));
    }

    public isLocationValidInsuredValue(location: IPickupLocation, insuredValue: number = 0): boolean {
        if (!location) {
            return true;
        }

        const normalizedStoreName = location.businessName.toUpperCase().replace(/ /g, '_');
        const store = Object.keys(PickupLocationConfig.storeMaxCoverage).find(key => normalizedStoreName.startsWith(key));

        return PickupLocationConfig.storeMaxCoverage[store] >= insuredValue;
    }
}

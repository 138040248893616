import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../shared/services/user/models/user.model';
import { RequestQuoteDialogComponent } from '../request-quote-dialog/request-quote-dialog.component';

@Component({
  selector: 'upsc-price-comparison',
  templateUrl: './price-comparison.component.html',
  styleUrls: ['./price-comparison.component.scss'],
})
export class PriceComparisonComponent implements OnInit, OnDestroy {
  @HostBinding('class') public hostClass = 'price-comparison';

  @Input() public user: User;

  constructor(private dialog: MatDialog) {
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
  }

  public requestQuote(event) {
    event.preventDefault();

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '513px',
      data: {
        user: this.user,
      },
    };

    const dialogRef: MatDialogRef<RequestQuoteDialogComponent> = this.dialog.open(RequestQuoteDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (!result) {
          return;
        }

        this.hideSelf();
      },
    );

  }

  private hideSelf() {
    const cssClasses = this.hostClass.split(' ');
    if (!cssClasses.find(cssClass => cssClass === 'hide')) {
      cssClasses.push('hide');
    }

    this.hostClass = cssClasses.join(' ');
  }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { nanoid } from 'nanoid';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { StorageService } from '../../core/services/storage/storage.service';
import { IDataTableAction } from '../../shared/components/data-table/models/data-table-action.interface';
import { Carriers } from '../../shared/enum/general-enum';
import { Customer } from '../../shared/models/customer/customer.model';
import { NotificationType } from '../../shared/models/notification-type';
import { CustomerService } from '../../shared/services/customer/customer.service';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { User } from '../../shared/services/user/models/user.model';
import { Commodity } from '../models/commodity.model';
import { HarmonizeCommodity } from '../models/harmonize-commodity.model';
import { Package } from '../models/package.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipConfig } from '../models/ship-config.model';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipmentService } from '../services/shipment.service';
import { CommodityFormDialogComponent } from './commodity-form-dialog/commodity-form-dialog.component';
import { CommodityTableComponent } from './commodity-table/commodity-table.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'upsc-ship-commodity',
    templateUrl: './ship-commodity.component.html',
    styleUrls: ['./ship-commodity.component.scss'],
})
export class ShipCommodityComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
    @Input() user: User;
    @Input() customer: Customer;
    @Input() shipFromCountryCode: string;
    @Input() shipToCountryCode: string;
    @Input() maxCommodityAllowed = 5;
    @Input() weightUOM = 'LBS';
    @Input() customsValueCurrency = 'USD';
    @Input() isRestoreShipment = false;
    @Input() carrier: Carriers;
    @Output() totalCustomsValueChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() totalWeightChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() commoditiesChanged: EventEmitter<Commodity[]> = new EventEmitter<Commodity[]>();

    @ViewChild('commodityTable') public commodityTable: CommodityTableComponent;

    public commodities: Commodity[] = [];
    public harmonizedCommodities: HarmonizeCommodity[];

    public config: ShipConfig;

    private totalCustomsValue: number;
    private totalWeight: number;
    private commodityActions = {
        edit: this.editCommodity2.bind(this),
        remove: this.removeCommodity2.bind(this),
        forceRemove: this.forceRemoveCommodity.bind(this),
    };
    private shouldHaveCommodities = false;
    private ngDestroyed$ = new Subject<void>();

    public constructor(private dialog: MatDialog,
                       private dialogService: DialogService,
                       private notificationService: NotificationService,
                       private errorHandlerService: ErrorHandlerService,
                       private readonly customerService: CustomerService,
                       private shipConfigService: ShipConfigService,
                       private shipmentService: ShipmentService,
                       private readonly storageService: StorageService,
                       private translateService: TranslateService,
    ) {
    }

    public ngOnInit() {
        this.restoreValues();

        this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;
                this.shouldHaveCommodities = config.showCommodities;
            },
        );
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
        this.onShipToCountryCodeChanged(changes['shipToCountryCode']);
        this.onCarrierChanged(changes['carrier']);
        this.onCustomsValueCurrencyChanged(changes['customsValueCurrency']);
    }

    public ngOnDestroy() {
        this.commodities = null;
        this.shipmentService.Quote.Commodities = [];

        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
    }

    public resetForm() {
        if (this.isRestoreShipment) {
            return;
        }

        this.resetComponents([
            this.commodityTable,
        ]);
    }

    public forceValidation() {
        this.markComponentFormAsDirty([
            this.commodityTable,
        ]);
    }

    public onShipFromCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.loadCommoditiesByCustomer();
        this.setCommodityRequirement();
    }

    public onShipToCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.setCommodityRequirement();
    }

    public openCommodityDialog(commodity?: Commodity) {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            data: {
                user: this.user,
                customer: this.customer,
                harmonizedCommodities: this.harmonizedCommodities,
                weightUOM: this.weightUOM,
                maxWeight: this.weightUOM === 'KGS' ? 70 : 125,
                customsValueCurrency: this.customsValueCurrency,
            },
            maxWidth: '100%',
            panelClass: ['mobile-fullscreen-dialog'],
        };

        if (commodity) {
            dialogConfig.data.commodity = commodity;
        }

        const dialogRef: MatDialogRef<CommodityFormDialogComponent> = this.dialog.open(CommodityFormDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (commodityValue: Commodity) => {
                if (!commodityValue) {
                    return;
                }

                this.saveCommodity(commodityValue);
            },
        );
    }

    public openNewCommodityDialog(event) {
        event.preventDefault();

        this.openCommodityDialog();
    }

    public openEditCommodityDialog(event, commodityIndex: number) {
        event.preventDefault();

        this.openCommodityDialog(this.commodities[commodityIndex]);
    }

    public removeCommodity(event, commodityIndex: number) {
        event.preventDefault();

        // TODO: open a confirmation dialog before removing.
        // this.commodities = this.commodities.filter((item: Commodity) => item.CommodityID !== commodity.CommodityID);
        this.commodities.splice(commodityIndex, 1);
        this.updateQuoteCommodities();

        this.isValid.emit(this.isFormValid());
    }

    public getTotalCustomsValue(): number {
        if (!this.commodities || !this.commodities.length) {
            this.totalCustomsValueChanged.emit(0);
            return 0;
        }

        const totalCustomsValue = this.commodities.reduce(
            (sum, value) => {
                if (!value.HarmonizedCode) {
                    return sum;
                }

                return sum + +value.CustomsValue;
            },
            0);

        this.totalCustomsValueChanged.emit(totalCustomsValue);

        return totalCustomsValue;
    }

    public getTotalWeight(): number {
        if (!this.commodities || !this.commodities.length) {
            return 0;
        }

        const totalWeight = this.commodities.reduce(
            (sum, value) => {
                if (!value.HarmonizedCode) {
                    return sum;
                }

                return sum + +value.Weight;
            },
            0);

        this.totalWeightChanged.emit(totalWeight);

        return totalWeight;
    }

    public onCommodityActionClicked(value: IDataTableAction<Commodity>) {
        if (!Object.prototype.hasOwnProperty.call(this.commodityActions, value.action)) {
            return;
        }

        this.commodityActions[value.action](value.data);
    }

    public getFormErrors() {
        if (!this.shouldHaveCommodities || this.hasCommodity()) {
            return [];
        }

        return [{
            source: 'Commodity',
            key: 'commodityCount',
            keyControl: 'min',
            errValue: { message: 'Minimum of 1 commodity is required for an international shipping' },
        }];
    }

    //#endregion

    public isFormValid() {
        return !this.shouldHaveCommodities || this.hasCommodity();
    }

    public clearCommodities() {
        this.commodities = null;
        this.commoditiesChanged.emit(null);
    }

    public restoreShipment(shipment: Package) {
        if (!shipment.Commodities || !shipment.Commodities.length) {
            return;
        }

        this.commodities = [];
        shipment.Commodities.forEach(commodity => this.saveCommodity(commodity));
    }

    private resetComponents(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return true;
                }

                component.resetForm();
            },
        );
    }

    private markComponentFormAsDirty(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return;
                }

                component.forceValidation();
            },
        );
    }

    private setCommodityRequirement() {
        if (!this.shipFromCountryCode || !this.shipToCountryCode) {
            return;
        }

        this.shouldHaveCommodities = this.shipFromCountryCode.trim().toUpperCase() !== this.shipToCountryCode.trim()
                                                                                           .toUpperCase();
        this.isValid.emit(this.isFormValid());
    }

    private loadCommoditiesByCustomer(): void {
        this.shipmentService.getCommoditiesByCustomer()
            .pipe(
                takeUntil(this.ngDestroyed$),
                catchError(err => {
                    this.handleGetHarmonizedCommoditiesFailure(err);
                    return of([]);
                }),
            )
            .subscribe(
                commodities => this.handleGetHarmonizedCommoditiesSuccess(commodities),
            );
    }

    private loadHarmonizedCommodities(shipFromCountryCode: string) {
        this.shipmentService.getHarmonizedCommodities(shipFromCountryCode)
            .subscribe(
                commodities => this.handleGetHarmonizedCommoditiesSuccess(commodities),
                err => this.handleGetHarmonizedCommoditiesFailure(err),
            );
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.resetComponents([
            this.commodityTable,
        ]);
    }

    private onCustomsValueCurrencyChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.shipmentService.Quote.CustomsCurrencyCode = change.currentValue;
        this.shipmentService.cacheQuote();
    }

    //#region GetHarmonizedCommodities handlers
    private handleGetHarmonizedCommoditiesSuccess(commodities: HarmonizeCommodity[]) {
        for (const commodity of commodities) {
            commodity.HarmonizedCode = commodity.HarmonizedCode?.trim();
            commodity.CommodityDescription = commodity.CommodityDescription?.trim();
            commodity.AESHarmonizedCode = commodity.AESHarmonizedCode?.trim();
            commodity.AESCommodityDescription = commodity.AESCommodityDescription?.trim();
            commodity.AESUOM = commodity.AESUOM?.trim();
        }

        this.harmonizedCommodities = commodities;
    }

    private handleGetHarmonizedCommoditiesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting harmonized commodities',
            NotificationType.ERROR);

        console.error(err);
    }

    private restoreValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        this.commodities = this.shipmentService.Quote.Commodities || [];
        this.totalCustomsValue = this.getTotalCustomsValue();
        this.totalWeight = this.getTotalWeight();
        this.updateCommoditySum();
        // this.shipConfigService.isEeiRequired(this.totalCustomsValue);

        this.isValid.emit(this.isFormValid());

        const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
        if (cachedShipment) {
            this.restoreShipment(cachedShipment);
        }
    }

    private saveCommodity(commodity: Commodity) {
        const existingCommodity = this.commodities.find((item: Commodity) => !!item._id && !!commodity._id && item._id === commodity._id);
        if (existingCommodity) {
            this.commodities.map(
                (item) => {
                    if (item._id !== commodity._id) {
                        return item;
                    }

                    item.AESHarmonizedCode = commodity.AESHarmonizedCode;
                    item.CommodityDescription = commodity.CommodityDescription;
                    item.CommodityName = commodity.CommodityName;
                    item.CountryOfManufacture = commodity.CountryOfManufacture;
                    item.CustomsValue = commodity.CustomsValue;
                    item.CustomsValueCurrencyExpression = commodity.CustomsValueCurrencyExpression;
                    item.ExportLicenseNo = commodity.ExportLicenseNo;
                    item.HarmonizedCode = commodity.HarmonizedCode;
                    item.Quantity = commodity.Quantity;
                    item.Weight = commodity.Weight;
                    item.WeightUnitExpression = commodity.WeightUnitExpression;

                    return item;
                },
            );
        } else {
            commodity._id = nanoid();
            // commodity.Weight = this.getRoundedWeight(+commodity.Weight);
            this.commodities.push(_.cloneDeep(commodity));
        }

        this.commoditiesChanged.emit(this.commodities.filter(item => !!item.HarmonizedCode));
        this.totalCustomsValue = this.getTotalCustomsValue();
        this.totalWeight = this.getTotalWeight();
        this.updateQuoteCommodities();

        this.isValid.emit(this.isFormValid());
    }

    private getRoundedWeight(weight: number): string {
        if (this.customerService.isUSCustomer(this.customer)) {
            return Math.ceil(weight).toString();
        }

        return weight.toFixed(2).replace(/\.?0+$/, '');
    }

    private updateQuoteCommodities() {
        this.shipmentService.saveCommodities(this.commodities.filter(commodity => !!commodity.HarmonizedCode));
        this.updateCommoditySum();
        // this.shipConfigService.isEeiRequired(this.totalCustomsValue);

        // TODO: remove this.
        this.shipmentService.Quote.ShipmentPurpose = 'Commercial';
        this.shipmentService.Quote.TotalCustomsValue = this.totalCustomsValue.toString();
        this.shipmentService.Quote.CustomsCurrencyCode = this.customsValueCurrency;

        this.shipmentService.cacheQuote();
    }

    private updateCommoditySum() {
        const sumCommodity = this.commodities.find(commodity => !commodity.HarmonizedCode);
        if (sumCommodity) {
            sumCommodity.Weight = this.translateService.instant('Total');
            sumCommodity.CustomsValue = `${ this.totalCustomsValue } ${ this.customsValueCurrency }`;

            const sumIndex = this.commodities.indexOf(sumCommodity);
            this.commodities.splice(sumIndex, 1);
            this.commodities.push(sumCommodity);
        } else {
            this.commodities.push({
                Weight: this.translateService.instant('Total'),
                CustomsValue: `${ this.totalCustomsValue } ${ this.customsValueCurrency }`,
            });
        }
    }

    private editCommodity2(commodity: Commodity) {
        this.openCommodityDialog(commodity);
    }

    private removeCommodity2(commodity: Commodity) {
        this.dialogService.confirm('', 'Do you want to remove this commodity?')
            .subscribe(
                (result) => {
                    if (!result) {
                        return;
                    }

                    const commodityIndex = this.commodities.indexOf(commodity);
                    this.commodities.splice(commodityIndex, 1);
                    this.totalCustomsValue = this.getTotalCustomsValue();
                    this.totalWeight = this.getTotalWeight();
                    this.updateQuoteCommodities();

                    this.commoditiesChanged.emit(this.commodities.filter(item => !!item.HarmonizedCode));
                    this.isValid.emit(this.isFormValid());
                },
            );
    }

    private forceRemoveCommodity(commodity: Commodity) {
        const commodityIndex = this.commodities.indexOf(commodity);
        this.commodities.splice(commodityIndex, 1);
        this.totalCustomsValue = this.getTotalCustomsValue();
        this.totalWeight = this.getTotalWeight();
        this.updateQuoteCommodities();

        this.commoditiesChanged.emit(this.commodities.filter(item => !!item.HarmonizedCode));
        this.isValid.emit(this.isFormValid());
    }

    private hasCommodity() {
        const noSumCommodities = this.commodities.filter(commodity => !!commodity.HarmonizedCode);
        return noSumCommodities && noSumCommodities.length > 0;
    }
}

<div class="address-book-search-form">
    <form [formGroup]="formGroup" class="ups-form">
        <div class="form-body">
            <div class="search">
                <div class="keyword">
                    <mat-form-field>
                        <mat-label>{{'keyword'|translate}}</mat-label>
                        <input matInput formControlName="keyword">
                    </mat-form-field>
                    <upsc-element-blocker *ngIf="isAddressBookSearching"></upsc-element-blocker>
                </div>
                <mat-form-field class="scope">
                    <mat-label>{{'scope'|translate}}</mat-label>
                    <mat-select formControlName="scope">
                        <mat-option value="0">{{'CompanyName'|translate}}</mat-option>
                        <mat-option value="1">{{'FirstName'|translate}}</mat-option>
                        <mat-option value="2">{{'LastName'|translate}}</mat-option>
                        <mat-option value="3">{{'Address'|translate}}</mat-option>
                        <mat-option value="4">{{'NickName'|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>

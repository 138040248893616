<div [innerHTML]="'ship.shipUps.accessPoint.address.title' | translate" class="pickup-location-title"></div>
<div class="address-wrapper">
    <div class="business-name">{{ location?.businessName }}</div>
    <div class="address">
        <span [innerHTML]="[location?.address, location?.city, location?.state, location?.zip] | concatAddress"></span>
    </div>
</div>
<div class="edit">
    <a [innerHTML]="'ship.shipUps.accessPoint.address.edit' | translate"
       (click)="editByLocationId.emit(location?.locationId)"></a>
</div>

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppState } from '../../app.state';
import { SharedService } from '../../core/services/shared.service';
import { StorageService } from '../../core/services/storage/storage.service';
import { Carriers } from '../../shared/enum/general-enum';
import { IContact } from '../../shared/models/contact.interface';
import { ICountry } from '../../shared/models/country.interface';
import { Customer } from '../../shared/models/customer/customer.model';
import { Location } from '../../shared/models/location/location.model';
import { NotificationType } from '../../shared/models/notification-type';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { FormService } from '../../shared/services/form/form.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { User } from '../../shared/services/user/models/user.model';
import { UserService } from '../../shared/services/user/user.service';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { LocationEditDialogComponent } from '../location-edit-dialog/location-edit-dialog.component';
import { Contact } from '../models/contact.model';
import { Package } from '../models/package.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipmentService } from '../services/shipment.service';
import { ShipFromEditComponent } from './ship-from-edit/ship-from-edit.component';
import { ShipFromStaticComponent } from './ship-from-static/ship-from-static.component';

@Component({
    selector: 'upsc-ship-from',
    templateUrl: './ship-from.component.html',
    styleUrls: ['./ship-from.component.scss'],
})
export class ShipFromComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, IShipComponent {
    @Input() user: User;
    @Input() customer: Customer;
    @Input() carrier: Carriers;
    @Input() shipFromCountryCode: string;
    @Input() shipFromCurrencyCode = 'USD';
    @Input() maxCoverageCurrencyCode = 'USD';
    @Input() isRestoreShipment = false;
    @Input() title = 'ShipFromAddress';
    @Input() returnShipment = false;
    @Input() shouldDisplayEUFields = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() countryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();
    @Output() countryCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() shipFromAddressChanged: EventEmitter<Location> = new EventEmitter<Location>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('shipFromStatic') shipFromStatic: ShipFromStaticComponent;
    @ViewChild('shipFromEdit', { static: true }) shipFromEdit: ShipFromEditComponent;
    public isEUUser: boolean;
    public currentUserIsAdmin: boolean;
    public formGroup: UntypedFormGroup;
    public countries: ICountry[];

    public locations: Location[];
    public isLocationsLoading = false;
    public shipFromAddress: Location;
    public isShowAddressBook = false;
    public isLocationEditable = false;
    public currentUser: any;
    public isFRITUser: boolean;
    private defaultLocation: Location;
    private cachedShipFromAddress: Contact;
    private getShipmentLocationsSubscription: Subscription;
    private locationsSubject = new BehaviorSubject<Location[]>(null);

    constructor(private formBuild: UntypedFormBuilder,
                private router: Router,
                private notificationService: NotificationService,
                private dialogService: DialogService,
                private formService: FormService,
                private storageService: StorageService,
                private utilityService: UtilityService,
                private errorHandlerService: ErrorHandlerService,
                private dialog: MatDialog,
                private translateService: TranslateService,
                private shipmentService: ShipmentService,
                private userService: UserService,
                private sharedService: SharedService,
                private readonly appState: AppState,
    ) {
        this.userService.getCurrentUser().subscribe(
            (currentUser) => {
                this.currentUser = currentUser;
                this.currentUserIsAdmin = currentUser?.isAdmin;
            },
        );

        this.formGroup = this.formBuild.group({
            location: [''],
        });

        this.monitorValueChanges();
        this.loadInitialValues();
        this.CheckIfEUUser();
    }

    public ngOnInit() {
        this.isFRITUser =  this.userService.isFRITUser(this.user);
        const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
        if (cachedShipment) {
            this.shipFromAddress = _.cloneDeep(cachedShipment.ShipFrom);
            this.shipFromAddressChanged.emit(this.shipFromAddress);
            this.countryCodeChanged.emit(this.shipFromAddress.Country);
            this.zipCodeChanged.emit(this.shipFromAddress.Zip);
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onCarrierChanged(changes['carrier']);
        this.onUserChanged(changes['user']);
    }

    public ngOnDestroy() {
        this.clearShipmentLocationsSubscription();
    }

    public ngAfterViewInit() {
        this.setLocationEditable();
    }

    public onShipFromCountryChanged(country: ICountry) {
        this.countryChanged.emit(country);
    }

    public onShipFromCountryCodeChanged(countryCode: string) {
        this.countryCodeChanged.emit(countryCode);
    }

    public onShipFromZipCodeChanged(zipCode: string) {
        this.zipCodeChanged.emit(zipCode);
    }

    public setFormValidity(isValid: boolean) {
        this.isValid.emit(isValid);
    }

    public getFormErrors() {
        if (!this.shipFromEdit) {
            return null;
        }

        return this.shipFromEdit.getFormErrors();
    }

    public isFormValid() {
        if (!this.shipFromEdit) {
            return true;
        }

        return this.shipFromEdit.isFormValid();
    }

    public openEditLocationDialog(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.isEUUser && environment.feature.disableEditShipFromLocationEU) {
            const RestrictMessage = this.userService.GetRestrictShipFromAddressChangeMessage(this.user.CountryCode);
            this.dialogService.alert('', RestrictMessage);

            // this.dialogService.alert(
            //     '',
            //     this.translateService.instant('noShipFromEditAlert'),
            // );

            return;
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '800px',
            data: {
                carrier: this.carrier,
                location: this.shipFromAddress,
                isFRIT: this.isFRITUser,
            },
            maxWidth: '100%',
            panelClass: ['mobile-fullscreen-dialog'],
        };

        const dialogRef: MatDialogRef<LocationEditDialogComponent> = this.dialog.open(LocationEditDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (updatedLocation: Location) => {
                if (!updatedLocation) {
                    return;
                }

                this.formGroup.controls.location.setValue('', { emitEvent: false });
                this.shipFromAddress = updatedLocation;
                this.shipFromAddressChanged.emit(this.shipFromAddress);
                this.countryCodeChanged.emit(this.shipFromAddress.Country);
                this.zipCodeChanged.emit(this.shipFromAddress.Zip);

                if (this.locations) {
                    // update item in the location list.
                    const targetLocation = this.locations.find(location => location.ContactId === updatedLocation.ContactId);

                    if (targetLocation) {
                        Object.assign(targetLocation, updatedLocation);
                        this.formGroup.controls.location.setValue(updatedLocation.ContactId);
                    }
                }
            },
        );
    }

    public resetForm() {
        this.resetComponents([
            this.shipFromStatic,
            this.shipFromEdit,
        ]);

        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            location: this.user.DefaultLocation,
        });

        this.loadShipmentLocations();
    }

    public forceValidation() {
        this.markComponentFormAsDirty([
            this.shipFromStatic,
            this.shipFromEdit,
        ]);

        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public onFormValueChanged(value) {
        this.formValueChanged.emit(value);
    }

    public fillCostEstimateValues(cachedCostEstimate: any) {
        this.formGroup.patchValue({
            location: cachedCostEstimate.ShipFromAddress,
        });

        this.formGroup.controls.location.markAsDirty();
        this.formGroup.controls.location.markAsTouched();
    }

    //#endregion

    public restoreShipment(shipment: Package) {
        this.shipFromAddress = shipment.ShipFrom;
        this.countryCodeChanged.emit(shipment.ShipFrom.Country);

        const locationId = shipment.ShipFrom.ContactId;
        if (!locationId || locationId === 'NOID') {
            // TODO: [MV3-2564]
            // The location ID should be returned with the GET /shipment/{id} instead of having to try to grab it from the location list.
            this.findLocationId(shipment.ShipFrom)
                .then(
                    (id) => {
                        if (!id || id === 'NOID') {
                            if (shipment.ShipFrom) {
                                this.restoreCustomAddress(shipment);
                            }

                            return;
                        }

                        this.restoreLocationId(id);
                    });

            return;
        }

        this.restoreLocationId(locationId);
    }

    public onLocationFocused(event, selector: MatSelect) {
        event.preventDefault();

        if (this.locations) {
            return;
        }

        this.isLocationsLoading = true;
        this.loadShipmentLocations();
        selector.open();
    }

    public onContactSelected(contact: IContact) {
        this.formGroup.controls.location.setValue('', { emitEvent: false });
        this.isLocationEditable = false;
        this.shipFromAddress = <Location>contact;
        this.countryCodeChanged.emit(this.shipFromAddress.Country);
        this.zipCodeChanged.emit(this.shipFromAddress.Zip);
        this.shipFromAddressChanged.emit(this.shipFromAddress);

        if (this.locations) {
            const targetLocation = this.locations.find(location => location.ContactId === contact.ContactId);

            if (targetLocation) {
                this.formGroup.controls.location.setValue(targetLocation.ContactId, { emitEvent: false });
            }
        }
    }

    public editShipFromLocation(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.dialogService.confirm(
            'Moving away from ship page',
            'Editing a location will take you to another page. All changes made to the shipment will be lost.' +
            '<p>Are you sure you want to continue?</p>',
        )
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    this.router.navigate(['/manage-locations']);
                },
            );
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.loadShipmentLocations();
        this.setVisibility(this.user);
    }

    private onUserChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.loadShipmentLocations();
        this.setVisibility(change.currentValue);
    }

    private loadShipmentLocations() {
        if (!this.carrier || !this.user) {
            return;
        }

        this.isLocationsLoading = true;

        this.clearShipmentLocationsSubscription();
        this.getShipmentLocationsSubscription = this.shipmentService.getShipmentLocations(this.returnShipment ? false : true, this.carrier)
                                                    .subscribe(
                                                        locations => this.handleGetShipmentLocationsSuccess(locations),
                                                        err => this.handleGetShipmentLocationsFailure(err),
                                                    );
    }

    private loadInitialValues() {
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges
            .subscribe(
                (value) => {
                    this.formValueChanged.emit(value);
                },
            );

        this.formGroup.controls.location.valueChanges
            .subscribe(
                (value) => {
                    // Create a new contact
                    if (value === '0') {
                        this.isLocationEditable = !this.isLocationEditable;
                        this.shipFromAddress = new Location();
                        this.shipFromAddress.Country = this.defaultLocation ? this.defaultLocation.Country : '';
                        this.shipFromAddressChanged.emit(this.shipFromAddress);
                        this.countryCodeChanged.emit(this.shipFromAddress.Country);
                        this.zipCodeChanged.emit(this.shipFromAddress.Zip);

                        return;
                    }

                    this.setLocationEditable();

                    // Address is selected from an address book.
                    if (!value) {
                        return;
                    }

                    // Address is selected from a location dropdown.
                    if (this.locations) {
                        this.shipFromAddress = _.cloneDeep(this.locations.find(location => location.ContactId === value));
                    }

                    if (!this.shipFromAddress) {
                        return;
                    }

                    const hasProvince = !!this.shipFromAddress.ProvinceRegion && !!this.shipFromAddress.ProvinceRegion.trim();
                    const hasState = !!this.shipFromAddress.State && !!this.shipFromAddress.State.trim();

                    if (hasProvince && !hasState) {
                        this.shipFromAddress.State = this.shipFromAddress.ProvinceRegion.trim();
                        this.shipFromAddress.ProvinceRegion = '';
                    }

                    if (!this.shipFromAddress.Email) {
                        this.shipFromAddress.Email = this.user.Email;
                    }

                    this.shipmentService.Quote.ShipFrom = <Contact>this.shipFromAddress;
                    this.shipFromAddressChanged.emit(this.shipFromAddress);
                    this.countryCodeChanged.emit(this.shipFromAddress.Country);
                    this.zipCodeChanged.emit(this.shipFromAddress.Zip);
                },
            );
    }

    private setLocationEditable() {
        if (!this.user || !this.user.IsGuest) {
            this.isLocationEditable = false;
            return;
        }

        this.isLocationEditable = this.user.IsGuestShipFrom;
    }

    //#region GetShipmentLocations handlers
    private handleGetShipmentLocationsSuccess(locations: Location[]) {
        this.locations = locations;
        this.locationsSubject.next(locations);

        if (this.formGroup && this.user && !this.isRestoreShipment) {
            if (!this.formGroup.controls.location.dirty
                && !this.formGroup.controls.location.touched) {
                this.formGroup.controls.location.setValue(this.user.DefaultLocation);
            } else {
                const selectedLocation = this.formGroup.controls.location.value;
                this.shipFromAddress = _.cloneDeep(this.locations.find(location => location.ContactId === selectedLocation));
                this.shipFromAddressChanged.emit(this.shipFromAddress);
                this.countryCodeChanged.emit(this.shipFromAddress.Country);
                this.zipCodeChanged.emit(this.shipFromAddress.Zip);
            }

            this.defaultLocation = locations.find(item => item.ContactId === this.user.DefaultLocation);
        }

        this.utilityService.delay(
            () => {
                if (this.formGroup && locations) {
                    this.shipFromAddress =
                        _.cloneDeep(this.locations.find(location => location.ContactId === this.formGroup.controls.location.value));

                    this.shipFromAddressChanged.emit(this.shipFromAddress);
                }
            },
            1000);

        this.isLocationsLoading = false;
    }

    private handleGetShipmentLocationsFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting ship-from locations',
            NotificationType.ERROR);
        this.isLocationsLoading = false;
    }

    private setVisibility(user: User): void {
        if (!user) {
            return;
        }

        this.setLocationEditable();
        this.isShowAddressBook = !user.IsGuest;

        if (!user.IsGuestShipFrom) {
            this.setShipFromAddress();

            if (!this.shipFromAddress) {
                this.getGuestContactAddress(user.DefaultLocation);
                return;
            }

            this.saveShipFromData();
        }
    }

    private setShipFromAddress() {
        if (!this.user || !this.locations) {
            return;
        }

        this.shipFromAddress = this.locations.find(location => location.ContactId === this.user.DefaultLocation);

        if (this.formGroup) {
            this.formGroup.controls.location.setValue(this.user.DefaultLocation, { emitEvent: false });
        }
    }

    private resetComponents(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return true;
                }

                component.resetForm();
            },
        );
    }

    private markComponentFormAsDirty(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return;
                }

                component.forceValidation();
            },
        );
    }

    private findLocationId(contact: Partial<Contact>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.locationsSubject.subscribe(
                (locations) => {
                    if (!locations || !locations.length) {
                        return;
                    }

                    const targetLocation = this.locations.find(
                        (location) => {
                            return this.utilityService.isSimilarObjectsByProperties(
                                location,
                                contact, [
                                    'FirstName',
                                    'LastName',
                                    'StreetAddress',
                                    'ApartmentSuite',
                                    'CompanyName',
                                    'City',
                                    'State',
                                    'ProvinceRegion',
                                    'Country',
                                    'Zip',
                                    // [MV3-2629] Excludes email from the comparison
                                    // since ship-from email address will be set from the system
                                    // if shipment's NotifySender is false and user's APIDevStatus is 0.
                                    // 'Email',
                                    'TelephoneNo',
                                ],
                            );
                        },
                    );

                    if (targetLocation) {
                        resolve(targetLocation.ContactId);
                        return;
                    }

                    resolve('');
                },
            );
        });
    }

    private restoreLocationId(locationId: string) {
        this.formGroup.patchValue({
            location: locationId,
        }, { emitEvent: false });

        this.formGroup.controls.location.markAsDirty();
        this.formGroup.controls.location.markAsTouched();

        if (!locationId || locationId === '0') {
            return;
        }

        this.saveShipFromData();
    }

    private restoreCustomAddress(shipment: Package) {
        this.formGroup.patchValue(
            {
                location: '0',
            },
            { emitEvent: false });

        this.formGroup.controls.location.markAsDirty();
        this.formGroup.controls.location.markAsTouched();

        this.isLocationEditable = true;
        this.formValueChanged.emit(this.formGroup.value);
        this.shipFromEdit.restoreShipment(shipment);
    }

    private CheckIfEUUser() {
        const user = this.appState.user$();
        this.isEUUser = this.userService.isEUUser(user?.CountryCode);
    }

    private saveShipFromData() {
        const data = {
            contactId: this.shipFromAddress.ContactId,
            firstName: this.shipFromAddress.FirstName,
            lastName: this.shipFromAddress.LastName,
            company: this.shipFromAddress.CompanyName,
            zipCode: this.shipFromAddress.Zip,
            city: this.shipFromAddress.City,
            state: this.shipFromAddress.State,
            country: this.shipFromAddress.Country,
            address1: this.shipFromAddress.StreetAddress,
            address2: this.shipFromAddress.ApartmentSuite,
            email: this.shipFromAddress.Email || this.user.Email,
            phone: this.shipFromAddress.TelephoneNo,
            vat: this.shipFromAddress.VAT,
            taxId: this.shipFromAddress.TaxID,
            eori: this.shipFromAddress.EORI,
        };

        this.shipmentService.saveShipFrom(data);
        this.countryCodeChanged.emit(this.shipFromAddress.Country);
        this.zipCodeChanged.emit(this.shipFromAddress.Zip);
    }

    //#endregion

    private getGuestContactAddress(guestContactId: string) {
        this.shipmentService.getContactAddress(guestContactId)
            .subscribe(
                address => this.handleGetContactAddressSuccess(address),
                err => this.handleGetContactAddressFailure(err),
            );
    }

    //#region GetContactAddress handlers
    private handleGetContactAddressSuccess(location: Location) {
        // [MV3-2501] Prevent an empty ship-from address from being set.
        if (location.ContactId === 'NOID' || this.isRestoreShipment) {
            return;
        }

        this.shipFromAddress = _.cloneDeep(location);

        const hasProvince = !!this.shipFromAddress.ProvinceRegion && !!this.shipFromAddress.ProvinceRegion.trim();
        const hasState = !!this.shipFromAddress.State && !!this.shipFromAddress.State.trim();

        if (hasProvince && !hasState) {
            this.shipFromAddress.State = this.shipFromAddress.ProvinceRegion.trim();
            this.shipFromAddress.ProvinceRegion = '';
        }

        if (!this.shipFromAddress.Email) {
            this.shipFromAddress.Email = this.user.Email;
        }

        this.saveShipFromData();
    }

    private handleGetContactAddressFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting contact address',
            NotificationType.ERROR);
    }

    private clearShipmentLocationsSubscription() {
        if (!this.getShipmentLocationsSubscription) {
            return;
        }

        this.getShipmentLocationsSubscription.unsubscribe();
        this.getShipmentLocationsSubscription = null;
    }
}

<div class="dialog-header">
    <h2 mat-dialog-title [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.title' | translate"></h2>
    <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="remark" [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.remark' | translate"></div>

<div class="dialog-content">
    <h3 [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.approvedCoverageLimits.title' | translate"></h3>
    <p [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.approvedCoverageLimits.p1' | translate"></p>

    <h3 [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.section1.title' | translate"></h3>
    <p [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.section1.p1' | translate"></p>

    <h3 [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.section2.title' | translate"></h3>
    <p [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.section2.p1' | translate"></p>

    <h3 [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.section3.title' | translate"></h3>
    <p [innerHTML]="'ship.shipUps.accessPoint.learnMoreDialog.section3.p1' | translate:{day: 7}"></p>
</div>

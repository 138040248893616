import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { IContact } from '../../../shared/models/contact.interface';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { PageEvent } from '@angular/material/paginator';

import * as _ from 'lodash';

@Component({
  selector: 'upsc-address-book-search-table',
  templateUrl: './address-book-search-table.component.html',
  styleUrls: ['./address-book-search-table.component.scss'],
})
export class AddressBookSearchTableComponent extends DataTableComponent<IContact> implements OnChanges {
  @Input() isSearching = false;
  @Output() contactSelected: EventEmitter<IContact> = new EventEmitter<IContact>();
  @Output() pageChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  public totalItems = 0;

  public ngOnChanges(changes: SimpleChanges) {
    this.onItemsChanged(changes['items']);
  }

  private onItemsChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.dataSource = null;
    this.items = change.currentValue.map(
      (contact: IContact) => {
        contact.ContactName = `${contact.FirstName} ${contact.LastName}`;

        return contact;
      },
    );

    // this.items = _.sortBy(this.items, ['ContactName', 'NickName']);

    this.totalItems = this.items && this.items.length ? +this.items[0].TotalContacts : 0;
  }

  public selectContact(event, contact: IContact) {
    event.preventDefault();

    this.contactSelected.emit(contact);
  }

  public onPageChanged(pageEvent: PageEvent) {
    this.pageChanged.emit(pageEvent);
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Carriers } from '../../shared/enum/general-enum';
import { Contact } from '../models/contact.model';
import { IPickupLocationRequest } from '../pickup-locations/models/pickup-location-request.interface';
import { PickupLocationsDialogComponent } from './pickup-locations-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class PickupLocationsDialogService<TRequest = IPickupLocationRequest, TResponse = any> {
    private dialogRef: MatDialogRef<PickupLocationsDialogComponent>;

    public constructor(private dialog: MatDialog) {
    }

    public open(carrier: Carriers,
                data: TRequest,
                isReadonly: boolean,
                shipFrom: Partial<Contact>,
                shipTo: Partial<Contact>,
                locationId: string,
                insuredValue?: number,
                useAccessPointCode?: boolean,
    ): Observable<TResponse> {
        const config: MatDialogConfig<{
            carrier: Carriers,
            request: TRequest,
            isReadonly: boolean,
            shipFrom: Partial<Contact>,
            shipTo: Partial<Contact>,
            locationId: string,
            insuredValue?: number,
            useAccessPointCode?: boolean,
        }> = {
            data: {
                carrier,
                isReadonly,
                shipFrom,
                shipTo,
                request: data,
                locationId,
                insuredValue,
                useAccessPointCode,
            },
            disableClose: true,
            maxWidth: '100%',
            width: '34.875rem',
            panelClass: ['mobile-fullscreen-dialog'],
        };

        this.dialogRef = this.dialog.open(PickupLocationsDialogComponent, config);
        return this.dialogRef.afterClosed();
    }

    public close(result?: unknown): void {
        if (!this.dialogRef) {
            return;
        }

        this.dialogRef.close(result);
    }
}

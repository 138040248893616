import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConcatAddressPipeModule } from '../../shared/pipes/concat-address/concat-address.module';
import { PickupLocationInfoComponent } from './pickup-location-info.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ConcatAddressPipeModule,
    ],
    declarations: [PickupLocationInfoComponent],
    providers: [],
    exports: [PickupLocationInfoComponent],
})
export class PickupLocationInfoModule {}

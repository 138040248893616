import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { ShipmentService } from '../services/shipment.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '../../shared/models/location/location.model';
import { ICountry } from '../../shared/models/country.interface';
import { NotificationType } from '../../shared/models/notification-type';
import { Carriers } from '../../shared/enum/general-enum';
import { FormService } from '../../shared/services/form/form.service';
import { ZipCodeService } from '../../shared/services/zip-code/zip-code.service';
import { PostalCode } from '../../shared/models/postal-code.model';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';

@Component({
  selector: 'upsc-location-edit-dialog',
  templateUrl: './location-edit-dialog.component.html',
  styleUrls: ['./location-edit-dialog.component.scss'],
})
export class LocationEditDialogComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;

  public countries: ICountry[];
  public isZipCodeValidating = false;
  public isZipCodeValid = false;
  public isFRIT: boolean;
  private carrier: Carriers;
  private location: Location;

  private validateZipCodeSubscription: Subscription;

  constructor(private validationService: ValidationService,
              private formService: FormService,
              private notificationService: NotificationService,
              private formBuild: UntypedFormBuilder,
              private zipCodeService: ZipCodeService,
              private utilityService: UtilityService,
              private errorHandlerService: ErrorHandlerService,
              private shipmentService: ShipmentService,
              public dialogRef: MatDialogRef<LocationEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.carrier = data.carrier;
    this.location = data.location;
    this.isFRIT = data.isFRIT;

    this.loadInitialValues();
  }

  public ngOnInit() {
    this.formGroup = this.formBuild.group({
      contactId: [''],
      locationName: ['', Validators.compose([Validators.required, Validators.maxLength(60)])],
      firstName: ['', Validators.compose([Validators.required, Validators.maxLength(15)])],
      lastName: ['', Validators.compose([Validators.required, Validators.maxLength(19)])],
      company: ['', Validators.compose([Validators.maxLength(35)])],
      zipCode: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
      city: ['', Validators.compose([Validators.required, Validators.maxLength(29)])],
      state: ['', Validators.compose([Validators.maxLength(35)])],
      country: ['', Validators.compose([Validators.required, Validators.maxLength(2)])],
      address1: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
      address2: ['', Validators.compose([Validators.maxLength(35)])],
      address3: ['', Validators.compose([Validators.maxLength(35)])],
      email: ['', Validators.compose([this.validationService.emailFormatValidator(), Validators.maxLength(100)])],
      phone: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
      fax: ['', Validators.compose([Validators.maxLength(35)])],
      isDefault: [false],
    });

    if (this.location) {
      this.restoreData();
    }

    this.monitorValueChanges();
  }

  public ngOnDestroy() {
    if (this.validateZipCodeSubscription) {
      this.validateZipCodeSubscription.unsubscribe();
      this.validateZipCodeSubscription = null;
    }
  }

  private loadInitialValues() {
    this.shipmentService.getShipFromCountries(this.carrier)
      .subscribe(
        (countries) => {
          this.countries = _.sortBy(countries, ['CountryName']);
        },
      );
  }

  private monitorValueChanges() {
    this.formGroup.controls.country.valueChanges
      .subscribe(
        (value) => {
          if (!value) {
            return;
          }

          this.validateZipCode(value, this.formGroup.controls.zipCode.value);
        },
      );

    this.formGroup.controls.zipCode.valueChanges
    .pipe(debounceTime(500),
    distinctUntilChanged())
      .subscribe(
        (value) => {
          if (!value) {
            return;
          }

          this.validateZipCode(this.formGroup.controls.country.value, value);
        },
      );
  }

  private validateZipCode(countryCode: string, zipCode: string) {
    if (!countryCode || !zipCode) {
      return;
    }

    const addressLookupCountries = ['US', 'CA', 'PR', 'VI', 'GU'];
    if (!addressLookupCountries.includes(countryCode)) {
      this.isZipCodeValid = true;
      this.formGroup.controls.zipCode.setErrors({ invalid: null });
      delete this.formGroup.controls.zipCode.errors.invalid;
      if (!Object.keys(this.formGroup.controls.zipCode.errors).length) {
        this.formGroup.controls.zipCode.setErrors(null);
      }

      return;
    }

    if (zipCode.length < 5) {
      this.formGroup.controls.zipCode.setErrors({ invalid: true });
      return;
    }

    if (this.validateZipCodeSubscription) {
      this.validateZipCodeSubscription.unsubscribe();
      this.validateZipCodeSubscription = null;
    }

    this.isZipCodeValidating = true;
    this.validateZipCodeSubscription = this.zipCodeService.getAddressByZipCode(zipCode)
      .subscribe(
        result => this.handleZipCodeValidationSuccess(result),
        err => this.handleZipCodeValidationFailure(err),
      );
  }

  private handleZipCodeValidationSuccess(zipCode: PostalCode) {
    this.formGroup.patchValue({
      city: zipCode.City,
      state: zipCode.State,
    });

    this.utilityService.delay(() => {
      this.isZipCodeValidating = false;
    });

    this.isZipCodeValid = true;
    this.formGroup.controls.zipCode.setErrors({ invalid: null });
    delete this.formGroup.controls.zipCode.errors.invalid;

    if (!Object.keys(this.formGroup.controls.zipCode.errors).length) {
      this.formGroup.controls.zipCode.setErrors(null);
    }
  }

  private handleZipCodeValidationFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed validating a zip code',
      NotificationType.ERROR);
    this.utilityService.delay(() => {
      this.isZipCodeValidating = false;
    });

    this.isZipCodeValid = false;
    this.formGroup.controls.zipCode.setErrors({ invalid: true });
  }

  private restoreData() {
    this.formGroup.patchValue({
      contactId: this.location.ContactId,
      locationName: this.location.NickName,
      firstName: this.location.FirstName,
      lastName: this.location.LastName,
      company: this.location.CompanyName,
      zipCode: this.location.Zip,
      city: this.location.City,
      state: this.location.State || this.location.ProvinceRegion,
      country: this.location.Country,
      address1: this.location.StreetAddress,
      address2: this.location.ApartmentSuite,
      address3: this.location.AdditionalAddressInformation,
      email: this.location.Email,
      phone: this.location.TelephoneNo,
      fax: this.location.FaxNo,
      isDefault: this.location.IsUserDefault,
    });

    this.formService.markAllAsTouchedAndDirty(this.formGroup);
  }

  public onFormSubmit(event, form) {
    event.preventDefault();

    const data = {
      ContactId: form.contactId,
      NickName: form.locationName,
      FirstName: form.firstName,
      LastName: form.lastName,
      CompanyName: form.company,
      Zip: form.zipCode,
      City: form.city,
      State: form.state,
      ProvinceRegion: form.state,
      Country: form.country,
      StreetAddress: form.address1,
      ApartmentSuite: form.address2,
      AdditionalAddressInformation : form.address3,
      Email: form.email,
      TelephoneNo: form.phone,
      FaxNo: form.fax,
      IsUserDefault: form.isDefault,
    };

    this.shipmentService.saveLocation(data)
      .subscribe(
        res => this.handleSaveLocationSuccess(res),
        err => this.handleSaveLocationFailure(err),
      );
  }

  private handleSaveLocationSuccess(res) {
    const cloneLocation = Object.assign({}, this.location);
    cloneLocation.ContactId = this.formGroup.controls.contactId.value;
    cloneLocation.NickName = this.formGroup.controls.locationName.value;
    cloneLocation.FirstName = this.formGroup.controls.firstName.value;
    cloneLocation.LastName = this.formGroup.controls.lastName.value;
    cloneLocation.CompanyName = this.formGroup.controls.company.value;
    cloneLocation.Zip = this.formGroup.controls.zipCode.value;
    cloneLocation.City = this.formGroup.controls.city.value;
    cloneLocation.State = this.formGroup.controls.state.value;
    cloneLocation.ProvinceRegion = this.formGroup.controls.state.value;
    cloneLocation.Country = this.formGroup.controls.country.value;
    cloneLocation.StreetAddress = this.formGroup.controls.address1.value;
    cloneLocation.ApartmentSuite = this.formGroup.controls.address2.value;
    cloneLocation.AdditionalAddressInformation =  this.formGroup.controls.address3.value;
    cloneLocation.Email = this.formGroup.controls.email.value;
    cloneLocation.TelephoneNo = this.formGroup.controls.phone.value;
    cloneLocation.FaxNo = this.formGroup.controls.fax.value;
    cloneLocation.IsUserDefault = this.formGroup.controls.isDefault.value;

    this.dialogRef.close(_.cloneDeep(cloneLocation));
  }

  private handleSaveLocationFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed saving location',
      NotificationType.ERROR);
  }
}

export const PickupLocationSearchConfig = {
    searchRange: {
        min: 10,
        max: 50,
        default: 10,
    },
    maxSearchResults: {
        options: [10, 20, 50],
        default: 10,
    },
};

<div class="ship-import-information ups-form">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">Import Information</h5>
        </div>

        <form [formGroup]="formGroup" class="ups-form">
            <div class="form-body">
                <mat-form-field>
                    <mat-label>{{ 'customsTariffCode'|translate }}</mat-label>
                    <input matInput type="text"
                           pattern="[0-9]+"
                           maxlength="18"
                           class="customs-tariff"
                           formControlName="customsTariffCode">
                    <img src="../../../assets/icons/help.svg"
                         alt="Help"
                         class="help-icon"
                         [matTooltip]="'customsTariffHelpText' |translate"
                         matTooltipPosition="above">
                    <mat-error *ngIf="formGroup.controls.customsTariffCode?.errors">
                        <span *ngIf="formGroup.controls.customsTariffCode?.errors?.pattern">{{ 'OnlyInteger'|translate: { field: 'customsTariffCode'|translate } }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
</div>

import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'upsc-pickup-location-map',
    templateUrl: './pickup-location-map.component.html',
    styleUrls: ['./pickup-location-map.component.scss'],
})
export class PickupLocationMapComponent {
    @HostBinding('class') public hostClass = 'pickup-location-map';

    constructor() {
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Location } from '../../shared/models/location/location.model';
import { ShipmentStateService } from '../services/shipment-state.service';
import { ShipmentService } from '../services/shipment.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { User } from '../../shared/services/user/models/user.model';
import { ShipToEditComponent } from './ship-to-edit/ship-to-edit.component';
import { Carriers } from '../../shared/enum/general-enum';
import { MatSelect } from '@angular/material/select';
import { of, Subscription } from 'rxjs';
import { IContact } from '../../shared/models/contact.interface';
import { ShipConfigService } from '../services/ship-config.service';
import { Contact } from '../models/contact.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipToStaticComponent } from './ship-to-static/ship-to-static.component';
import * as _ from 'lodash';
import { FormService } from '../../shared/services/form/form.service';
import { Customer } from '../../shared/models/customer/customer.model';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { IAddressValidation } from '../models/address-validation.interface';
import { Package } from '../models/package.model';
import { ICountry } from '../../shared/models/country.interface';

@Component({
    selector: 'upsc-ship-to',
    templateUrl: './ship-to.component.html',
    styleUrls: ['./ship-to.component.scss'],
})
export class ShipToComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
    @Input() user: User;
    @Input() customer: Customer;
    @Input() carrier: Carriers;
    @Input() shipFromCountryCode: string;
    @Input() shipFromCurrencyCode = 'USD';
    @Input() maxCoverageCurrencyCode = 'USD';
    @Input() restrictedCountryCodes: any;
    @Input() returnShipment = false;
    @Input() shouldDisplayEUFields = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() countryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();
    @Output() countryCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() isResidentialAddressChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() adultSignatureRequested: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() residentialCheckChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() shipToAddressChanged: EventEmitter<Location> = new EventEmitter<Location>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() addressValidationChanged: EventEmitter<IAddressValidation> = new EventEmitter<IAddressValidation>();
    @Output() forceClearAdultSignatureRequiredChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('shipToStatic') shipToStatic: ShipToStaticComponent;
    @ViewChild('shipToEdit') shipToEdit: ShipToEditComponent;

    public formGroup: UntypedFormGroup;

    public locations: Location[];
    public allLocations: Location[];
    public isLocationsLoading = false;
    public shipToAddress: Location;
    public shipToCountryCode: string;
    public isShowAddressBook = false;
    public isLocationEditable = false;

    private getShipmentLocationsSubscription: Subscription;

    public constructor(private formBuilder: UntypedFormBuilder,
                       private notificationService: NotificationService,
                       private formService: FormService,
                       private errorHandlerService: ErrorHandlerService,
                       private shipConfigService: ShipConfigService,
                       private shipmentService: ShipmentService,
                       private shipState: ShipmentStateService,
    ) {
        this.loadInitialValues();
    }

    public ngOnInit() {
        this.formGroup = this.formBuilder.group({
            location: [''],
        });

        this.monitorValueChanges();
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onCarrierChanged(changes['carrier']);
        this.onUserChanged(changes['user']);
        this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
    }

    public ngOnDestroy() {
        this.clearShipmentLocationsSubscription();
    }

    public onCountryChanged(country: ICountry) {
        this.countryChanged.emit(country);
    }

    public onCountryCodeChanged(countryCode: string) {
        this.countryCodeChanged.emit(countryCode);
    }

    public onZipCodeChanged(zipCode: string) {
        this.zipCodeChanged.emit(zipCode);
    }

    public setFormValidity(isValid: boolean) {
        this.isValid.emit(isValid);
    }

    public getFormErrors() {
        if (!this.shipToEdit) {
            return null;
        }

        return this.shipToEdit.getFormErrors();
    }

    public isFormValid() {
        return this.shipToEdit.isFormValid();
    }

    public onLocationFocused(event, selector: MatSelect) {
        event.preventDefault();

        if (this.locations) {
            return;
        }

        this.isLocationsLoading = true;
        this.loadShipmentLocations();
        selector.open();
    }

    public onContactSelected(contact: IContact) {
        const data = {
            contactId: contact.ContactId,
            firstName: contact.FirstName,
            lastName: contact.LastName,
            company: contact.CompanyName,
            zipCode: contact.Zip,
            city: contact.City,
            state: contact.State,
            country: contact.Country,
            address1: contact.StreetAddress,
            address2: contact.ApartmentSuite,
            email: contact.Email,
            phone: contact.TelephoneNo,
        };

        this.shipmentService.saveShipTo(data);

        let targetLocation: Location;

        if (this.locations) {
            targetLocation = this.locations.find(location => location.ContactId === contact.ContactId);
        }

        if (targetLocation) {
            this.formGroup.patchValue({
                location: targetLocation.ContactId,
            });

            return;
        }

        this.formGroup.patchValue(
            { location: '' },
            { emitEvent: false },
        );

        this.shipToAddress = <Location>contact;
        this.countryCodeChanged.emit(this.shipToAddress.Country);
        this.zipCodeChanged.emit(this.shipToAddress.Zip);
        this.shipToAddressChanged.emit(this.shipToAddress);
    }

    //#endregion

    public onAdultSignatureRequested(isRequested: boolean) {
        this.adultSignatureRequested.emit(isRequested);
    }

    public onResidentialCheckChanged(isChecked: boolean) {
        this.residentialCheckChanged.emit(isChecked);
    }

    public onIsResidentialAddressChanged(isRequired: boolean) {
        this.isResidentialAddressChanged.emit(isRequired);
    }

    //#endregion

    public resetForm() {
        this.resetComponents([
            this.shipToStatic,
            this.shipToEdit,
        ]);

        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            location: '',
        });

        this.shipToAddress = null;
        this.loadShipmentLocations();
    }

    public forceValidation() {
        this.markComponentFormAsDirty([
            this.shipToStatic,
            this.shipToEdit,
        ]);

        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public onFormValueChanged(value) {
        this.formValueChanged.emit(value);
    }

    public fillCostEstimateValues(cachedCostEstimate: any) {
        this.shipToEdit.fillCostEstimateValues(cachedCostEstimate);
    }

    public restoreShipment(shipment: Package) {
        this.countryCodeChanged.emit(shipment.ShipTo.Country);

        // If a location ID is specified, select it from the location list.
        if (shipment.ShipTo.ContactId && shipment.ShipTo.ContactId !== 'NOID') {
            this.isLocationsLoading = true;

            this.clearShipmentLocationsSubscription();
            this.getShipmentLocationsSubscription = this.shipmentService.getShipmentLocations(this.returnShipment ? true : false, this.carrier)
                .subscribe(
                    (locations) => {
                        this.handleGetShipmentLocationsSuccess(locations);

                        const shipToLocation = locations.filter(location => location.ContactId === shipment.ShipTo.ContactId);

                        // If the specified location doesn't exist, simply fill the form with address information.
                        if (!shipToLocation && this.shipToEdit) {
                            this.shipToEdit.restoreShipment(shipment);
                            return;
                        }

                        this.setLocation(shipment.ShipTo.ContactId);
                    },
                    err => this.handleGetShipmentLocationsFailure(err),
                );

            return;
        }

        if (this.shipToEdit) {
            this.shipToEdit.restoreShipment(shipment);
        }
    }

    public onAddressValidationChanged(addressValidation: IAddressValidation) {
        this.addressValidationChanged.emit(addressValidation);
    }

    public onForceClearAdultSignatureRequiredChanged(isForce: boolean) {
        this.forceClearAdultSignatureRequiredChanged.emit(isForce);
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.loadShipmentLocations();
    }

    private onUserChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        const shipToCountry = change.currentValue.DefaultShipToCountry || change.currentValue.DefaultShiptToCountry;
        this.shipToCountryCode = shipToCountry.toUpperCase();
        this.shipState.shipToCountryCode.set(shipToCountry?.toUpperCase());

        this.setVisibility(change.currentValue);
    }

    private onShipFromCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.locations = this.allLocations;
        if (!this.restrictedCountryCodes || !this.restrictedCountryCodes.availableCountryCodes) {
            return;
        }

        if (!this.shipConfigService.isDomesticOnlylUser(this.user)) {
            return;
        }

        if (!this.allLocations) {
            return;
        }

        if (this.restrictedCountryCodes.availableCountryCodes.length) {
            this.locations = this.allLocations.filter(
                location => this.restrictedCountryCodes.availableCountryCodes.includes(location.Country),
            );
        }
    }

    private loadShipmentLocations() {
        if (!this.carrier || !this.user) {
            return;
        }

        this.isLocationsLoading = true;

        this.clearShipmentLocationsSubscription();
        this.getShipmentLocationsSubscription = this.shipmentService.getShipmentLocations(this.returnShipment ? true : false, this.carrier)
            .subscribe(
                locations => this.handleGetShipmentLocationsSuccess(locations),
                err => this.handleGetShipmentLocationsFailure(err),
            );
    }

    private loadInitialValues() {
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges.subscribe(
            (value) => {
                this.formValueChanged.emit(value);
            });

        this.formGroup.controls.location.valueChanges
            .subscribe(
                (value) => {
                    if (!value) {
                        this.resetComponents([
                            this.shipToEdit,
                        ]);

                        return;
                    }

                    this.shipToAddress = _.cloneDeep(this.locations.find(location => location.ContactId === value));

                    if (!this.shipToAddress) {
                        return;
                    }

                    const hasProvince = !!this.shipToAddress.ProvinceRegion && !!this.shipToAddress.ProvinceRegion.trim();
                    const hasState = !!this.shipToAddress.State && !!this.shipToAddress.State.trim();

                    if (hasProvince && !hasState) {
                        this.shipToAddress.State = this.shipToAddress.ProvinceRegion.trim();
                        this.shipToAddress.ProvinceRegion = '';
                    }

                    // [MV3-6696]: Casting `Location` to `Contact` makes some properties lost.
                    // If the object (shipToAddress) is later used elsewhere, those properties will not be present there.
                    // Using a spread operator to copy the object fixes the issue.
                    this.shipmentService.Quote.ShipTo = { ...this.shipToAddress };
                    this.shipToAddressChanged.emit(this.shipToAddress);
                    this.countryCodeChanged.emit(this.shipToAddress.Country);
                    this.zipCodeChanged.emit(this.shipToAddress.Zip);
                },
            );
    }

    //#region GetShipmentLocations handlers
    private handleGetShipmentLocationsSuccess(locations) {
        this.locations = locations;
        this.allLocations = this.locations;

        if (this.restrictedCountryCodes && this.restrictedCountryCodes.availableCountryCodes) {
            if (this.restrictedCountryCodes.availableCountryCodes.length) {
                this.locations = this.allLocations.filter(
                    location => this.restrictedCountryCodes.availableCountryCodes.includes(location.Country),
                );
            }
        }

        this.setVisibility(this.user);
        this.isLocationsLoading = false;
    }

    private handleGetShipmentLocationsFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting ship-to address books',
            NotificationType.ERROR);
        this.isLocationsLoading = false;

        return of(new Location());
    }

    private setVisibility(user: User): void {
        this.isLocationEditable = !user.IsGuest;
        this.isShowAddressBook = !user.IsGuest || (user.IsGuest && user.IsGuestAddressBook);

        if (user.IsGuest) {
            this.shipmentService.getContactAddress(user.GuestContactID)
                .subscribe(
                    address => this.handleGetContactAddressSuccess(address),
                    err => this.handleGetContactAddressFailure(err),
                );
        }
    }

    //#region GetContactAddress handlers
    private handleGetContactAddressSuccess(location: Location) {
        this.shipToAddress = _.clone(location);

        const hasProvince = !!this.shipToAddress.ProvinceRegion && !!this.shipToAddress.ProvinceRegion.trim();
        const hasState = !!this.shipToAddress.State && !!this.shipToAddress.State.trim();

        if (hasProvince && !hasState) {
            this.shipToAddress.State = this.shipToAddress.ProvinceRegion.trim();
            this.shipToAddress.ProvinceRegion = '';
        }

        this.shipToAddressChanged.emit(this.shipToAddress);
        this.countryCodeChanged.emit(this.shipToAddress.Country);
        this.zipCodeChanged.emit(this.shipToAddress.Zip);

        const data = {
            contactId: this.shipToAddress.ContactId,
            firstName: this.shipToAddress.FirstName,
            lastName: this.shipToAddress.LastName,
            company: this.shipToAddress.CompanyName,
            zipCode: this.shipToAddress.Zip,
            city: this.shipToAddress.City,
            state: this.shipToAddress.State,
            country: this.shipToAddress.Country,
            address1: this.shipToAddress.StreetAddress,
            address2: this.shipToAddress.ApartmentSuite,
            email: this.shipToAddress.Email,
            phone: this.shipToAddress.TelephoneNo,
        };

        this.shipmentService.saveShipTo(data);
    }

    private handleGetContactAddressFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting contact address',
            NotificationType.ERROR);
    }

    private clearShipmentLocationsSubscription() {
        if (!this.getShipmentLocationsSubscription) {
            return;
        }

        this.getShipmentLocationsSubscription.unsubscribe();
        this.getShipmentLocationsSubscription = null;
    }

    private resetComponents(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return true;
                }

                component.resetForm();
            },
        );
    }

    private markComponentFormAsDirty(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return;
                }

                component.forceValidation();
            },
        );
    }

    private setLocation(locationId: string) {
        this.formGroup.patchValue({
            location: locationId,
        });

        this.formGroup.controls.location.markAsDirty();
        this.formGroup.controls.location.markAsTouched();

        this.formValueChanged.emit(this.formGroup.value);
    }
}

<div class="location-edit-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{'EditShipFromLocation'|translate}}</h2>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #form>
    <div class="notifications">
        <div class="notification">
            {{ 'EditLocationMessage' | translate }}
        </div>
    </div>
    <form [formGroup]="formGroup" class="ups-form" (ngSubmit)="onFormSubmit($event, formGroup.value)">
        <div class="form-body">
            <div class="location-section-wrapper">
                <div class="location-section">
                    <mat-form-field>
                        <mat-label>{{'LocationName'|translate}}</mat-label>
                        <input matInput
                               required
                               maxlength="60"
                               formControlName="locationName">
                        <mat-error *ngIf="formGroup.controls.locationName?.errors?.required">
                            {{'LocationNameRequired'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'FirstName'|translate}}</mat-label>
                        <input matInput
                               required
                               maxlength="15"
                               formControlName="firstName">
                        <mat-error *ngIf="formGroup.controls.firstName?.errors?.required">
                            {{'FirstNameRequired'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'LastName'|translate}}</mat-label>
                        <input matInput
                               required
                               maxlength="19"
                               formControlName="lastName">
                        <mat-error *ngIf="formGroup.controls.lastName?.errors?.required">
                            {{'LastNameRequired'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'CompanyName'|translate}}</mat-label>
                        <input matInput
                               maxlength="35"
                               formControlName="company">
                        <mat-error *ngIf="formGroup.controls.company?.errors?.required">
                            {{'CompanyRequired'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'AddressLine1'|translate}}</mat-label>
                        <input matInput
                               required
                               maxlength="35"
                               formControlName="address1">
                        <mat-error *ngIf="formGroup.controls.address1?.errors?.required">
                            {{'Address1Required'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'AddressLine2'|translate}}</mat-label>
                        <input matInput
                               maxlength="35"
                               formControlName="address2">
                        <mat-error *ngIf="formGroup.controls.address2?.errors?.required">
                            {{'Address2Required'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="isFRIT">
                        <mat-label>{{'AddressLine3'|translate}}</mat-label>
                        <input matInput
                               maxlength="35"
                               formControlName="address3"></mat-form-field>
                    <div class="select-wrapper">
                        <mat-form-field>
                            <mat-label>Country</mat-label>
                            <mat-select formControlName="country" required>
                                <mat-option disabled selected>{{'selectCountry'|translate}}</mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                                    {{ country.CountryName }} ({{ country.MaxCoverage }})
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <upsc-element-blocker *ngIf="isCountriesLoading || isZipCodeValidating"></upsc-element-blocker>
                    </div>
                </div>
                <div class="location-section">
                    <mat-form-field>
                        <mat-label>{{'ZipCode'|translate}}</mat-label>
                        <input matInput
                               required
                               maxlength="10"
                               formControlName="zipCode">
                        <mat-error *ngIf="formGroup.controls.zipCode?.errors">
                            <span *ngIf="formGroup.controls.zipCode?.errors?.required">{{'ZipCodeRequired'|translate}}</span>
                            <span *ngIf="formGroup.controls.zipCode?.errors?.invalid">{{'invalidZipcode'|translate}}</span>
                        </mat-error>
                        <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'City'|translate}}</mat-label>
                        <input matInput
                               required
                               maxlength="29"
                               formControlName="city">
                        <mat-error *ngIf="formGroup.controls.city?.errors?.required">
                            {{'CityRequired'|translate}}
                        </mat-error>
                        <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'StateOrProvince'|translate}}</mat-label>
                        <input matInput
                               maxlength="35"
                               formControlName="state">
                        <mat-error *ngIf="formGroup.controls.state?.errors?.required">
                            {{'StateProvinceRequired'|translate}}
                        </mat-error>
                        <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'TelephoneNo'|translate}}</mat-label>
                        <input matInput
                               required
                               maxlength="15"
                               formControlName="phone">
                        <mat-error *ngIf="formGroup.controls.phone?.errors?.required">
                            {{'TelephoneNumberRequired'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'FaxNo'|translate}}</mat-label>
                        <input matInput
                               maxlength="15"
                               formControlName="fax">
                        <mat-error *ngIf="formGroup.controls.fax?.errors?.required">
                            {{'FaxNumberRequired'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'EmailAddress'|translate}}</mat-label>
                        <input matInput
                               maxlength="100"
                               formControlName="email">
                        <mat-error *ngIf="formGroup.controls.email?.errors?.emailFormat">
                            {{'EmailAddressInvalidFormat'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-checkbox formControlName="isDefault" class="checkbox-right">
                        {{'SetAsDefaultLocation'|translate}}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="form-footer">
            <div class="form-actions">
                <button type="button" mat-raised-button class="alternative-button" mat-dialog-close>{{'Cancel'|translate}}</button>
                <button type="submit" mat-raised-button [disabled]="!formGroup.valid">
                    {{'Save'|translate}}
                </button>
            </div>
        </div>
    </form>
</ng-template>

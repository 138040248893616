import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../shared/services/user/models/user.model';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { ShipmentService } from '../../services/shipment.service';

@Component({
  selector: 'upsc-request-quote-dialog',
  templateUrl: './request-quote-dialog.component.html',
  styleUrls: ['./request-quote-dialog.component.scss'],
})
export class RequestQuoteDialogComponent implements OnInit, OnDestroy {
  @HostBinding('class') public hostClass = 'request-quote-dialog';

  public user: User;
  public formGroup: UntypedFormGroup;
  public isFormSubmitting = false;
  public isFormSubmitted = false;
  public formSubmitErrorMessage: string;

  private requestQuoteSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              private utilityService: UtilityService,
              private validationService: ValidationService,
              private shipmentService: ShipmentService,
              public dialogRef: MatDialogRef<RequestQuoteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = this.data.user;
    this.formGroup = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,15}$')])],
      email: ['', Validators.compose([Validators.required, this.validationService.emailFormatValidator()])],
    });
  }

  public ngOnInit() {
    if (this.user) {
      this.formGroup.patchValue({
        firstName: this.user.FirstName,
        lastName: this.user.LastName,
        phone: this.user.TelNum,
        email: this.user.Email,
      });
    }
  }

  public ngOnDestroy() {
    this.utilityService.clearSubscriptions([
      this.requestQuoteSubscription,
    ]);
  }

  public onFormSubmitted(event, formValue) {
    this.formSubmitErrorMessage = null;
    this.isFormSubmitting = true;

    this.utilityService.clearSubscriptions([this.requestQuoteSubscription]);
    this.requestQuoteSubscription = this.shipmentService.requestQuote(formValue)
      .subscribe(
        res => this.handleRequestQuoteSuccess(res),
        err => this.handleRequestQuoteFailure(err),
      );
  }

  private handleRequestQuoteSuccess(res) {
    this.isFormSubmitting = false;
    this.isFormSubmitted = true;
  }

  private handleRequestQuoteFailure(err) {
    this.isFormSubmitting = false;
    const formSubmitError = err.json();
    if (formSubmitError) {
      this.formSubmitErrorMessage = formSubmitError.Message;
    }
  }
}

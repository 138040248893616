import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PickupLocationsModule } from '../pickup-locations/pickup-locations.module';
import { PickupLocationsDialogComponent } from './pickup-locations-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
        PickupLocationsModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NgOptimizedImage,
    ],
    declarations: [PickupLocationsDialogComponent],
    providers: [],
    exports: [PickupLocationsDialogComponent],
})
export class PickupLocationsDialogModule {
}

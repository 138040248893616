<div class="ship-options ups-form"
     *ngIf="shouldShowSection"
     [formGroup]="shipOptionsForm">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{'ShippingOptions'|translate}}</h5>
        </div>

        <mat-radio-group formControlName="pickupType">
            <mat-radio-button value="02" *ngIf="config?.showDropOffUPS && !returnShipment">{{'DropOffPackAt'|translate}}&nbsp;
                <a href="http://www.theupsstore.com/pages/locations.aspx" target="_blank">
                    {{'TheUPSStore'|translate}}<sup>&reg;</sup>
                </a>
            </mat-radio-button>
            <mat-radio-button value="02" *ngIf="config?.showDropOffFedEx && !returnShipment">{{'DropOffPackAtFEDEXLocation'|translate}}
                <a href="#" (click)="openLocationSearchDialog($event)">
                    {{'FindLocation'|translate}}
                </a>
            </mat-radio-button>
            <mat-radio-button value="01" *ngIf="config?.showUseAlreadyScheduledPickUp && !returnShipment">
                {{'UseAnalreadySchedulePickup'|translate}}
            </mat-radio-button>
            <mat-radio-button value="03" *ngIf="config?.showSchedulePickUp" (click)="toggleSchedulePickUp($event)" >
                <span class="option-text">
                    {{ (shouldShowSmartPickup ? 'SmartPickUp' : 'SchedulePickUp') | translate }}
                    <img src="../../../assets/icons/help.svg"
                         alt="Help"
                         class="help-icon"
                         matTooltip="{{ (isFedEx ? 'SchedulePickUpFedExTooltip' : 'SchedulePickUpUPSTooltip')  | translate }}"
                         matTooltipPosition="above">
                </span>
            </mat-radio-button>
        </mat-radio-group>
        <div class="schedule-pickup" *ngIf="isShowSchedulePickup && config?.showSchedulePickUp ">
            <mat-form-field class="date-input">
                <mat-label>{{'PickUpDate'|translate}}</mat-label>
                <input matInput
                       required
                       [matDatepicker]="picker"
                       [min]="getMinDate()"
                       [max]="getMaxDate()"
                       [matDatepickerFilter]="filterDates"
                       formControlName="pickUpDate">
                <span class="formatted-date" (click)="picker.open()">{{ formattedPickUpDate }}</span>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="!isMatDatepickerValid(shipOptionsForm.controls.pickUpDate)">
                    <span *ngIf="shipOptionsForm.controls.pickUpDate?.errors?.required">{{'PickupDateRequired'|translate}}</span>
                    <span *ngIf="shipOptionsForm.controls.pickUpDate?.errors?.matDatepickerMin">{{'MinimumPickupDate'|translate}} {{ getMinDate('LL') }}</span>
                    <span *ngIf="shipOptionsForm.controls.pickUpDate?.errors?.matDatepickerMax">{{'MaximumPickupDate'|translate}} {{ getMaxDate('LL') }}</span>
                </mat-error>
            </mat-form-field>
            <div class="time-selectors">
                <div class="select-wrapper">
                    <mat-form-field class="label--float-top" [subscriptSizing]="'dynamic'">
                        <mat-label>{{'PickupAtHour'|translate}}</mat-label>
                        <mat-select formControlName="pickupAtHour">
                            <mat-option *ngFor="let hourItem of pickupAtHours" [value]="hourItem.hour" #pickupAtHour>
                                {{ hourItem.hour }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="connector">:</div>
                <div class="select-wrapper">
                    <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-select formControlName="pickupAtMinute">
                            <mat-option *ngFor="let minute of pickupMinutes" [value]="minute">
                                {{ minute }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="period">{{ getPickupAtHourPeriod() }}</div>
                <img src="../../../assets/icons/help.svg"
                     alt="Help"
                     class="help-icon"
                     matTooltip="{{ 'PickupAtTooltip'  | translate }}"
                     matTooltipPosition="above">
            </div>

            <div class="time-selectors">
                <div class="select-wrapper">
                    <mat-form-field class="label--float-top" [subscriptSizing]="'dynamic'">
                        <mat-label>{{'PickupByHour'|translate}}</mat-label>
                        <mat-select formControlName="pickupByHour">
                            <mat-option *ngFor="let hourItem of pickupByHours" [value]="hourItem.hour">
                                {{ hourItem.hour }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="connector">:</div>
                <div class="select-wrapper">
                    <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-select formControlName="pickupByMinute">
                            <mat-option *ngFor="let minute of pickupMinutes" [value]="minute">
                                {{ minute }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="period">{{ getPickupByHourPeriod() }}</div>
            </div>
            <mat-error *ngIf="shipOptionsForm.controls.pickupByHour.errors?.pickupTime || shipOptionsForm.controls.pickupByMinute.errors?.pickupTime || shipOptionsForm.controls.pickupAtHour.errors?.pickupTime || shipOptionsForm.controls.pickupAtMinute.errors?.pickupTime">
                {{ 'PickupTimeInvalid' | translate }}
            </mat-error>

            <div class="return-ship-from-address" *ngIf="returnShipment">
                <div class="name">{{ shipFromAddress.FirstName }} {{ shipFromAddress.LastName }}</div>
                <div class="company">{{ shipFromAddress.CompanyName }}</div>
                <div class="address vertical">
                    <span>{{ shipFromAddress.StreetAddress }},</span>
                    <span>{{ shipFromAddress.ApartmentSuite}}</span>
                </div>
                <div class="address">
                    <span>{{ shipFromAddress.City }},</span>
                    <span>{{ shipFromAddress.State || shipFromAddress.ProvinceRegion }}</span>
                    <span>{{ shipFromAddress.Zip }}</span>
                    <span>{{ shipFromAddress.Country }}</span>
                </div>
                <div class="phone">{{ shipFromAddress.TelephoneNo }}</div>
            </div>

            <mat-form-field *ngIf="!returnShipment">
                <mat-label>{{'SchedulePickUpContactName'|translate}}</mat-label>
                <input matInput
                       required
                       [maxlength]="22"
                       formControlName="contactName">
                <mat-error *ngIf="shipOptionsForm.controls.contactName?.errors">
                    <span *ngIf="shipOptionsForm.controls.contactName?.errors?.required">
                        {{ 'ContactNameRequired' | translate }}
                    </span>
                    <span *ngIf="shipOptionsForm.controls.contactName?.errors?.maxLength">
                        {{ 'ContactNameMaxLengthError' | translate : { length: "35" } }}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="!returnShipment">
                <mat-label>{{'PhoneNumber'|translate}}</mat-label>
                <input matInput
                       required
                       formControlName="phoneNumber">
                <mat-error *ngIf="shipOptionsForm.controls.phoneNumber?.errors?.required">
                    {{'PhoneRequired'|translate}}
                </mat-error>
            </mat-form-field>
            <div class="warning-message" *ngIf="isDailyPickUp()">
                {{'DailyPickupOption'|translate}}
            </div>

            <!-- [MV3-2151] Remove carrier-specific condition for this smart/daily pickup warning message. -->
            <!-- [MV3-2151] Revert the condition back as requested. -->
            <div class="warning-message" *ngIf="isUPS() && !isDailyPickUp() && !isSmartPickUp() && !returnShipment">
                {{'SmartOrDailyPickupOption'|translate}}

            </div>
            <div class="warning-message" *ngIf="isExistingSchedulePickup && !returnShipment">
                {{'IsExistingSchedulePickupWarning'|translate}}
            </div>
        </div>

        <mat-checkbox formControlName="thermalPrinter" *ngIf="config?.showThermalPrinter && showThermalPrinter">
            {{'ThermalPrinter'|translate}}
        </mat-checkbox>

        @if (!shipState.isInternational() && !returnShipment) {
            <mat-checkbox formControlName="returnLabel">{{ 'ReturnLabel'|translate }}
            </mat-checkbox>
        }

        <div class="return-date" *ngIf="config?.showReturnLabel && isPrintReturnLabel && !returnShipment">
            <div class="select-wrapper">
                <mat-form-field>
                    <mat-label>{{'ScheduleDate'|translate}}</mat-label>
                    <mat-select required
                                formControlName="scheduledReturnDate">
                        <mat-option value="" disabled selected>{{'SelectReturnDate'|translate}}</mat-option>
                        <mat-option *ngFor="let returnDate of returnDates" [value]="returnDate.value">
                            {{ returnDate.text }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="shipOptionsForm.controls.scheduledReturnDate.errors?.required">
                        <span *ngIf="shipOptionsForm.controls.scheduledReturnDate.errors?.required">
                            {{'ReturnDateRequired'|translate}}
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/core/services/shared.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { Carriers } from 'app/shared/enum/general-enum';
import { IContact } from 'app/shared/models/contact.interface';
import { ICountry } from 'app/shared/models/country.interface';
import { Customer } from 'app/shared/models/customer/customer.model';
import { Location } from 'app/shared/models/location/location.model';
import { NotificationType } from 'app/shared/models/notification-type';
import { DialogService } from 'app/shared/services/dialog/dialog.service';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { FormService } from 'app/shared/services/form/form.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { User } from 'app/shared/services/user/models/user.model';
import { UserService } from 'app/shared/services/user/user.service';
import { UtilityService } from 'app/shared/services/utility/utility.service';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../app.state';
import { LocationEditDialogComponent } from '../../location-edit-dialog/location-edit-dialog.component';
import { Contact } from '../../models/contact.model';
import { Package } from '../../models/package.model';
import { IShipComponent } from '../../models/ship-component.interface';
import { ShipmentService } from '../../services/shipment.service';
import { ReturnShipToEditComponent } from './return-ship-to-edit/return-ship-to-edit.component';
import { ReturnShipToStaticComponent } from './return-ship-to-static/return-ship-to-static.component';

@Component({
    selector: 'upsc-return-ship-to',
    templateUrl: './return-ship-to.component.html',
    styleUrls: ['./return-ship-to.component.scss'],
})
export class ReturnShipToComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, IShipComponent {
    @Input() user: User;
    @Input() customer: Customer;
    @Input() carrier: Carriers;
    @Input() shipToCountryCode: string;
    @Input() shipFromCurrencyCode = 'USD'; // TODO check
    @Input() maxCoverageCurrencyCode = 'USD';
    @Input() isRestoreShipment = false;
    @Input() returnShipment = false;
    @Input() shouldDisplayEUFields = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() countryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();
    @Output() countryCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() shipToAddressChanged: EventEmitter<Location> = new EventEmitter<Location>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('shipToStatic') shipToStatic: ReturnShipToStaticComponent;
    @ViewChild('shipToEdit', { static: true }) shipToEdit: ReturnShipToEditComponent;

    public formGroup: UntypedFormGroup;
    public countries: ICountry[];

    public locations: Location[];
    public isLocationsLoading = false;
    public shipToAddress: Location;
    public isShowAddressBook = false;
    public isLocationEditable = false;
    public isEUUser: boolean;
    public currentUserIsAdmin: boolean;
    public currentUser: any;
    private defaultLocation: Location;
    private cachedshipToAddress: Contact;
    private getShipmentLocationsSubscription: Subscription;
    private locationsSubject = new BehaviorSubject<Location[]>(null);
    public isFRITUser: boolean;

    public constructor(private formBuild: UntypedFormBuilder,
                       private router: Router,
                       private notificationService: NotificationService,
                       private dialogService: DialogService,
                       private formService: FormService,
                       private storageService: StorageService,
                       private utilityService: UtilityService,
                       private errorHandlerService: ErrorHandlerService,
                       private dialog: MatDialog,
                       private translateService: TranslateService,
                       private shipmentService: ShipmentService,
                       private userService: UserService,
                       private sharedService: SharedService,
                       private readonly appState: AppState,
    ) {
        this.userService.getCurrentUser().subscribe(
            (currentUser) => {
                this.currentUser = currentUser;
                this.currentUserIsAdmin = currentUser?.isAdmin;
            },
        );

        this.formGroup = this.formBuild.group({
            location: [''],
        });

        this.monitorValueChanges();
        this.loadInitialValues();
        this.CheckIfEUUser();
    }

    public ngOnInit() {
        this.isFRITUser =  this.userService.isFRITUser(this.user);
        const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
        if (cachedShipment) {
            this.shipToAddress = _.cloneDeep(cachedShipment.ShipTo);
            this.shipToAddressChanged.emit(this.shipToAddress);
            this.countryCodeChanged.emit(this.shipToAddress.Country);
            this.zipCodeChanged.emit(this.shipToAddress.Zip);
        }
        this.shipToAddress = new Location();
        this.shipmentService.updateToLocation$.subscribe(
            (location: Location) => this.shipToAddress = location);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onCarrierChanged(changes['carrier']);
        this.onUserChanged(changes['user']);
    }

    public ngOnDestroy() {
        this.clearShipmentLocationsSubscription();
    }

    public ngAfterViewInit() {
        this.setLocationEditable();
    }

    public openEditLocationDialog(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.isEUUser && environment.feature.disableEditShipFromLocationEU) {
            const RestrictMessage = this.userService.GetRestrictShipFromAddressChangeMessage(this.user.CountryCode);
            this.dialogService.alert('', RestrictMessage);
            // this.dialogService.alert(
            //     '',
            //     this.translateService.instant('noShipFromEditAlert'),
            // );

            return;
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '800px',
            data: {
                carrier: this.carrier,
                location: this.shipToAddress,
                isFRIT: this.isFRITUser,
            },
            maxWidth: '100%',
            panelClass: ['mobile-fullscreen-dialog'],
        };

        const dialogRef: MatDialogRef<LocationEditDialogComponent> = this.dialog.open(LocationEditDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (updatedLocation: Location) => {
                if (!updatedLocation) {
                    return;
                }

                this.formGroup.controls.location.setValue('', { emitEvent: false });
                this.shipToAddress = updatedLocation;
                this.shipToAddressChanged.emit(this.shipToAddress);
                this.countryCodeChanged.emit(this.shipToAddress.Country);
                this.zipCodeChanged.emit(this.shipToAddress.Zip);

                if (this.locations) {
                    // update item in the location list.
                    const targetLocation = this.locations.find(location => location.ContactId === updatedLocation.ContactId);

                    if (targetLocation) {
                        Object.assign(targetLocation, updatedLocation);
                        this.formGroup.controls.location.setValue(updatedLocation.ContactId);
                    }
                }
            },
        );
    }

    public resetForm() {
        this.resetComponents([
            this.shipToStatic,
            this.shipToEdit,
        ]);

        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            location: this.user.DefaultLocation,
        });

        this.loadShipmentLocations();
    }

    public forceValidation() {
        this.markComponentFormAsDirty([
            this.shipToStatic,
            this.shipToEdit,
        ]);

        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public onFormValueChanged(value) {
        this.formValueChanged.emit(value);
    }

    public fillCostEstimateValues(cachedCostEstimate: any) {
        this.formGroup.patchValue({
            location: cachedCostEstimate.shipToAddress,
        });

        this.formGroup.controls.location.markAsDirty();
        this.formGroup.controls.location.markAsTouched();
    }

    //#endregion

    public restoreShipment(shipment: Package) {
        this.shipToAddress = shipment.ShipTo;
        const locationId = shipment.ShipFrom.ContactId; // TODO check
        if (!locationId || locationId === 'NOID') {
            // TODO: [MV3-2564]
            // The location ID should be returned with the GET /shipment/{id} instead of having to try to grab it from the location list.
            this.findLocationId(shipment.ShipFrom) // TODO check
                .then(
                    (id) => {
                        if (!id || id === 'NOID') {
                            if (shipment.ShipFrom) { //TODO check
                                this.restoreCustomAddress(shipment);
                            }

                            return;
                        }

                        this.restoreLocationId(id);
                    });

            return;
        }

        this.restoreLocationId(locationId);
    }

    public onShipToCountryChanged(country: ICountry) {
        this.countryChanged.emit(country);
    }

    public onShipToCountryCodeChanged(countryCode: string) {
        this.countryCodeChanged.emit(countryCode);
    }

    public onShipToZipCodeChanged(zipCode: string) {
        this.zipCodeChanged.emit(zipCode);
    }

    public setFormValidity(isValid: boolean) {
        this.isValid.emit(isValid);
    }

    public getFormErrors() {
        if (!this.shipToEdit) {
            return null;
        }

        return this.shipToEdit.getFormErrors();
    }

    public isFormValid() {
        if (!this.shipToEdit) {
            return true;
        }

        return this.shipToEdit.isFormValid();
    }

    public onLocationFocused(event, selector: MatSelect) {
        event.preventDefault();

        if (this.locations) {
            return;
        }

        this.isLocationsLoading = true;
        this.loadShipmentLocations();
        selector.open();
    }

    public onContactSelected(contact: IContact) {
        this.formGroup.controls.location.setValue('', { emitEvent: false });
        this.isLocationEditable = false;
        this.shipToAddress = <Location>contact;
        this.countryCodeChanged.emit(this.shipToAddress.Country);
        this.zipCodeChanged.emit(this.shipToAddress.Zip);
        this.shipToAddressChanged.emit(this.shipToAddress);

        if (this.locations) {
            const targetLocation = this.locations.find(location => location.ContactId === contact.ContactId);

            if (targetLocation) {
                this.formGroup.controls.location.setValue(targetLocation.ContactId, { emitEvent: false });
            }
        }
    }

    public editShipFromLocation(event) {
        event.preventDefault();

        this.dialogService.confirm(
            'Moving away from ship page',
            'Editing a location will take you to another page. All changes made to the shipment will be lost.' +
            '<p>Are you sure you want to continue?</p>',
        )
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    this.router.navigate(['/manage-locations']);
                },
            );
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.loadShipmentLocations();
        this.setVisibility(this.user);
    }

    private onUserChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.loadShipmentLocations();
        this.setVisibility(change.currentValue);
    }

    private loadShipmentLocations() {
        if (!this.carrier || !this.user) {
            return;
        }

        this.isLocationsLoading = true;

        this.clearShipmentLocationsSubscription();
        this.getShipmentLocationsSubscription = this.shipmentService.getShipmentLocations(true, this.carrier)
                                                    .subscribe(
                                                        locations => this.handleGetShipmentLocationsSuccess(locations),
                                                        err => this.handleGetShipmentLocationsFailure(err),
                                                    );
    }

    private loadInitialValues() {
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges
            .subscribe(
                (value) => {
                    this.formValueChanged.emit(value);
                },
            );

        this.formGroup.controls.location.valueChanges
            .subscribe(
                (value) => {
                    // Create a new contact
                    if (value === '0') {
                        this.isLocationEditable = !this.isLocationEditable;
                        this.shipToAddress = new Location();
                        this.shipToAddress.Country = this.defaultLocation ? this.defaultLocation.Country : '';
                        this.shipmentService.Quote.ShipTo.NickName = ''; // MV3-4743
                        this.shipToAddressChanged.emit(this.shipToAddress);
                        this.countryCodeChanged.emit(this.shipToAddress.Country);
                        this.zipCodeChanged.emit(this.shipToAddress.Zip);

                        return;
                    }

                    this.setLocationEditable();

                    // Address is selected from an address book.
                    if (!value) {
                        return;
                    }

                    // Address is selected from a location dropdown.
                    if (this.locations) {
                        this.shipToAddress = _.cloneDeep(this.locations.find(location => location.ContactId === value));
                    }

                    if (!this.shipToAddress) {
                        return;
                    }

                    const hasProvince = !!this.shipToAddress.ProvinceRegion && !!this.shipToAddress.ProvinceRegion.trim();
                    const hasState = !!this.shipToAddress.State && !!this.shipToAddress.State.trim();

                    if (hasProvince && !hasState) {
                        this.shipToAddress.State = this.shipToAddress.ProvinceRegion.trim();
                        this.shipToAddress.ProvinceRegion = '';
                    }

                    if (!this.shipToAddress.Email) {
                        this.shipToAddress.Email = this.user.Email;
                    }

                    this.shipmentService.Quote.ShipTo = <Contact>this.shipToAddress;
                    this.shipToAddressChanged.emit(this.shipToAddress);
                    this.countryCodeChanged.emit(this.shipToAddress.Country);
                    this.zipCodeChanged.emit(this.shipToAddress.Zip);
                },
            );
    }

    private setLocationEditable() {
        if (!this.user || !this.user.IsGuest) {
            this.isLocationEditable = false;
            return;
        }

        this.isLocationEditable = this.user.IsGuestShipFrom;
    }

    //#region GetShipmentLocations handlers
    private handleGetShipmentLocationsSuccess(locations: Location[]) {
        this.locations = locations;
        this.locationsSubject.next(locations);

        if (this.formGroup && this.user && !this.isRestoreShipment) {
            if (!this.formGroup.controls.location.dirty
                && !this.formGroup.controls.location.touched) {
                this.formGroup.controls.location.setValue(this.user.DefaultLocation);
            } else {
                const selectedLocation = this.formGroup.controls.location.value;
                this.shipToAddress = _.cloneDeep(this.locations.find(location => location.ContactId === selectedLocation));
                this.shipToAddressChanged.emit(this.shipToAddress);
                this.countryCodeChanged.emit(this.shipToAddress.Country);
                this.zipCodeChanged.emit(this.shipToAddress.Zip);
            }

            this.defaultLocation = locations.find(item => item.ContactId === this.user.DefaultLocation);
        }

        this.utilityService.delay(
            () => {
                if (this.formGroup && locations) {
                    this.shipToAddress =
                        _.cloneDeep(this.locations.find(location => location.ContactId === this.formGroup.controls.location.value));

                    this.shipToAddressChanged.emit(this.shipToAddress);
                }
            },
            1000);

        this.isLocationsLoading = false;
    }

    private handleGetShipmentLocationsFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting ship-from locations',
            NotificationType.ERROR);
        this.isLocationsLoading = false;
    }

    private setVisibility(user: User): void {
        if (!user) {
            return;
        }

        this.setLocationEditable();
        this.isShowAddressBook = !user.IsGuest;

        if (!user.IsGuestShipFrom) {
            this.setshipToAddress();

            if (!this.shipToAddress) {
                this.getGuestContactAddress(user.DefaultLocation);
                return;
            }

            this.saveShipToData();
        }
    }

    private setshipToAddress() {
        if (!this.user || !this.locations) {
            return;
        }

        this.shipToAddress = this.locations.find(location => location.ContactId === this.user.DefaultLocation);

        if (this.formGroup) {
            this.formGroup.controls.location.setValue(this.user.DefaultLocation, { emitEvent: false });
        }
    }

    private saveShipToData() {
        const data = {
            contactId: this.shipToAddress.ContactId,
            firstName: this.shipToAddress.FirstName,
            lastName: this.shipToAddress.LastName,
            company: this.shipToAddress.CompanyName,
            zipCode: this.shipToAddress.Zip,
            city: this.shipToAddress.City,
            state: this.shipToAddress.State,
            country: this.shipToAddress.Country,
            address1: this.shipToAddress.StreetAddress,
            address2: this.shipToAddress.ApartmentSuite,
            email: this.shipToAddress.Email || this.user.Email,
            phone: this.shipToAddress.TelephoneNo,
            vat: this.shipToAddress.VAT,
            taxId: this.shipToAddress.TaxID,
            eori: this.shipToAddress.EORI,
        };

        this.shipmentService.saveShipTo(data);
        this.countryCodeChanged.emit(this.shipToAddress.Country);
        this.zipCodeChanged.emit(this.shipToAddress.Zip);
    }

    //#endregion

    private getGuestContactAddress(guestContactId: string) {
        this.shipmentService.getContactAddress(guestContactId)
            .subscribe(
                address => this.handleGetContactAddressSuccess(address),
                err => this.handleGetContactAddressFailure(err),
            );
    }

    private resetComponents(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return true;
                }

                component.resetForm();
            },
        );
    }

    private markComponentFormAsDirty(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return;
                }

                component.forceValidation();
            },
        );
    }

    private findLocationId(contact: Partial<Contact>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.locationsSubject.subscribe(
                (locations) => {
                    if (!locations || !locations.length) {
                        return;
                    }

                    const targetLocation = this.locations.find(
                        (location) => {
                            return this.utilityService.isSimilarObjectsByProperties(
                                location,
                                contact, [
                                    'FirstName',
                                    'LastName',
                                    'StreetAddress',
                                    'ApartmentSuite',
                                    'CompanyName',
                                    'City',
                                    'State',
                                    'ProvinceRegion',
                                    'Country',
                                    'Zip',
                                    // [MV3-2629] Excludes email from the comparison
                                    // since ship-from email address will be set from the system
                                    // if shipment's NotifySender is false and user's APIDevStatus is 0.
                                    // 'Email',
                                    'TelephoneNo',
                                ],
                            );
                        },
                    );

                    if (targetLocation) {
                        resolve(targetLocation.ContactId);
                        return;
                    }

                    resolve('');
                },
            );
        });
    }

    private restoreLocationId(locationId: string) {
        this.formGroup.patchValue({
            location: locationId,
        }, { emitEvent: false });

        this.formGroup.controls.location.markAsDirty();
        this.formGroup.controls.location.markAsTouched();

        if (!locationId || locationId === '0') {
            return;
        }

        this.saveShipToData();
    }

    private restoreCustomAddress(shipment: Package) {
        this.formGroup.patchValue(
            {
                location: '0',
            },
            { emitEvent: false });

        this.formGroup.controls.location.markAsDirty();
        this.formGroup.controls.location.markAsTouched();

        this.isLocationEditable = true;
        this.formValueChanged.emit(this.formGroup.value);
        this.shipToEdit.restoreShipment(shipment);
    }

    private CheckIfEUUser() {
        const user = this.appState.user$();
        this.isEUUser = this.userService.isEUUser(user?.CountryCode);
    }

    //#region GetContactAddress handlers
    private handleGetContactAddressSuccess(location: Location) {
        // [MV3-2501] Prevent an empty ship-from address from being set.
        if (location.ContactId === 'NOID' || this.isRestoreShipment) {
            return;
        }

        this.shipToAddress = _.cloneDeep(location);

        const hasProvince = !!this.shipToAddress.ProvinceRegion && !!this.shipToAddress.ProvinceRegion.trim();
        const hasState = !!this.shipToAddress.State && !!this.shipToAddress.State.trim();

        if (hasProvince && !hasState) {
            this.shipToAddress.State = this.shipToAddress.ProvinceRegion.trim();
            this.shipToAddress.ProvinceRegion = '';
        }

        if (!this.shipToAddress.Email) {
            this.shipToAddress.Email = this.user.Email;
        }

        this.saveShipToData();
    }

    private handleGetContactAddressFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting contact address',
            NotificationType.ERROR);
    }

    private clearShipmentLocationsSubscription() {
        if (!this.getShipmentLocationsSubscription) {
            return;
        }

        this.getShipmentLocationsSubscription.unsubscribe();
        this.getShipmentLocationsSubscription = null;
    }
}

import { Component, ElementRef, HostBinding, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Carriers } from '../../shared/enum/general-enum';
import { GetShippingUpdatesDialogService } from '../get-shipping-updates-dialog/get-shipping-updates-dialog.service';
import {
    IShippingUpdateInformation,
} from '../get-shipping-updates-dialog/models/shipping-update-information.interface';
import { Contact } from '../models/contact.model';
import { IPickupLocationRequest } from '../pickup-locations/models/pickup-location-request.interface';
import { IPickupLocation } from '../pickup-locations/models/pickup-location.interface';

@Component({
    selector: 'upsc-pickup-locations-dialog',
    templateUrl: './pickup-locations-dialog.component.html',
    styleUrls: ['./pickup-locations-dialog.component.scss'],
})
export class PickupLocationsDialogComponent {
    @HostBinding('class') public hostClass = 'pickup-locations-dialog';
    @ViewChild('dialogFooter') public dialogFooter: ElementRef;

    public pickupLocation: IPickupLocation;

    public carrier: Carriers;
    public request: IPickupLocationRequest;
    public isReadonly = false;
    public locationId: string;
    public insuredValue: number;
    public useAccessPointCodeControl = new FormControl(false);

    protected readonly Carriers = Carriers;

    private shipFrom: Partial<Contact>;
    private shipTo: Partial<Contact>;

    public constructor(public dialogRef: MatDialogRef<PickupLocationsDialogComponent>,
                       @Inject(MAT_DIALOG_DATA) public data: {
                           carrier: Carriers,
                           request: IPickupLocationRequest,
                           isReadonly: boolean,
                           shipFrom: Partial<Contact>,
                           shipTo: Partial<Contact>,
                           locationId: string,
                           insuredValue?: number,
                           useAccessPointCode: boolean,
                       },
                       private getShippingUpdatesDialogService: GetShippingUpdatesDialogService,
                       private snackBar: MatSnackBar,
                       private el: ElementRef,
    ) {
        this.carrier = this.data.carrier;
        this.request = this.data.request;
        this.shipFrom = this.data.shipFrom;
        this.shipTo = this.data.shipTo;
        this.isReadonly = this.data.isReadonly || false;
        this.locationId = this.data.locationId;
        this.insuredValue = this.data.insuredValue;
        this.useAccessPointCodeControl.setValue(this.data.useAccessPointCode || false);
    }

    public get dialogFooterHeight(): number {
        if (!this.dialogFooter) {
            return 156.8;
        }

        return this.dialogFooter.nativeElement.offsetHeight;
    }

    public get dialogHeight(): number {
        return this.el.nativeElement.offsetHeight;
    }

    public continue(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const shippingUpdateInformation: IShippingUpdateInformation = {
            carrier: this.carrier,
            shouldSendToSender: true,
            sender: {
                firstName: this.shipFrom.FirstName || '',
                lastName: this.shipFrom.LastName || '',
                email: this.shipFrom.Email || '',
            },
            recipients: [
                {
                    firstName: this.shipTo.FirstName || '',
                    lastName: this.shipTo.LastName || '',
                    email: this.shipTo.Email || '',
                },
            ],
        };

        // [DD-349] Only close dialog if a user has confirmed the email notifications.
        // If it's not confirmed, reset the pickup location selection and remain on this dialog.
        this.getShippingUpdatesDialogService.open(shippingUpdateInformation).afterClosed().subscribe(
            (shippingUpdateInformation) => {
                if (!shippingUpdateInformation) {
                    this.snackBar.open(
                        'Email notification is required when using a pickup location.',
                        null,
                        { duration: 3000 },
                    );

                    return;
                }

                this.dialogRef.close({
                    shippingUpdateInformation,
                    request: this.request,
                    pickupLocation: this.pickupLocation,
                    useAccessPointCode: this.useAccessPointCodeControl.value,
                });
            },
        );
    }
}

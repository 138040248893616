import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ShipmentService } from '../../services/shipment.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { NotificationType } from 'app/shared/models/notification-type';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { FormService } from 'app/shared/services/form/form.service';
import { TranslateService } from '@ngx-translate/core';
import { IShipComponent } from 'app/ship/models/ship-component.interface';
import { ValidationService } from 'app/shared/services/validation/validation.service';

@Component({
  selector: 'upsc-return-options',
  templateUrl: './return-options.component.html',
  styleUrls: ['./return-options.component.scss']
})
export class ReturnOptionsComponent implements OnInit, OnDestroy,IShipComponent {

  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() returnOptionChanged: EventEmitter<string> = new EventEmitter<string>();

  public formGroup: UntypedFormGroup;
  public message = false;

  public returnOptions = [{ "ReturnServiceCode": "3", "ReturnServiceCodeDesc": "returnOptions.option1", "CarrierCode": "UPS", "ServiceCode": "." },
  { "ReturnServiceCode": "5", "ReturnServiceCodeDesc": "returnOptions.option2", "CarrierCode": "UPS", "ServiceCode": "." },
  { "ReturnServiceCode": "10", "ReturnServiceCodeDesc": "returnOptions.option3", "CarrierCode": "UPS", "ServiceCode": "." },
  { "ReturnServiceCode": "8", "ReturnServiceCodeDesc": "returnOptions.option4", "CarrierCode": "UPS", "ServiceCode": "." },
  { "ReturnServiceCode": "2", "ReturnServiceCodeDesc": "returnOptions.option5", "CarrierCode": "UPS", "ServiceCode": "." }];

  constructor(private formBuild: UntypedFormBuilder,
    private shipmentService: ShipmentService,
    private formService: FormService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private validationService: ValidationService,
    private errorHandlerService: ErrorHandlerService,) {

  }

  ngOnInit(): void {
    this.getReturnServices();

    // Setup a form group here.
    this.formGroup = this.formBuild.group({
      returnOption: ['', Validators.required],
    });

    // Subscribe to form value changes.
    this.monitorValueChanges();
  }

  private monitorValueChanges() {
    this.formGroup.valueChanges
      .subscribe(
        (value) => {
          const selectedValue = this.returnOptions.filter(x => x.ReturnServiceCode == value.returnOption);
          // Save the form values to the Quote object.
          if (selectedValue && selectedValue.length > 0) {
            selectedValue[0].ReturnServiceCodeDesc = this.translateService.instant(selectedValue[0].ReturnServiceCodeDesc);
            this.shipmentService.saveReturnServiceInformation(selectedValue[0]);
          }

          if (value.returnOption === '2') {
            this.message = true;
          }
          else {
            this.message = false;
          }

          // Emit the form validity to its parent.
          this.isValid.emit(this.formGroup.valid);
          this.returnOptionChanged.emit(value.returnOption);
          this.formValueChanged.emit(value);
        },
      );
  }

  private getReturnServices() {
    this.shipmentService.getRequestServiceOptions().subscribe((response) => {

      this.returnOptions = response;
    }, (error) => {
      this.notificationService.notify(
        this.errorHandlerService.getHttpErrorMessage(error),
        'Failed getting Return Services Options',
        NotificationType.ERROR);
    })
  }

  public ngOnDestroy() {
    this.shipmentService.resetReturnOption();
  }

  public resetForm() {
    if (!this.formGroup) {
      return;
    }

    this.formGroup.reset({
      returnOption: '',
    });
  }

  public getFormErrors() {
    return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Return Options');
  }

  public isFormValid() {
    return this.formGroup.valid;
  }

  public forceValidation() {
    this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
  }

  public returnOptionDesc(value): string {
    switch (value) {
      case '3': {
        return this.translateService.instant('returnOptions.option1');
      }
      case '5': {
        return this.translateService.instant('returnOptions.option2');
      }
      case '9': {
        return this.translateService.instant('returnOptions.option3');
      }
      case '8': {
        return this.translateService.instant('returnOptions.option4');
      }
      case '2': {
        return this.translateService.instant('returnOptions.option5');
      }
    }
  }
}

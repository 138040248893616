import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { GetShippingUpdatesDialogComponent } from './get-shipping-updates-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
        MatInputModule,
        MatCheckboxModule,
        RxReactiveFormsModule,
    ],
    declarations: [GetShippingUpdatesDialogComponent],
    providers: [],
    exports: [GetShippingUpdatesDialogComponent],
})
export class GetShippingUpdatesDialogModule {
    // static forRoot(): ModuleWithProviders {
    //   return {
    //     ngModule: GetShippingUpdatesDialogModule,
    //     providers: [GetShippingUpdatesDialogService],
    //   };
    // }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Carriers } from '../../shared/enum/general-enum';
import { NotificationType } from '../../shared/models/notification-type';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { GetShippingUpdatesDialogService } from '../get-shipping-updates-dialog/get-shipping-updates-dialog.service';
import {
    IShippingUpdateInformation
} from '../get-shipping-updates-dialog/models/shipping-update-information.interface';
import { ShipmentService } from '../services/shipment.service';
import { ICarrierLocation } from './models/carrier-location.interface';

@Component({
    selector: 'upsc-location-search-dialog',
    templateUrl: './location-search-dialog.component.html',
    styleUrls: ['./location-search-dialog.component.scss'],
})
export class LocationSearchDialogComponent implements OnInit, OnDestroy {
    public formGroup: UntypedFormGroup;
    public searchResult: ICarrierLocation[];
    public isLocationSearching = false;
    public isTableActionsAllowed = false;

    private locationSearchSubscription: Subscription;

    constructor(private formBuilder: UntypedFormBuilder,
                private shipmentService: ShipmentService,
                private notificationService: NotificationService,
                private errorHandlerService: ErrorHandlerService,
                public dialogRef: MatDialogRef<LocationSearchDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private getShippingUpdatesDialogService: GetShippingUpdatesDialogService,
                private snackBar: MatSnackBar,
    ) {
        this.isTableActionsAllowed = this.data.isTableActionsAllowed;
    }

    public ngOnInit() {
        this.formGroup = this.formBuilder.group({
            phoneNumber: ['4243189540'],
            zipCode: [''],
            distance: ['30'],
        });
    }

    public ngOnDestroy() {
        if (this.locationSearchSubscription) {
            this.locationSearchSubscription.unsubscribe();
            this.locationSearchSubscription = null;
        }
    }

    public onFormSubmit(event, form) {
        event.preventDefault();

        if (this.locationSearchSubscription) {
            this.locationSearchSubscription.unsubscribe();
            this.locationSearchSubscription = null;
        }

        this.isLocationSearching = true;
        // TODO: submit the search and display the result in a table.
        this.locationSearchSubscription =
            this.shipmentService.searchCarrierLocations(this.data.carrier, form.phoneNumber, form.zipCode, form.distance)
                .subscribe(
                    res => this.handleSearchCarrierLocationsSuccess(res),
                    err => this.handleSearchCarrierLocationsFailure(err),
                );
    }

    /**
     * Checks whether the form is valid.
     * This allows a parent form to get the child form validity.
     */
    public isFormValid() {
        const phoneNumber = this.formGroup.controls.phoneNumber.value;
        const zipCode = this.formGroup.controls.zipCode.value;

        return this.formGroup.valid && (phoneNumber || zipCode);
    }

    public onRowActionClicked(value: { action: string, data: ICarrierLocation }) {
        const shippingUpdateInformation: IShippingUpdateInformation = {
            carrier: Carriers.Fedex,
            shouldSendToSender: true,
            sender: {
                firstName: this.shipmentService.Quote.ShipFrom.FirstName || '',
                lastName: this.shipmentService.Quote.ShipFrom.LastName || '',
                email: this.shipmentService.Quote.ShipFrom.Email || '',
            },
            recipients: [
                {
                    firstName: this.shipmentService.Quote.ShipTo.FirstName || '',
                    lastName: this.shipmentService.Quote.ShipTo.LastName || '',
                    email: this.shipmentService.Quote.ShipTo.Email || '',
                },
            ],
        };

        switch (value.action) {
            case 'select':
                // [DD-349] Only close dialog if a user has confirmed the email notifications.
                // If it's not confirmed, reset the pickup location selection and remain on this dialog.
                this.getShippingUpdatesDialogService.open(shippingUpdateInformation).afterClosed().subscribe(
                    (shippingUpdateInformation) => {
                        if (!shippingUpdateInformation) {
                            this.formGroup.patchValue({
                                selectedAccessPointId: null,
                            });

                            this.snackBar.open(
                                'Email notification is required when using a pickup location.',
                                null,
                                { duration: 3000 },
                            );

                            return;
                        }

                        this.shipmentService.shippingUpdateInformationChanged$.next(shippingUpdateInformation);

                        this.dialogRef.close(value.data);
                    },
                );
                break;
            default:
                break;
        }
    }

    private handleSearchCarrierLocationsSuccess(locations) {
        this.searchResult = locations;
        this.searchResult.map(
            (location) => {
                location.AddressDisplay = `<div class="address-display">
          <h5 class="business-name">${ location.BusinessName }</h5>
          <span class="address">${ location.Address }</span>
          <span class="address">${ location.City }, ${ location.State }, ${ location.PostalCode }</span>
        </div>`;

                return location;
            },
        );

        this.isLocationSearching = false;
    }

    private handleSearchCarrierLocationsFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting carrier locations',
            NotificationType.ERROR);
        this.isLocationSearching = false;
    }
}

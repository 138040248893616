<div class="ship-electronic-export ups-form">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{ 'returnOptionsTitle'|translate }}</h5>
        </div>

        <form [formGroup]="formGroup" class="ups-form">
            <div class="form-body">
                <div class="select-wrapper">
                    <mat-form-field>
                        <mat-label>{{ 'returnServiceOptions'|translate }}</mat-label>
                        <mat-select formControlName="returnOption">
                            <mat-option value="" disabled selected>{{ 'selectOne'|translate }}
                            </mat-option>
                            <mat-option *ngFor="let option of returnOptions" [value]="option.ReturnServiceCode">
                                {{ returnOptionDesc(option.ReturnServiceCode) }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.returnOption?.errors?.required">
                            <span *ngIf="formGroup.controls.returnOption?.errors?.required">
                                {{ 'returnOptionRequired'|translate }}
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="message" class="warning-message">
                    {{ 'returnOptionWarningMessage' | translate }}
                </div>

            </div>
        </form>
    </div>
</div>

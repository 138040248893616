import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Carriers } from '../../../../shared/enum/general-enum';
import { IPickupLocationRequest } from '../../models/pickup-location-request.interface';
import { IPickupLocation } from '../../models/pickup-location.interface';
import { PickupLocationService } from '../../pickup-location.service';
import { PickupLocationSearchConfig } from './pickup-location-search-config';

dayjs.extend(relativeTime);

@Component({
    selector: 'upsc-pickup-location-search',
    templateUrl: './pickup-location-search.component.html',
    styleUrls: ['./pickup-location-search.component.scss'],
})
export class PickupLocationSearchComponent implements OnChanges {
    @Input() public isReadonly = false;
    @Input() public carrier: Carriers;
    @Input() public request: IPickupLocationRequest;
    @Output() public searchParametersChanged = new EventEmitter<IPickupLocationRequest>();
    @Output() public response = new EventEmitter<IPickupLocation[]>();
    @HostBinding('class') public hostClass = 'pickup-location-search';

    public formGroup: FormGroup;
    public isEditMode = false;
    public shouldAllowEditShipToAddress = false;
    protected readonly PickupLocationSearchConfig = PickupLocationSearchConfig;
    private controlSubscriptions: Map<string, Subscription> = new Map<string, Subscription>();
    private searchSubscription: Subscription;

    constructor(private formBuilder: FormBuilder,
                private pickupLocationService: PickupLocationService,
    ) {
        this.setupForm();
    }

    public get address(): IPickupLocationRequest {
        return this.formGroup?.getRawValue();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.request?.currentValue) {
            this.restoreData(changes?.request?.currentValue as IPickupLocationRequest);
        }
    }

    public onFormSubmit(event: SubmitEvent, formValue: IPickupLocationRequest) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.search(formValue);
    }

    public search(request: IPickupLocationRequest): void {
        this.searchParametersChanged.emit(request);

        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }

        const carrierCode = Carriers[this.carrier].toLowerCase();
        this.searchSubscription =
            forkJoin({
                locations: this.pickupLocationService.getPickupLocations(carrierCode, request),
                usedLocations: this.pickupLocationService.getUsedPickupLocations(carrierCode),
            })
                .pipe(
                    map((results) => {
                        const usedLocationsIds = results?.usedLocations.map(location => location.locationId);
                        for (const location of (results?.locations || [])) {
                            location.isPreviouslyUsed = usedLocationsIds.includes(location.locationId);

                            if (location.isPreviouslyUsed) {
                                const lastUsed = results?.usedLocations.find(item => item.locationId === location.locationId)?.lastUsed;
                                if (dayjs(lastUsed).isValid()) {
                                    location.lastUsed = dayjs(lastUsed).fromNow();
                                }
                            }
                        }

                        return results?.locations || [];
                    }),
                    catchError(() => {
                        this.response.emit([]);
                        return of([]);
                    }),
                )
                .subscribe(
                    (locations) => {
                        this.sortHoursOfOperations(locations);
                        this.response.emit(locations);
                    },
                );
    }

    public onAddressChanged(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.isEditMode = !this.isEditMode;
        this.search(this.formGroup.getRawValue());
    }

    /**
     * Sort hours of operation from Monday (1) to Saturday (6) and put Sunday (0) at the end.
     */
    private sortHoursOfOperations(locations) {
        locations.forEach(location => {
            if (location.hoursOfOperations !== null) {
                location.hoursOfOperations.sort((a, b) => {
                    if (a.day === 0) {return 1;} // Push Sundays to the end
                    if (b.day === 0) {return -1;} // Keep Sundays at the end
                    return a.day - b.day; // Sort the rest by day number
                });
            }
            
        });
    }

    private setupForm(): void {
        this.formGroup = this.formBuilder.group({
            streetAddress: [null],
            apartmentSuite: [null],
            city: [null],
            state: [null],
            zip: [null, RxwebValidators.required()],
            country: [{ value: 'US', disabled: true }],
            phoneNumber: [{ value: null, disabled: true }],
            miles: [PickupLocationSearchConfig.searchRange.default],
            noOfRecords: [PickupLocationSearchConfig.maxSearchResults.default],
        });

        ['miles', 'noOfRecords'].forEach((controlName) => this.searchOnValueChanges(controlName));
    }

    private searchOnValueChanges(controlName: string): void {
        // Unsubscribe, if exists, before re-subscribing.
        // This will prevent multiple subscriptions on the same control.
        if (this.controlSubscriptions.has(controlName)) {
            const subscription = this.controlSubscriptions.get(controlName);
            subscription.unsubscribe();
            this.controlSubscriptions.delete(controlName);
        }

        const control = this.formGroup.get(controlName);
        if (!control) {
            return;
        }

        const newSubscription =
            control.valueChanges
                   .pipe(
                       distinctUntilChanged(),
                       debounceTime(300),
                   )
                   .subscribe(() => {
                       this.search(this.formGroup.getRawValue());
                   });

        this.controlSubscriptions.set(controlName, newSubscription);
    }

    private restoreData(request: IPickupLocationRequest): void {
        if (!request) {
            return;
        }

        this.formGroup.patchValue(request, { emitEvent: false });

        if (this.carrier && this.formGroup.valid) {
            this.search(request);
        }
    }
}

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Carriers } from '../../shared/enum/general-enum';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import {
    AccessPointsLearnMoreDialogComponent,
} from '../access-points-dialog/access-point-learn-more/access-points-learn-more-dialog.component';
import {
    FedExLocationLearnMoreDialogComponent,
} from '../fedex-location-dialog/fedex-location-learn-more/fedex-location-learn-more-dialog.component';
import { Contact } from '../models/contact.model';
import { NoCoverageWarningDialogComponent } from '../no-coverage-warning-dialog/no-coverage-warning-dialog.component';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipmentService } from '../services/shipment.service';

/**
 * This component extensively uses properties since it is to be dynamically created.
 * In a dynamically created component, `@Input()` properties may not be available in time (e.g. not in ngOnInit()).
 * This is because ngOnInit() is called immediately upon component creation, before you have a chance to set the @Input() properties.
 * A workaround is to use a property to access each `@Input()` property and do whatever you want with it.
 */
@Component({
    selector: 'upsc-address-confidence',
    templateUrl: './address-confidence.component.html',
    styleUrls: ['./address-confidence.component.scss'],
})
export class AddressConfidenceComponent implements OnInit {
    @Input() public value = 0;
    @Input() public address: Partial<Contact>;
    @Input() public addressType: 'commercial' | 'residential' = 'residential';
    @Input() public carrier: Carriers = Carriers.Ups;
    @Input() public isCachedScore = false;

    @Output() public closeParentDialog = new EventEmitter<string>();

    @HostBinding('class') public hostClass = 'address-confidence';

    public readonly Carriers = Carriers;
    public formGroup: UntypedFormGroup;

    public constructor(private formBuilder: UntypedFormBuilder,
                       private shipmentService: ShipmentService,
                       private shipConfigService: ShipConfigService,
                       private dialog: MatDialog,
                       private dialogService: DialogService,
                       private translateService: TranslateService,
    ) {
        this.formGroup = this.formBuilder.group({
            action: [null, Validators.required],
        });
    }

    public get shouldSuggestOnNoScore(): boolean {
        return this.shipmentService.ddNoScoreAttemptCount > 1;
    }

    public get selectedAction(): string {
        return this.formGroup?.get('action')?.value;
    }

    public get confidenceLevel(): 'none' | 'low' | 'average' | 'high' {
        return this.shipmentService.getAddressConfidenceLevel(this.value || 0);
    }

    public get ddScore(): number {
        return this.value || 0;
    }

    public get isCachedDDScore(): boolean {
        return this.isCachedScore || false;
    }

    public get shipmentCarrier(): Carriers {
        if (this.shipConfigService.isUps(this.carrier)) {
            return Carriers.Ups;
        }

        if (this.shipConfigService.isFedEx(this.carrier)) {
            return Carriers.Fedex;
        }

    }

    public get addressString(): string {
        if (!this.address) {
            return '';
        }

        return [
            this.address.StreetAddress,
            this.address.ApartmentSuite,
            this.address.City,
            this.address.State,
            this.address.Zip,
        ].filter(Boolean).join(', ');
    }

    public get shouldShowOptions(): boolean {
        return (this.addressType === 'residential' && ['low', 'average'].includes(this.confidenceLevel))
            || (this.addressType === 'commercial' && ['low'].includes(this.confidenceLevel));
    }

    public ngOnInit() {
        this.formGroup.get('action').valueChanges
            .pipe(
                distinctUntilChanged(),
            ).subscribe(
            (action) => {
                if (action !== 'pickupLocation') {
                    this.shipmentService.accessPointSelected$.next(null);
                    this.shipmentService.fedexHoldAtLocationSelected$.next(null);
                    this.shipmentService.shippingUpdateInformationChanged$.next(null);
                    this.shipmentService.savePickupLocation(null);
                }

                switch (action) {
                    case 'pickupLocation':
                        switch (this.shipmentCarrier) {
                            case Carriers.Ups:
                                this.shipmentService.accessPointSelectorRequested$.next(true);
                                break;
                            case Carriers.Fedex:
                                this.shipmentService.fedexHoldAtLocationRequested$.next(true);
                                break;
                            default:
                                break;
                        }

                        break;
                    case 'low':
                    case 'no-signature':
                        this.openNoCoverageWarningDialog(action);
                        break;
                    default:
                        break;
                }

                if (action !== 'pickupLocation' && this.shipmentCarrier === Carriers.Fedex) {
                    this.shipmentService.fedexHoldAtLocationSelected$.next(false);
                }
            },
        );

        this.shipmentService.accessPointSelected$.subscribe(
            (isSelected) => {
                if (!isSelected && this.formGroup?.get('action')?.value === 'pickupLocation') {
                    this.formGroup?.get('action')?.setValue(null);
                }
            },
        );

        this.shipmentService.fedexHoldAtLocationSelected$.subscribe(
            (isSelected) => {
                if (!isSelected && this.formGroup?.get('action')?.value === 'pickupLocation') {
                    this.formGroup?.get('action')?.setValue(null);
                }
            },
        );
    }

    public openUPSAccessPointLearnMoreDialog(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '34.625rem',
            panelClass: 'mobile-fullscreen-dialog',
            data: {},
        };

        this.dialog.open(AccessPointsLearnMoreDialogComponent, dialogConfig);
    }

    public openFedExLocationLearnMoreDialog(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '34.625rem',
            panelClass: 'mobile-fullscreen-dialog',
            data: {},
        };

        this.dialog.open(FedExLocationLearnMoreDialogComponent, dialogConfig);
    }

    public openLearnMoreDialog(event: MouseEvent, addressConfidenceType: string) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        let titleKey: string;
        let messageKey: string;

        switch (addressConfidenceType) {
            case 'residential-average':
                switch (this.carrier.toString()) {
                    case 'Ups':
                        titleKey = 'ship.shipUps.accessPoint.residentialAverageConfidenceLearnMore.title';
                        messageKey = 'ship.shipUps.accessPoint.residentialAverageConfidenceLearnMore.p1';
                        break;
                    case 'Fedex':
                        titleKey = 'ship.shipFedEx.location.residentialAverageConfidenceLearnMore.title';
                        messageKey = 'ship.shipFedEx.location.residentialAverageConfidenceLearnMore.p1';
                        break;
                    default:
                        titleKey = 'ship.shipUps.accessPoint.residentialAverageConfidenceLearnMore.title';
                        messageKey = 'ship.shipUps.accessPoint.residentialAverageConfidenceLearnMore.p1';
                        break;
                }
                break;
            case 'commercial-low':
                switch (this.carrier.toString()) {
                    case 'Ups':
                        titleKey = 'ship.shipUps.accessPoint.commercialLowConfidenceLearnMore.title';
                        messageKey = 'ship.shipUps.accessPoint.commercialLowConfidenceLearnMore.p1';
                        break;
                    case 'Fedex':
                        titleKey = 'ship.shipFedEx.location.commercialLowConfidenceLearnMore.title';
                        messageKey = 'ship.shipFedEx.location.commercialLowConfidenceLearnMore.p1';
                        break;
                    default:
                        titleKey = 'ship.shipUps.accessPoint.commercialLowConfidenceLearnMore.title';
                        messageKey = 'ship.shipUps.accessPoint.commercialLowConfidenceLearnMore.p1';
                        break;
                }

                break;
            default:
                break;
        }

        if (!titleKey || !messageKey) {
            return;
        }

        const title = this.translateService.instant(titleKey);
        const message = this.translateService.instant(messageKey);

        const dialogRef = this.dialogService.notify(title, message);
        dialogRef.subscribe(() => {
        });
    }

    private openNoCoverageWarningDialog(action: string): void {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '50rem',
            panelClass: 'mobile-fullscreen-dialog',
            data: {
                carrier: Carriers[this.carrier],
                addressType: this.addressType,
            },
        };

        const dialogRef = this.dialog.open(NoCoverageWarningDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((value) => {
            if (!value) {
                this.formGroup.get('action').setValue(null);
                return;
            }

            this.closeParentDialog.emit(action);
        });
    }
}

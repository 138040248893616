<div *ngIf="shouldShowSection"
     [formGroup]="shipInfoAddForm"
     class="ship-info-add ups-form">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{ 'AdditionalShipmentInformation'|translate }}</h5>
        </div>

        @if (shouldShowUPSAccessPoint) {
            <upsc-access-point-banner></upsc-access-point-banner>

            <mat-checkbox formControlName="useAccessPoint">
                <div class="ap-option">
                    <span>{{ 'holdAt' | translate }}</span>
                    <img alt="UPS Access Point"
                         class="ap-logo"
                         src="assets/images/logos/logo-ups-ap-alt.svg">
                    Location
                    <a (click)="showLearnMoreDialog($event)"
                       href="#">{{ 'learnMore' | translate }}</a>
                </div>
            </mat-checkbox>

            @if (shipInfoAddForm?.get('useAccessPoint')?.value && pickupLocation) {
                <upsc-pickup-location-info [location]="pickupLocation"
                                           (editByLocationId)="openPickupLocationsDialog(carrier, $event)"></upsc-pickup-location-info>
            }
        }

        @if (shipmentStateService.isInternational()) {
            <mat-checkbox formControlName="brokerSelect">{{ 'BrokerSelect'|translate }}</mat-checkbox>
        }

        <div *ngIf="isBrokerSelectEnabled"
             class="checkbox-child-content">
            <mat-form-field>
                <mat-label>{{ 'BrokerAccountNumber'|translate }}</mat-label>
                <input formControlName="brokerAccountNumber"
                       matInput
                       maxlength="50">
                <mat-error *ngIf="shipInfoAddForm.controls.brokerAccountNumber?.errors?.maxlength">
                    <span>{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'BrokerCompanyName'|translate }}</mat-label>
                <input formControlName="brokerCompanyName"
                       matInput
                       maxlength="35"
                       required>
                <mat-error
                        *ngIf="shipInfoAddForm.controls.brokerCompanyName?.errors?.required || shipInfoAddForm.controls.brokerCompanyName?.errors?.maxlength">
                    <span *ngIf="shipInfoAddForm.controls.brokerCompanyName?.errors?.required">{{ 'BrokerCompanyNameRequired'|translate }}</span>
                    <span *ngIf="shipInfoAddForm.controls.brokerCompanyName?.errors?.maxlength">{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'BrokerContactName'|translate }}</mat-label>
                <input formControlName="brokerContactName"
                       matInput
                       maxlength="35">
                <mat-error *ngIf="shipInfoAddForm.controls.brokerContactName?.errors?.maxlength">
                    <span>{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'Address1'|translate }}</mat-label>
                <input formControlName="brokerAddress1"
                       matInput
                       maxlength="35"
                       required>
                <mat-error
                        *ngIf="shipInfoAddForm.controls.brokerAddress1?.errors?.required || shipInfoAddForm.controls.brokerAddress1?.errors?.maxlength">
                    <span *ngIf="shipInfoAddForm.controls.brokerAddress1?.errors?.required">{{ 'Address1Required'|translate }}</span>
                    <span *ngIf="shipInfoAddForm.controls.brokerAddress1?.errors?.maxlength">{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'Address2'|translate }}</mat-label>
                <input formControlName="brokerAddress2"
                       matInput
                       maxlength="35">
                <mat-error *ngIf="shipInfoAddForm.controls.brokerAddress2?.errors?.maxlength">
                    <span>{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'ZipCode'|translate }}</mat-label>
                <input [required]="config?.isShipToPostalCodeAware ? true : null"
                       formControlName="brokerPostalCode"
                       matInput
                       maxlength="10">
                <mat-error
                        *ngIf="shipInfoAddForm.controls.brokerPostalCode?.errors?.required || shipInfoAddForm.controls.brokerPostalCode?.errors?.maxlength">
                    <span *ngIf="shipInfoAddForm.controls.brokerPostalCode?.errors?.required">{{ 'ZipCodeRequired'|translate }}</span>
                    <span *ngIf="shipInfoAddForm.controls.brokerPostalCode?.errors?.maxlength">{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'City'|translate }}</mat-label>
                <input formControlName="brokerCity"
                       matInput
                       maxlength="35"
                       required>
                <mat-error
                        *ngIf="shipInfoAddForm.controls.brokerCity?.errors?.required || shipInfoAddForm.controls.brokerCity?.errors?.maxlength">
                    <span *ngIf="shipInfoAddForm.controls.brokerCity?.errors?.required">{{ 'CityRequired'|translate }}</span>
                    <span *ngIf="shipInfoAddForm.controls.brokerCity?.errors?.maxlength">{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'StateOrProvince'|translate }}</mat-label>
                <input [required]="shipInfoAddForm.controls.brokerCountryCode?.value==='US' || shipInfoAddForm.controls.brokerCountryCode?.value==='CA' ? true : null"
                       formControlName="brokerStateProvince"
                       matInput
                       maxlength="2">
                <mat-error *ngIf="shipInfoAddForm.controls.brokerStateProvince?.errors?.required">
                    <span>{{ 'StateProvinceRequired'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'Country'|translate }}</mat-label>
                <input formControlName="brokerCountryCode"
                       matInput
                       readonly
                       required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'PhoneNo'|translate }}</mat-label>
                <input formControlName="brokerPhoneNumber"
                       matInput
                       maxlength="25"
                       required>
                <mat-error
                        *ngIf="shipInfoAddForm.controls.brokerPhoneNumber?.errors?.required || shipInfoAddForm.controls.brokerPhoneNumber?.errors?.maxlength">
                    <span *ngIf="shipInfoAddForm.controls.brokerPhoneNumber?.errors?.required">{{ 'PhoneRequired'|translate }}</span>
                    <span *ngIf="shipInfoAddForm.controls.brokerPhoneNumber?.errors?.maxlength">{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'BrokerTaxID'|translate }}</mat-label>
                <input formControlName="brokerTaxId"
                       matInput
                       maxlength="35">
                <mat-error *ngIf="shipInfoAddForm.controls.brokerTaxId?.errors?.maxlength">
                    <span>{{ 'LengthMustNotExceed50'|translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>

        <mat-checkbox *ngIf="config?.showSaturdayDelivery && !isExpressSaverDEGB"
                      formControlName="saturdayDelivery">
            <span class="option-text">
                {{ 'SaturdayDelivery'|translate }}
                <img alt="Help"
                     class="help-icon"
                     matTooltip="{{ 'SaturdayDeliveryTooltip' | translate }}"
                     matTooltipPosition="above"
                     src="../../../assets/icons/help.svg">
            </span>
        </mat-checkbox>
        <mat-checkbox *ngIf="config?.showSaturdayPickUp && !shipInfoAddForm.controls.saturdayDelivery.value"
                      formControlName="saturdayPickup">
            {{ 'SaturdayPickup'|translate }}
        </mat-checkbox>

        <mat-checkbox *ngIf="config?.showDirectDeliveryOnly"
                      formControlName="directDeliveryOnly">
            {{ 'DirectDeliveryOnly'|translate }}
        </mat-checkbox>

        <mat-checkbox *ngIf="shouldShowAdultSignatureRequiredOption"
                      formControlName="adultSignature">
            <span class="option-text">
                {{ 'AdultSignatureRequired'|translate }}
                <img alt="Help"
                     class="help-icon"
                     matTooltip="{{ 'AdultSignatureTooltip' | translate }}"
                     matTooltipPosition="above"
                     src="../../../assets/icons/help.svg">
            </span>
        </mat-checkbox>
        <p *ngIf="config?.showAdultSignature && config?.showAdultSignatureMessage && !!shipInfoAddForm?.controls.adultSignature.value"
           class="adult-signature-warning">
            <span class="warning-title hide-me">{{ 'AdultSignatureRequiredNotification'|translate }}</span>
            <span class="warning-message">
                {{ 'AdultSignatureRequiredWarning'|translate }}
            </span>
        </p>
        <mat-checkbox *ngIf="config?.showDirectSignature"
                      formControlName="directSignature">
            {{ 'DirectSignatureRequired'|translate }}
            <img alt="Help"
                 class="help-icon"
                 matTooltip="{{ 'DirectSignatureTooltip' | translate }}"
                 matTooltipPosition="above"
                 src="../../../assets/icons/help.svg">
        </mat-checkbox>

        @if (config?.showHoldAtFedexLocation) {
            <mat-checkbox formControlName="holdAtFedExLocation">
                {{ 'HoldAtFedExLocation'|translate }}
            </mat-checkbox>
            @if (shipInfoAddForm?.get('holdAtFedExLocation')?.value && pickupLocation) {
                <upsc-pickup-location-info [location]="pickupLocation"
                                           (editByLocationId)="openPickupLocationsDialog(carrier, $event)"></upsc-pickup-location-info>
            }
        }

        <!-- <mat-checkbox formControlName="codService" *ngIf="showCOD  || ">{{'CODServices'|translate}}</mat-checkbox> -->
        <mat-checkbox *ngIf="(showCOD) || (isUPS && config?.showCod)"
                      formControlName="codService">{{ 'CODServices'|translate }}
        </mat-checkbox>
        <div *ngIf="!!shipInfoAddForm?.get('codService')?.value"
             class="cod-options">
            <mat-form-field>
                <mat-label>{{ 'CODAmount'|translate }}</mat-label>
                <input formControlName="codAmount"
                       matInput
                       max="{{ maxCODValue }}"
                       min="0"
                       onfocus="this.select();"
                       type="number">
                <span matSuffix>USD</span>
                <mat-error *ngIf="shipInfoAddForm.controls.codAmount?.errors?.max">
                    <span>{{ 'CODAmountCannotExceed'|translate }} {{ maxCODValue }}.</span>
                </mat-error>
            </mat-form-field>
            <mat-checkbox formControlName="isSecuredCod">{{ 'SecuredCODOnly'|translate }}</mat-checkbox>
        </div>
    </div>
</div>

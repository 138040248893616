
@if (!isEditMode) {
    <div class="address-title-container">
        <div class="address-title">Ship To: </div>
        <div class="address-panel">
            <div class="address-content">
                <div class="address">
                    <span [innerHTML]="[address?.streetAddress, address?.apartmentSuite, address?.city, address?.state, address?.zip] | concatAddress"></span>
                </div>
                <div class="address-actions">
                    @if (!isReadonly && shouldAllowEditShipToAddress) {
                        <button mat-icon-button
                                class="address-action edit-button"
                                (click)="isEditMode = !isEditMode">
                            <span class="material-symbols-rounded">edit</span>
                        </button>
                    }
                </div>
            </div>
        </div>
    </div>
} @else {
    <form [formGroup]="formGroup"
          class="address-form"
          (ngSubmit)="onFormSubmit($event, formGroup.getRawValue())">
        <div class="form-fields">
            <mat-form-field>
                <mat-label>Street Address</mat-label>
                <input matInput
                       type="text"
                       formControlName="streetAddress"
                       placeholder="Street Address">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Apartment Suite</mat-label>
                <input matInput
                       type="text"
                       formControlName="apartmentSuite"
                       placeholder="Apartment Suite">
            </mat-form-field>
            <mat-form-field>
                <mat-label>City</mat-label>
                <input matInput
                       type="text"
                       formControlName="city"
                       placeholder="City">
            </mat-form-field>
            <mat-form-field>
                <mat-label>State</mat-label>
                <input matInput
                       type="text"
                       formControlName="state"
                       placeholder="State">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Zip</mat-label>
                <input matInput
                       type="text"
                       [required]="!formGroup?.get('phoneNumber')?.value"
                       formControlName="zip"
                       placeholder="Zip">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Country</mat-label>
                <input matInput
                       type="text"
                       formControlName="country"
                       placeholder="Country">
            </mat-form-field>

            <!-- Carriers current do not seem to support searching via a phone number alone -->
            <mat-form-field *ngIf="false">
                <mat-label>Phone</mat-label>
                <input matInput
                       type="text"
                       [required]="!formGroup?.get('zip')?.value"
                       formControlName="phoneNumber"
                       placeholder="Phone Number">
            </mat-form-field>
        </div>

        <div class="actions">
            <button mat-flat-button
                    class="action"
                    type="submit"
                    color="primary"
                    (click)="onAddressChanged($event)"
                    [disabled]="!formGroup.valid">
                <span class="material-symbols-rounded">search</span>
                <span>Search</span>
            </button>
        </div>
    </form>
}

<form [formGroup]="formGroup"
      class="address-form"
      (ngSubmit)="onFormSubmit($event, formGroup.getRawValue())">
    <div class="filters">
        <div class="filter range">
            <mat-label>Range</mat-label>
            <mat-slider aria-label="miles"
                        discrete
                        color="primary"
                        [max]="PickupLocationSearchConfig.searchRange.max"
                        [min]="PickupLocationSearchConfig.searchRange.min"
                        step="1">
                <input #range
                       matSliderThumb
                       formControlName="miles"/>
            </mat-slider>
            <span class="range-text">{{ range.value }} miles</span>
        </div>
        <div class="filter record-count">
            <mat-form-field [subscriptSizing]="'dynamic'">
                <mat-label>Max items</mat-label>
                <mat-select formControlName="noOfRecords">
                    @for (option of PickupLocationSearchConfig.maxSearchResults.options; track option) {
                        <mat-option [value]="option">{{ option }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>

import { computed, Injectable, signal } from '@angular/core';

@Injectable()
export class ShipmentStateService {
    public isShipToZipRestricted = signal<boolean>(false);

    public shipFromCountryCode = signal<string>(null);
    public shipToCountryCode = signal<string>(null);
    public isInternational = computed(() => {
        if (!this.shipFromCountryCode() || !this.shipToCountryCode()) {
            return false;
        }

        return this.shipFromCountryCode() !== this.shipToCountryCode();
    });
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { StorageService } from '../../../core/services/storage/storage.service';
import { FormService } from '../../../shared/services/form/form.service';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { Commodity } from '../../models/commodity.model';
import { Package } from '../../models/package.model';
import { IShipComponent } from '../../models/ship-component.interface';
import { ShipmentPurpose } from '../../models/shipment-purpose.enum';
import { ShipConfigService } from '../../services/ship-config.service';
import { ShipmentService } from '../../services/shipment.service';

@Component({
    selector: 'upsc-commodity-ship-info',
    templateUrl: './commodity-ship-info.component.html',
    styleUrls: ['./commodity-ship-info.component.scss'],
})
export class CommodityShipInfoComponent implements OnInit, OnChanges, IShipComponent {
    @Input() commodities: Commodity[];
    @Input() customsValueCurrency = 'USD';
    @Input() isRestoreShipment = false;
    @Input() cachedShipment: Package;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    public formGroup: UntypedFormGroup;
    public shipmentPurposes = [];
    public totalCustomsValue = 0;
    public packageContent = '';

    constructor(private validationService: ValidationService,
                private formService: FormService,
                private formBuild: UntypedFormBuilder,
                private translateService: TranslateService,
                private storageService: StorageService,
                private shipConfigService: ShipConfigService,
                private shipmentService: ShipmentService,
                private translate : TranslateService,
    ) {
        this.shipmentPurposes = _.orderBy(Object.keys(ShipmentPurpose).map(key => ShipmentPurpose[key]));
        this.translate.onLangChange.subscribe(() => {
            this.packageContent = this.translate.instant('DefaultPackageContent');
            this.formGroup = this.formBuild.group({
                packageContent: this.packageContent,
                shipmentPurpose: ['', Validators.compose([Validators.required])],
                totalCustomsValue: [0],
            });
        });
    }

    public ngOnInit() {
        // Setup a form group here.
        this.packageContent = this.translate.instant('DefaultPackageContent');
        this.formGroup = this.formBuild.group({
            packageContent: this.packageContent,
            shipmentPurpose: ['', Validators.compose([Validators.required])],
            totalCustomsValue: [0],
        });

        // Subscribe to form value changes.
        this.monitorValueChanges();

        // Then set the form values from cache.
        // This allows the values to persist after a refresh.
        // this.setFormValues();

        if (this.cachedShipment) {
            this.restoreShipment(this.cachedShipment);
        }
    }

    public resetForm() {
        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            packageContent: this.packageContent,
            shipmentPurpose: '',
            totalCustomsValue: 0,
        });

        if (this.isRestoreShipment && this.cachedShipment) {
            this.restoreShipment(this.cachedShipment);
        }
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onCommoditiesChanged(changes['commodities']);
    }

    /**
     * Gets the form errors.
     * This allows a parent form to get this child form errors.
     */
    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Commodity Ship Info');
    }

    /**
     * Checks whether the form is valid.
     * This allows a parent form to get the child form validity.
     */
    public isFormValid() {
        return this.formGroup.valid;
    }

    public restoreShipment(shipment: Package) {
        setTimeout(
            () => {
                this.formGroup.patchValue({
                    shipmentPurpose: shipment.ShipmentPurpose,
                    totalCustomsValue: parseInt(shipment.TotalCustomsValue || '0', 10),
                });

                this.formGroup.markAsDirty();
                this.formGroup.markAsTouched();

                const formValue = this.formGroup.value;
                this.shipmentService.saveCommodityShipInfo(formValue);
                this.isValid.emit(this.formGroup.valid);
                this.formValueChanged.emit(formValue);
            },
            1000);
    }

    private onCommoditiesChanged(change: SimpleChange) {
        if (!change || !change.currentValue || !this.formGroup) {
            return;
        }

        if (!this.commodities || !this.commodities.length) {
            this.validationService.clearFormControlValidators([
                this.formGroup.controls.totalCustomsValue,
            ]);

            this.validationService.setFormControlValidators(
                this.formGroup.controls.totalCustomsValue,
                Validators.compose([Validators.required]),
            );

            return;
        }

        const totalCustomsValue = this.getTotalCustomsValue();
        this.validationService.setFormControlValidators(
            this.formGroup.controls.totalCustomsValue,
            Validators.compose([Validators.required, , Validators.pattern('^[0-9]*$'), this.totalCustomsValueMatchedValidator(totalCustomsValue)]),
        );

        this.formGroup.controls.totalCustomsValue.markAsDirty();
        this.formGroup.controls.totalCustomsValue.markAsTouched();
    }

    /**
     * Subscribes to form value changes.
     * Other form control value change subscription may be setup here as well.
     */
    private monitorValueChanges() {
        this.formGroup.valueChanges
            .subscribe(
                (value) => {
                    // Save the form values to the Quote object.
                    this.shipmentService.saveCommodityShipInfo(value);

                    // Emit the form validity to its parent.
                    this.isValid.emit(this.formGroup.valid);

                    this.formValueChanged.emit(value);
                },
            );
    }

    /**
     * Restores form values from the cache.
     */
    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        // Restore the value from the Quote object to the form.
        this.formGroup.patchValue({
            shipmentPurpose: this.shipmentService.Quote.ShipmentPurpose,
            totalCustomsValue: this.shipmentService.Quote.TotalCustomsValue,
        });

        // Mark form as touched/dirty to trigger form validation.
        this.formService.markAllAsTouchedAndDirty(this.formGroup);

        // Emit the form validity to its parent.
        this.isValid.emit(this.formGroup.valid);
    }

    private totalCustomsValueMatchedValidator(expectedValue: number): ValidatorFn {
        return (control: AbstractControl) => {
            if (+control.value !== expectedValue) {
                return {
                    totalCustomsValueMatched: {
                        valid: false,
                    },
                };
            }

            return null;
        };
    }

    private getTotalCustomsValue(): number {
        if (!this.commodities || !this.commodities.length) {
            return 0;
        }

        this.totalCustomsValue = this.commodities.reduce(
            (sum, value) => {
                if (!value.HarmonizedCode) {
                    return sum;
                }

                return sum + +value.CustomsValue;
            },
            0);

        return this.totalCustomsValue;
    }
}

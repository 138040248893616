import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { Package } from '../models/package.model';
import { ShipmentService } from '../services/shipment.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { Router } from '@angular/router';
import { StorageService } from '../../core/services/storage/storage.service';
import { EmailShippingLabelComponent } from '../../drop-shipment/email-shipping-label/email-shipping-label.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ShipConfigService } from '../services/ship-config.service';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ShipDetailsComponent } from '../../shared/components/ship-details/ship-details.component';
import { SavedQuotesService } from '../../history/services/saved-quotes.service';
import MobileDetect from 'mobile-detect';
import { S3FileDownloaderComponent } from '../../shared/components/s3-file-downloader/s3-file-downloader.component';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { ShipmentStatus } from '../../shared/models/shipments/shipment-status.enum';
import { SiteConfig } from '../../../config/site-config';
import { BlobService } from '../../shared/services/file/blob.service';
import * as FileSaver from 'file-saver';
import { S3Service } from 'app/shared/services/s3/s3.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'upsc-confirmed-shipment-details',
    templateUrl: './confirmed-shipment-details.component.html',
    styleUrls: ['./confirmed-shipment-details.component.scss'],
})
export class ConfirmedShipmentDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('shipDetails', { static: true }) public shipDetails: ShipDetailsComponent;
    @ViewChild('s3FileDownloader') public s3FileDownloader: S3FileDownloaderComponent;

    public shipmentPackage: Package;
    public carrier = '';
    public isAESShipment = false;
    public isHighValueShipment = false;
    public isNAFTA = false;
    public isPendingShipment = false;
    public isMobileWebView = false;
    public isPrintPdfSupported = true;
    public isAllowShipmentEdit = false;
    public isPrintReturnLabel = true;
    public isReturnService = false;

    private storageName = 'shipment-details';
    private savedQuoteId: string;
    private deleteShipmentSubscription: Subscription;
    private getCommercialInvoiceSubscription: Subscription;
    private downloadFileSubscription: Subscription;

    constructor(private notificationService: NotificationService,
                private storageService: StorageService,
                private router: Router,
                public dialog: MatDialog,
                private dialogService: DialogService,
                private spinnerService: SpinnerService,
                private s3Service: S3Service,
                private blobService: BlobService,
                private utilityService: UtilityService,
                private errorHandlerService: ErrorHandlerService,
                private savedQuotesService: SavedQuotesService,
                private shipConfigService: ShipConfigService,
                private shipmentService: ShipmentService,
                private translate: TranslateService
            ) {
        const md = new MobileDetect(window.navigator.userAgent);
        this.isPrintPdfSupported = !md.mobile();

        this.isMobileWebView = !!sessionStorage.getItem('is-mobile-web-view') || false;

        // this.shipmentService.restoreQuote();
        this.shipmentPackage = this.getShipmentPackage();

        const isEditableShipmentStatus = this.shipmentPackage.Status === ShipmentStatus.BIRNew
            || this.shipmentPackage.Status === ShipmentStatus.LabelPrinted;
        this.isAllowShipmentEdit = SiteConfig.shouldAllowShipmentEdit && isEditableShipmentStatus;

        // Update address without reloading for use with the mobile web view.
        window.history.pushState({}, '', this.router.url);

        if (this.shipmentPackage) {
            this.isReturnService = this.shipmentPackage.IsReturnService;
            this.isPrintReturnLabel = (this.isReturnService && this.shipmentPackage.ReturnServiceCode !== '9') ? false : true;

            this.isAESShipment = this.shipmentPackage.EeiFilingOption === '1';
            this.isHighValueShipment = this.shipmentPackage.IsHighValueShipment;
            this.isNAFTA = this.shipConfigService.isNAFTA(this.shipmentPackage.ShipFrom.Country, this.shipmentPackage.ShipTo.Country);
            this.carrier = ['', 'UPS', 'FedEx'][this.shipmentPackage.CarrierCode.valueOf()];

            if (this.isHighValueShipment) {
                this.savedQuotesService.getSavedQuote(this.shipmentPackage.QuoteId)
                    .pipe(takeWhile(() => !this.savedQuoteId))
                    .subscribe(
                        (quote) => {
                            this.savedQuoteId = quote.QuoteId;
                            this.isPendingShipment = quote.Status === 1;
                        },
                    );
            }

            return;
        }

        this.notificationService.notify(
            'Sorry, we could not find the shipment data',
            'No Shipment Data',
            NotificationType.ERROR);
        console.warn('no shipment data');

        this.router.navigate(['/ship/ups']);
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
        this.shipmentService.clearDetailQuote();
        this.shipmentService.clearCache();
        this.storageService.remove(this.storageName);

        this.utilityService.clearSubscriptions([this.deleteShipmentSubscription, this.getCommercialInvoiceSubscription]);
    }

    public openEmailLabelDialog(event, shipmentId: string) {
        if (event) {
            event.preventDefault();
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '600px',
            data: {
                shipmentId,
            },
            maxWidth: '100%',
            panelClass: ['mobile-fullscreen-dialog'],
        };

        let dialogRef: MatDialogRef<EmailShippingLabelComponent>;
        dialogRef = this.dialog.open(EmailShippingLabelComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (result) => {
            });
    }

    public tryVoidShipment(event) {
        if (event) {
            event.preventDefault();
        }

        this.dialogService.confirm(this.translate.instant('VoidShipmentKey'), this.translate.instant('ConfirmationMessageKey'))
            .subscribe(
                (result) => {
                    if (!result) {
                        return;
                    }
    
                    this.voidShipment();
                },
            );
       
    }

    public printShipmentDetails(event) {
        if (event) {
            event.preventDefault();
        }

        this.shipDetails.printShipmentDetails();
    }

    public reprintCommercialInvoice() {
        this.utilityService.clearSubscriptions([this.getCommercialInvoiceSubscription]);
        this.getCommercialInvoiceSubscription = this.shipmentService.getCommercialInvoice(this.shipmentPackage.ShipmentId)
            .subscribe(
                base64 => this.handleGetCommercialInvoiceSuccess(base64),
                err => this.handleGetCommercialInvoiceFailure(err),
            );
        //throw new Error('Not Implemented');
    }

    public printNAFTAForm(carrier: string) {
        if (carrier === 'UPS' || carrier === 'FedEx') {
            this.downloadFileSubscription = this.s3Service.getFile('members-pdf.upsc', `USMCA-${ carrier }.pdf`)
                .subscribe(
                    base64 => {
                        const blob = this.blobService.base64PdfToBlob(base64);
                        FileSaver.saveAs(blob, `USMCA-${ carrier }.pdf`);
                    },
                    err => console.error(err),
                );
        }
    }

    public viewPendingShipments() {
        this.router.navigate(['/pending-shipments']);
    }

    public shouldShowButton(buttonName: string) {
        const isConfirmedHighValueShipment = this.isHighValueShipment && this.shipmentPackage.Status === ShipmentStatus.LabelPrinted;

        switch (buttonName) {
            case 'Reship':
                return (!this.isHighValueShipment && !this.isAESShipment) || isConfirmedHighValueShipment;
            case 'ReprintLabel':
                return this.isPrintPdfSupported
                    && !this.isMobileWebView
                    && !this.isAESShipment
                    && this.isPrintReturnLabel
                    && (!this.isHighValueShipment || isConfirmedHighValueShipment);
            case 'EmailLabel':
                return !this.isMobileWebView
                    && !this.isAESShipment
                    && this.isPrintReturnLabel
                    && (!this.isHighValueShipment || isConfirmedHighValueShipment);
            case 'DownloadLabel':
                return !this.isMobileWebView
                    && !this.isAESShipment
                    && this.isPrintReturnLabel
                    && (!this.isHighValueShipment || isConfirmedHighValueShipment);
            case 'PrintNAFTAForm':
                return this.isNAFTA
                    && this.shipmentPackage.IsNAFTACertificate;
            case 'PrintShipmentDetails':
                return !this.isHighValueShipment || isConfirmedHighValueShipment;
            case 'ViewPendingShipments':
                return this.isHighValueShipment
                    && this.shipmentPackage.Status === ShipmentStatus.BillingInfo;
            case 'VoidShipment':
                return !this.isHighValueShipment || isConfirmedHighValueShipment;
            case 'ReprintCommercialInvoice':
                return this.shipmentPackage.IsCommercialInvoice && this.shipmentPackage.Status !== 3 && this.isPrintReturnLabel; // this.showReprintCommercialInvoice(); - commented as there is an issue in API
            default:
                return false;
        }
    }

    public onShipmentVoiding(isVoiding: boolean) {
        if (isVoiding) {
            this.spinnerService.show();
            return;
        }

        this.spinnerService.hide();
    }

    private getShipmentPackage(): Package {
        let shipmentPackage = this.shipmentService.DetailsQuote;

        if (!shipmentPackage) {
            shipmentPackage = <Package>this.storageService.get(this.storageName);
        }

        return shipmentPackage;
    }

    private voidShipment() {
        this.utilityService.clearSubscriptions([this.deleteShipmentSubscription]);

        this.spinnerService.show();
        this.deleteShipmentSubscription = this.shipmentService.deleteShipment(this.shipmentPackage.ShipmentId)
            .subscribe(
                res => this.handleDeleteShipmentSuccess(res),
                err => this.handleDeleteShipmentFailure(err),
            );
    }

    private handleDeleteShipmentSuccess(res) {
        this.notificationService.notify(
            'Your shipment has been voided. ' +
            'This will also void any return label generated with it. ' +
            'Please note this has canceled insurance coverage.',
            'SUCCESS',
            NotificationType.SUCCESS);
        this.spinnerService.hide();
        this.router.navigate(['/shipment-history']);
    }

    private handleDeleteShipmentFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Deleting Shipment',
            NotificationType.ERROR);
        this.spinnerService.hide();
    }

    private handleGetCommercialInvoiceSuccess(base64: string) {
        const blob = this.blobService.base64PdfToBlob(base64);
        FileSaver.saveAs(blob, 'commercial-invoice.pdf');
    }

    private handleGetCommercialInvoiceFailure(error) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(error),
            'Failed Downloading Commercial Invoice',
            NotificationType.ERROR);
    }

    private showReprintCommercialInvoice(): boolean {
        return this.isPrintReturnLabel || (this.isReturnService && this.shipmentPackage.IsCommercialInvoice && (this.shipmentPackage.CommercialInvoiceType == '1'));
    }
}

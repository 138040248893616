import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RendererService {
    private renderer: Renderer2;

    public constructor(private readonly rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public setElementStyles(elementRef: ElementRef<HTMLElement>, styles: { [key: string]: string }): void {
        for (const [styleName, styleValue] of Object.entries(styles)) {
            this.renderer.setStyle(elementRef.nativeElement, styleName, styleValue);
        }
    }
}

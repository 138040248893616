import { Component, HostBinding, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Carriers } from '../../shared/enum/general-enum';
import { IShippingUpdateInformation } from './models/shipping-update-information.interface';

@Component({
    selector: 'upsc-get-shipping-updates-dialog',
    templateUrl: './get-shipping-updates-dialog.component.html',
    styleUrls: ['./get-shipping-updates-dialog.component.scss'],
})
export class GetShippingUpdatesDialogComponent {
    @HostBinding('class') public hostClass = 'get-shipping-updates-dialog';

    public formGroup: FormGroup;
    public carrier: Carriers;
    public shouldAllowMultipleRecipients = false;
    protected readonly Carriers = Carriers;

    public constructor(public dialogRef: MatDialogRef<GetShippingUpdatesDialogComponent>,
                       @Inject(MAT_DIALOG_DATA) public data: IShippingUpdateInformation,
                       private readonly formBuilder: FormBuilder,
    ) {
        this.carrier = this.data.carrier;
        this.setupForm();
        this.restoreData(this.data);
    }

    public get recipientFormArray(): FormArray {
        return this.formGroup.get('recipients') as FormArray;
    }

    public addRecipient(event: MouseEvent = null): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.recipientFormArray.push(this.getNewPersonFormGroup());
    }

    public removeRecipient(event: MouseEvent, index: number): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (!(index > -1 && index < this.recipientFormArray.length)) { return; }
        this.recipientFormArray.removeAt(index);
    }

    public closeDialog(event: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.dialogRef.close(this.formGroup.value);
    }

    public submitForm() {
        this.dialogRef.close(this.formGroup.value);
    }

    private setupForm(): void {
        this.formGroup = this.formBuilder.group({
            sender: this.formBuilder.group({
                firstName: [''],
                lastName: [''],
                email: [''],
            }),
            recipients: this.formBuilder.array([]),
            carrier: [null],
            shouldSendToSender: [true, RxwebValidators.requiredTrue()],
        });
    }

    private getNewPersonFormGroup(): FormGroup {
        return this.formBuilder.group({
            firstName: ['', RxwebValidators.required()],
            lastName: ['', RxwebValidators.required()],
            email: ['', [RxwebValidators.required(), RxwebValidators.email()]],
        });
    }

    private restoreData(data: IShippingUpdateInformation): void {
        data.recipients.forEach(() => this.addRecipient());
        this.formGroup.setValue(data);

        if (data.recipients.length === 0) {
            // Add a blank recipient form if no recipient is set.
            this.recipientFormArray.push(this.getNewPersonFormGroup());
        } else {
            // For each recipient, disable a field with a value.
            for (const recipientFormGroup of this.recipientFormArray.controls) {
                const keys = Object.keys((recipientFormGroup as FormGroup).controls);
                for (const key of keys) {
                    const control = recipientFormGroup.get(key);
                    if (control?.value) {
                        // NOTE: Disabling a control make it disappear from formGroup.value.
                        //  You'll need formGroup.getRawValue() in order to get the value from it.
                        // control.disable();
                        control['_isReadonly'] = true;
                    }
                }
            }
        }
    }
}

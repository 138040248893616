import { Component, DoCheck, Input } from '@angular/core';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { Commodity } from '../../models/commodity.model';
import { IShipComponent } from '../../models/ship-component.interface';

@Component({
  selector: 'upsc-commodity-table',
  templateUrl: './commodity-table.component.html',
  styleUrls: ['./commodity-table.component.scss'],
})
export class CommodityTableComponent extends DataTableComponent<Commodity> implements IShipComponent {
  @Input() weightUnit: string;
  @Input() customsValueCurrency: string;

  public resetForm() {
    this.items.forEach(
      (item) => {
        this.rowActionClicked.emit({
          action: 'forceRemove',
          data: item,
        });
      },
    );
  }

  public forceValidation() {
  }

  public editCommodity(event, commodity: Commodity) {
    event.preventDefault();

    this.rowActionClicked.emit({
      action: 'edit',
      data: commodity,
    });
  }

  public removeCommodity(event, commodity: Commodity) {
    event.preventDefault();

    this.rowActionClicked.emit({
      action: 'remove',
      data: commodity,
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/shared/services/user/models/user.model';
import { AppState } from '../app.state';
import { Carriers } from '../shared/enum/general-enum';
import { IContact } from '../shared/models/contact.interface';
import { DialogService } from '../shared/services/dialog/dialog.service';
import { UserService } from '../shared/services/user/user.service';
import { AddressBookSearchDialogComponent } from './address-book-dialog/address-book-search-dialog.component';

@Component({
    selector: 'upsc-address-book-search',
    templateUrl: './address-book-search.component.html',
    styleUrls: ['./address-book-search.component.scss'],
})
export class AddressBookSearchComponent {
    @Input() carrier: Carriers;
    @Input() dialogTitle = 'Address Book';
    @Input() pageIndex = 1;
    @Input() pageSize = 25;
    @Input() availableCountryCodes: string[];
    @Input() forbiddenCountryCodes: string[];
    @Input() currentUserIsAdmin: boolean;
    @Output() contactSelected: EventEmitter<IContact> = new EventEmitter<IContact>();

    constructor(private dialog: MatDialog,
                private dialogService: DialogService,
                private translateService: TranslateService,
                private readonly appState: AppState,
                private userService: UserService,
                private user: User
    ) {
    }

    public openAddressBookSearchDialog(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '80%',
            data: {
                carrier: this.carrier,
                dialogTitle: this.dialogTitle,
                searchPage: this.pageIndex,
                itemsPerPage: this.pageSize,
                availableCountryCodes: this.availableCountryCodes,
                forbiddenCountryCodes: this.forbiddenCountryCodes,
            },
            maxWidth: '100%',
            panelClass: ['mobile-fullscreen-dialog'],
        };

        const dialogRef: MatDialogRef<AddressBookSearchDialogComponent> = this.dialog.open(AddressBookSearchDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (contact) => {
                if (!contact) {
                    return;
                }

                this.contactSelected.emit(contact);
            },
        );
        
    }
    public isLangFrench(): boolean {
        return this.translateService.currentLang === 'fr';
    }
}

<div class="confirmed-shipment-details">
    <ng-container [ngTemplateOutlet]="header"></ng-container>

    <div class="shipment-details-content">
        <upsc-ship-details #shipDetails
                           [isMobileWebView]="isMobileWebView"
                           [shouldPrintLabel]="isPrintReturnLabel"
                           [shipmentPackage]="shipmentPackage"
                           [isPendingShipment]="isPendingShipment"
                           [shipmentType]="'web'"
                           (shipmentVoiding)="onShipmentVoiding($event)"></upsc-ship-details>
    </div>
</div>

<ng-template #header>
    <div class="ups-page-header">
        <h1 class="page-title">{{'ShipmentDetails'|translate}}</h1>
        <upsc-ship-details-actions class=""
                                   [carrier]="carrier"
                                   [shipment]="shipmentPackage"
                                   [showReship]="shouldShowButton('Reship') && !isReturnService"
                                   [showReprintLabel]="shouldShowButton('ReprintLabel')"
                                   [showEmailLabel]="shouldShowButton('EmailLabel')"
                                   [showDownloadLabel]="shouldShowButton('DownloadLabel')"
                                   [showReprintCommercialInvoice]="shouldShowButton('ReprintCommercialInvoice')"
                                   [showPrintNAFTAForm]="shouldShowButton('PrintNAFTAForm')"
                                   [showPrintShipmentDetails]="shouldShowButton('PrintShipmentDetails')"
                                   [showViewPendingShipments]="shouldShowButton('ViewPendingShipments')"
                                   [showVoidShipment]="shouldShowButton('VoidShipment')"
                                   [isAllowShipmentEdit]="isAllowShipmentEdit && !isReturnService"
                                   (reshipClicked)="shipDetails.reship()"
                                   (reprintLabelClicked)="shipDetails.printLabel()"
                                   (emailLabelClicked)="openEmailLabelDialog(null, shipmentPackage?.ShipmentId)"
                                   (downloadLabelClicked)="shipDetails.downloadLabel()"
                                   (reprintCommercialInvoiceClicked)="reprintCommercialInvoice()"
                                   (printNAFTAFormClicked)="printNAFTAForm($event)"
                                   (printShipmentDetailsClicked)="printShipmentDetails()"
                                   (viewPendingShipmentClicked)="viewPendingShipments()"
                                   (voidShipmentClicked)="tryVoidShipment()"
                                   (editShipmentClicked)="shipDetails.showEditConfirmationDialog()"></upsc-ship-details-actions>
    </div>
    <div class="page-actions hide-me">
        <upsc-s3-file-downloader *ngIf="isNAFTA && shipmentPackage.IsNAFTACertificate"
                                 class="page-action"
                                 #s3FileDownloader
                                 [bucketName]="'members-pdf.upsc'"
                                 [fileName]="'CBP_Form_434_3.pdf'"
                                 [buttonText]="'DownloadNAFTAForm'"></upsc-s3-file-downloader>

        <button type="button"
                *ngIf="!isMobileWebView && !isAESShipment && !isHighValueShipment"
                mat-raised-button
                (click)="openEmailLabelDialog($event, shipmentPackage?.ShipmentId)"
                class="page-action">
            {{'EmailLabel'|translate}}
        </button>
        <button type="button"
                *ngIf="isPrintPdfSupported && !isMobileWebView && !isAESShipment && !isHighValueShipment"
                mat-raised-button
                (click)="shipDetails.printLabel($event)"
                class="page-action">
            {{'ReprintLabel'|translate}}
        </button>
        <button mat-raised-button
                type="button"
                *ngIf="!isPrintPdfSupported && !isMobileWebView && !isAESShipment && !isHighValueShipment"
                (click)="shipDetails.downloadLabel($event)">{{'DownloadLabel'|translate}}
        </button>
        <button type="button"
                mat-raised-button
                class="page-action alternative-button"
                (click)="printShipmentDetails($event)">
            {{ 'PrintShipmentDetails' | translate }}
        </button>
        <button type="button"
                *ngIf="isHighValueShipment && shipmentPackage?.Status === 0"
                mat-raised-button
                routerLink="/pending-shipments"
                class="page-action">
            {{'ViewPendingShipments'|translate}}
        </button>
        <button type="button"
                *ngIf="!isHighValueShipment"
                mat-raised-button
                (click)="tryVoidShipment($event)"
                class="page-action secondary-button">
            {{'Void'|translate}}
        </button>
    </div>
</ng-template>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';

import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Carriers, UPSPickUpType } from '../../shared/enum/general-enum';
import { Location } from '../../shared/models/location/location.model';
import { FormService } from '../../shared/services/form/form.service';
import { User } from '../../shared/services/user/models/user.model';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { LocationSearchDialogComponent } from '../location-search-dialog/location-search-dialog.component';
import { Package } from '../models/package.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipConfig } from '../models/ship-config.model';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipmentStateService } from '../services/shipment-state.service';

import { ShipmentService } from '../services/shipment.service';

@Component({
    selector: 'upsc-ship-options',
    templateUrl: './ship-options.component.html',
    styleUrls: ['./ship-options.component.scss'],
})
export class ShipOptionsComponent implements OnInit, OnChanges, IShipComponent {
    @Input() dateFormat = 'MM/DD/YYYY';
    @Input() user: User;
    @Input() carrier: Carriers;
    @Input() isExistingSchedulePickup = false;
    @Input() shipDate: Date;
    @Input() shipFromAddress: Location;
    @Input() isRestoreShipment = false;
    @Input() isReship = false;
    @Input() returnShipment = false;
    @Input() returnOption = '0';
    @Output() pickupTypeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() schedulePickupDateChanged: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    public shipOptionsForm: UntypedFormGroup;
    public radioSchedulePickUpStatus = false;
    public isShowSchedulePickup = false;
    public isPrintReturnLabel = false;
    public returnDates = [];
    public isUps = false;
    public isFedEx = false;
    public config: ShipConfig;
    public shouldShowSection = false;
    public shouldShowSmartPickup = false;
    public formattedPickUpDate = moment().format('MM/DD/YYYY');
    public showThermalPrinter = true;
    public ShowSmartPickup = true;
    public pickupAtHours = [
        { hour: '08', period: 'AM' },
        { hour: '09', period: 'AM' },
        { hour: '10', period: 'AM' },
        { hour: '11', period: 'AM' },
        { hour: '12', period: 'PM' },
        { hour: '01', period: 'PM' },
        { hour: '02', period: 'PM' },
        { hour: '03', period: 'PM' },
        { hour: '04', period: 'PM' },
        { hour: '05', period: 'PM' },
        { hour: '06', period: 'PM' },
    ];
    public pickupByHours = [
        { hour: '01', period: 'PM' },
        { hour: '02', period: 'PM' },
        { hour: '03', period: 'PM' },
        { hour: '04', period: 'PM' },
        { hour: '05', period: 'PM' },
        { hour: '06', period: 'PM' },
        { hour: '07', period: 'PM' },
        { hour: '08', period: 'PM' },
    ];
    public pickupMinutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

    private isDefaultSmartPickupTimesSet = false;
    private shouldRestoreUPSPickupType = false;

    public constructor(private formBuild: UntypedFormBuilder,
                       private validationService: ValidationService,
                       private formService: FormService,
                       private utilityService: UtilityService,
                       private dialog: MatDialog,
                       private route: ActivatedRoute,
                       private shipConfigService: ShipConfigService,
                       private shipmentService: ShipmentService,
                       public readonly shipState: ShipmentStateService,
    ) {
        this.loadInitialValues();
    }

    public ngOnInit() {
        this.shipOptionsForm = this.formBuild.group({
            contactName: ['', Validators.compose([Validators.maxLength(22)])],
            phoneNumber: [''],
            pickupType: ['01', Validators.compose([Validators.required, Validators.maxLength(15)])],
            thermalPrinter: [false],
            returnLabel: [false],
            scheduledReturnDate: [new Date()],
            pickUpDate: [{
                value: moment().toDate(),
                disabled: false,
            }],

            pickupAtHour: ['08'],
            pickupAtMinute: ['00'],
            pickupByHour: ['01'],
            pickupByMinute: ['00'],
        });

        this.monitorValueChanges();

        // [MV3-2566] This marks the form as dirty which prevents some values from being set.
        // this.setFormValues();

        if (!this.isRestoreShipment) {
            this.setValuesFromConfig();
        }

        this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;

                if (!this.isRestoreShipment) {
                    this.setValuesFromConfig();
                }

                this.toggleSectionVisibility();

                this.setSmartPickupVisibility();
            },
        );
    }

    public resetForm() {
        if (!this.shipOptionsForm) {
            return;
        }

        this.shipOptionsForm.reset({
            contactName: '',
            phoneNumber: '',
            pickupType: '01',
            thermalPrinter: false,
            returnLabel: false,
            scheduledReturnDate: new Date(),
            pickUpDate: {
                value: moment().toDate(),
                disabled: false,
            },

            pickupAtHour: '08',
            pickupAtMinute: '00',
            pickupByHour: '01',
            pickupByMinute: '00',
        });
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.shipOptionsForm, false);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onCarrierChanged(changes['carrier']);
        this.onUserChanged(changes['user']);
        this.onShipDateChanged(changes['shipDate']);
        this.onDateFormatChanged(changes['dateFormat']);
        this.onShipFromAddressChanged(changes['shipFromAddress']);
        this.onReturnOptionChanged(changes['returnOption']);
    }

    public openLocationSearchDialog(event) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            data: {
                carrier: this.carrier,
            },
        };

        const dialogRef: MatDialogRef<LocationSearchDialogComponent> = this.dialog.open(LocationSearchDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (location) => {
                if (!location) {
                    return;
                }

                // console.log(location);
            },
        );
    }

    /**
     * The min date should be today.
     * If today is Sunday, the min date should be moved to the next day.
     *
     * @param {string} format
     * @returns {string | Date}
     */
    public getMinDate(format?: string) {
        let minDate = moment();
        if (minDate.day() === 0) {
            minDate = moment().add(1, 'days');
        }

        return format ? minDate.format(format) : minDate.toDate();
    }

    /**
     * The max date should be the next 5 days from the min date.
     * If any of the day between min and max dates fall into a Sunday,
     * move the max date one day beyond the original max date.
     *
     * @param {string} format
     * @returns {string | Date}
     */
    public getMaxDate(format?: string) {
        let minDate = moment();
        if (minDate.day() === 0) {
            minDate = moment().add(1, 'days');
        }

        if (this.shouldShowSmartPickup) {
            return format ? minDate.format(format) : minDate.toDate();
        }

        let maxDate = minDate;
        let dayCount = 5;
        while (dayCount > 0) {
            maxDate = maxDate.add(1, 'days');
            if (maxDate.day() === 0) {
                continue;
            }

            dayCount = dayCount - 1;
        }

        return format ? maxDate.format(format) : maxDate.toDate();
    }

    public filterDates(date?: Date) {
        return moment(date).day() !== 0;
    }

    public isMatDatepickerValid(dateControl: AbstractControl): boolean {
        if (!dateControl || !dateControl.errors) {
            return true;
        }

        return dateControl.errors.required && dateControl.errors.matDatepickerMin && dateControl.errors.matDatepickerMax;
    }

    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.shipOptionsForm.controls, 'Ship Options');
    }

    public isFormValid() {
        return this.shipOptionsForm.valid;
    }

    public isDailyPickUp() {
        if (!this.config) {
            return false;
        }

        return +this.config.pickupUPSType === UPSPickUpType.DailyPickUp;
    }

    public isSmartPickUp() {
        if (!this.config) {
            return false;
        }

        return +this.config.pickupUPSType === UPSPickUpType.SmartPickUp;
    }

    public isUPS() {
        if (!this.carrier) {
            return false;
        }

        return +Carriers[this.carrier] === Carriers.Ups;
    }

    public getPickupAtHourPeriod(): string {
        if (!this.shipOptionsForm) {
            return 'AM';
        }

        const hourItem = this.pickupAtHours.find(item => item.hour === this.shipOptionsForm.controls.pickupAtHour.value);
        return hourItem ? hourItem.period || 'AM' : 'AM';
    }

    public getPickupByHourPeriod(): string {
        if (!this.shipOptionsForm) {
            return 'PM';
        }

        const hourItem = this.pickupByHours.find(item => item.hour === this.shipOptionsForm.controls.pickupByHour.value);
        return hourItem ? hourItem.period || 'PM' : 'PM';
    }

    public toggleSectionVisibility() {
        if (!this.config) {
            return;
        }

        this.shouldShowSection = this.config.showDropOffUPS
            || this.config.showDropOffFedEx
            || this.config.showUseAlreadyScheduledPickUp
            || this.config.showSchedulePickUp
            || this.config.showReturnLabel;
    }

    public setPickupType(pickupType: string) {
        this.shipOptionsForm.controls.pickupType.setValue(pickupType, { emitEvent: false });
    }

    public fillCostEstimateValues(cachedCostEstimate: any) {
        let pickupType = '02';

        switch (cachedCostEstimate.PickupOption) {
            case '1': // SchedulePickup
                pickupType = '03';
                break;
            case '2': // ShippingDropOff
                pickupType = '02';
                break;
            case '3': // MyPickupLocation
                pickupType = '01';
                break;
            default:
                pickupType = this.config && this.config.showDropOffFedEx ? '02' : this.getPickupType();
                break;
        }

        this.shipOptionsForm.patchValue({
            pickupType,
        });

        this.shipOptionsForm.controls.pickupType.markAsDirty();
        this.shipOptionsForm.controls.pickupType.markAsTouched();
    }

    public restoreShipment(shipment: Package) {
        if (!shipment.PickUpDate || shipment.PickUpDate === '0001-01-01T00:00:00') {
            shipment.PickUpDate = moment().toDate();
        }

        // [MV3-2645] Always set the pickup type to IsDropoff for FedEx
        // and Use Already Scheduled Pickup for UPS when restoring shipment data.
        let pickupType = this.isFedEx ? '02' : '01';

        if (!this.isFedEx) {
            if (!this.isUps || (this.isUps && this.shouldRestoreUPSPickupType)) {
                switch (true) {
                    case shipment.IsRegularPickUp: // use already scheduled pickup
                        pickupType = '01';
                        break;
                    case shipment.IsDropoff:
                        pickupType = '02';
                        break;
                    case shipment.IsPickUpRequested: // schedule pickup or smart pickup.
                        pickupType = '03';
                        break;
                    default:
                        break;
                }
            }
        }

        // [MV3-2719] if is reship && is UPS && the pickup type of default location is a smart pickup, always set pickupType to '03'.
        if (this.isReship && this.isUPS() && shipment.UPSPickUpType === UPSPickUpType.SmartPickUp) {
            pickupType = '03';
        }

        this.shipOptionsForm.patchValue({
            pickupType,
            contactName: shipment.PickUpContactName,
            phoneNumber: shipment.PickUpTelephone,
            thermalPrinter: shipment.IsThermal,
            returnLabel: shipment.ReturnLabel,
            scheduledReturnDate: moment(shipment.ReturnLabelScheduleDate).format('YYYY-MM-DD'),
            pickUpDate: moment(shipment.PickUpDate).format('YYYY-MM-DD'),
            pickupAtHour: shipment.PickUpAtHour ? this.get12HourValue(shipment.PickUpAtHour) : '08',
            pickupAtMinute: shipment.PickUpAtMinute ? this.get12HourValue(shipment.PickUpAtMinute) : '00',
            pickupByHour: shipment.PickUpByHour ? this.get12HourValue(shipment.PickUpByHour) : '01',
            pickupByMinute: shipment.PickUpByMinute ? this.get12HourValue(shipment.PickUpByMinute) : '00',
        });

        // [MV3-2746] Set Return Label to false upon reship.
        if (this.isReship) {
            this.shipOptionsForm.patchValue({
                returnLabel: false,
                scheduledReturnDate: '',
            });
        }

        this.shipOptionsForm.markAsDirty();
        this.shipOptionsForm.markAsTouched();

        const formValue = this.shipOptionsForm.value;
        this.shipmentService.saveShipmentOptions(formValue);
        this.formValueChanged.emit(formValue);
    }

    public toggleSchedulePickUp(event) {
        if (this.returnShipment) {
            event.preventDefault();
            this.radioSchedulePickUpStatus = !this.radioSchedulePickUpStatus;
            if (!this.radioSchedulePickUpStatus) {
                this.shipOptionsForm.controls.pickupType.reset();
                this.validationService.clearFormControlValidators([this.shipOptionsForm.controls.pickupType]);
            } else {
                this.shipOptionsForm.controls.pickupType.setValue('03');
            }
        }
    }

    private loadInitialValues() {
        this.generateReturnDates();
    }

    private generateReturnDates(startDate?: Date) {
        const firstDate = startDate ? startDate : moment().toDate();

        let nextDate = moment(firstDate).add(7, 'days');
        const endDate = moment(firstDate).add(14, 'days');

        this.returnDates = [];
        while (!nextDate.isAfter(endDate)) {
            if (nextDate.day() === 0) {
                nextDate = nextDate.add(1, 'days');
                continue;
            }

            this.returnDates.push({
                value: nextDate.format('YYYY-MM-DD'),
                text: nextDate.format(`${ this.dateFormat } dddd`),
            });

            nextDate = nextDate.add(1, 'days');
        }

        if (this.shipOptionsForm && this.returnDates.length) {
            this.shipOptionsForm.controls.scheduledReturnDate.setValue(this.returnDates[0].value);
        }
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.isUps = this.shipConfigService.isUps(change.currentValue);
        this.isFedEx = this.shipConfigService.isFedEx(change.currentValue);
    }

    private onUserChanged(change: SimpleChange) {
        if (!change || !change.currentValue || !this.shipOptionsForm) {
            return;
        }

        this.shipOptionsForm.patchValue({
            thermalPrinter: this.user.AlwaysUseThermal,
        });
    }

    private onShipDateChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        if (this.shipOptionsForm) {
            this.shipOptionsForm.controls.pickUpDate.setValue(change.currentValue);
        }
    }

    private onDateFormatChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.generateReturnDates();
    }

    private onShipFromAddressChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.setSmartPickupVisibility();
        this.setDefaultSmartPickupTimes();
    }

    private onReturnOptionChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        if (this.returnShipment) {
            if (this.returnOption === '9') {
                this.showThermalPrinter = true;
            } else {
                this.showThermalPrinter = false;
            }
        } else {

        }
    }

    private monitorValueChanges() {
        this.shipOptionsForm.valueChanges.subscribe((form) => {
            this.isValid.emit(this.shipOptionsForm.valid);

            const isPickupRequested = form.pickupType === '03';
            if (isPickupRequested) {
                const pickupAtHourItem = this.pickupAtHours.find(item => item.hour === form.pickupAtHour);
                if (pickupAtHourItem && pickupAtHourItem.period === 'PM' && +form.pickupAtHour !== 12) {
                    form.pickupAtHour = (+form.pickupAtHour + 12) + '';
                }

                const pickupByHourItem = this.pickupByHours.find(item => item.hour === form.pickupByHour);
                if (pickupByHourItem && pickupByHourItem.period === 'PM' && +form.pickupByHour !== 12) {
                    form.pickupByHour = (+form.pickupByHour + 12) + '';
                }
            }

            this.shipmentService.saveShipmentOptions(form);
            this.formValueChanged.emit(form);
        });

        this.shipOptionsForm.controls.pickupType.valueChanges
            .subscribe(
                (value) => {
                    this.pickupTypeChanged.emit(value);
                    this.shipmentService.mapPickUpType(this.shipOptionsForm.controls.pickupType.value);

                    if (value === '03') {
                        this.shipOptionsForm.patchValue({
                            contactName: `${ this.shipmentService.Quote.ShipFrom.FirstName } ${ this.shipmentService.Quote.ShipFrom.LastName }`,
                            phoneNumber: this.shipmentService.Quote.ShipFrom.TelephoneNo,
                        });

                        this.setSchedulePickupValidators();
                        this.isShowSchedulePickup = true;

                        this.schedulePickupDateChanged.emit(this.shipOptionsForm.controls.pickUpDate.value);
                    } else {
                        this.validationService.clearFormControlValidators([
                            this.shipOptionsForm.controls.pickUpDate,
                            this.shipOptionsForm.controls.pickupAtHour,
                            this.shipOptionsForm.controls.pickupAtMinute,
                            this.shipOptionsForm.controls.pickupByHour,
                            this.shipOptionsForm.controls.pickupByMinute,
                            this.shipOptionsForm.controls.contactName,
                            this.shipOptionsForm.controls.phoneNumber,
                        ]);

                        this.isShowSchedulePickup = false;
                    }
                },
            );

        this.shipOptionsForm.controls.thermalPrinter.valueChanges
            .subscribe(
                (value) => {
                    this.shipConfigService.Config.isThermalLabel = value;
                },
            );

        this.shipOptionsForm.controls.returnLabel.valueChanges
            .subscribe(
                (value) => {
                    this.isPrintReturnLabel = value;
                },
            );

        // TODO: seems like monitoring valueChanges on a date is not a good idea since it seems like it takes milliseconds into account.
        let pickupDate = moment().format('YYYYMMDD');
        this.shipOptionsForm.controls.pickUpDate.valueChanges
            .subscribe(
                (value) => {
                    const newValue = moment(value).format('YYYYMMDD');
                    this.formattedPickUpDate = moment(value).format(this.dateFormat);

                    if (pickupDate === newValue) {
                        return;
                    }

                    if (this.shipOptionsForm.controls.pickupType.value === '03') {
                        pickupDate = newValue;
                        this.schedulePickupDateChanged.emit(value);
                    }

                    this.generateReturnDates(value);
                },
            );

        this.shipOptionsForm.controls.pickupAtHour.valueChanges
            .subscribe(
                (value) => {
                    this.shipOptionsForm.controls.pickupAtMinute.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.shipOptionsForm.controls.pickupByHour.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.shipOptionsForm.controls.pickupByMinute.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                },
            );

        this.shipOptionsForm.controls.pickupAtMinute.valueChanges
            .subscribe(
                (value) => {
                    this.shipOptionsForm.controls.pickupAtHour.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.shipOptionsForm.controls.pickupByHour.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.shipOptionsForm.controls.pickupByMinute.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                },
            );

        this.shipOptionsForm.controls.pickupByHour.valueChanges
            .subscribe(
                (value) => {
                    this.shipOptionsForm.controls.pickupAtHour.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.shipOptionsForm.controls.pickupAtMinute.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.shipOptionsForm.controls.pickupByMinute.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                },
            );

        this.shipOptionsForm.controls.pickupByMinute.valueChanges
            .subscribe(
                (value) => {
                    this.shipOptionsForm.controls.pickupAtHour.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.shipOptionsForm.controls.pickupAtMinute.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.shipOptionsForm.controls.pickupByHour.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                },
            );
    }

    private setValuesFromConfig() {
        if (!this.config) {
            return;
        }

        this.shouldShowSmartPickup = this.config.pickupUPSType === UPSPickUpType.SmartPickUp;
        this.shipOptionsForm.controls.thermalPrinter.setValue(this.config.isThermalLabel, { emitEvent: true });

        if (!this.shipOptionsForm.controls.pickupType.dirty && !this.shipOptionsForm.controls.pickupType.touched) {

            this.shipOptionsForm.controls.pickupType.setValue(
                this.config.showDropOffFedEx ? '02' : this.getPickupType(),
                { emitEvent: false },
            );

            this.shipmentService.mapPickUpType(this.shipOptionsForm.controls.pickupType.value);

            if (this.shouldShowSmartPickup) {
                this.shipOptionsForm.patchValue({
                    pickupType: '03',
                });

                if (!this.isDefaultSmartPickupTimesSet) {
                    this.setDefaultSmartPickupTimes();
                }
            }
        }
    }

    private setDefaultSmartPickupTimes() {
        if (!this.config || !this.shipFromAddress || !this.shipOptionsForm) {
            return;
        }

        if (!this.isSmartPickUp()) {
            return;
        }

        const pickupAtHour = moment().startOf('day')
                                     .hour(this.utilityService.get12HourTime(+this.shipFromAddress.UPSNotifyPickUpHour))
                                     .format('HH');
        const pickupByHour = moment().startOf('day')
                                     .hour(this.utilityService.get12HourTime(+this.shipFromAddress.UPSLatestPickUpHour))
                                     .format('HH');

        const isPickupAtHourValid = !!this.pickupAtHours.find(item => item.hour === pickupAtHour);
        const isPickupByHourValid = !!this.pickupByHours.find(item => item.hour === pickupByHour);

        this.shipOptionsForm.patchValue({
            pickupAtHour: isPickupAtHourValid ? pickupAtHour : '05',
            pickupAtMinute: this.shipFromAddress.UPSNotifyPickUpMinute || '00',
            pickupByHour: isPickupByHourValid ? pickupByHour : '08',
            pickupByMinute: this.shipFromAddress.UPSLatestPickUpMinute || '00',
        });

        this.isDefaultSmartPickupTimesSet = true;
    }

    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        this.shipOptionsForm.patchValue({
            contactName: this.shipmentService.Quote.PickUpContactName,
            phoneNumber: this.shipmentService.Quote.PickUpTelephone,
            pickupType: this.getPickupType(),
            thermalPrinter: this.shipmentService.Quote.IsThermal,
            returnLabel: this.shipmentService.Quote.ReturnLabel,
            pickUpDate: moment(this.shipmentService.Quote.PickUpDate).toDate(),
            pickupAtHour: this.shipmentService.Quote.PickUpAtHour || '08',
            pickupAtMinute: this.shipmentService.Quote.PickUpAtMinute || '00',
            pickupByHour: this.shipmentService.Quote.PickUpByHour || '01',
            pickupByMinute: this.shipmentService.Quote.PickUpByMinute || '00',
        });

        this.formService.markAllAsTouchedAndDirty(this.shipOptionsForm);
        this.isValid.emit(this.shipOptionsForm.valid);
    }

    private getPickupType(): string {
        if (this.shipmentService.Quote.IsRegularPickUp) {
            return '01';
        }

        if (this.shipmentService.Quote.IsDropoff) {
            return '02';
        }

        if (this.shipmentService.Quote.IsPickUpRequested) {
            return '03';
        }

        return '01';
    }

    private setSchedulePickupValidators() {
        this.validationService.setFormControlValidators(
            this.shipOptionsForm.controls.pickUpDate,
            Validators.compose([Validators.required, Validators.maxLength(15)]),
        );

        this.validationService.setValidatorFormControls(
            Validators.compose([Validators.required, this.pickupTimeValidator()]),
            [
                this.shipOptionsForm.controls.pickupAtHour,
                this.shipOptionsForm.controls.pickupAtMinute,
                this.shipOptionsForm.controls.pickupByHour,
                this.shipOptionsForm.controls.pickupByMinute,
            ],
        );

        this.validationService.setFormControlValidators(
            this.shipOptionsForm.controls.contactName,
            Validators.compose([Validators.required, Validators.maxLength(35)]),
        );
        this.validationService.setFormControlValidators(
            this.shipOptionsForm.controls.phoneNumber,
            Validators.compose([Validators.required, Validators.maxLength(20)]),
        );
    }

    private isPickupTimeValid(): boolean {
        let pickupAtHour = this.shipOptionsForm.controls.pickupAtHour.value;
        const pickupAtItem = this.pickupAtHours.find(item => item.hour === pickupAtHour);
        if (pickupAtItem && pickupAtItem.hour !== '12' && pickupAtItem.period === 'PM') {
            pickupAtHour = +pickupAtHour + 12;
        }

        let pickupByHour = this.shipOptionsForm.controls.pickupByHour.value;
        const pickupByItem = this.pickupByHours.find(item => item.hour === pickupByHour);
        if (pickupByItem && pickupByItem.hour !== '12' && pickupByItem.period === 'PM') {
            pickupByHour = +pickupByHour + 12;
        }

        const pickupAt = moment(this.shipOptionsForm.controls.pickUpDate.value)
            .hour(pickupAtHour)
            .minute(+this.shipOptionsForm.controls.pickupAtMinute.value);

        const expectedPickupBy = moment(pickupAt).add(2, 'hours');

        const pickupBy = moment(this.shipOptionsForm.controls.pickUpDate.value)
            .hour(pickupByHour)
            .minute(+this.shipOptionsForm.controls.pickupByMinute.value);

        return pickupBy.isSameOrAfter(expectedPickupBy, 'minute');
    }

    private pickupTimeValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (!this.isPickupTimeValid()) {
                return {
                    pickupTime: {
                        valid: false,
                    },
                };
            }

            return null;
        };
    }

    private setSmartPickupVisibility() {
        if (!this.shipFromAddress || !this.config) {
            return;
        }

        // [MV3-2370] Remove a condition for showing smart pickup.
        this.shouldShowSmartPickup = this.config.pickupUPSType === UPSPickUpType.SmartPickUp
            // && this.shipFromAddress.ContactId === this.user.DefaultLocation;
            // [MV3-2775] show smart pickup if the selected ship-from address is a smart-pickup type.
            && this.shipFromAddress.UPSPickUpType === UPSPickUpType.SmartPickUp;
    }

    private get12HourValue(hour: string): string {
        const isPM = +hour > 12;
        if (!isPM) {
            return hour;
        }

        return `0${ (+hour - 12) }`;
    }
}

<div class="commodity-table">
    <mat-table #table [dataSource]="dataSource">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="Commodity">
            <mat-header-cell *matHeaderCellDef>{{'Commodity'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let commodity">
                {{commodity.CommodityName || commodity.AESCommodityDescription || commodity.CommodityDescription}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Quantity">
            <mat-header-cell *matHeaderCellDef>{{'Quantity'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let commodity">{{commodity.Quantity}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Weight">
            <mat-header-cell *matHeaderCellDef>{{'Weight'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let commodity">
                {{commodity.Weight}}
                <span *ngIf="commodity.HarmonizedCode">&nbsp;{{ weightUnit }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomsValue">
            <mat-header-cell *matHeaderCellDef>{{'CustomsValue'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let commodity">
                {{commodity.CustomsValue}}
                <span *ngIf="commodity.HarmonizedCode">&nbsp;{{ customsValueCurrency }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let commodity">
                <div class="row-actions" *ngIf="commodity.HarmonizedCode">
                    <button mat-button type="button" [matMenuTriggerFor]="actions" class="actions">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #actions="matMenu" xPosition="before">
                        <button mat-menu-item type="button" (click)="editCommodity($event, commodity)">
                            {{'Edit'|translate}}
                        </button>
                        <button mat-menu-item type="button" (click)="removeCommodity($event, commodity)">
                            {{'Remove'|translate}}
                        </button>
                    </mat-menu>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>

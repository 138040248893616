<div class="commodity-form-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{'AddCommodity'|translate}}</h2>
        <button class="icon-button close-button" mat-dialog-close mat-raised-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #form>
    <form (ngSubmit)="onFormSubmit($event, formGroup.value)" [formGroup]="formGroup" class="ups-form">
        <div class="form-body">
            <div class="select-wrapper">
                <mat-form-field>
                    <mat-label>{{'SavedCommodity'|translate}}</mat-label>
                    <mat-select formControlName="savedCommodity">
                        <mat-option disabled selected value="">{{'SelectCommodity'|translate}}</mat-option>
                        <mat-option *ngFor="let commodity of commodities" [value]="commodity.CommodityID">
                            {{ commodity.CommodityName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <upsc-element-blocker *ngIf="isCommoditiesLoading"></upsc-element-blocker>
            </div>
            <mat-form-field>
                <mat-label>{{'HarmonizedCode'|translate}}</mat-label>
                <mat-select formControlName="harmonizedCode" required>
                    <mat-option disabled selected value="">{{'SelectHarmonizedCode'|translate}}</mat-option>
                    <mat-option *ngFor="let commodity of filteredHarmonizedCommodities"
                                [value]="commodity.HarmonizedCode">
                        {{ commodity.CommodityDescription }} - {{ commodity.HarmonizedCode }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls.harmonizedCode?.errors">
                    <span *ngIf="formGroup.controls.harmonizedCode?.errors.required">{{'HarmonizedRequired'|translate}}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="false">
                <mat-label>{{'HarmonizedCode'|translate}}</mat-label>
                <input [matAutocomplete]="harmonizedCommodities"
                       formControlName="harmonizedCodeText"
                       matInput>
                <mat-autocomplete #harmonizedCommodities="matAutocomplete"
                                  (optionSelected)="onHarmonizedCommoditySelected($event)"
                                  [displayWith]="getCommodityDisplayText.bind(this)">
                    <mat-option *ngFor="let commodity of filteredHarmonizedCommodities"
                                [value]="commodity.HarmonizedCode">
                        {{ commodity.CommodityDescription }} - {{ commodity.HarmonizedCode }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-error *ngIf="formGroup.controls.harmonizedCodeText?.dirty && formGroup.controls.harmonizedCode?.errors"
                       class="autocomplete-error">
                <span *ngIf="formGroup.controls.harmonizedCode?.errors.required">{{'HarmonizedRequired'|translate}}</span>
            </mat-error>
            <mat-form-field>
                <mat-label>{{'Description'|translate}}</mat-label>
                <input formControlName="description" matInput readonly>
            </mat-form-field>
            <div class="quantity">
                <mat-form-field>
                    <mat-label>{{'Quantity'|translate}}</mat-label>
                    <input formControlName="quantity"
                           matInput
                           onfocus="this.select();"
                           pattern="\d*"
                           required
                           type="text">
                    <span matSuffix>{{ quantityUOM }}</span>
                    <mat-error *ngIf="formGroup.controls.quantity?.errors">
                        <span *ngIf="formGroup.controls.quantity?.errors.pattern">{{'onlyIntegerAllowed'|translate}}</span>
                        <span *ngIf="formGroup.controls.quantity?.errors.required">{{'QuantityRequired'|translate}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="weight">
                <mat-form-field>
                    <mat-label>{{'Weight'|translate}}</mat-label>
                    <input (blur)="roundWeight()"
                           formControlName="weight"
                           matInput
                           onfocus="this.select();"
                           required
                           type="number">
                    <span matSuffix>{{ weightUnit }}</span>
                    <mat-error
                            *ngIf="formGroup.controls.weight?.errors?.required || formGroup.controls.weight?.errors?.min || formGroup.controls.weight?.errors?.max || formGroup.controls.weight?.errors?.pattern">
                        <span *ngIf="formGroup.controls.weight?.errors?.required">{{'WeightRequired'|translate}}</span>
                        <span *ngIf="formGroup.controls.weight?.errors?.min">{{'WeightMoreThanzero'|translate}}</span>
                        <span *ngIf="formGroup.controls.weight?.errors?.max">{{'WeightCannotExceed'|translate}}
                            <strong>{{ maxWeight }}.</strong></span>
                        <span *ngIf="formGroup.controls.weight?.errors?.pattern">{{'WeightPattern'|translate}}</span>
                    </mat-error>
                </mat-form-field>
                <div class="select-wrapper">
                    <mat-form-field>
                        <mat-label>{{'Amount'|translate}}</mat-label>
                        <mat-select formControlName="weightUnitExpression"
                                    required>
                            <mat-option disabled selected value="">{{'Amount'|translate}}</mat-option>
                            <mat-option *ngFor="let expression of unitExpressions" [value]="expression">
                                {{ expression }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.weightUnitExpression?.errors">
                            <span *ngIf="formGroup.controls.weightUnitExpression?.errors?.required" class = "small-font">{{'WeightUnitExpressionRequired'|translate}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="customs-value">
                <mat-form-field>
                    <mat-label>{{ 'CustomsValue' | translate }}</mat-label>
                    <input formControlName="customsValue"
                           matInput
                           onfocus="this.select();"
                           required
                           type="number">
                    <span matSuffix>{{ customsValueCurrency }}</span>
                    <mat-error *ngIf="formGroup.controls.customsValue?.errors?.required">
                        <span *ngIf="formGroup.controls.customsValue?.errors?.required">{{'CustomsValueRequired'|translate}}</span>
                    </mat-error>
                </mat-form-field>
                <div class="select-wrapper">
                    <mat-form-field>
                        <mat-label>{{'Per'|translate}}</mat-label>
                        <mat-select formControlName="customsValueCurrencyExpression"
                                    required>
                            <mat-option disabled selected value="">{{'Per'|translate}}</mat-option>
                            <mat-option *ngFor="let expression of unitExpressions" [value]="expression">
                                {{ expression }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.customsValueCurrencyExpression?.errors">
                            <span *ngIf="formGroup.controls.customsValueCurrencyExpression?.errors?.required" class = "small-font">{{'CurrencyUnitExpressionRequired'|translate}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <mat-form-field>
                <mat-label>{{'ManufacturerCountry'|translate}}</mat-label>
                <mat-select formControlName="manufacturerCountry"
                            required>
                    <mat-option disabled selected value="">{{'SelectManufacturerCountry'|translate}}</mat-option>
                    <mat-option *ngFor="let country of manufacturerCountries" [value]="country.CountryCode">
                        {{ country.CountryName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf = "this.formGroup.controls.manufacturerCountry?.errors">
                    <span *ngIf="this.formGroup.controls.manufacturerCountry?.errors?.required" class="small-font">{{'ManufacturerCountryRequired'|translate}}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'ExportLicenseNo'|translate}}</mat-label>
                <input formControlName="exportLicenseNumber"
                       matInput
                       readonly>
                <mat-hint align="start">{{'NoLicenseRequired'|translate}}</mat-hint>
            </mat-form-field>

            <mat-checkbox formControlName="isSaveCommodity">{{'SaveCommodityToMyAccount'|translate}}</mat-checkbox>
            <mat-form-field *ngIf="isSaveToProfile">
                <mat-label>{{'AssignProductName'|translate}}</mat-label>
                <input formControlName="commodityName" matInput>
                <mat-hint align="start">
                    {{'AssignNameToCommodity'|translate}}
                </mat-hint>
            </mat-form-field>
        </div>

        <div class="form-footer">
            <div class="form-actions">
                <button (click)="resetForm($event)"
                        class="form-action alternative-button"
                        color="accent"
                        mat-raised-button
                        type="button">
                    {{'Reset'|translate}}

                </button>
                <button [disabled]="!isFormValid()"
                        class="form-action"
                        color="primary"
                        mat-raised-button
                        type="submit">
                    {{'Save'|translate}}
                </button>
            </div>
        </div>
    </form>
</ng-template>

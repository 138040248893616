import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Location } from 'app/shared/models/location/location.model';
import { ShipmentService } from '../../services/shipment.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { NotificationType } from 'app/shared/models/notification-type';
import { User } from 'app/shared/services/user/models/user.model';
import { ReturnShipFromEditComponent } from './return-ship-from-edit/return-ship-from-edit.component';
import { Carriers } from 'app/shared/enum/general-enum';
import { MatSelect } from '@angular/material/select';
import { of } from 'rxjs';
import { Subscription } from 'rxjs';
import { IContact } from 'app/shared/models/contact.interface';
import { ShipConfigService } from '../../services/ship-config.service';
import { Contact } from '../../models/contact.model';
import { IShipComponent } from '../../models/ship-component.interface';
import { ReturnShipFromStaticComponent } from './return-ship-from-static/return-ship-from-static.component';

import * as _ from 'lodash';
import { FormService } from 'app/shared/services/form/form.service';
import { Customer } from 'app/shared/models/customer/customer.model';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { IAddressValidation } from '../../models/address-validation.interface';
import { Package } from '../../models/package.model';
import { ICountry } from 'app/shared/models/country.interface';
import { UserService } from 'app/shared/services/user/user.service';

@Component({
  selector: 'upsc-return-ship-from',
  templateUrl: './return-ship-from.component.html',
  styleUrls: ['./return-ship-from.component.scss'],
})
export class ReturnShipFromComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
  @Input() user: User;
  @Input() customer: Customer;
  @Input() carrier: Carriers;
  @Input() shipFromCountryCode: string;
  @Input() shipFromCurrencyCode = 'USD';
  @Input() maxCoverageCurrencyCode = 'USD';
  @Input() restrictedCountryCodes: any;
  @Input() shouldDisplayEUFields = false;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() countryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();
  @Output() countryCodeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() isResidentialAddressChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() adultSignatureRequested: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() residentialCheckChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() shipFromAddressChanged: EventEmitter<Location> = new EventEmitter<Location>();
  @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() addressValidationChanged: EventEmitter<IAddressValidation> = new EventEmitter<IAddressValidation>();
  @Output() forceClearAdultSignatureRequiredChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() shipFromAddressChanges: EventEmitter<Location> = new EventEmitter<Location>();

  @ViewChild('shipFromStatic') shipFromStatic: ReturnShipFromStaticComponent;
  @ViewChild('shipFromEdit') shipFromEdit: ReturnShipFromEditComponent;

  public formGroup: UntypedFormGroup;

  public locations: Location[];
  public allLocations: Location[];
  public isLocationsLoading = false;
  public shipFromAddress: Location;
  //public shipToCountryCode: string; TODO may need update
  public isShowAddressBook = false;
  public isLocationEditable = false;
  public initialTrackingId: string;
  public isFRITUser: boolean;
  private getShipmentLocationsSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private formService: FormService,
    private errorHandlerService: ErrorHandlerService,
    private shipConfigService: ShipConfigService,
    private shipmentService: ShipmentService,private userService: UserService) {
    this.loadInitialValues();
  }

  public ngOnInit() {
    this.isFRITUser =  this.userService.isFRITUser(this.user);
    this.formGroup = this.formBuilder.group({
      location: [''],
      initialTrackingId: [''],
    });
    this.shipFromAddress = new Location();
    this.shipmentService.updateFromLocation$.subscribe((location: Location) => this.shipFromAddress = location);

    this.monitorValueChanges();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.onCarrierChanged(changes['carrier']); // TODO need to check. DE & GB has 1 carrier only
    this.onUserChanged(changes['user']);
    this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']); // TODO need to confirm whether this is essential
  }

  public ngOnDestroy() {
    this.clearShipmentLocationsSubscription();
  }

  public getShipmentDetails(trackingId: string, event: Event) {
    event.preventDefault();
    
    this.shipmentService.getShipmentLocationDetailsByTrackingId(trackingId);
  }

  private onCarrierChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.loadShipmentLocations();
  }

  private onUserChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }
    // TODO update
    // const shipToCountry = change.currentValue.DefaultShipToCountry || change.currentValue.DefaultShiptToCountry;
    // this.shipToCountryCode = shipToCountry.toUpperCase();

    this.setVisibility(change.currentValue);
  }

  private onShipFromCountryCodeChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.locations = this.allLocations;
    if (!this.restrictedCountryCodes || !this.restrictedCountryCodes.availableCountryCodes) {
      return;
    }

    if (!this.shipConfigService.isDomesticOnlylUser(this.user)) {
      return;
    }

    if (!this.allLocations) {
      return;
    }

    if (this.restrictedCountryCodes.availableCountryCodes.length) {
      this.locations = this.allLocations.filter(
        location => this.restrictedCountryCodes.availableCountryCodes.includes(location.Country),
      );
    }
  }

  private loadShipmentLocations() {
    if (!this.carrier || !this.user) {
      return;
    }

    this.isLocationsLoading = true;

    this.clearShipmentLocationsSubscription();
    this.getShipmentLocationsSubscription = this.shipmentService.getShipmentLocations(false, this.carrier)
      .subscribe(
        locations => this.handleGetShipmentLocationsSuccess(locations),
        err => this.handleGetShipmentLocationsFailure(err),
      );
  }

  private loadInitialValues() {
  }

  private monitorValueChanges() {
    this.formGroup.valueChanges.subscribe(
      (value) => {
        this.formValueChanged.emit(value);
      });

    this.formGroup.controls.location.valueChanges
      .subscribe(
        (value) => {
          if (!value) {

            this.resetComponents([
              this.shipFromEdit,
            ]);

            return;
          }

          this.shipFromAddress = _.cloneDeep(this.locations.find(location => location.ContactId === value));

          if (!this.shipFromAddress) {
            return;
          }

          this.populateStateProvince();

          
          this.shipmentService.Quote.ShipFrom = <Contact>this.shipFromAddress;
          this.shipFromAddressChanged.emit(this.shipFromAddress);
          this.countryCodeChanged.emit(this.shipFromAddress.Country);
          this.zipCodeChanged.emit(this.shipFromAddress.Zip);
        },
      );
  }

  private populateStateProvince() {
    const hasProvince = !!this.shipFromAddress.ProvinceRegion && !!this.shipFromAddress.ProvinceRegion.trim();
    const hasState = !!this.shipFromAddress.State && !!this.shipFromAddress.State.trim();

    if (hasProvince && !hasState) {
      this.shipFromAddress.State = this.shipFromAddress.ProvinceRegion.trim();
      this.shipFromAddress.ProvinceRegion = '';
    }
  }

  //#region GetShipmentLocations handlers
  private handleGetShipmentLocationsSuccess(locations) {
    this.locations = locations;
    this.allLocations = this.locations;

    // TODO this block may be needed in Ship To
    if (this.restrictedCountryCodes && this.restrictedCountryCodes.availableCountryCodes) {
      if (this.restrictedCountryCodes.availableCountryCodes.length) {
        this.locations = this.allLocations.filter(
          location => this.restrictedCountryCodes.availableCountryCodes.includes(location.Country),
        );
      }
    }

    //this.setVisibility(this.user); //TODO this functionality may be needed in ship to. need to confirm
    this.isLocationsLoading = false;
  }

  private handleGetShipmentLocationsFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed getting ship-to address books',
      NotificationType.ERROR);
    this.isLocationsLoading = false;

    return of(new Location());
  }

  //#endregion

  private setVisibility(user: User): void {
    this.isLocationEditable = !user.IsGuest;
    this.isShowAddressBook = !user.IsGuest || (user.IsGuest && user.IsGuestAddressBook);

    if (user.IsGuest) {
      this.shipmentService.getContactAddress(user.GuestContactID)
        .subscribe(
          address => this.handleGetContactAddressSuccess(address),
          err => this.handleGetContactAddressFailure(err),
        );
    }
  }

  //#region GetContactAddress handlers
  private handleGetContactAddressSuccess(location: Location) {
    this.shipFromAddress = _.clone(location);

    this.populateStateProvince();

    this.shipFromAddressChanged.emit(this.shipFromAddress);
    this.countryCodeChanged.emit(this.shipFromAddress.Country);
    this.zipCodeChanged.emit(this.shipFromAddress.Zip);

    const data = {
      contactId: this.shipFromAddress.ContactId,
      firstName: this.shipFromAddress.FirstName,
      lastName: this.shipFromAddress.LastName,
      company: this.shipFromAddress.CompanyName,
      zipCode: this.shipFromAddress.Zip,
      city: this.shipFromAddress.City,
      state: this.shipFromAddress.State,
      country: this.shipFromAddress.Country,
      address1: this.shipFromAddress.StreetAddress,
      address2: this.shipFromAddress.ApartmentSuite,
      email: this.shipFromAddress.Email,
      phone: this.shipFromAddress.TelephoneNo,
      vat: this.shipFromAddress.VAT,
      taxId: this.shipFromAddress.TaxID,
      eori: this.shipFromAddress.EORI,
    };

    this.shipmentService.saveShipFrom(data);
  }

  private handleGetContactAddressFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed getting contact address',
      NotificationType.ERROR);
  }

  //#endregion

  public onCountryChanged(country: ICountry) {
    this.countryChanged.emit(country);
  }

  public onCountryCodeChanged(countryCode: string) {
    this.countryCodeChanged.emit(countryCode);
  }

  public onZipCodeChanged(zipCode: string) {
    this.zipCodeChanged.emit(zipCode);
  }

  public setFormValidity(isValid: boolean) {
    this.isValid.emit(isValid);
  }

  public getFormErrors() {

    if (!this.shipFromEdit) {
      return null;
    }

    return this.shipFromEdit.getFormErrors();
  }

  public isFormValid() {

    return this.shipFromEdit.isFormValid();
  }

  public onLocationFocused(event, selector: MatSelect) {
    event.preventDefault();

    if (this.locations) {
      return;
    }

    this.isLocationsLoading = true;
    this.loadShipmentLocations();
    selector.open();
  }

  private clearShipmentLocationsSubscription() {
    if (!this.getShipmentLocationsSubscription) {
      return;
    }

    this.getShipmentLocationsSubscription.unsubscribe();
    this.getShipmentLocationsSubscription = null;
  }

  public onContactSelected(contact: IContact) {
    const data = {
      contactId: contact.ContactId,
      firstName: contact.FirstName,
      lastName: contact.LastName,
      company: contact.CompanyName,
      zipCode: contact.Zip,
      city: contact.City,
      state: contact.State,
      country: contact.Country,
      address1: contact.StreetAddress,
      address2: contact.ApartmentSuite,
      email: contact.Email,
      phone: contact.TelephoneNo,
    };

    this.shipmentService.saveShipFrom(data);

    let targetLocation: Location;

    if (this.locations) {
      targetLocation = this.locations.find(location => location.ContactId === contact.ContactId);
    }

    if (targetLocation) {
      this.formGroup.patchValue({
        location: targetLocation.ContactId,
      });

      return;
    }

    this.formGroup.patchValue(
      { location: '' },
      { emitEvent: false },
    );

    this.shipFromAddress = <Location>contact;
    this.countryCodeChanged.emit(this.shipFromAddress.Country);
    this.zipCodeChanged.emit(this.shipFromAddress.Zip);
    this.shipFromAddressChanged.emit(this.shipFromAddress);
  }

  public onAdultSignatureRequested(isRequested: boolean) {
    this.adultSignatureRequested.emit(isRequested);
  }

  public onResidentialCheckChanged(isChecked: boolean) {
    this.residentialCheckChanged.emit(isChecked);
  }

  public onIsResidentialAddressChanged(isRequired: boolean) {
    this.isResidentialAddressChanged.emit(isRequired);
  }

  public resetForm() {

    this.resetComponents([
      this.shipFromStatic,
      this.shipFromEdit,
    ]);

    if (!this.formGroup) {
      return;
    }

    this.formGroup.reset({
      location: '',
    });

    this.shipFromAddress = null;
    this.loadShipmentLocations();
  }

  private resetComponents(components: IShipComponent[]) {
    components.forEach(
      (component) => {
        if (!component) {
          return true;
        }

        component.resetForm();
      },
    );
  }

  public forceValidation() {

    this.markComponentFormAsDirty([
      this.shipFromStatic,
      this.shipFromEdit,
    ]);

    this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
  }

  private markComponentFormAsDirty(components: IShipComponent[]) {
    components.forEach(
      (component) => {
        if (!component) {
          return;
        }

        component.forceValidation();
      },
    );
  }

  public onFormValueChanged(value) {
    this.formValueChanged.emit(value);
    this.shipFromAddressChanges.emit(value);
  }

  public fillCostEstimateValues(cachedCostEstimate: any) {
    // TODO update
    //this.shipToEdit.fillCostEstimateValues(cachedCostEstimate);
  }

  public restoreShipment(shipment: Package) {
    // If a location ID is specified, select it from the location list.
    if (shipment.ShipTo.ContactId && shipment.ShipTo.ContactId !== 'NOID') { // TODO need to check whether to change
      this.isLocationsLoading = true;

      this.clearShipmentLocationsSubscription();
      this.getShipmentLocationsSubscription = this.shipmentService.getShipmentLocations(true, this.carrier)
        .subscribe(
          (locations) => {
            this.handleGetShipmentLocationsSuccess(locations);

            const shipFromLocation = locations.filter(location => location.ContactId === shipment.ShipFrom.ContactId);

            // If the specified location doesn't exist, simply fill the form with address information.
            if (!shipFromLocation && this.shipFromEdit) {
              this.shipFromEdit.restoreShipment(shipment);
              return;
            }

            this.setLocation(shipment.ShipFrom.ContactId);
          },
          err => this.handleGetShipmentLocationsFailure(err),
        );

      return;
    }

    if (this.shipFromEdit) {
      this.shipFromEdit.restoreShipment(shipment);
    }
  }

  private setLocation(locationId: string) {
    this.formGroup.patchValue({
      location: locationId,
    });

    this.formGroup.controls.location.markAsDirty();
    this.formGroup.controls.location.markAsTouched();

    this.formValueChanged.emit(this.formGroup.value);
  }

  public onAddressValidationChanged(addressValidation: IAddressValidation) {
    this.addressValidationChanged.emit(addressValidation);
  }

  public onForceClearAdultSignatureRequiredChanged(isForce: boolean) {
    this.forceClearAdultSignatureRequiredChanged.emit(isForce);
  }
}

<div class="dialog-header">
    <h2 mat-dialog-title>{{ 'ship.shipUps.accessPoint.accessPointDialog.title' | translate }}</h2>
    <a (click)="showLearnMoreDialog($event)" href="#">{{  'ship.shipUps.accessPoint.accessPointDialog.learnMore' | translate }}</a>
    <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="dialog-content">
    <form [formGroup]="formGroup">
        <div class="content">
            <div class="search">
                <div class="origin">
                    <div class="origin-title">Origin</div>
                    <div class="origin-address">
                        <span *upscAppendCommaIfNotEmpty="originConfig?.StreetAddress"></span>
                        <span *upscAppendCommaIfNotEmpty="originConfig?.ApartmentSuite"></span>
                        <span *upscAppendCommaIfNotEmpty="originConfig?.City"></span>
                        <span *upscAppendCommaIfNotEmpty="originConfig?.State"></span>
                        <span>{{ originConfig?.Zip }}</span>
                    </div>
                </div>
                <div class="search-parameters">
                    <div class="search-parameter range">
                        <mat-label>Range</mat-label>
                        <mat-slider aria-label="miles"
                                    discrete
                                    color="primary"
                                    max="50"
                                    min="10"
                                    step="1">
                            <input #range matSliderThumb formControlName="searchRangeMiles" />
                        </mat-slider>
                        <span class="range-text">{{ range.value }} miles</span>
                    </div>
                    <div class="search-parameter record-count">
                        <mat-form-field appearance="outline">
                            <mat-label>Max items</mat-label>
                            <mat-select formControlName="recordCount">
                                <mat-option [value]="10">10</mat-option>
                                <mat-option [value]="20">20</mat-option>
                                <mat-option [value]="50">50</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="search-results">
                    <mat-radio-group formControlName="selectedAccessPointId" *spinOn="isSearching">
                        <ul class="access-points">
                            <li *ngFor="let accessPoint of accessPoints" class="access-point">
                                <mat-radio-button [value]="accessPoint.UPSAccessPointID">
                                    <div class="option-content">
                                        <div class="location">
                                            <div class="address">
                                                <h4>{{ accessPoint?.Consignee }}</h4>
                                                <span *upscAppendCommaIfNotEmpty="accessPoint?.StreetAddress"></span>
                                                <span *upscAppendCommaIfNotEmpty="accessPoint?.ApartmentSuite"></span>
                                                <span *upscAppendCommaIfNotEmpty="accessPoint?.City"></span>
                                                <span *upscAppendCommaIfNotEmpty="accessPoint?.State"></span>
                                                <span>{{ accessPoint?.Zip }}</span>
                                            </div>
                                            <div class="distance">{{ accessPoint.Distance | replace: 'MI': 'miles' | lowercase }}</div>
                                        </div>
                                        <div class="office-hours-wrapper">
                                            <div class="office-hours-title">{{ 'ship.shipUps.accessPoint.accessPointDialog.officeHours.title' | translate }}</div>
                                            <div [innerHTML]="accessPoint.HoursOfOperation | replace: ';' : '<br>' "
                                                 class="office-hours"></div>
                                        </div>
                                    </div>
                                </mat-radio-button>
                            </li>
                        </ul>
                    </mat-radio-group>
                </div>
            </div>
            <div class="map"></div>
        </div>
        <div class="dialog-actions">
            <button (click)="setAccessPoint($event)"
                    class="dialog-action"
                    color="primary"
                    mat-flat-button
                    [disabled]="!formGroup.valid"
                    type="button">Continue
            </button>
            <button class="dialog-action"
                    mat-dialog-close
                    mat-stroked-button
                    type="button">Cancel
            </button>
        </div>
    </form>
</div>

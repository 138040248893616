<div class="credit-row">
    <div class="credit-column">
        <upsc-logo-dd *ngIf="false" height="30px" width="28.4px"></upsc-logo-dd>
        <div [innerHTML]="'ship.shipUps.ddRemainingCredits.description' | translate"
             class="credit-description"></div>
    </div>
    <div class="credit-column">
        <div class="credits">
            <span [upscRotaryCounter]="remainingCredit" class="current-credits"></span>
            <span class="full-credits">{{ fullCredit }}</span>
        </div>
    </div>
</div>
<div class="credit-row">
    <div class="credit-column">
        <upsc-gauge [value]="remainingCredit * 100 / fullCredit"></upsc-gauge>
    </div>

    <a (click)="showDeliveryDefenseSignUpDialog($event)"
       [class.disabled]="remainingCredit > 0"
       class="credit-cta"
       href="#">
        <mat-icon>add_circle_outline</mat-icon>
        <span [innerHTML]="'ship.shipUps.ddRemainingCredits.addCredits' | translate"></span>
    </a>
</div>

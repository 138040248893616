<div class="dialog-header">
    <h2 [innerHTML]="'ship.shipUps.accessPoint.accessPointSuggestionDialog.title' | translate" mat-dialog-title></h2>
    <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>


<div class="dialog-content">
    <p class="p1">
        <span [innerHTML]="'ship.shipUps.accessPoint.accessPointSuggestionDialog.p1' | translate"></span>
        <img alt="UPS Access Point" class="ap-logo" src="assets/images/logos/logo-ups-ap-alt.svg">
    </p>
    <p class="p2">
        <span [innerHTML]="'ship.shipUps.accessPoint.accessPointSuggestionDialog.p2' | translate"></span>
    </p>
    <div class="actions">
        <button color="primary" mat-raised-button mat-dialog-close="accessPoint">
            <span [innerHTML]="'ship.shipUps.accessPoint.accessPointSuggestionDialog.action.selectAccessPoint' | translate"></span>
        </button>
        <button color="primary" mat-stroked-button mat-dialog-close="residential">
            <span [innerHTML]="'ship.shipUps.accessPoint.accessPointSuggestionDialog.action.selectResidential' | translate"></span>
        </button>
        <button color="primary" mat-dialog-close mat-stroked-button *ngIf="false">
            <span [innerHTML]="'ship.shipUps.accessPoint.accessPointSuggestionDialog.action.cancel' | translate"></span>
        </button>
    </div>
</div>


import { NgModule } from '@angular/core';
import { AddressBookSearchComponent } from './address-book-search.component';
import { SharedModule } from '../shared/shared.module';
import { AddressBookSearchDialogComponent } from './address-book-dialog/address-book-search-dialog.component';
import { AddressBookSearchFormComponent } from './address-book-dialog/address-book-search-form/address-book-search-form.component';
import { AddressBookSearchTableComponent } from './address-book-dialog/address-book-search-table/address-book-search-table.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorHandlerService } from '../shared/services/error-handler/error-handler.service';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
    declarations: [
        AddressBookSearchComponent,
        AddressBookSearchDialogComponent,
        AddressBookSearchFormComponent,
        AddressBookSearchTableComponent,
    ],
    providers: [
        ErrorHandlerService,
    ],
    exports: [AddressBookSearchComponent]
})
export class AddressBookSearchModule {
}

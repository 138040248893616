<div class="dialog-header">
    <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="dialog-content">
    <h3 class="title">
        <mat-icon>warning_amber</mat-icon>
        <span [innerHTML]="'noCoverageWarning.title' | translate"></span>
    </h3>
    <h3 class="subtitle" *ngIf="false">
        <span [innerHTML]="'noCoverageWarning.subtitle' | translate"></span>
    </h3>

    @if (carrier === Carriers.Ups || carrier.toString().toLowerCase() === "ups") {
        <p [innerHTML]="('noCoverageWarning.p1.ups.' + addressType) | translate"></p>
        <p [innerHTML]="'noCoverageWarning.p2.ups' | translate"></p>
    } @else if (carrier === Carriers.Fedex || carrier.toString().toLowerCase() === "fedex") {
        <p [innerHTML]="('noCoverageWarning.p1.fedex.' + addressType) | translate"></p>
        <p [innerHTML]="'noCoverageWarning.p2.fedex' | translate"></p>
    }

    <div class="actions">
        <button class="action" mat-stroked-button mat-dialog-close>
            <span [innerHTML]="'noCoverageWarning.button.cancel' | translate"></span>
        </button>
        <button class="action" mat-raised-button color="primary" mat-dialog-close="true">
            <span [innerHTML]="'noCoverageWarning.button.ok' | translate"></span>
        </button>
    </div>
</div>

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import dayjs from 'dayjs';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { Carriers } from '../../shared/enum/general-enum';
import { Customer } from '../../shared/models/customer/customer.model';
import { NotificationType } from '../../shared/models/notification-type';
import { FormService } from '../../shared/services/form/form.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { User } from '../../shared/services/user/models/user.model';
import { UserService } from '../../shared/services/user/user.service';
import { ValidationService } from '../../shared/services/validation/validation.service';
import {
    AccessPointsLearnMoreDialogComponent,
} from '../access-points-dialog/access-point-learn-more/access-points-learn-more-dialog.component';
import { IAccessPoint } from '../models/access-point.interface';
import { ICarrierService } from '../models/carrier-service.interface';
import { Package } from '../models/package.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipConfig } from '../models/ship-config.model';
import { PickupLocationsDialogService } from '../pickup-locations-dialog/pickup-locations-dialog.service';
import { PickupLocationSearchConfig } from '../pickup-locations/components/search/pickup-location-search-config';
import { IPickupLocationRequest } from '../pickup-locations/models/pickup-location-request.interface';
import { IPickupLocation } from '../pickup-locations/models/pickup-location.interface';
import { PickupLocationStateService } from '../pickup-locations/pickup-location-state.service';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipmentStateService } from '../services/shipment-state.service';
import { ShipmentService } from '../services/shipment.service';

@Component({
    selector: 'upsc-ship-info-add',
    templateUrl: './ship-info-add.component.html',
    styleUrls: ['./ship-info-add.component.scss'],
})
export class ShipInfoAddComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
    @Input() public serviceType: ICarrierService;
    @Input() public carrier: Carriers;
    @Input() public user: User;
    @Input() public isDomestic: boolean;
    @Input() public shipFromCountryCode: string;
    @Input() public shipToCountryCode: string;
    @Input() public isRestoreShipment = false;
    @Input() public returnShipment = false;
    @Input() public shouldForceClearAdultSignatureRequired = false;
    @Input() public insuredValue: number;
    @Input() public isShipToZipRestricted = false;
    @Output() public isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public adultSignatureChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    public readonly environment = environment;
    public shipInfoAddForm: UntypedFormGroup;
    public customer: Customer;
    public adultChecked = false;
    public directChecked = false;
    public isCodEnabled = false;
    public isBrokerSelectEnabled = false;
    public isDomesticFedEx = false;
    public maxCODValue = 5000;
    public config: ShipConfig;
    public shouldShowSection = false;
    public quote: Package;
    public shouldShowFedExLocationForm = false;
    public isExpressSaverDEGB = false;
    public shouldShowAdultSignatureRequiredOption = false;
    public isUPS = false;
    public showCOD = false;

    public isAccessPointEnabled = false;
    private pickupLocationSearchRange = PickupLocationSearchConfig.searchRange.default;
    private pickupLocationSearchMaxItems = PickupLocationSearchConfig.maxSearchResults.default;
    private isOneTimeValuesSet = false;
    private isResidentialAddress = false;
    private ngUnsubscribe = new Subject();

    public constructor(private validationService: ValidationService,
                       private formService: FormService,
                       private formBuild: UntypedFormBuilder,
                       private dialog: MatDialog,
                       private userService: UserService,
                       private shipConfigService: ShipConfigService,
                       private shipmentService: ShipmentService,
                       private readonly notificationService: NotificationService,
                       private snackBar: MatSnackBar,
                       private readonly appState: AppState,
                       private readonly pickupLocationsDialogService: PickupLocationsDialogService,
                       public readonly shipmentStateService: ShipmentStateService,
                       private readonly pickupLocationStateService: PickupLocationStateService,
    ) {
        this.quote = this.shipmentService.Quote;
        this.customer = this.appState.customer$();
    }

    public get usePickupLocation(): boolean {
        return this.shipInfoAddForm?.get('usePickupLocation')?.value;
    }

    public get pickupLocation(): IPickupLocation {
        return this.shipInfoAddForm?.get('pickupLocation')?.value;
    }

    public get accessPoint(): IAccessPoint {
        return this.shipInfoAddForm?.get('accessPoint')?.value;
    }

    public get useFedExHoldAtLocation(): boolean {
        return this.shipInfoAddForm?.get('holdAtFedExLocation')?.value;
    }

    public get shouldShowUPSAccessPoint(): boolean {
        return this.environment.feature?.upsAccessPoint
            && this.user.CountryCode === 'US'
            && this.shipToCountryCode === 'US'

            // TEMP: [DD-307]
            // Only show UPS Access Point option for DD subscribers and potential DD subscribers.
            && this.customer.DDEnabled !== null

            && this.shipConfigService.isUps(this.carrier);
    }

    public ngOnDestroy() {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    }

    public ngOnInit() {
        this.shipInfoAddForm = this.formBuild.group({
            useAccessPoint: [false],
            accessPoint: [null],
            saturdayDelivery: [false],
            saturdayPickup: [false],
            directDeliveryOnly: [false],
            adultSignature: [false],
            directSignature: [false],
            holdAtFedExLocation: [false],
            codService: [false],
            codAmount: [0],
            isSecuredCod: [false],
            brokerSelect: [false],
            brokerAccountNumber: ['', Validators.compose([Validators.maxLength(50)])],
            brokerCompanyName: ['', Validators.compose([Validators.maxLength(35)])],
            brokerContactName: ['', Validators.compose([Validators.maxLength(35)])],
            brokerAddress1: ['', Validators.compose([Validators.maxLength(35)])],
            brokerAddress2: ['', Validators.compose([Validators.maxLength(35)])],
            brokerPostalCode: ['', Validators.compose([Validators.maxLength(10)])],
            brokerCity: ['', Validators.compose([Validators.maxLength(35)])],
            brokerStateProvince: ['', Validators.compose([Validators.maxLength(2)])],
            brokerCountryCode: [''],
            brokerPhoneNumber: ['', Validators.compose([Validators.maxLength(25)])],
            brokerTaxId: ['', Validators.compose([Validators.maxLength(35)])],
            usePickupLocation: [false],
            pickupLocation: [null],
            useAccessPointCode: [false],
        });

        this.monitorValueChanges();
        this.setFormValues();
        this.shipInfoAddForm.controls.brokerCountryCode.setValue(this.shipToCountryCode);

        this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;
                this.toggleSectionVisibility();

                if (this.shipConfigService.configSetCount > 0) {
                    this.setOneTimeValues();
                }

                // [MV3-3060] Adult Signature Required option should always be shown if UPS config.showAdultSignatureMessage is false.
                this.shouldShowAdultSignatureRequiredOption = this.config.showAdultSignature
                    || (this.shipConfigService.isUps(this.carrier) && this.config && !this.config.showAdultSignatureMessage);

            },
        );

        this.shipmentService.shipToZip$.subscribe(
            (zip) => {
                if (!this.isRestoreShipment) {
                    this.resetAccessPoint();
                }
            },
        );

        this.shipmentService.shipToAddressType$.subscribe(
            (addressType) => {
                this.isResidentialAddress = addressType === 1;

                if (!this.isResidentialAddress) {
                    this.shipInfoAddForm.patchValue({
                        residentialCheck: false,
                        adultSignature: false,
                    });
                }
            },
        );

        this.shipmentService.accessPointSelectorRequested$
            .pipe(
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(
                (isRequested) => {
                    if (!isRequested) {
                        return;
                    }

                    this.shipInfoAddForm?.get('useAccessPoint').setValue(true);
                    this.shipInfoAddForm?.get('usePickupLocation').setValue(true);
                },
            );

        this.shipmentService.accessPointSelected$.subscribe(
            (isSelected) => {
                if (!isSelected) {
                    this.shipInfoAddForm.get('adultSignature').enable();
                    this.shipInfoAddForm.get('codService').enable();
                    this.shipInfoAddForm.get('codAmount').enable();
                    this.shipInfoAddForm?.get('directDeliveryOnly')?.enable();

                    this.shipInfoAddForm?.get('useAccessPoint').setValue(false, { emitEvent: false, onlySelf: true });
                    this.shipInfoAddForm?.get('accessPoint').setValue(null, { emitEvent: false, onlySelf: true });
                    this.shipInfoAddForm?.get('pickupLocation').setValue(null, { emitEvent: false, onlySelf: true });

                    this.handleRestrictedShipToZip(this.shipmentStateService.isShipToZipRestricted());

                    this.shipInfoAddForm.patchValue({
                        UPSAccessPointID: null,
                        holdAtFedExLocation: false,
                    });

                    if (this.isResidentialAddress) {
                        this.shipInfoAddForm.patchValue({
                            adultSignature: true,
                        });
                    }

                    this.shipmentService.Quote.HoldAtCountry = '';

                    return;
                }

                this.shipInfoAddForm.patchValue({
                    adultSignature: false,
                    codService: false,
                    codAmount: 0,
                });

                // NOTE: To get a value from a disabled control, use getRawValue().
                // this.shipInfoAddForm.get('adultSignature').disable();
                this.shipInfoAddForm.get('codService').disable();
                // this.shipInfoAddForm.get('codAmount').disable();

                this.shipmentService.skipDeliveryDefenseFlagChanged$.next(true);
            },
        );

        this.shipmentService.fedexHoldAtLocationRequested$
            .pipe(
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    this.shipInfoAddForm?.get('holdAtFedExLocation').setValue(true);
                    this.shipInfoAddForm?.get('usePickupLocation').setValue(true);
                },
            );

        this.shipmentService.fedexHoldAtLocationSelected$.subscribe(
            (value) => {
                if (!value) {
                    this.shipmentService.savePickupLocation(null);

                    // this.shipInfoAddForm.get('adultSignature').enable();
                    // this.shipInfoAddForm.get('directSignature').enable();
                    this.shipInfoAddForm.get('codService').enable();
                    this.shipInfoAddForm.get('adultSignature').enable();

                    if (this.isResidentialAddress) {
                        this.shipInfoAddForm.patchValue({
                            adultSignature: true,
                        });
                    }

                    return;
                }

                this.shipInfoAddForm.patchValue({
                    adultSignature: false,
                    directSignature: false,
                    codService: false,
                    codAmount: 0,
                });

                // this.shipInfoAddForm.get('adultSignature').disable();
                // this.shipInfoAddForm.get('directSignature').disable();
                this.shipInfoAddForm.get('codService').disable();
                this.shipInfoAddForm.get('adultSignature').disable();
            },
        );
    }

    public resetForm() {

        if(this.config === undefined || this.config === null){
            this.shipConfigService.configSubject.subscribe(
                (config) => {
                    if (!config) {
                        return;
                    }
                    this.config = config;
                    this.shipInfoAddForm.reset({
                        directDeliveryOnly: config?.isDirectDeliveryOnly || false,
                        adultSignature: config?.isAdultSignature || false,
                        directSignature: config?.isDirectSignature || false,
                    })
    
                },
            );
        }

        if (!this.shipInfoAddForm) {
            return;
        }

        this.shipInfoAddForm.reset({
            useAccessPoint: false,
            accessPoint: null,
            saturdayDelivery: false,
            saturdayPickup: false,
            holdAtFedExLocation: false,
            codService: false,
            codAmount: 0,
            isSecuredCod: false,
            brokerSelect: false,
            brokerAccountNumber: '',
            brokerCompanyName: '',
            brokerContactName: '',
            brokerAddress1: '',
            brokerAddress2: '',
            brokerPostalCode: '',
            brokerCity: '',
            brokerStateProvince: '',
            brokerPhoneNumber: '',
            brokerTaxId: '',
            usePickupLocation: false,
            pickupLocation: null,
            useAccessPointCode: false,
            directDeliveryOnly: this.config?.isDirectDeliveryOnly || false,
            adultSignature: this.config?.isAdultSignature || false,
            directSignature: this.config?.isDirectSignature || false,
        });
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.shipInfoAddForm, false);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onServiceTypeChanged(changes['serviceType']);
        this.onCarrierChanged(changes['carrier']);
        this.onIsDomesticChanged(changes['isDomestic']);
        this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
        this.onShipToCountryCodeChanged(changes['shipToCountryCode']);
        this.onForceClearAdultSignatureRequiredChanged(changes['shouldForceClearAdultSignatureRequired']);
        this.onIsShipToZipRestrictedChanged(changes.isShipToZipRestricted);

        this.isAccessPointEnabled = this.user.CountryCode === 'US'
            && this.shipToCountryCode === 'US'
            && this.shipConfigService.isUps(this.carrier);
    }

    public onCarrierChanged(change: SimpleChange) {

        if (!change || !change.currentValue) {
            return;
        }
        this.isShowCOD();
        this.isDomesticFedEx = +Carriers[change.currentValue] === Carriers.Fedex && this.isDomestic;
    }

    public onIsDomesticChanged(change: SimpleChange) {
        if (!change) {
            return;
        }

        this.isDomesticFedEx = +Carriers[this.carrier] === Carriers.Fedex && change.currentValue;

        if (change.currentValue) {
            this.resetBrokerInformation();
        } else {
            this.shipmentService.savePickupLocation(null);
        }
    }

    public isShowCOD() {
        if (+Carriers[this.carrier] === Carriers.Fedex) {
            this.isUPS = false;
            if ((this.shipmentService.Quote.ShipFrom.Country === 'CA') && (this.shipmentService.Quote.ShipTo.Country === 'CA')) {
                this.showCOD = true;
            } else {
                this.showCOD = false;
                this.shipInfoAddForm?.patchValue({
                    codAmount: 0,
                    isSecuredCod: false,
                });
            }
        }
        if (+Carriers[this.carrier] === Carriers.Ups) {
            this.isUPS = true;
            this.showCOD = false;
        }

    }

    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.shipInfoAddForm.controls, 'Additional Shipping Information');
    }

    public isFormValid() {
        return this.shipInfoAddForm.valid;
    }

    public enableAdultSignature() {
        const isPickupLocationSelected = this.shipInfoAddForm.get('usePickupLocation').value
            || this.shipInfoAddForm.get('useAccessPoint').value
            || this.shipInfoAddForm.get('holdAtFedExLocation').value;

        if (isPickupLocationSelected) {
            this.shipInfoAddForm.controls.adultSignature.setValue(false);
            return;
        }

        this.shipInfoAddForm.controls.adultSignature.setValue(true);
    }

    public toggleSectionVisibility() {
        if (!this.config) {
            return;
        }

        this.shouldShowSection = this.config.showBrokerSelect
            || this.config.showSaturdayDelivery
            || this.config.showSaturdayPickUp
            || this.config.showAdultSignature
            || this.config.showDirectSignature
            || this.config.showHoldAtFedexLocation;
    }

    public fillCostEstimateValues(cachedCostEstimate: any) {
        this.shipInfoAddForm.patchValue({
            saturdayDelivery: cachedCostEstimate.IsSaturdayDelivery,
            saturdayPickup: cachedCostEstimate.IsSaturdayPickUp,
            codService: cachedCostEstimate.IsCOD,
            codAmount: cachedCostEstimate.CodAmount,
            isSecuredCod: cachedCostEstimate.IsSecuredCOD,
        });

        this.shipInfoAddForm.controls.saturdayDelivery.markAsDirty();
        this.shipInfoAddForm.controls.saturdayDelivery.markAsTouched();

        this.shipInfoAddForm.controls.saturdayPickup.markAsDirty();
        this.shipInfoAddForm.controls.saturdayPickup.markAsTouched();

        this.shipInfoAddForm.controls.codService.markAsDirty();
        this.shipInfoAddForm.controls.codService.markAsTouched();

        this.shipInfoAddForm.controls.codAmount.markAsDirty();
        this.shipInfoAddForm.controls.codAmount.markAsTouched();

        this.shipInfoAddForm.controls.isSecuredCod.markAsDirty();
        this.shipInfoAddForm.controls.isSecuredCod.markAsTouched();
    }

    public restoreShipment(shipment: Package) {
        this.shipInfoAddForm.patchValue(
            {
                saturdayDelivery: shipment.IsSaturdayDelivery,

                // [MV3-2764] Set saturdayPickup to false if ship date is not Saturday.
                // Note: upon restoration, ship date is always set to today.
                saturdayPickup: dayjs().day() !== 6 ? false : shipment.IsSaturdayPickUp,

                directDeliveryOnly: shipment.IsDDO,
                adultSignature: shipment.IsDeliveryConfirmation,
                directSignature: shipment.IsDirectSignature,
                holdAtFedExLocation: shipment.IsHoldAtLocation,
                codService: shipment.IsCod,
                codAmount: shipment.CodAmount,
                isSecuredCod: shipment.IsSecuredCod,
                brokerSelect: shipment.IsBrokerSelect,
                brokerAccountNumber: shipment.BrokerAccountNo,
                brokerCompanyName: shipment.BrokerCompanyName,
                brokerContactName: shipment.BrokerContactName,
                brokerAddress1: shipment.BrokerAddress1,
                brokerAddress2: shipment.BrokerAddress2,
                brokerPostalCode: shipment.BrokerPostalCode,
                brokerCity: shipment.BrokerCity,
                brokerStateProvince: shipment.BrokerStateProvince,
                brokerCountryCode: shipment.BrokerCountryCode,
                brokerPhoneNumber: shipment.BrokerPhoneNumber,
                brokerTaxId: shipment.BrokerTaxID,
                usePickupLocation: shipment.IsHoldAtLocation,
                useAccessPointCode: shipment.UseAccessPointCode,
            },
            { emitEvent: false });

        this.restorePickupLocation(shipment);

        switch (true) {
            case this.shipConfigService.isFedEx(this.carrier):
                this.shipInfoAddForm.patchValue(
                    {
                        useAccessPoint: false,
                        accessPoint: null,
                    },
                    { emitEvent: false });

                this.shouldShowFedExLocationForm = !!shipment.IsHoldAtLocation;

                break;
            case this.shipConfigService.isUps(this.carrier):
                this.shipInfoAddForm.patchValue(
                    {
                        useAccessPoint: shipment.IsHoldAtLocation,
                        accessPoint: {
                            UPSAccessPointID: shipment.HoldAtLocationID,
                            StreetAddress: shipment.HoldAtAddress1,
                            Consignee: shipment.HoldAtAddress2,
                            City: shipment.HoldAtCity,
                            State: shipment.HoldAtState,
                            Zip: shipment.HoldAtZip,
                            Country: shipment.HoldAtCountry,
                        },
                    },
                    { emitEvent: false });

                break;
            default:
                break;
        }

        if (shipment.IsHoldAtLocation) {
            this.shipInfoAddForm.patchValue({
                adultSignature: false,
                codService: false,
                codAmount: 0,
            });

            // NOTE: To get a value from a disabled control, use getRawValue().
            // this.shipInfoAddForm.get('adultSignature').disable();
            this.shipInfoAddForm.get('codService').disable();
            // this.shipInfoAddForm.get('codAmount').disable();

            this.shipmentService.skipDeliveryDefenseFlagChanged$.next(true);
        }

        this.shipInfoAddForm.markAsDirty();
        this.shipInfoAddForm.markAsTouched();
    }

    public showLearnMoreDialog(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '34.625rem',
            panelClass: 'mobile-fullscreen-dialog',
            data: {},
        };

        this.dialog.open(AccessPointsLearnMoreDialogComponent, dialogConfig);
    }

    public openPickupLocationsDialog(carrier: Carriers, locationId: string, isReadonly = false): void {
        // Sometimes an enum parameter can be the key, not value.
        // This check ensures that it's converted back to an enum.
        // Note that this only works if the type of the enum value is not a string.
        if (typeof carrier === 'string') {
            carrier = Carriers[carrier as keyof typeof Carriers];
        }

        const shipToAddress = this.shipmentService.Quote.ShipTo;
        const isValidAddress = shipToAddress?.StreetAddress
            && shipToAddress?.City
            && shipToAddress?.State
            && shipToAddress?.Zip;

        if (!isValidAddress) {
            return;
        }

        const data: IPickupLocationRequest = {
            streetAddress: shipToAddress.StreetAddress,
            apartmentSuite: shipToAddress.ApartmentSuite,
            city: shipToAddress.City,
            state: shipToAddress.State,
            country: shipToAddress.Country,
            zip: shipToAddress.Zip,
            miles: locationId
                ? PickupLocationSearchConfig.searchRange.max
                : (this.pickupLocationSearchRange || PickupLocationSearchConfig.searchRange.default),
            noOfRecords: locationId
                ? Math.max(...PickupLocationSearchConfig.maxSearchResults.options)
                : (this.pickupLocationSearchMaxItems || PickupLocationSearchConfig.maxSearchResults.default),
        };

        const dialogRef = this.pickupLocationsDialogService.open(
            carrier,
            data,
            isReadonly,
            this.shipmentService.Quote.ShipFrom,
            this.shipmentService.Quote.ShipTo,
            locationId,
            this.insuredValue,
            this.shipmentService.Quote.UseAccessPointCode || false,
        );
        dialogRef.subscribe(
            (result) => {
                if (!result?.pickupLocation && !this.shipmentService.Quote.HoldAtLocationID) {
                    this.shipInfoAddForm.patchValue({
                        useAccessPoint: false,
                        holdAtFedExLocation: false,
                        useAccessPointCode: false,
                    });

                    return;
                }

                this.shipInfoAddForm.get('useAccessPointCode').setValue(result.useAccessPointCode || false);
                this.shipmentService.shippingUpdateInformationChanged$.next(result.shippingUpdateInformation);
                this.pickupLocationSearchRange = result?.request?.miles;
                this.pickupLocationSearchMaxItems = result?.request?.noOfRecords;

                switch (carrier) {
                    case Carriers.Ups:
                        this.onUPSPickupLocationSelected(result.pickupLocation);
                        break;
                    case Carriers.Fedex:
                        this.onFedExPickupLocationSelected(result.pickupLocation);
                        break;
                    default:
                        break;
                }

                this.shipmentService.savePickupLocation(result.pickupLocation);
                // this.setPickupLocation(result.pickupLocation);
            },
        );
    }

    public forceSelectDirectSignature(): void {
        this.config.showDirectSignature = true;
        this.shipInfoAddForm.get('directSignature').setValue(true);
    }

    public setDirectSignature(isChecked: boolean): void {
        this.shipInfoAddForm.get('directSignature').setValue(isChecked);
    }

    public setAdultSignature(isChecked: boolean): void {
        this.shipInfoAddForm.get('adultSignature').setValue(isChecked);
    }

    public onForceClearAdultSignatureRequiredChanged(change: SimpleChange) {
        if (!change || !this.shipInfoAddForm) {
            return;
        }

        this.shipInfoAddForm.patchValue({
            adultSignature: !change.currentValue,
        });
    }

    /**
     * DDO and Pickup Location should not be available if the insured value exceeds the coverage (based on a carrier and zip)
     * since the shipment will be considered a high risk shipment and will be re-routed to a secure facility.
     */
    public handleRestrictedShipToZip(isRestrictedShipToZip: boolean): void {
        const ddoControl = this.shipInfoAddForm.controls.directDeliveryOnly;
        const usePickupLocationControl = this.shipInfoAddForm.controls.usePickupLocation;
        const pickupLocationControl = this.shipInfoAddForm.controls.pickupLocation;
        const useUpsPickupLocationControl = this.shipInfoAddForm.controls.useAccessPoint;
        const upsPickupLocationControl = this.shipInfoAddForm.controls.accessPoint;
        const useFedExPickupLocationControl = this.shipInfoAddForm.controls.holdAtFedExLocation;

        if (isRestrictedShipToZip) {
            ddoControl.setValue(false, { emitEvent: false });
            ddoControl.disable({ emitEvent: false });

            usePickupLocationControl.setValue(false, { emitEvent: false });
            usePickupLocationControl.disable({ emitEvent: false });
            pickupLocationControl.setValue(null, { emitEvent: false });

            useUpsPickupLocationControl.setValue(false, { emitEvent: false });
            useUpsPickupLocationControl.disable({ emitEvent: false });
            upsPickupLocationControl.setValue(null, { emitEvent: false });

            useFedExPickupLocationControl.setValue(false, { emitEvent: false });
            useFedExPickupLocationControl.disable({ emitEvent: false });

            return;
        }

        ddoControl.enable({ emitEvent: false });
        usePickupLocationControl.enable({ emitEvent: false });
        useUpsPickupLocationControl.enable({ emitEvent: false });
        useFedExPickupLocationControl.enable({ emitEvent: false });
    }

    protected setOneTimeValues() {
        if (this.isOneTimeValuesSet || this.isRestoreShipment) {
            return;
        }

        this.shipInfoAddForm.patchValue({
            directSignature: this.returnShipment ? false : this.config.isDirectSignature,
            directDeliveryOnly: this.returnShipment ? false : this.config.isDirectDeliveryOnly,
        });

        this.isOneTimeValuesSet = true;
    }

    private onIsShipToZipRestrictedChanged(change: SimpleChange): void {
        if (change?.currentValue === null || change?.currentValue === undefined) {
            return;
        }

        if (!this.isShipToZipRestricted) {
            this.shipInfoAddForm?.controls?.useAccessPoint?.enable();
            this.shipInfoAddForm?.controls?.holdAtFedExLocation?.enable();
            this.shipInfoAddForm?.controls?.directDeliveryOnly?.enable();

            return;
        }

        const useAccessPointControl = this.shipInfoAddForm?.controls?.useAccessPoint;
        const holdAtFedExLocationControl = this.shipInfoAddForm?.controls?.holdAtFedExLocation;
        const directDeliveryOnlyControl = this.shipInfoAddForm?.controls?.directDeliveryOnly;

        if (useAccessPointControl.value || holdAtFedExLocationControl.value) {
            this.snackBar.open(
                'Pickup location is not available for this destination with the specified insured value.',
                '',
                { duration: 5000 },
            );
        }

        this.shipInfoAddForm?.patchValue({
            useAccessPoint: false,
            accessPoint: null,
            holdAtFedExLocation: false,
            directDeliveryOnly: false,
        }, { emitEvent: false });

        useAccessPointControl?.disable();
        holdAtFedExLocationControl?.disable();
        directDeliveryOnlyControl?.disable();

        this.shipmentService.accessPointSelected$.next(false);
        this.shipmentService.fedexHoldAtLocationSelected$.next(false);
    }

    private restorePickupLocation(shipment: Package): void {
        const pickupLocation: Partial<IPickupLocation> = {
            locationId: shipment.HoldAtLocationID,
            businessName: shipment.HoldAtAddress2,
            address: shipment.HoldAtAddress1,
            city: shipment.HoldAtCity,
            state: shipment.HoldAtState,
            country: shipment.HoldAtCountry,
            zip: shipment.HoldAtZip,
        };

        if (!pickupLocation.locationId) {
            this.shipInfoAddForm.patchValue({
                pickupLocation: null,
                usePickupLocation: false,
                useAccessPoint: false,
                holdAtFedExLocation: false,
                useAccessPointCode: false,
            }, { emitEvent: false });

            this.shipmentService.savePickupLocation(null);
            return;
        }

        this.shipInfoAddForm.patchValue({
            pickupLocation: pickupLocation,
            usePickupLocation: true,
            useAccessPointCode: shipment.UseAccessPointCode,
            useAccessPoint: this.shipConfigService.isUps(this.carrier),
            holdAtFedExLocation: this.shipConfigService.isFedEx(this.carrier),
        }, { emitEvent: false });

        this.pickupLocationStateService.selectedPickupLocation$.set(pickupLocation);
        this.shipmentService.savePickupLocation(pickupLocation);

        switch (true) {
            case this.shipConfigService.isUps(this.carrier):
                this.shipmentService.accessPointSelected$.next(true);
                break;
            case this.shipConfigService.isFedEx(this.carrier):
                this.shipmentService.fedexHoldAtLocationSelected$.next(true);
                break;
            default:
                break;
        }
    }

    private onUPSPickupLocationSelected(location: IPickupLocation): void {
        const hasExistingAccessPoint = !!this.shipInfoAddForm.get('pickupLocation').value;
        if (!location && hasExistingAccessPoint) {
            this.shipInfoAddForm?.get('adultSignature').enable();
            return;
        }

        if (!location) {
            this.shipInfoAddForm.get('useAccessPoint').setValue(false);
            this.shipInfoAddForm.get('accessPoint').setValue(null);
            this.shipInfoAddForm.get('pickupLocation').setValue(null);
            this.shipInfoAddForm?.get('adultSignature').enable();
            this.shipInfoAddForm?.get('directDeliveryOnly')?.enable();
            this.shipmentService.accessPointSelected$.next(false);

            return;
        }

        this.shipInfoAddForm.patchValue({
            pickupLocation: location,
            adultSignature: false,
            directDeliveryOnly: false,
        });

        this.shipmentService.accessPointSelected$.next(true);
        this.shipInfoAddForm?.get('adultSignature').disable();
        this.shipInfoAddForm?.get('directDeliveryOnly')?.disable();
    }

    private onFedExPickupLocationSelected(location: IPickupLocation): void {
        const hasExistingAccessPoint = !!this.shipInfoAddForm.get('pickupLocation').value;
        if (!location && hasExistingAccessPoint) {
            this.shipInfoAddForm?.get('adultSignature').enable();
            return;
        }

        if (!location) {
            this.shipInfoAddForm.get('pickupLocation').setValue(null);
            this.shipInfoAddForm?.get('adultSignature').enable();
            this.shipInfoAddForm?.get('directDeliveryOnly')?.enable();
            this.shipmentService.fedexHoldAtLocationSelected$.next(false);

            return;
        }

        this.shipInfoAddForm.patchValue({
            pickupLocation: location,
            adultSignature: false,
            directDeliveryOnly: false,
        });

        this.shipmentService.fedexHoldAtLocationSelected$.next(true);
        this.shipInfoAddForm?.get('adultSignature').disable();
        this.shipInfoAddForm?.get('directDeliveryOnly')?.disable();
    }

    private monitorValueChanges() {
        this.shipInfoAddForm.valueChanges
            .subscribe(
                (value) => {
                    const data = {
                        useAccessPoint: value.useAccessPoint,
                        accessPoint: value.accessPoint,
                        useAccessPointCode: value.useAccessPointCode,

                        directChecked: value.directSignature, // this.directChecked,
                        adultChecked: value.adultSignature, // this.adultChecked,
                        directDeliveryOnly: value.directDeliveryOnly,
                        saturdayDelivery: value.saturdayDelivery,
                        saturdayPickup: value.saturdayPickup,

                        holdAtFedExLocation: value.holdAtFedExLocation,

                        codService: value.codService,
                        codAmount: value.codAmount,
                        isSecuredCod: value.isSecuredCod || false,
                        brokerSelect: value.brokerSelect,

                        brokerAccountNumber: value.brokerAccountNumber,
                        brokerCompanyName: value.brokerCompanyName,
                        brokerContactName: value.brokerContactName,
                        brokerAddress1: value.brokerAddress1,
                        brokerAddress2: value.brokerAddress2,
                        brokerPostalCode: value.brokerPostalCode,
                        brokerCity: value.brokerCity,
                        brokerStateProvince: value.brokerStateProvince,
                        brokerCountryCode: value.brokerCountryCode,
                        brokerPhoneNumber: value.brokerPhoneNumber,
                        brokerTaxId: value.brokerTaxId,
                    };

                    this.shipmentService.saveAdditionalShipmentInformation(data);

                    if (value?.pickupLocation) {
                        this.shipmentService.savePickupLocation(value.pickupLocation);
                    }

                    this.isValid.emit(this.shipInfoAddForm.valid);
                    this.formValueChanged.emit(value);
                },
            );

        this.shipInfoAddForm.controls.adultSignature.valueChanges
            .subscribe(
                (value) => {
                    this.adultSignatureChanged.emit(value);

                    if (value === true) {
                        this.directChecked = false;
                        this.adultChecked = true;

                        return;
                    }

                    // [MV3-1960] Make Adult Signature Required optional.
                    // // one of the signature MUST be selected for DE-GB users.
                    // if (this.user && this.shipmentService.isDEGBUser(this.user) && !value) {
                    //   this.directChecked = true;
                    // }
                },
            );

        this.shipInfoAddForm.controls.directSignature.valueChanges
            .subscribe(
                (value) => {
                    if (value === true) {
                        this.directChecked = true;
                        this.adultChecked = false;

                        return;
                    }

                    // [MV3-1960] Make Direct Signature Required optional.
                    // // one of the signature MUST be selected for DE-GB users.
                    // if (this.user && this.shipmentService.isDEGBUser(this.user) && !value) {
                    //   this.adultChecked = true;
                    // }
                },
            );

        this.shipInfoAddForm.controls.codService.valueChanges
            .subscribe(
                (value) => {
                    this.isCodEnabled = value;

                    if (!this.isCodEnabled) {
                        this.shipInfoAddForm.patchValue({
                            codAmount: 0,
                            isSecuredCod: false,
                        });
                    }

                    this.enforceCODValidators(this.serviceType);
                },
            );

        this.shipInfoAddForm.controls.brokerSelect.valueChanges
            .subscribe(
                (value) => {
                    this.isBrokerSelectEnabled = value;

                    this.validationService.clearFormControlValidators([
                        this.shipInfoAddForm.controls.brokerCompanyName,
                        this.shipInfoAddForm.controls.brokerAddress1,
                        this.shipInfoAddForm.controls.brokerPostalCode,
                        this.shipInfoAddForm.controls.brokerCity,
                        this.shipInfoAddForm.controls.brokerPhoneNumber,
                        this.shipInfoAddForm.controls.brokerStateProvince,
                    ]);

                    if (!this.isBrokerSelectEnabled) {
                        this.shipInfoAddForm.patchValue({
                            brokerAccountNumber: '',
                            brokerCompanyName: '',
                            brokerContactName: '',
                            brokerAddress1: '',
                            brokerAddress2: '',
                            brokerPostalCode: '',
                            brokerCity: '',
                            brokerStateProvince: '',
                            brokerPhoneNumber: '',
                            brokerTaxId: '',
                        });

                        return;
                    }
                    this.shipInfoAddForm.controls.brokerCountryCode.setValue(this.shipToCountryCode);
                    this.validationService.setFormControlValidators(
                        this.shipInfoAddForm.controls.brokerCompanyName,
                        Validators.compose([Validators.required, Validators.maxLength(35)]),
                    );

                    this.validationService.setFormControlValidators(
                        this.shipInfoAddForm.controls.brokerAddress1,
                        Validators.compose([Validators.required, Validators.maxLength(35)]),
                    );

                    this.validationService.setFormControlValidators(
                        this.shipInfoAddForm.controls.brokerCity,
                        Validators.compose([Validators.required, Validators.maxLength(35)]),
                    );

                    this.validationService.setFormControlValidators(
                        this.shipInfoAddForm.controls.brokerPhoneNumber,
                        Validators.compose([Validators.required, Validators.maxLength(25)]),
                    );

                    this.setBrokerStateValidation();

                    // MV3-3525 Remove postal code validation for the countries where Postal code is not required
                    // so commenting out the below lines and invoking setBrokerPostalCodeValidation()
                    // if (+Carriers[this.carrier] !== Carriers.Fedex) {
                    //   this.validationService.setFormControlValidators(
                    //     this.shipInfoAddForm.controls.brokerPostalCode,
                    //     Validators.compose([Validators.required, Validators.maxLength(10)]),
                    //   );
                    // }
                    this.setBrokerPostalCodeValidation();
                },
            );

        this.shipInfoAddForm.controls.saturdayDelivery.valueChanges
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    this.shipInfoAddForm.patchValue({
                        saturdayPickup: false,
                    });
                },
            );

        this.shipInfoAddForm.controls.brokerCountryCode.valueChanges
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    if (this.isBrokerSelectEnabled) {
                        this.setBrokerStateValidation();
                    }
                });

        this.shipInfoAddForm.controls.directDeliveryOnly.valueChanges
            .subscribe(
                (value) => {
                    if (value) {
                        this.shipInfoAddForm.controls.useAccessPoint.setValue(false, { emitEvent: false });
                        this.shipInfoAddForm.controls.useAccessPoint.disable();
                        this.shipInfoAddForm.controls.accessPoint.setValue(null, { emitEvent: false });

                        this.shipInfoAddForm.controls.holdAtFedExLocation.setValue(false, { emitEvent: false });
                        this.shipInfoAddForm.controls.holdAtFedExLocation.disable();

                        this.shipInfoAddForm.controls.usePickupLocation.setValue(false, { emitEvent: false });
                        this.shipInfoAddForm.controls.pickupLocation.setValue(null, { emitEvent: false });

                        return;
                    }

                    this.shipInfoAddForm.controls.useAccessPoint.enable();
                    this.shipInfoAddForm.controls.holdAtFedExLocation.enable();
                },
            );

        this.monitorUseAccessPointValueChanges();
        this.monitorHoldAtFedExLocationValueChanges();

        this.shipmentService.accessPointSelected$.subscribe((isSelected) => {
            const asrControl = this.shipInfoAddForm.get('adultSignature');

            if (!isSelected) {
                asrControl.enable();
                asrControl.setValue(this.isResidentialAddress);
                return;
            }

            asrControl.setValue(false);
            asrControl.disable();
        });

        this.shipmentService.fedexHoldAtLocationSelected$.subscribe((isSelected) => {
            const asrControl = this.shipInfoAddForm.get('adultSignature');

            if (!isSelected) {
                asrControl.enable();
                asrControl.setValue(this.isResidentialAddress);
                return;
            }

            asrControl.setValue(false);
            asrControl.disable();
        });
    }

    private monitorUseAccessPointValueChanges(): void {
        this.shipInfoAddForm.get('useAccessPoint').valueChanges
            .pipe(
                distinctUntilChanged(),
            )
            .subscribe(
                (value) => {
                    if (!value) {
                        this.shipInfoAddForm.get('accessPoint').setValue(null);
                        this.shipInfoAddForm.get('pickupLocation').setValue(null);
                        this.shipmentService.accessPointSelected$.next(false);
                        this.pickupLocationSearchRange = PickupLocationSearchConfig.searchRange.default;
                        this.pickupLocationSearchMaxItems = PickupLocationSearchConfig.maxSearchResults.default;
                        this.shipmentService.savePickupLocation(null);

                        // TODO: only revalidate address in certain cases:
                        //  - ShipTo address is changed.
                        //  - `useAccessPoint` is not deselected via the Address Confidence dialog.
                        // Force an address revalidation.
                        // This should re-trigger any option changes made by opting-in to using an access point.
                        // These changes include "Residential Address" and "Adult Signature Required" options (could be more).
                        // Also, a user could have changed the ShipTo address after selecting an access point.
                        // This address revalidation will ensure that we don't miss anything.
                        // this.shipmentService.requestShipToAddressValidation$.next(true);

                        return;
                    }

                    const shipToAddress = this.shipmentService.Quote.ShipTo;
                    const isValidAddress = shipToAddress?.StreetAddress
                        && shipToAddress?.City
                        && shipToAddress?.State
                        && shipToAddress?.Zip;

                    if (!isValidAddress) {
                        this.shipInfoAddForm.get('useAccessPoint').setValue(false);
                        this.shipmentService.accessPointSelected$.next(false);

                        this.notificationService.notify(
                            'ShipTo address is required',
                            'ShipTo address required',
                            NotificationType.WARNING,
                        );

                        return;
                    }

                    this.openPickupLocationsDialog(Carriers.Ups, null);
                },
            );
    }

    private monitorHoldAtFedExLocationValueChanges(): void {
        this.shipInfoAddForm.get('holdAtFedExLocation').valueChanges
            .pipe(
                distinctUntilChanged(),
            )
            .subscribe(
                (value) => {
                    if (!value) {
                        this.shipInfoAddForm.get('pickupLocation').setValue(null);
                        this.shipmentService.fedexHoldAtLocationSelected$.next(false);
                        this.pickupLocationSearchRange = PickupLocationSearchConfig.searchRange.default;
                        this.pickupLocationSearchMaxItems = PickupLocationSearchConfig.maxSearchResults.default;
                        this.shipmentService.savePickupLocation(null);

                        return;
                    }

                    const shipToAddress = this.shipmentService.Quote.ShipTo;
                    const isValidAddress = shipToAddress?.StreetAddress
                        && shipToAddress?.City
                        && shipToAddress?.State
                        && shipToAddress?.Zip;

                    if (!isValidAddress) {
                        this.shipInfoAddForm.get('holdAtFedExLocation').setValue(false);
                        this.shipmentService.fedexHoldAtLocationSelected$.next(false);

                        this.notificationService.notify(
                            'ShipTo address is required',
                            'ShipTo address required',
                            NotificationType.WARNING,
                        );

                        return;
                    }

                    this.openPickupLocationsDialog(Carriers.Fedex, null);
                },
            );
    }

    private setBrokerStateValidation() {
        const value = this.shipInfoAddForm.controls.brokerCountryCode.value;
        if (value.toLowerCase() === 'us' || value.toLowerCase() === 'ca') {
            this.validationService.setFormControlValidators(
                this.shipInfoAddForm.controls.brokerStateProvince,

                // MV3-3855 - State/Province region field should be mandatory for Country US and CA
                Validators.compose([Validators.required, Validators.maxLength(2)]));
        } else {
            this.validationService.setFormControlValidators(
                this.shipInfoAddForm.controls.brokerStateProvince,
                Validators.compose([Validators.maxLength(2)]));
        }
    }

    private setBrokerPostalCodeValidation() {
        if (this.config.isShipToPostalCodeAware) {
            this.validationService.setFormControlValidators(
                this.shipInfoAddForm.controls.brokerPostalCode,
                Validators.compose([Validators.required, Validators.maxLength(10)]),
            );
        } else {
            this.validationService.setFormControlValidators(
                this.shipInfoAddForm.controls.brokerPostalCode,
                Validators.compose([Validators.maxLength(10)]),
            );
        }
    }

    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        this.shipInfoAddForm.patchValue({
            useAccessPoint: this.shipmentService.Quote.UseAccessPoint,
            accessPoint: this.shipmentService.Quote.AccessPoint,
            useAccessPointCode: this.shipmentService.Quote.UseAccessPointCode,

            saturdayDelivery: this.shipmentService.Quote.IsSaturdayDelivery,
            saturdayPickup: this.shipmentService.Quote.IsSaturdayPickUp,
            adultSignature: this.shipmentService.Quote.IsDeliveryConfirmation,
            directSignature: this.shipmentService.Quote.IsDirectSignature,

            holdAtFedExLocation: this.shipmentService.Quote.IsHoldAtLocation,

            codService: this.shipmentService.Quote.IsCod,
            codAmount: this.shipmentService.Quote.CodAmount,
            isSecuredCod: this.shipmentService.Quote.IsSecuredCod,
            brokerSelect: this.shipmentService.Quote.IsBrokerSelect,

            brokerAccountNumber: this.shipmentService.Quote.BrokerAccountNo,
            brokerCompanyName: this.shipmentService.Quote.BrokerCompanyName,
            brokerContactName: this.shipmentService.Quote.BrokerContactName,
            brokerAddress1: this.shipmentService.Quote.BrokerAddress1,
            brokerAddress2: this.shipmentService.Quote.BrokerAddress2,
            brokerPostalCode: this.shipmentService.Quote.BrokerPostalCode,
            brokerCity: this.shipmentService.Quote.BrokerCity,
            brokerStateProvince: this.shipmentService.Quote.BrokerStateProvince,
            brokerCountryCode: this.shipToCountryCode,
            brokerPhoneNumber: this.shipmentService.Quote.BrokerPhoneNumber,
            brokerTaxId: this.shipmentService.Quote.BrokerTaxID,
        });

        this.restorePickupLocation(this.shipmentService.Quote);

        this.formService.markAllAsTouchedAndDirty(this.shipInfoAddForm);
        this.isValid.emit(this.shipInfoAddForm.valid);
    }

    private onServiceTypeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        const carrierService: ICarrierService = change.currentValue;
        this.enforceCODValidators(carrierService);

        // [MV3-2352] Hide Saturday Delivery for DE and GB customers if Express Saver is selected.
        this.isExpressSaverDEGB = this.userService.isDEGBUser(this.user) && carrierService.ServiceCode === '65';
        if (this.isExpressSaverDEGB) {
            this.shipInfoAddForm.patchValue({
                saturdayDelivery: false,
            });
        }
    }

    private resetBrokerInformation() {
        if (!this.shipInfoAddForm) {
            return;
        }

        this.shipInfoAddForm.patchValue({
            brokerSelect: false,
            brokerAccountNumber: '',
            brokerCompanyName: '',
            brokerContactName: '',
            brokerAddress1: '',
            brokerAddress2: '',
            brokerPostalCode: '',
            brokerCity: '',
            brokerStateProvince: '',
            brokerCountryCode: '',
            brokerPhoneNumber: '',
            brokerTaxId: '',
        });
    }

    private onShipFromCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }
        this.isShowCOD();
        this.isDomestic = change.currentValue === this.shipToCountryCode;
        if (this.isDomestic) {
            this.resetBrokerInformation();
        } else {
            this.shipmentService.savePickupLocation(null);
        }

        if (this.config && !this.config.showCod) { // MV3-3480
            this.shipInfoAddForm.controls.codService.setValue(false);
        }

    }

    private onShipToCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }
        this.isShowCOD();
        this.isDomestic = change.currentValue === this.shipFromCountryCode;

        if (this.config && !this.config.showCod) { // MV3-3480
            this.shipInfoAddForm.controls.codService.setValue(false);
        }

        if (this.isDomestic) {
            this.resetBrokerInformation();
        } else {
            this.shipmentService.savePickupLocation(null);
        }

        if (this.shipInfoAddForm) {
            this.shipInfoAddForm.controls.brokerCountryCode.setValue(change.currentValue);
        }

        if (this.isRestoreShipment) {
            return;
        }

        if (this.config && this.shipInfoAddForm) {
            this.shipInfoAddForm.controls.directSignature.setValue(this.config.isDirectSignature);
        }
    }

    private enforceCODValidators(carrierServiceType: ICarrierService) {
        if (!this.shipInfoAddForm) {
            return;
        }

        if (!this.isCodEnabled) {
            this.validationService.clearFormControlValidators([
                this.shipInfoAddForm.controls.codAmount,
                this.shipInfoAddForm.controls.isSecuredCod,
            ]);

            return;
        }

        if (!carrierServiceType) {
            return;
        }

        this.maxCODValue = this.shipInfoAddForm.controls.isSecuredCod.value ?
            parseFloat(carrierServiceType.SecuredCODValue) :
            parseFloat(carrierServiceType.CODValue);
        this.validationService.setFormControlValidators(
            this.shipInfoAddForm.controls.codAmount,
            Validators.compose([Validators.max(this.maxCODValue)]),
        );

        // this.shipInfoAddForm.controls.codAmount.markAsTouched();
    }

    private resetAccessPoint(): void {
        const isUsingAccessPoint = this.shipInfoAddForm?.get('useAccessPoint')?.value as boolean;
        if (!isUsingAccessPoint) {
            return;
        }

        this.shipInfoAddForm?.patchValue(
            {
                useAccessPoint: false,
                accessPoint: null,
            },
            {
                emitEvent: false,
                onlySelf: true,
            });

        this.shipmentService.accessPointSelected$.next(false);

        this.snackBar.open('Zip code change detected. Access point has been reset.', '', { duration: 3000 });
    }
}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { IContact } from '../../shared/models/contact.interface';
import { Carriers } from '../../shared/enum/general-enum';
import { Subject } from 'rxjs';
import { PaginationInstance } from 'ngx-pagination';
import * as _ from 'lodash';
import { SiteConfig } from '../../../config/site-config';

@Component({
  selector: 'upsc-address-book-dialog',
  templateUrl: './address-book-search-dialog.component.html',
  styleUrls: ['./address-book-search-dialog.component.scss'],
})
export class AddressBookSearchDialogComponent implements OnInit {
  @Output() contactSelected: EventEmitter<IContact> = new EventEmitter<IContact>();

  public carrier: Carriers;
  public searchResults: IContact[];
  public displayedColumns = ['NickName', 'ContactName', 'CompanyName', 'StreetAddress', 'City', 'State', 'Country', 'Actions'];
  public isSearching = false;
  public pageEvent: PageEvent = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  public availableCountryCodes: string[];
  public forbiddenCountryCodes: string[];

  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();

  public paginate: PaginationInstance;
  private totalItems = 0;
  private tablePageIndex = 1;
  private tablePageSize = 10;

  public constructor(public dialogRef: MatDialogRef<AddressBookSearchDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private readonly translateService: TranslateService,
  ) {
    this.carrier = data.carrier;
    this.availableCountryCodes = data.availableCountryCodes;
    this.forbiddenCountryCodes = data.forbiddenCountryCodes;
  }

  public ngOnInit() {
    this.dtOptions = SiteConfig.tableConfig;
    this.dtOptions.language = { emptyTable: this.translateService.instant('NoRecordsFound') };

    this.updatePaginateConfig();
  }

  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.tablePageIndex,
      itemsPerPage: this.tablePageSize,
    };
  }

  public onSearchResultsChanged(contacts: IContact[]) {
    this.searchResults = _.cloneDeep(contacts);
    if (this.searchResults && this.searchResults.length) {
      this.totalItems = this.searchResults[0].TotalContacts;
    }

    this.updatePaginateConfig();

    this.dtTrigger.next(null);
  }

  public onSearchingStatusChanged(isSearching: boolean) {
    this.isSearching = isSearching;
  }

  public onPageChanged(pageNumber: number) {
    this.pageEvent.pageIndex = pageNumber - 1;
    this.pageEvent = _.cloneDeep(this.pageEvent);
    this.tablePageIndex = pageNumber;
    this.updatePaginateConfig();
  }

  public onContactSelected(contact: IContact) {
    this.contactSelected.emit(contact);
    this.dialogRef.close(contact);
  }

  public isSearchResultDisplayed(isSearchResult: boolean) {
    if (isSearchResult) {
      this.tablePageSize = this.totalItems > 0 ? this.totalItems * 99 : 999;
    }
    else this.tablePageSize = 10;
    
    this.updatePaginateConfig();
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Carriers } from '../../shared/enum/general-enum';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ShipmentService } from '../services/shipment.service';
import { GetShippingUpdatesDialogComponent } from './get-shipping-updates-dialog.component';
import { IShippingUpdateInformation } from './models/shipping-update-information.interface';

@Injectable({
    providedIn: 'root',
})
export class GetShippingUpdatesDialogService {
    constructor(private shipmentService: ShipmentService,
                private dialog: MatDialog,
                private utilityService: UtilityService,
    ) {
    }

    public open(data?: Partial<IShippingUpdateInformation>): MatDialogRef<GetShippingUpdatesDialogComponent> {
        const dialogConfig: MatDialogConfig<IShippingUpdateInformation> = {
            disableClose: true,
            width: '39.875rem',
            panelClass: 'mobile-fullscreen-dialog',
            data: this.utilityService.deepMerge(this.getDefaultShippingInformation(), data),
        };

        return this.dialog.open(GetShippingUpdatesDialogComponent, dialogConfig);
    }

    private getDefaultShippingInformation(): IShippingUpdateInformation {
        return {
            carrier: Carriers.Ups,
            shouldSendToSender: true,
            sender: {
                firstName: this.shipmentService.Quote.ShipFrom.FirstName || '',
                lastName: this.shipmentService.Quote.ShipFrom.LastName || '',
                email: this.shipmentService.Quote.ShipFrom.Email || '',
            },
            recipients: [
                {
                    firstName: this.shipmentService.Quote.ShipTo.FirstName || '',
                    lastName: this.shipmentService.Quote.ShipTo.LastName || '',
                    email: this.shipmentService.Quote.ShipTo.Email || '',
                },
            ],
        };
    }
}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

/**
 * Service to handle navigation-related functionalities within the application.
 * This service listens for router navigation events and provides methods to determine
 * the navigation state, such as whether a page refresh has occurred.
 *
 * @example
 * // Import the NavigationService in your component
 * // constructor(private navigationService: NavigationService) {}
 *
 * // Check if the current navigation is a refresh
 * const isPageRefresh = this.navigationService.isRefresh();
 *
 * @see Router
 * @see NavigationEnd
 */
@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private navigationId = 0;

    public constructor(private readonly router: Router) {
        this.subscribeToNavigationEndEvents();
    }

    /**
     * Subscribes to the router's navigation end events and updates the navigation ID whenever a navigation ends.
     *
     * @return {void} This method returns nothing.
     */
    private subscribeToNavigationEndEvents(): void {
        this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        ).subscribe(({ id }) => {
            this.navigationId = id;
        });
    }

    /**
     * Determines if the current navigation state indicates a refresh.
     * Typically, a navigation ID of 1 or less signifies that the page
     * has been refreshed.
     *
     * @return {boolean} True if the navigation state corresponds to a refresh; otherwise, false.
     */
    public isRefresh(): boolean {
        return this.navigationId <= 1;
    }
}

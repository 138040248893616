import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AccountModule } from '../account/account.module';
import { AddressBookSearchModule } from '../address-book-search/address-book-search.module';
import { CarrierPipe } from '../history/pipes/carrier.pipe';
import { LayoutModule } from '../layout/layout.module';
import { NotifyTemplatesModule } from '../notify-templates/notify-templates.module';
import { NotifyTemplateService } from '../notify-templates/services/notify-template.service';
import { DataTableModule } from '../shared/components/data-table/data-table.module';
import { ElementBlockerModule } from '../shared/components/element-blocker/element-blocker.module';
import { FileSelectorModule } from '../shared/components/file-selector/file-selector.module';
import {
    PaperlessCommercialInvoiceModule,
} from '../shared/components/paperless-commercial-invoice/paperless-commercial-invoice.module';
import {
    PaperlessCommercialInvoiceService,
} from '../shared/components/paperless-commercial-invoice/services/paperless-commercial-invoice.service';
import { S3FileDownloaderModule } from '../shared/components/s3-file-downloader/s3-file-downloader.module';
import { ShipDetailsActionsModule } from '../shared/components/ship-details-actions/ship-details-actions.module';
import { ShipDetailsDialogModule } from '../shared/components/ship-details-dialog/ship-details-dialog.module';
import { ShipDetailsModule } from '../shared/components/ship-details/ship-details.module';
import { ShipLabelModule } from '../shared/components/ship-label/ship-label.module';
import { ShipmentPipesModule } from '../shared/pipes/shipment/shipment-pipes.module';
import { DialogModule } from '../shared/services/dialog/dialog.module';
import { ErrorHandlerService } from '../shared/services/error-handler/error-handler.service';
import { SharedModule } from '../shared/shared.module';
import {
    AccessPointsLearnMoreDialogComponent,
} from './access-points-dialog/access-point-learn-more/access-points-learn-more-dialog.component';
import { AccessPointsDialogComponent } from './access-points-dialog/access-points-dialog.component';
import { AddressConfidenceComponent } from './address-confidence/address-confidence.component';
import {
    ResidentialAccessPointSuggestionDialogComponent,
} from './address-dialogs/residential-access-point-suggestion-dialog/residential-access-point-suggestion-dialog.component';
import {
    DeliveryDefenseScoreInformationDialogComponent,
} from './components/delivery-defense/delivery-defense-score-information-dialog/delivery-defense-score-information-dialog.component';
import {
    DDRemainingCreditsComponent,
} from './components/delivery-defense/remaining-credits/dd-remaining-credits.component';
import { ImportInformationComponent } from './components/import-information/import-information.component';
import { PriceComparisonComponent } from './components/price-comparison/price-comparison.component';
import { RequestQuoteDialogComponent } from './components/request-quote-dialog/request-quote-dialog.component';
import { ReturnOptionsComponent } from './components/return-options/return-options.component';
import { ShipCountriesComponent } from './components/ship-countries/ship-countries.component';
import { ConfirmedShipmentDetailsComponent } from './confirmed-shipment-details/confirmed-shipment-details.component';
import {
    FedExLocationLearnMoreDialogComponent,
} from './fedex-location-dialog/fedex-location-learn-more/fedex-location-learn-more-dialog.component';
import { GetShippingUpdatesDialogModule } from './get-shipping-updates-dialog/get-shipping-updates-dialog.module';
import { LocationEditDialogComponent } from './location-edit-dialog/location-edit-dialog.component';
import { LocationSearchDialogComponent } from './location-search-dialog/location-search-dialog.component';
import {
    LocationSearchTableComponent,
} from './location-search-dialog/location-search-table/location-search-table.component';
import { NoCoverageWarningDialogComponent } from './no-coverage-warning-dialog/no-coverage-warning-dialog.component';
import { PickupLocationInfoModule } from './pickup-location-info/pickup-location-info.module';
import { PickupLocationsDialogModule } from './pickup-locations-dialog/pickup-locations-dialog.module';
import { PickupLocationsModule } from './pickup-locations/pickup-locations.module';
import {
    ReturnShipFromEditComponent,
} from './return-shipment/return-ship-from/return-ship-from-edit/return-ship-from-edit.component';
import {
    ReturnShipFromStaticComponent,
} from './return-shipment/return-ship-from/return-ship-from-static/return-ship-from-static.component';
import { ReturnShipFromComponent } from './return-shipment/return-ship-from/return-ship-from.component';
import {
    ReturnShipToEditComponent,
} from './return-shipment/return-ship-to/return-ship-to-edit/return-ship-to-edit.component';
import {
    ReturnShipToStaticComponent,
} from './return-shipment/return-ship-to/return-ship-to-static/return-ship-to-static.component';
import { ReturnShipToComponent } from './return-shipment/return-ship-to/return-ship-to.component';
import { ReturnShipmentComponent } from './return-shipment/return-shipment.component';
import { ShipConfigService } from './services/ship-config.service';
import { ShipmentAesService } from './services/shipment-aes.service';
import { ShipmentService } from './services/shipment.service';
import { ShipBillingDetailsComponent } from './ship-billing-details/ship-billing-details.component';
import { CommodityFormDialogComponent } from './ship-commodity/commodity-form-dialog/commodity-form-dialog.component';
import { CommodityShipInfoComponent } from './ship-commodity/commodity-ship-info/commodity-ship-info.component';
import { CommodityTableComponent } from './ship-commodity/commodity-table/commodity-table.component';
import { ShipCommodityComponent } from './ship-commodity/ship-commodity.component';
import { ShipCustomsDocsComponent } from './ship-customs-docs/ship-customs-docs.component';
import { ShipElectronicExportComponent } from './ship-electronic-export/ship-electronic-export.component';
import { ShipFromEditComponent } from './ship-from/ship-from-edit/ship-from-edit.component';
import { ShipFromStaticComponent } from './ship-from/ship-from-static/ship-from-static.component';
import { ShipFromComponent } from './ship-from/ship-from.component';
import { ShipInfoAddComponent } from './ship-info-add/ship-info-add.component';
import { ShipInfoComponent } from './ship-info/ship-info.component';
import { ShipOptionsComponent } from './ship-options/ship-options.component';
import { routedComponents, ShipRoutingModule } from './ship-routing.module';
import { ShipToEditComponent } from './ship-to/ship-to-edit/ship-to-edit.component';
import { ShipToStaticComponent } from './ship-to/ship-to-static/ship-to-static.component';
import { ShipToComponent } from './ship-to/ship-to.component';
import { ShipComponent } from './ship.component';
import { ShipResolver } from './ship.resolver';

@NgModule({
    imports: [
        AccountModule,
        AddressBookSearchModule,
        CommonModule,
        DataTableModule,
        DialogModule,
        ElementBlockerModule,
        LayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatOptionModule,
        MatRadioModule,
        MatSelectModule,
        MatTableModule,
        MatTooltipModule,
        NotifyTemplatesModule,
        PaperlessCommercialInvoiceModule,
        ReactiveFormsModule,
        routedComponents,
        S3FileDownloaderModule,
        ShipDetailsActionsModule,
        ShipDetailsDialogModule,
        ShipDetailsModule,
        ShipLabelModule,
        ShipmentPipesModule,
        ShipRoutingModule,
        FileSelectorModule,
        TranslateModule,
        SharedModule,
        MatSliderModule,
        FormsModule,
        NgOptimizedImage,
        GetShippingUpdatesDialogModule,
        PickupLocationsModule,
        PickupLocationsDialogModule,
        PickupLocationInfoModule,
    ],
    declarations: [
        CommodityFormDialogComponent,
        CommodityShipInfoComponent,
        CommodityTableComponent,
        ConfirmedShipmentDetailsComponent,
        LocationEditDialogComponent,
        LocationSearchDialogComponent,
        LocationSearchTableComponent,
        PriceComparisonComponent,
        RequestQuoteDialogComponent,
        ShipBillingDetailsComponent,
        ShipCommodityComponent,
        ShipComponent,
        ShipCountriesComponent,
        ShipCustomsDocsComponent,
        ShipElectronicExportComponent,
        ShipFromComponent,
        ShipFromEditComponent,
        ShipFromStaticComponent,
        ShipInfoAddComponent,
        ShipInfoComponent,
        ShipOptionsComponent,
        ShipToComponent,
        ShipToEditComponent,
        ShipToStaticComponent,
        ReturnShipmentComponent,
        ReturnOptionsComponent,
        ReturnShipFromComponent,
        ReturnShipFromEditComponent,
        ReturnShipFromStaticComponent,
        ReturnShipToComponent,
        ReturnShipToEditComponent,
        ReturnShipToStaticComponent,
        ImportInformationComponent,
        DDRemainingCreditsComponent,
        AccessPointsDialogComponent,
        AccessPointsLearnMoreDialogComponent,
        FedExLocationLearnMoreDialogComponent,
        ResidentialAccessPointSuggestionDialogComponent,
        AddressConfidenceComponent,
        NoCoverageWarningDialogComponent,
        DeliveryDefenseScoreInformationDialogComponent,
    ],
    exports: [DDRemainingCreditsComponent],
    providers: [
        CarrierPipe,
        NotifyTemplateService,
        ShipConfigService,
        ShipmentService,
        ShipmentAesService,
        ErrorHandlerService,
        PaperlessCommercialInvoiceService,
        ShipResolver,
    ],
})
export class ShipModule {
}

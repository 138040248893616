<div *ngIf="ddScore" [class.no-options]="!shouldShowOptions && !shouldSuggestOnNoScore"
     class="circular-gauge-container">
    <upsc-circular-confidence-gauge [backgroundColor]="shouldShowOptions ? '#E7F2F2' : '#FFF'"
                                    [value]="ddScore"></upsc-circular-confidence-gauge>
</div>

<div [class.no-gauge]="confidenceLevel === 'none'" [class.no-options]="!shouldShowOptions && !shouldSuggestOnNoScore"
     class="dd-score-header">
    <div class="address">
        <span *ngIf="confidenceLevel !== 'high'">{{ addressString }}</span>
        <span *ngIf="confidenceLevel === 'high'"
              class="greeting">{{ 'addressConfidence.high.congratulations' | translate }}</span>
    </div>
    <div class="message">
        <ng-container *ngIf="confidenceLevel !== 'none'">
            <span [innerHTML]="'addressConfidence.' + confidenceLevel + '.message' | translate: { addressType: addressType }"></span>
            <span *ngIf="addressType === 'residential'"
                  [innerHTML]="'addressConfidence.' + confidenceLevel + '.message2' | translate: { addressType: addressType }"></span>
        </ng-container>
        <ng-container *ngIf="confidenceLevel === 'none'">
        <span *ngIf="!shouldSuggestOnNoScore" class="no-score first">
            <span class="with-icon">
                <mat-icon>warning</mat-icon>
                <span [innerHTML]="'addressConfidence.none.message' | translate"></span>
            </span>
        </span>
            <span *ngIf="shouldSuggestOnNoScore" class="no-score second">
            <span class="with-icon">
                <mat-icon>warning</mat-icon>
                @if (shipmentCarrier === Carriers.Ups) {
                    <span [innerHTML]="'addressConfidence.noneAgain.ups.message' | translate"></span>
                } @else if (shipmentCarrier === Carriers.Fedex) {
                    <span [innerHTML]="'addressConfidence.noneAgain.fedex.message' | translate"></span>
                }
            </span>
        </span>
        </ng-container>
        <ng-container *ngIf="isCachedDDScore">
        <span class="cached">
            <mat-icon>warning</mat-icon>
            <span [innerHTML]="'addressConfidence.cached' | translate"></span>
        </span>
        </ng-container>
    </div>

    <div *ngIf="ddScore" class="linear-gauge-container">
        <upsc-confidence-gauge [shouldShowCoverageRange]="confidenceLevel === 'low'"
                               [value]="ddScore"></upsc-confidence-gauge>
    </div>
</div>

<form [formGroup]="formGroup">
    <div [class.no-options]="!shouldShowOptions && !shouldSuggestOnNoScore" class="options">
        <!-- low/average residential: UPS Access Point / FedEx HoldAt Location -->
        <label *ngIf="((confidenceLevel === 'average' || confidenceLevel === 'low') && addressType === 'residential') || shouldSuggestOnNoScore"
               [class.selected]="selectedAction === 'pickupLocation'"
               class="custom-radio">
            <span class="option-label">
                <span *ngIf="confidenceLevel === 'low'">{{ 'addressConfidence.option.label.recommended' | translate }}</span>
                <span *ngIf="confidenceLevel === 'average' || confidenceLevel === 'none'">{{ 'addressConfidence.option.label.recommended' | translate }}</span>
            </span>
            <input formControlName="action" type="radio" value="pickupLocation">
            <span class="circle"></span>
            <span class="radio-content">
            <span class="radio-message">
                <ng-container *ngIf="shipmentCarrier === Carriers.Ups">
                    <span [innerHTML]="'addressConfidence.option.accessPoint.title' | translate"
                          class="title"></span>
                    <span class="details">
                        <a (click)="openUPSAccessPointLearnMoreDialog($event)" href="#" tabindex="-1">
                            {{ 'addressConfidence.option.learnMore' | translate }}
                        </a>
                    </span>

                    @if (value < 500) {
                        <span class="remark">
                            <span>{{ 'addressConfidence.option.accessPoint.remark' | translate }}</span>
                        </span>
                    }
                </ng-container>
                <ng-container *ngIf="shipmentCarrier === Carriers.Fedex">
                    <span [innerHTML]="'addressConfidence.option.holdAtLocation.title' | translate"
                          class="title"></span>
                    <span class="details">
                        <a (click)="openFedExLocationLearnMoreDialog($event)" href="#" tabindex="-1">
                            {{ 'addressConfidence.option.learnMore' | translate }}
                        </a>
                    </span>

                    @if (value < 500) {
                        <span class="remark">
                            <span>{{ 'addressConfidence.option.holdAtLocation.remark' | translate }}</span>
                        </span>
                    }
                </ng-container>
            </span>
            <span class="message-icon">
                <img *ngIf="shipmentCarrier === Carriers.Ups" alt="UPS" class="logo ups"
                     height="29.75347" ngSrc="assets/images/logos/logo_ups.png" width="25">
                <img *ngIf="shipmentCarrier === Carriers.Fedex" alt="FedEx" class="logo fedex"
                     height="13.86006" ngSrc="assets/icons/logo-fedex.svg" width="50">
            </span>
        </span>
        </label>

        <!-- low commercial: request signature -->
        <label *ngIf="confidenceLevel === 'low' && addressType === 'commercial'"
               [class.selected]="selectedAction === 'direct-signature'"
               class="custom-radio">
            <span class="option-label">
                @if (value < 500) {
                    {{ 'addressConfidence.option.label.recommended' | translate }}
                } @else {
                    {{ 'addressConfidence.option.label.withCoverage' | translate }}
                }
            </span>
            <input formControlName="action" type="radio" value="direct-signature">
            <span class="circle"></span>
            <span class="radio-content">
            <span class="radio-message">
                <span class="title">{{ 'addressConfidence.option.requestSignature.title' | translate }}</span>
                <span class="details">{{ 'addressConfidence.option.requestSignature.details' | translate }}</span>
                <span class="details">
                    <a (click)="openLearnMoreDialog($event, 'commercial-low')" href="#" tabindex="-1">
                        {{ 'addressConfidence.option.learnMore' | translate }}
                    </a>
                </span>
            </span>
        </span>
        </label>

        <!-- enter a different address (close the dialog) -->
        <label *ngIf="(addressType === 'commercial' && confidenceLevel === 'low') || (addressType === 'residential' && confidenceLevel !== 'high')  || shouldSuggestOnNoScore"
               [class.selected]="selectedAction === 'cancel'"
               class="custom-radio">
            <input formControlName="action" type="radio" value="cancel">
            <span class="circle"></span>
            <span class="radio-content">
            <span class="radio-message">
                <span [innerHtml]="'addressConfidence.option.cancel.title' | translate" class="title"></span>
                <span [innerHTML]="'addressConfidence.option.cancel.remark' | translate" class="remark"></span>
            </span>
        </span>
        </label>

        <!-- low commercial: no signature -->
        <label *ngIf="confidenceLevel === 'low' && addressType === 'commercial'"
               [class.selected]="selectedAction === 'no-signature'"
               class="custom-radio error">

            @if (value >= 500) {
                <span class="option-label">{{ 'addressConfidence.option.label.noCoverage' | translate }}</span>
            }

            <input formControlName="action" type="radio" value="no-signature">
            <span class="circle error"></span>
            <span class="radio-content">
            <span class="radio-message">
                <span class="title">{{ 'addressConfidence.option.noSignature.title' | translate }}</span>
                <span class="details">{{ 'addressConfidence.option.noSignature.details' | translate }}</span>
                <span class="details">
                    <a (click)="openLearnMoreDialog($event, 'commercial-low')" href="#" tabindex="-1">
                        {{ 'addressConfidence.option.learnMore' | translate }}
                    </a>
                </span>
            </span>
        </span>
        </label>

        <!-- low residential: proceed with low -->
        <label *ngIf="confidenceLevel === 'low' && addressType === 'residential'"
               [class.selected]="selectedAction === 'low'"
               class="custom-radio error">

            @if (value >= 500) {
                <span class="option-label">{{ 'addressConfidence.option.label.noCoverage' | translate }}</span>
            }

            <input formControlName="action" type="radio" value="low">
            <span class="circle error"></span>
            <span class="radio-content">
            <span class="radio-message">
                <span class="title">{{ 'addressConfidence.option.proceedWithLow.title' | translate }}</span>
                @if (value < 500) {
                    @if (carrier === Carriers.Ups || carrier?.toString().toLowerCase() === "ups") {
                        <span class="remark">{{ 'addressConfidence.option.proceedWithLow.remarkUPS' | translate }}</span>
                    } @else if (carrier === Carriers.Fedex || carrier?.toString().toLowerCase() === "fedex") {
                        <span class="remark">{{ 'addressConfidence.option.proceedWithLow.remarkFedEx' | translate }}</span>
                    }
                } @else {
                    <span class="details">{{ 'addressConfidence.option.proceedWithLow.details' | translate }}</span>
                }
            </span>
            <span class="message-icon">
                <mat-icon>warning_amber</mat-icon>
            </span>
        </span>
        </label>

        <!-- average residential: request signature & proceed with average -->
        <label *ngIf="confidenceLevel === 'average' && addressType === 'residential'"
               [class.selected]="selectedAction === 'average'"
               class="custom-radio">
            <span class="option-label"></span>
            <input formControlName="action" type="radio" value="average">
            <span class="circle"></span>
            <span class="radio-content">
            <span class="radio-message">
                <span class="title">{{ 'addressConfidence.option.proceedWithAverage.title' | translate }}</span>
                <span class="details">{{ 'addressConfidence.option.proceedWithAverage.details' | translate }}</span>
                                <span class="details"><a (click)="openLearnMoreDialog($event, 'residential-average')"
                                                         href="#" tabindex="-1">
                        {{ 'addressConfidence.option.learnMore' | translate }}
                </a></span>
            </span>
            <span class="message-icon">
                <mat-icon>warning_amber</mat-icon>
            </span>
        </span>
        </label>

        <!-- no score -->
        <label *ngIf="shouldSuggestOnNoScore"
               [class.selected]="selectedAction === 'none'"
               class="custom-radio">
            <span class="option-label"></span>
            <input formControlName="action" type="radio" value="none">
            <span class="circle"></span>
            <span class="radio-content">
            <span class="radio-message">
                <span class="title">{{ 'addressConfidence.option.proceedWithNone.title' | translate }}</span>
            </span>
            <span class="message-icon">
                <mat-icon>warning_amber</mat-icon>
            </span>
        </span>
        </label>
    </div>

    <div *ngIf="(confidenceLevel !== 'high' && addressType === 'residential') || (confidenceLevel === 'low' && addressType === 'commercial') || confidenceLevel === 'none'"
         class="actions">
        <ng-container *ngIf="confidenceLevel !== 'none' || shouldSuggestOnNoScore">
            <button (click)="closeParentDialog.emit(selectedAction)"
                    [disabled]="!formGroup.valid"
                    class="action continue"
                    color="primary"
                    mat-raised-button>
                <span [innerHTML]="'addressConfidence.action.continue' | translate"></span>
            </button>
        </ng-container>
        <ng-container *ngIf="false">
            <button (click)="closeParentDialog.emit(selectedAction)"
                    *ngIf="ddScore || shouldSuggestOnNoScore" [disabled]="!formGroup.valid" class="action"
                    color="primary"
                    mat-raised-button>
                <span *ngIf="selectedAction !== 'low'">{{ 'addressConfidence.action.generateLabel' | translate }}</span>
                <span *ngIf="selectedAction === 'low'">{{ 'addressConfidence.action.continue' | translate }}</span>
            </button>
        </ng-container>
        <ng-container *ngIf="confidenceLevel === 'none' && !shouldShowOptions && !shouldSuggestOnNoScore">
            <button (click)="closeParentDialog.emit(null)" class="action cancel" mat-stroked-button>
            <span *ngIf="ddScore || shouldSuggestOnNoScore"
                  [innerHTML]="'addressConfidence.action.cancel' | translate"></span>
                <span *ngIf="confidenceLevel === 'none' && !shouldSuggestOnNoScore"
                      [innerHTML]="'addressConfidence.action.noScoreFirstAttempt' | translate"></span>
            </button>
            <span class="remark">{{ 'addressConfidence.action.cancelRemark' | translate }}</span>
        </ng-container>
    </div>
</form>

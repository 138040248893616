<div class="return-ship-to ups-form" [formGroup]="formGroup">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{ 'ShipTo' | translate }}</h5>
            <div class="form-section-actions" *ngIf="!user?.IsGuest">
                <div class="address-book-search">
                    <upsc-address-book-search class="form-section-action"
                                              [carrier]="carrier"
                                              [currentUserIsAdmin]="currentUserIsAdmin"
                                              (contactSelected)="onContactSelected($event)"></upsc-address-book-search>
                    <!--                    <button *ngIf = "isEUUser" mat-raised-button-->
<!--                            type="button"-->
<!--                            class="alternative-button"-->
<!--                            [currentUserIsAdmin]="currentUserIsAdmin"-->
<!--                            (click)="openEditLocationDialog($event)">-->
<!--                            {{'AddressBook'|translate}}-->
<!--                    </button>-->
                </div>              
                <a href="#"
                   class="form-section-action ship-to-edit hide-me"
                   (click)="editShipToLocation($event)">
                    <span>{{'Edit'|translate}}</span>
                </a>
            </div>
        </div>

        <div class="location-selector-wrapper">
            <div class="select-wrapper" class="location-selector" *ngIf="isShowAddressBook">
                <mat-form-field>
                    <mat-label>{{'selectLocation'|translate}}</mat-label>
                    <mat-select #locationSelector
                                formControlName="location">
                        <mat-option value="" disabled selected>{{'SelectALocation'|translate}}</mat-option>                        
                        <mat-option value="0">{{'ShipToOneTime'|translate}}</mat-option>
                        <mat-option *ngFor="let location of locations" [value]="location.ContactId">
                            {{ location.NickName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <upsc-element-blocker *ngIf="isLocationsLoading"></upsc-element-blocker>
            </div>

            <div class="edit-location" *ngIf="!user?.IsGuest && !isLocationEditable">
                <a href="#" (click)="openEditLocationDialog($event)">
                    <span>{{'EditLocation'|translate}}</span>
                </a>
            </div>
        </div>

        <span class="location-id hide-me">{{ formGroup.controls.location.value }}</span>
        <upsc-return-ship-to-static
                #shipToStatic
                *ngIf="!isLocationEditable"
                [user]="user"
                [location]="shipToAddress"
                [shouldDisplayEUFields]="shouldDisplayEUFields"
                (countryCodeChanged)="onShipToCountryCodeChanged($event)"
                (zipCodeChanged)="onShipToZipCodeChanged($event)"
                (isValid)="setFormValidity($event)"
                (formValueChanged)="onFormValueChanged($event)"></upsc-return-ship-to-static>
        <upsc-return-ship-to-edit
                #shipToEdit
                [class.hide-me]="!isLocationEditable"
                [user]="user"
                [customer]="customer"
                [location]="shipToAddress"
                [carrier]="carrier"
                [isFRITUser]="isFRITUser"
                [shipToCountryCode]="shipToCountryCode"
                [shipFromCurrencyCode]="shipFromCurrencyCode"
                [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                [isRestoreShipment]="isRestoreShipment"
                [shouldDisplayEUFields]="shouldDisplayEUFields"
                (countryChanged)="onShipToCountryChanged($event)"
                (countryCodeChanged)="onShipToCountryCodeChanged($event)"
                (zipCodeChanged)="onShipToZipCodeChanged($event)"
                (isValid)="setFormValidity($event)"
                (formValueChanged)="onFormValueChanged($event)"></upsc-return-ship-to-edit>
    </div>
</div>

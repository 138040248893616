<div class="shipping">
    <div *ngIf="shouldShowDeliveryDefenseBanner"
         class="dd-banner">
        <div class="dd-logo">
            <img alt="Delivery Defense - Address Confidence"
                 width="172"
                 height="62"
                 [ngSrc]="'assets/images/logos/logo-dd-shipping-intelligence.svg'">
        </div>
        <div class="dd-content">
            <div [innerHTML]="'ship.shipUps.ddBanner.ddDescription' | translate" class="dd-description"></div>
            <button (click)="signUpForDeliveryDefense($event)"
                    *ngIf="canGetComplimentaryScores"
                    class="cta secondary-button"
                    mat-raised-button>{{ 'ship.shipUps.ddBanner.cta' | translate }}</button>
        </div>
    </div>

    <upsc-price-comparison *ngIf="carrierCode !== 'UPS'"
                           [user]="user"></upsc-price-comparison>
    <div class="shipping-content">
        <form (ngSubmit)="onFormSubmitted($event, parentForm.value)" [formGroup]="parentForm" class="ups-form">
            <ng-container [ngTemplateOutlet]="header"></ng-container>

            <div class="ship-sections-wrapper form-body">
                <div class="ship-sections">
                    <div class="ship-section">
                        <upsc-ship-to #shipTo
                                      (addressValidationChanged)="onAddressValidationChanged($event)"
                                      (adultSignatureRequested)="onAdultSignatureRequested($event)"
                                      (countryChanged)="onShipToCountryChanged($event)"
                                      (countryCodeChanged)="onShipToCountryCodeChanged($event)"
                                      (forceClearAdultSignatureRequiredChanged)="onForceClearAdultSignatureRequiredChanged($event)"
                                      (formValueChanged)="onFormValueChanged($event)"
                                      (isResidentialAddressChanged)="onIsResidentialAddressChanged($event)"
                                      (isValid)="setFormValidity('shipTo', $event)"
                                      (residentialCheckChanged)="onResidentialCheckChanged($event)"
                                      (shipToAddressChanged)="onShipToAddressChanged($event)"
                                      (zipCodeChanged)="onShipToZipCodeChanged($event)"
                                      [carrier]="carrier"
                                      [customer]="customer"
                                      [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                                      [restrictedCountryCodes]="restrictedShipToCountryCodes"
                                      [shipFromCountryCode]="shipFromCountryCode"
                                      [shipFromCurrencyCode]="shipFromCurrencyCode"
                                      [shouldDisplayEUFields]="shouldDisplayShipToEUFields"
                                      [user]="user"
                                      class="ship-section-item"></upsc-ship-to>
                        <upsc-ship-from #shipFrom
                                        (countryChanged)="onShipFromCountryChanged($event)"
                                        (countryCodeChanged)="onShipFromCountryCodeChanged($event)"
                                        (formValueChanged)="onFormValueChanged($event)"
                                        (isValid)="setFormValidity('shipFrom', $event)"
                                        (shipFromAddressChanged)="onShipFromAddressChanged($event)"
                                        (zipCodeChanged)="onShipFromZipCodeChanged($event)"
                                        [carrier]="carrier"
                                        [customer]="customer"
                                        [isRestoreShipment]="isRestoreShipment"
                                        [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                                        [shipFromCountryCode]="shipFromCountryCode"
                                        [shipFromCurrencyCode]="shipFromCurrencyCode"
                                        [shouldDisplayEUFields]="shouldDisplayShipFromEUFields"
                                        [user]="user"
                                        class="ship-section-item"></upsc-ship-from>
                    </div>
                    <div class="ship-section">
                        <upsc-ship-info #shipInfo
                                        (formValueChanged)="onFormValueChanged($event)"
                                        (insuredValueChanged)="onInsuredValueChanged($event)"
                                        (isValid)="setFormValidity('shipInfo', $event)"
                                        (selectedServiceTypeChanged)="onServiceTypeChanged($event)"
                                        (shipDateChanged)="onShipDateChanged($event)"
                                        [carrier]="carrier"
                                        [countryMaxCoverage]="countryMaxCoverage"
                                        [customer]="customer"
                                        [dateFormat]="dateFormat"
                                        [dimensionUnit]="dimensionUnit"
                                        [insuredValueCurrency]="insuredValueCurrencyCode"
                                        [isDomestic]="isDomestic"
                                        [isReship]="isReship"
                                        [isRestoreShipment]="isRestoreShipment"
                                        [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                                        [shipFromCountryCode]="shipFromCountryCode"
                                        [shipFromCountry]="shipFromCountry"
                                        [shipFromCurrencyCode]="shipFromCurrencyCode"
                                        [shipFromZipCode]="shipFromZipCode"
                                        [shipToCountryCode]="shipToCountryCode"
                                        [shipToCountry]="shipToCountry"
                                        [shipToZipCode]="shipToZipCode"
                                        [totalWeight]="totalWeight"
                                        [user]="user"
                                        [weightUOM]="weightUOM"
                                        class="ship-section-item"></upsc-ship-info>
                        <div class="commodity-ship-info-section">
                            @if (shipmentStateService.isInternational()) {
                                <upsc-commodity-ship-info #commodityShipInfo
                                                          (formValueChanged)="onFormValueChanged($event)"
                                                          (isValid)="setFormValidity('commodityShipInfo', $event)"
                                                          [cachedShipment]="cachedShipment"
                                                          [commodities]="commodities"
                                                          [customsValueCurrency]="shipFromCurrencyCode"
                                                          [isRestoreShipment]="isRestoreShipment"
                                                          class="ship-section-item sub-section"></upsc-commodity-ship-info>
                            }
                        </div>
                        <upsc-ship-info-add #shipInfoAdd
                                            (adultSignatureChanged)="onAdultSignatureChanged($event)"
                                            (formValueChanged)="onFormValueChanged($event)"
                                            (isValid)="setFormValidity('shipInfoAdd', $event)"
                                            [carrier]="carrier"
                                            [hidden]="!config?.showAdditionalShipmentInfo"
                                            [isDomestic]="isDomestic"
                                            [isRestoreShipment]="isRestoreShipment"
                                            [serviceType]="serviceType"
                                            [shipFromCountryCode]="shipFromCountryCode"
                                            [shipToCountryCode]="shipToCountryCode"
                                            [shouldForceClearAdultSignatureRequired]="shouldForceClearAdultSignatureRequired"
                                            [user]="user"
                                            [insuredValue]="insuredValue"
                                            [isShipToZipRestricted]="shipmentStateService?.isShipToZipRestricted()"
                                            class="ship-section-item"></upsc-ship-info-add>

                        @if (shipmentStateService.isInternational()) {
                            <upsc-ship-commodity #shipCommodity
                                                 (commoditiesChanged)="onCommoditiesChanged($event)"
                                                 (isValid)="setFormValidity('shipCommodity', $event)"
                                                 (totalCustomsValueChanged)="onTotalCustomsValueChanged($event)"
                                                 (totalWeightChanged)="onTotalWeightChanged($event)"
                                                 [carrier]="carrier"
                                                 [customer]="customer"
                                                 [customsValueCurrency]="shipFromCurrencyCode"
                                                 [isRestoreShipment]="isRestoreShipment"
                                                 [maxCommodityAllowed]="5"
                                                 [shipFromCountryCode]="shipFromCountryCode"
                                                 [shipToCountryCode]="shipToCountryCode"
                                                 [user]="user"
                                                 [weightUOM]="weightUOM"
                                                 class="ship-section-item"></upsc-ship-commodity>
                        }

                        <upsc-ship-options #shipOptions
                                           (formValueChanged)="onFormValueChanged($event)"
                                           (isValid)="setFormValidity('shipOptions', $event)"
                                           (pickupTypeChanged)="onPickupTypeChanged($event)"
                                           (schedulePickupDateChanged)="onSchedulePickupDateChanged($event)"
                                           [carrier]="carrier"
                                           [dateFormat]="dateFormat"
                                           [isExistingSchedulePickup]="isExistingSchedulePickup"
                                           [isReship]="isReship"
                                           [isRestoreShipment]="isRestoreShipment"
                                           [shipDate]="shipDate"
                                           [shipFromAddress]="shipFromAddress"
                                           [user]="user"
                                           class="ship-section-item"></upsc-ship-options>
                        <upsc-ship-billing-details #shipBillingDetails
                                                   (formValueChanged)="onFormValueChanged($event)"
                                                   (isValid)="setFormValidity('billingDetails', $event)"
                                                   [carrier]="carrier"
                                                   [isDomestic]="isDomestic"
                                                   [user]="user"
                                                   class="ship-section-item"></upsc-ship-billing-details>
                        <upsc-ship-electronic-export #shipElectronicExport
                                                     (eeiFilingOptionChanged)="onEeiFilingOptionChanged($event)"
                                                     (formValueChanged)="onFormValueChanged($event)"
                                                     (isValid)="setFormValidity('shipElectronicExport', $event)"
                                                     [class.hide-me]="!shouldShowEEI"
                                                     [customer]="customer"
                                                     [isEEIRequired]="isEEIRequired"
                                                     [shipFromCountryCode]="shipFromCountryCode"
                                                     [shipToCountryCode]="shipToCountryCode"
                                                     [totalCustomsValue]="totalCustomsValue"
                                                     class="ship-section-item"></upsc-ship-electronic-export>

                        @if (shipmentStateService.isInternational()) {
                            <upsc-ship-customs-docs #shipCustomsDocs
                                                    (formValueChanged)="onFormValueChanged($event)"
                                                    (isValid)="setFormValidity('shipCustomsDocs', $event)"
                                                    [carrierKey]="carrierKey"
                                                    [carrier]="carrier"
                                                    [customerImages]="customerImages"
                                                    [customer]="customer"
                                                    [shipFromCountryCode]="shipFromCountryCode"
                                                    [shipToCountryCode]="shipToCountryCode"
                                                    [validatePaperlessInvoiceCapabilityResult]="validatePaperlessInvoiceCapabilityResult"
                                                    class="ship-section-item"></upsc-ship-customs-docs>
                        }
                    </div>
                    <div class="ship-section">
                        <ng-container [ngTemplateOutletContext]="{formGroup: parentForm}"
                                      [ngTemplateOutlet]="notifications"></ng-container>

                        <ng-container [ngTemplateOutletContext]="{formGroup: parentForm}"
                                      [ngTemplateOutlet]="costEstimate"></ng-container>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="isForbidden" class="forbidden">
        <span class="message">{{'YouAreNotAllowedToUseThiscarrier'|translate}}</span>
    </div>
</div>

<ng-template #header>
    <div class="ups-page-header ship-page-header">
        <h1 class="page-title">{{'CreateShipment'|translate : { carrier: carrierCode } }} </h1>
        <div class="required-field-indicator">{{ 'requiredFields' | translate }}</div>
        <div *ngIf="false" class="page-actions">
            <button (click)="showFormValue($event)"
                    class="page-action"
                    mat-raised-button
                    type="button">
                {{'ShowFormValues'|translate}}
            </button>
            <button (click)="resetForms()"
                    class="page-action"
                    mat-raised-button
                    type="button">
                {{'ResetShipment'|translate}}
            </button>
            <button (click)="showFormErrors($event)"
                    class="page-action"
                    color="warn"
                    mat-raised-button
                    type="button">
                {{'ShowFormERRORS'|translate}}
            </button>
            <button (click)="showFormValidity($event)"
                    class="page-action"
                    color="accent"
                    mat-raised-button
                    type="button">
                {{'ValidateForms'|translate}}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #costEstimate let-formGroup="formGroup">
    <div [formGroup]="formGroup" class="ship-section-item sub-section cost-estimator-wrapper">
        <ng-container
                *ngIf="environment.feature?.deliveryDefense && user?.CountryCode === 'US' && customer?.DDEnabled === true && isUSDomestic">
            <upsc-dd-remaining-credits [fullCredit]="ddFullCredit"
                                       [remainingCredit]="ddRemainingCredit"
                                       [subTypeId]="ddSubTypeID"></upsc-dd-remaining-credits>
        </ng-container>

        <div class="cost-estimate">
            <div class="form-section-header">
                <h5 class="form-section-title">{{ (shouldShowCostEstimate ? 'CostEstimate' : 'Quote') | translate
                    }}</h5>
            </div>

            <div class="estimate-items">
                <div class="estimate-item">
                    <div class="key">{{'EstShippingandInsurance'|translate}}</div>
                    <div class="value">{{ estimatedCost }}</div>
                </div>
                <div class="estimate-item">
                    <div class="key">{{'SurchargeandServices'|translate}}</div>
                    <div class="value">{{ surcharge }}</div>
                </div>

                <div class="estimate-item total">
                    <div class="key">{{'YourTotal'|translate}}<sup>*</sup></div>
                    <div class="value">{{ totalCharges }}</div>
                </div>
            </div>
            <div *ngIf="false" class="estimate-items">
                <div class="estimate-item">
                    <div class="key">{{'InsuranceCost'|translate}}</div>
                    <div class="value">{{ formattedInsuredValue }}</div>
                </div>
            </div>
            <div class="footnotes">
                <div class="footnote">
                    {{'FootNote'|translate}}
                </div>
            </div>

            <div class="form-actions">
                <button (click)="saveQuote($event)"
                        [disabled]="!isQuoteReady || !shouldEnableSaveQuote"
                        class="form-action alternative-button"
                        mat-raised-button
                        type="button">
                    {{'SaveQuote'|translate}}
                </button>
                <button (click)="onFormSubmitted($event, parentForm.value)"
                        class="form-action"
                        mat-raised-button
                        type="button">
                    {{'GetQuote'|translate}}
                </button>
            </div>
        </div>

        <div class="confirm-shipment">
            <div class="terms-notice">
                {{'YouAreAgreeingTo'|translate}}
                <a (click)="openTermsAndConditionsDialog($event)" href="#">{{'TermsandConditions'|translate}}</a>.
            </div>

            <ng-container *ngIf="environment.feature?.deliveryDefense && customer?.DDEnabled === true && isUSDomestic">
                <mat-checkbox [disabled]="useAccessPoint || useFedExHoldAtLocation"
                              (change)="onUserChangeSkipDeliveryDefense($event)"
                              formControlName="skipDeliveryDefense">
                    <span [innerHTML]="'ship.shipUps.skipDeliveryDefense' | translate"></span>
                </mat-checkbox>
            </ng-container>

            <button (click)="getQuoteAndShip($event, parentForm.value)"
                    *ngIf="!isAESShipment"
                    class="form-action"
                    mat-raised-button
                    type="button">
                {{'GetLabel'|translate}}
            </button>

            <button (click)="submitToACE($event, parentForm.value)"
                    *ngIf="isAESShipment"
                    class="form-action"
                    mat-raised-button
                    type="button">
                {{'SubmitToACE'|translate}}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #notifications let-formGroup="formGroup">
    <div [formGroup]="formGroup" class="notifications">
        <div *ngIf="shouldShowShipToReroute" class="notification alert reroute">
            <div class="notification-content">
                <h5>{{'DestinationZipCodeRestricted'|translate}}</h5>
                <p>
                    {{'DestinationZipCodeRestrictedNotification'|translate}}
                </p>
            </div>
        </div>
        <div *ngIf="shouldShowResidentialVerification && !useAccessPoint"
             class="notification alert residential-verification">
            <div class="notification-content">
                <mat-checkbox formControlName="isResidentialVerificationAgreed">
                    {{'IAgreeToRESIDENTIALVERIFICATION'|translate}}
                </mat-checkbox>
                <p>
                    {{'IsResidentialVerificationAgreedParagraph'|translate}}
                    <a (click)="openAddressValidationInformationDialog($event)" href="#">
                        {{'InformationOnAddressValidationandResidentialDelivery'|translate}}
                    </a>
                    {{'ToSeeDetailedInformation'|translate}}
                </p>
                <mat-error *ngIf="parentForm.controls.isResidentialVerificationAgreed?.errors">
                <span *ngIf="parentForm.controls.isResidentialVerificationAgreed?.errors.required">
                    {{ 'residentialVerificationAgreement' | translate }}
                </span>
                </mat-error>
            </div>
        </div>
        <div *ngIf="isHighValueShipment" class="notification alert high-value">
            <div class="notification-content">
                <h5>{{ 'HighValueShipmentNotification.Title' | translate }}</h5>
                <p>{{ 'HighValueShipmentNotification.Message' | translate }}</p>
            </div>
        </div>
        <div *ngIf="isAESShipment" class="notification alert aes">
            <div class="notification-content">
                <h5>{{ 'AESShipmentNotification.Title' | translate }}</h5>
                <p>{{ 'AESShipmentNotification.Message' | translate }}</p>
            </div>
        </div>
        <div *ngIf="shouldShowValuationDiscrepancy" class="notification alert valuation-discrepancy">
            <div class="notification-content">
                <mat-checkbox formControlName="isValuationDiscrepancyAgreed">
                    {{'IAgreeToValuationDiscrepancy'|translate}}
                </mat-checkbox>
                <h5>{{'ValuationDiscrepancy.Title'|translate}}</h5>
                <p>
                    {{'ValuationDiscrepancy.Message'|translate}}
                </p>
                <p>
                    <strong>{{'ValuationDiscrepancy.Notice'|translate}}</strong>
                </p>
                <mat-error *ngIf="parentForm.controls.isValuationDiscrepancyAgreed?.errors">
                <span *ngIf="parentForm.controls.isValuationDiscrepancyAgreed?.errors.required">
                    {{ 'valuationDiscrepancyAgreement' | translate }}
                </span>
                </mat-error>
            </div>
        </div>
    </div>
</ng-template>

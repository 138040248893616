import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'upsc-residential-access-point-suggestion-dialog',
    templateUrl: './residential-access-point-suggestion-dialog.component.html',
    styleUrls: ['./residential-access-point-suggestion-dialog.component.scss'],
})
export class ResidentialAccessPointSuggestionDialogComponent {
    @HostBinding('class') public hostClass = 'residential-access-point-suggestion-dialog';

    constructor(public dialogRef: MatDialogRef<ResidentialAccessPointSuggestionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }
}

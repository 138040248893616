import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { AddressBookService } from '../../../address-book/services/address-book.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Carriers } from '../../../shared/enum/general-enum';
import { IContact } from '../../../shared/models/contact.interface';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import * as _ from 'lodash';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upsc-address-book-search-form',
  templateUrl: './address-book-search-form.component.html',
  styleUrls: ['./address-book-search-form.component.scss'],
})
export class AddressBookSearchFormComponent implements OnInit, OnChanges {
  @Input() carrier: Carriers;
  @Input() pageEvent: PageEvent;
  @Input() availableCountryCodes: string[];
  @Input() forbiddenCountryCodes: string[];
  @Output() searchResultsChanged: EventEmitter<IContact[]> = new EventEmitter<IContact[]>();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isSearchResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  public formGroup: UntypedFormGroup;
  public isAddressBookSearching = false;

  private getAddressBookPageSubscription: Subscription;
  private searchCarrierAddressBookSubscription: Subscription;

  constructor(private formBuild: UntypedFormBuilder,
    private notificationService: NotificationService,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
    private addressBookService: AddressBookService) {
  }

  public ngOnInit() {
    this.formGroup = this.formBuild.group({
      keyword: [''],
      scope: ['0'],
    });

    this.monitorValueChanges();
    this.getDefaultAddressBook();
  }

  public ngOnChanges(changes: SimpleChanges) {
    try {
      if (!this.formGroup.controls.keyword.value) {
        this.onPageEventChanged(changes['pageEvent']);
      }
    }
    catch (e) {
      this.onPageEventChanged(changes['pageEvent']);
    }
  }

  private monitorValueChanges() {
    this.formGroup.controls.keyword.valueChanges
      .pipe(debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        if (value && value.trim().length > 0)
          this.isSearchResult.emit(true);
        else this.isSearchResult.emit(false);
        this.searchAddressBook(value, this.formGroup.controls.scope.value)
      });

    this.formGroup.controls.scope.valueChanges
      .pipe(debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => this.searchAddressBook(this.formGroup.controls.keyword.value, value));
  }

  private searchAddressBook(keyword: string, scope: string) {
    if (!keyword) {
      this.getDefaultAddressBook();
      return;
    }

    this.searchResultsChanged.emit(null);

    if (this.searchCarrierAddressBookSubscription) {
      this.searchCarrierAddressBookSubscription.unsubscribe();
      this.searchCarrierAddressBookSubscription = null;
    }

    this.isAddressBookSearching = true;
    this.isSearching.emit(true);
    this.searchCarrierAddressBookSubscription = this.addressBookService.searchCarrierAddressBook(this.carrier, keyword.trim(), scope)
      .subscribe(
        contacts => this.handleGetCarrierAddressBookSuccess(contacts),
        err => this.handleGetCarrierAddressBookFailure(err),
      );
  }

  //#region getCarrierAddressBook handlers
  private handleGetCarrierAddressBookSuccess(contacts: IContact[]) {
    const filteredContacts = this.getFilteredContacts(contacts);
    this.searchResultsChanged.emit(filteredContacts);
    this.isAddressBookSearching = false;
    this.isSearching.emit(false);
  }

  private handleGetCarrierAddressBookFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      this.translateService.instant('errorSearchingAddress'),
      NotificationType.ERROR);
    this.isAddressBookSearching = false;
    this.isSearching.emit(false);
  }

  //#endregion

  private getDefaultAddressBook() {
    if (!this.pageEvent) {
      return;
    }

    if (this.getAddressBookPageSubscription) {
      this.getAddressBookPageSubscription.unsubscribe();
      this.getAddressBookPageSubscription = null;
    }

    this.isAddressBookSearching = true;
    this.isSearching.emit(true);
    this.getAddressBookPageSubscription =
      this.addressBookService.getAddressBookPage((this.pageEvent.pageIndex + 1) + '', this.pageEvent.pageSize + '')
        .subscribe(
          contacts => this.handleGetAddressBookPageSuccess(contacts),
          err => this.handleGetAddressBookPageFailure(err),
        );
  }

  private getFilteredContacts(contacts: IContact[]): IContact[] {
    let filteredContacts = _.cloneDeep(contacts);

    if (this.availableCountryCodes && this.availableCountryCodes.length) {
      filteredContacts = filteredContacts.filter(contact => this.availableCountryCodes.includes(contact.Country));
    }

    if (this.forbiddenCountryCodes && this.forbiddenCountryCodes.length) {
      filteredContacts = filteredContacts.filter(contact => !this.forbiddenCountryCodes.includes(contact.Country));
    }

    return filteredContacts;
  }

  //#region getAddressBookPage handlers
  private handleGetAddressBookPageSuccess(contacts: IContact[]) {
    const fitleredContacts = this.getFilteredContacts(contacts);
    this.searchResultsChanged.emit(fitleredContacts);
    this.isAddressBookSearching = false;
    this.isSearching.emit(false);
  }

  private handleGetAddressBookPageFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      this.translateService.instant('errorSearchingAddress'),
      NotificationType.ERROR);
    this.isAddressBookSearching = false;
    this.isSearching.emit(false);
  }

  //#endregion

  private onPageEventChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.getDefaultAddressBook();
  }
}

<div class="locations-header">
    <div class="title-wrapper">
        <h2 class="locations-header-title"
            [innerHTML]="('pickupLocations.title.' + carrierString) | translate"></h2>
        <a (click)="openLearnMoreDialog($event)"
           href="#"
           class="learn-more">{{ 'pickupLocations.learnMore' | translate }}</a>
    </div>
    <div class="maximum-coverage">
        @if (carrier === Carriers.Ups) {
            <span class="coverage cvs michaels " [innerHTML]="'pickupLocations.maximumCoverage.cvsMichaelsAndUpsStore' | translate: { maxCoverage1: '10,000', maxCoverage2: '70,000' }"></span>
            
        } @else if (carrier === Carriers.Fedex) {
            <div class="coverage fedex-hold-at-location"
                 [innerHTML]="'pickupLocations.maximumCoverage.fedExHoldAtLocation' | translate: { maxCoverage: '70,000' }"></div>
        }
    </div>
</div>

<upsc-pickup-location-search [carrier]="carrier"
                             [request]="request"
                             [isReadonly]="isReadonly"
                             (searchParametersChanged)="onSearchParametersChanged($event)"
                             (response)="onSearchResultsReady($event)"></upsc-pickup-location-search>

@if (isSearching) {
    <div class="spinner-container">
        <mat-spinner [diameter]="'48'"></mat-spinner>
    </div>
}

<mat-radio-group [formControl]="pickupLocationControl"
                 [disabled]="isReadonly">
    <ul class="locations {{ Carriers[carrier]?.toLowerCase() }}"
        #locationsEl
        [class.readonly]="isReadonly">
        @for (location of locations; track location.locationId) {
            <li class="location"
                [class.disabled]="!location.isEnabled"
                [class.selected]="!isReadonly && pickupLocationControl.value === location">
                <mat-radio-button [value]="location"
                                  [disabled]="!location.isEnabled">
                    <upsc-pickup-location [origin]="origin"
                                          [location]="location"
                                          [isEnabled]="location.isEnabled"></upsc-pickup-location>
                </mat-radio-button>
            </li>
        }
    </ul>
</mat-radio-group>

<div class="ship-to ups-form" [formGroup]="formGroup">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{ 'ShipTo' |translate}}</h5>
            <div class="form-section-actions" *ngIf="!user?.IsGuest">
                <upsc-address-book-search [carrier]="carrier"
                                          [availableCountryCodes]="restrictedCountryCodes?.availableCountryCodes"
                                          [forbiddenCountryCodes]="restrictedCountryCodes?.forbiddenCountryCodes"
                                          (contactSelected)="onContactSelected($event)"></upsc-address-book-search>
            </div>
        </div>

        <div class="select-wrapper" *ngIf="isShowAddressBook">
            <mat-form-field>
                <mat-label>{{'ExpressContacts'|translate}}</mat-label>
                <mat-select #locationSelector
                            (focus)="onLocationFocused($event, locationSelector)"
                            formControlName="location">
                    <mat-option value="" selected>{{'SelectALocation'|translate}}</mat-option>
                    <mat-option *ngFor="let location of locations" [value]="location.ContactId">
                        {{ location.NickName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <upsc-element-blocker *ngIf="isLocationsLoading"></upsc-element-blocker>
        </div>

        <upsc-ship-to-static
                #shipToStatic
                *ngIf="!isLocationEditable"
                [location]="shipToAddress"
                [carrier]="carrier"
                [user]="user"
                [shipToCountryCode]="shipToCountryCode"
                [shouldDisplayEUFields]="shouldDisplayEUFields"
                (isValid)="setFormValidity($event)"></upsc-ship-to-static>
        <upsc-ship-to-edit
                #shipToEdit
                *ngIf="isLocationEditable"
                [location]="shipToAddress"
                [carrier]="carrier"
                [customer]="customer"
                [user]="user"
                [shipToCountryCode]="shipToCountryCode"
                [shipFromCurrencyCode]="shipFromCurrencyCode"
                [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                [restrictedCountryCodes]="restrictedCountryCodes"
                [shouldDisplayEUFields]="shouldDisplayEUFields"
                (countryChanged)="onCountryChanged($event)"
                (countryCodeChanged)="onCountryCodeChanged($event)"
                (zipCodeChanged)="onZipCodeChanged($event)"
                (isResidentialAddressChanged)="onIsResidentialAddressChanged($event)"
                (adultSignatureRequested)="onAdultSignatureRequested($event)"
                (residentialCheckChanged)="onResidentialCheckChanged($event)"
                (isValid)="setFormValidity($event)"
                (addressValidationChanged)="onAddressValidationChanged($event)"
                (forceClearAdultSignatureRequiredChanged)="onForceClearAdultSignatureRequiredChanged($event)"
                (formValueChanged)="onFormValueChanged($event)"></upsc-ship-to-edit>
    </div>
</div>

<div class="ship-countries" [formGroup]="formGroup">
    <mat-form-field>
        <mat-label>{{ 'Country'|translate }}</mat-label>
        <mat-select required [formControlName]="countryFormControlName">
            <mat-option disabled selected>{{ 'SelectACountry'|translate }}</mat-option>
            @for (country of countries; track country.CountryCode) {
                <mat-option [value]="country.CountryCode">
                    {{ country.CountryName }}
                    <!-- 
                        As part of FI-485 below changes are made
                        <span *ngIf="country.CountryCode !== 'US' || appState.user$().CountryCode !== 'US'">
                        ({{ country.MaxCoverage | currency: 'USD': 'symbol': '0.0-2' }})
                    </span> -->
                    <!--Below changes were made as part of the FI-485-->
                    @if(appState.user$().CountryCode == 'GB'){
                        <span>
                            ({{ country.MaxCoverage | currency: 'GBP': 'symbol': '0.0-2' }})
                        </span>
                    }@else if(appState.user$().CountryCode == 'DE' || appState.user$().CountryCode == 'FR' || appState.user$().CountryCode == 'IT'){
                        <span>
                            ({{ country.MaxCoverage | currency: 'EUR': 'symbol': '0.0-2' }})
                        </span>
                    }@else if(country.CountryCode !== 'US' || appState.user$().CountryCode !== 'US'){
                        <span>
                            ({{ country.MaxCoverage | currency: 'USD': 'symbol': '0.0-2' }})
                        </span>
                    }
                </mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>

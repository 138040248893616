import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { StorageService } from '../../core/services/storage/storage.service';
import { Customer } from '../../shared/models/customer/customer.model';
import { NotificationType } from '../../shared/models/notification-type';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { FormService } from '../../shared/services/form/form.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { Package } from '../models/package.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipConfig } from '../models/ship-config.model';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipmentService } from '../services/shipment.service';

@Component({
    selector: 'upsc-ship-electronic-export',
    templateUrl: './ship-electronic-export.component.html',
    styleUrls: ['./ship-electronic-export.component.scss'],
})
export class ShipElectronicExportComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
    @Input() totalCustomsValue: number;
    @Input() shipFromCountryCode: string;
    @Input() shipToCountryCode: string;
    @Input() customer: Customer;
    @Input() isEEIRequired = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() eeiFilingOptionChanged: EventEmitter<number | string> = new EventEmitter<number | string>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    public formGroup: UntypedFormGroup;
    public eeiFilingOptions = [
        { value: '1', message: 'File for me using AES Direct' },
        { value: '2', message: 'I have already filed or will file Post Departure' },
        { value: '4', message: 'Stamped manual B13A Attached' },
        { value: '5', message: 'B13A Filed Electronically' },
    ];
    public filteredEeiFilingOptions: [{ value: string, message: string }];
    public aesCitationTypes = [
        { value: 'Pre-Departure ITN', message: 'Pre-Departure ITN' },
        { value: 'Post Departure', message: 'Post Departure' },
    ];
    public destinationControlStatements = [
        { value: '1', message: 'Department of Commerce' },
        { value: '2', message: 'Department of State' },
        { value: '3', message: 'Departments of Commerce and State' },
    ];
    public hasPostDeparture = false;
    public powerOfAttorneyContent: string;
    public mrnTooltipKey: string;
    public config: ShipConfig;
    public lang = 'en';
    public showFilingOptionsByCountry = false;

    constructor(private validationService: ValidationService,
                private notificationService: NotificationService,
                private formService: FormService,
                private formBuild: UntypedFormBuilder,
                private storage: StorageMap,
                private errorHandlerService: ErrorHandlerService,
                private shipConfigService: ShipConfigService,
                private shipmentService: ShipmentService,
                private readonly storageService: StorageService,
    ) {
        this.storage.get('lang').subscribe(
            (lang: string) => {
                this.lang = lang || 'en';
            },
        );
    }

    public ngOnInit() {
        // Setup a form group here.
        this.formGroup = this.formBuild.group({
            eeiFilingOption: ['1'],
            aesCitationType: [''],
            aesCitation: [''],
            destinationControlStatement: ['1'],
            mrn: ['', Validators.compose([Validators.maxLength(18)])],
            isPowerOfAttorneyAgreed: [false],
        });

        // Subscribe to form value changes.
        this.monitorValueChanges();

        // Then set the form values from cache.
        // This allows the values to persist after a refresh.
        // this.setFormValues();

        this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;
                if (this.config) {
                    this.setMrnValidation();
                    this.setPowerOfAttorneyValidation();
                    this.filterEeiOptions(this.config.showB13);

                    if (this.shipConfigService.Config.showEEI) {
                        this.formGroup.patchValue({
                            eeiFilingOption: this.config.requireEEI || this.showFilingOptionsByCountry ? '1' : '0',
                            destinationControlStatement: this.config.requireEEI || this.showFilingOptionsByCountry ? '1' : '',
                        });
                    } else {
                        this.formGroup.patchValue({
                            eeiFilingOption: this.config.requireEEI ? '0' : '0',
                            destinationControlStatement: this.config.requireEEI ? '0' : '0',
                        });

                    }
                }
            },
        );

        // Intentionally DO NOT restore this section as some values must be unique.
        // const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
        // if (cachedShipment) {
        //     this.restoreShipment(cachedShipment);
        // }
    }

    public ngOnDestroy() {
        this.shipmentService.resetElectronicExportInformation();
    }

    public resetForm() {
        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            eeiFilingOption: '',
            aesCitationType: '',
            aesCitation: '',
            destinationControlStatement: '',
            mrn: '',
            isPowerOfAttorneyAgreed: false,
        });
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onTotalCustomsValueChanged(changes['totalCustomsValue']);
        this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
        this.onIsEEIRequiredChanged(changes['isEEIRequired']);
        this.onShipToCountryCodeChanged(changes['shipToCountryCode']);
    }

    /**
     * Gets the form errors.
     * This allows a parent form to get this child form errors.
     */
    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Electronic Export Information');
    }

    /**
     * Checks whether the form is valid.
     * This allows a parent form to get the child form validity.
     */
    public isFormValid() {
        return this.formGroup.valid;
    }

    public setMRNTooltipKey(countryCode: string) {
        switch (countryCode) {
            case 'DE':
                this.mrnTooltipKey = 'MRNTooltipDE';
                break;
            case 'GB':
                this.mrnTooltipKey = 'MRNTooltipGB';
                break;
            default:
                this.mrnTooltipKey = '';
                break;
        }
    }

    public restoreShipment(shipment: Package) {
        this.formGroup.patchValue({
            eeiFilingOption: shipment.EeiFilingOption || '1',
            aesCitationType: shipment.AESCitationType,

            // MV3-6288, MV3-6737: only restore AES Citation if EeiFilingOption is 2.
            aesCitation: shipment.EeiFilingOption === '2' ? shipment.AESCitation : null,

            destinationControlStatement: shipment.DestinationControlStatement || '1',
            mrn: shipment.MovementReferenceNumber,
        });

        this.formGroup.markAsDirty();
        this.formGroup.markAsTouched();
    }

    private onTotalCustomsValueChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.setMrnValidation();
    }

    private onShipFromCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.setMRNTooltipKey(change.currentValue);
        this.setEEIByCountry();
    }

    private onShipToCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.setEEIByCountry();
    }

    private setEEIByCountry() {
        if (this.shipFromCountryCode === 'US' && (this.shipToCountryCode === 'CN' || this.shipToCountryCode === 'VE' || this.shipToCountryCode === 'RU')) { // MV3-4802 remove HK for AES
            this.showFilingOptionsByCountry = true;
            //this.config.requireEEI = true;
        } else {
            this.showFilingOptionsByCountry = false;
        }
        this.setEEISEDValidationByCountry();
    }

    private setEEISEDValidationByCountry() {
        if (!this.formGroup) {
            return;
        }
        this.formGroup.patchValue({
            eeiFilingOption: this.config.requireEEI || this.showFilingOptionsByCountry ? '1' : '0',
            destinationControlStatement: this.config.requireEEI || this.showFilingOptionsByCountry ? '1' : '',
        });
    }

    private onIsEEIRequiredChanged(change: SimpleChange) {
        if (!this.formGroup) {
            return;
        }

        if (!change || !change.currentValue) {
            this.validationService.clearFormControlValidators([
                this.formGroup.controls.eeiFilingOption,
                this.formGroup.controls.destinationControlStatement,
            ]);

            return;
        }

        this.validationService.setValidatorFormControls(
            Validators.compose([Validators.required, Validators.pattern('^(?!\\s*$).+')]),
            [
                this.formGroup.controls.eeiFilingOption,
                this.formGroup.controls.destinationControlStatement,
            ],
        );
    }

    private setMrnValidation() {
        if (!this.formGroup) {
            return;
        }

        this.validationService.clearFormControlValidators([
            this.formGroup.controls.mrn,
        ]);

        if (this.config.showMRN) {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.mrn,
                Validators.compose([Validators.required, Validators.maxLength(18), this.validationService.mrnFormatValidator()]),
            );
        }
    }

    private setPowerOfAttorneyValidation() {
        if (!this.config.requireEEI || !this.config.showPowerOfAttorney) {
            this.validationService.clearFormControlValidators([this.formGroup.controls.isPowerOfAttorneyAgreed]);
            return;
        }

        this.validationService.setFormControlValidators(
            this.formGroup.controls.isPowerOfAttorneyAgreed,
            Validators.compose([Validators.requiredTrue]),
        );

        this.shipmentService.getPowerOfAttorneyContent()
            .pipe(takeWhile(() => !this.powerOfAttorneyContent))
            .subscribe(
                content => this.handleGetPowerOfAttorneyContentSuccess(content),
                err => this.handleGetPowerOfAttorneyContentFailure(err),
            );
    }

    private handleGetPowerOfAttorneyContentSuccess(content: string) {
        // TODO: API should handle the transformation as the template could be changed at any time.

        // Remove the leading and trailing double quotes.
        let refinedContent = content.slice(1, -1);

        // Remove newline characters.
        refinedContent = refinedContent
            .replace(/\\r?\\n|\\r/g, '')
            .replace(/\\"/g, '"');

        if (this.customer) {
            // Replace content placeholders.
            refinedContent = refinedContent
                .replace(/\[COMPANYNAME\]/g, this.customer.CompanyName)
                .replace(/\[CLIENTSTATE\]/g, this.customer.CorpContact.State || this.customer.CorpContact.ProvinceRegion)
                .replace(/\[CLIENTADDRESS\]/g, `${ this.customer.CorpContact.StreetAddress }, ${ this.customer.CorpContact.ApartmentSuite }`)
                .replace(/\[CLIENTNAME\]/g, `${ this.customer.CorpContact.FirstName }, ${ this.customer.CorpContact.LastName }`);
        }

        this.powerOfAttorneyContent = refinedContent;
    }

    private handleGetPowerOfAttorneyContentFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting power of attorney content',
            NotificationType.ERROR);
    }

    private filterEeiOptions(isShowB13: boolean) {
        this.filteredEeiFilingOptions = <any>_.cloneDeep(this.eeiFilingOptions).filter(
            (option) => {
                return isShowB13 ? ['4', '5'].includes(option.value) : ['1', '2'].includes(option.value);
            });
    }

    /**
     * Subscribes to form value changes.
     * Other form control value change subscription may be setup here as well.
     */
    private monitorValueChanges() {
        this.formGroup.valueChanges
            .subscribe(
                (value) => {
                    // Save the form values to the Quote object.
                    this.shipmentService.saveElectronicExportInformation(value);

                    // Emit the form validity to its parent.
                    this.isValid.emit(this.formGroup.valid);

                    this.formValueChanged.emit(value);
                },
            );

        this.formGroup.controls.eeiFilingOption.valueChanges
            .subscribe(
                (value) => {
                    this.eeiFilingOptionChanged.emit(value);
                    this.hasPostDeparture = value === '2';

                    this.validationService.clearFormControlValidators([
                        this.formGroup.controls.aesCitationType,
                        this.formGroup.controls.aesCitation,
                        this.formGroup.controls.destinationControlStatement,
                    ]);

                    if (this.hasPostDeparture) {
                        this.validationService.setFormControlValidators(
                            this.formGroup.controls.aesCitationType,
                            Validators.compose([Validators.required]),
                        );

                        this.validationService.setFormControlValidators(
                            this.formGroup.controls.aesCitation,
                            Validators.compose([Validators.required]),
                        );

                        this.validationService.setFormControlValidators(
                            this.formGroup.controls.destinationControlStatement,
                            Validators.compose([Validators.required]),
                        );
                    }
                },
            );
    }

    /**
     * Restores form values from the cache.
     */
    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        // Restore the value from the Quote object to the form.
        this.formGroup.patchValue(
            {
                eeiFilingOption: this.shipmentService.Quote.EeiFilingOption,
                aesCitationType: this.shipmentService.Quote.AESCitationType,
                aesCitation: this.shipmentService.Quote.AESCitation,
                destinationControlStatement: this.shipmentService.Quote.DestinationControlStatement,
                mrn: this.shipmentService.Quote.MovementReferenceNumber,
            },
            { emitEvent: false });

        // Mark form as touched/dirty to trigger form validation.
        this.formService.markAllAsTouchedAndDirty(this.formGroup);

        // Emit the form validity to its parent.
        this.isValid.emit(this.formGroup.valid);
    }
}

import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Carriers } from '../../shared/enum/general-enum';

@Component({
    selector: 'upsc-no-coverage-warning-dialog',
    templateUrl: './no-coverage-warning-dialog.component.html',
    styleUrls: ['./no-coverage-warning-dialog.component.scss'],
})
export class NoCoverageWarningDialogComponent {
    @HostBinding('class') public hostClass = 'upsc-no-coverage-warning-dialog';
    public carrier: Carriers;
    public addressType: 'commercial' | 'residential';
    protected readonly Carriers = Carriers;

    public constructor(public dialogRef: MatDialogRef<NoCoverageWarningDialogComponent>,
                       @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.carrier = this.data?.carrier;
        this.addressType = this.data?.addressType;
    }
}

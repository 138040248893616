<div class="address-book-search-table">
    <div class="no-result" *ngIf="!dataSource">
        <span>{{'noResult'|translate}}</span>
    </div>
    <mat-table #table [dataSource]="dataSource" *ngIf="dataSource">
        <ng-container matColumnDef="NickName" mdSort>
            <mat-header-cell *matHeaderCellDef>{{'NickName'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{ contact.NickName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ContactName" md-sort-header>
            <mat-header-cell *matHeaderCellDef>{{'ContactName'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{ contact.ContactName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CompanyName" md-sort-header>
            <mat-header-cell *matHeaderCellDef>{{'Company'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{ contact.CompanyName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="StreetAddress" md-sort-header>
            <mat-header-cell *matHeaderCellDef>{{'Address'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{ contact.StreetAddress }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="City" md-sort-header>
            <mat-header-cell *matHeaderCellDef>{{'City'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{ contact.City }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="State" md-sort-header>
            <mat-header-cell *matHeaderCellDef>{{'State'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{ contact.State || contact.ProvinceRegion }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Country" md-sort-header>
            <mat-header-cell *matHeaderCellDef>{{'Country'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{ contact.Country }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let contact">
                <div class="row-actions">
                    <button mat-raised-button type="button" (click)="selectContact($event, contact)">{{'Select'|translate}}</button>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator #paginator
                  *ngIf="totalItems"
                  [length]="totalItems"
                  [pageIndex]="0"
                  [pageSize]="25"
                  (page)="onPageChanged($event)"
                  [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <!--<upsc-element-blocker *ngIf="isSearching"></upsc-element-blocker>-->
    <div class="blocking-overlay" *ngIf="isSearching"></div>
</div>

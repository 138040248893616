import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ShipmentService } from '../../services/shipment.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { NotificationType } from 'app/shared/models/notification-type';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { FormService } from 'app/shared/services/form/form.service';
import { ValidationService } from 'app/shared/services/validation/validation.service';
import { IShipComponent } from 'app/ship/models/ship-component.interface';


@Component({
  selector: 'upsc-import-information',
  templateUrl: './import-information.component.html',
  styleUrls: ['./import-information.component.scss']
})
export class ImportInformationComponent implements OnInit, OnDestroy, IShipComponent {

  @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

  public formGroup: UntypedFormGroup;

  constructor(private formBuild:UntypedFormBuilder,
    private shipmentService: ShipmentService,
    private formService: FormService,
    private notificationService: NotificationService,
    private validationService: ValidationService,
    private errorHandlerService: ErrorHandlerService,) {

  }

  ngOnInit(): void {
    this.formGroup = this.formBuild.group({
      customsTariffCode: ['', Validators.compose([Validators.maxLength(18)])],
    });

    // Subscribe to form value changes.
    this.monitorValueChanges();
  }

  private monitorValueChanges() {
    this.formGroup.valueChanges
      .subscribe(
        (value) => {
          this.formValueChanged.emit(value);
          this.shipmentService.saveImportInformation(value);
        },
      );
  }

  public getFormErrors() {
    return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Import Information');
  }

  public isFormValid() {
    return this.formGroup.valid;
  }

  public forceValidation() {
    this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
  }

  public ngOnDestroy() {
    this.shipmentService.resetImportInformation();
  }

  public resetForm() {
    if (!this.formGroup) {
      return;
    }

    this.formGroup.reset({
      customsTariffCode: '',
    });
  }

}

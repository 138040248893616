<div class="ship-commodity ups-form">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{'Commodities'|translate}}</h5>
        </div>

        <p class="commodity-message">
            <span>{{'commodityMessage'| translate : { maxCommodityAllowed : maxCommodityAllowed } }}</span>
            <button type="button"
                    mat-raised-button
                    [disabled]="commodities?.length >= (maxCommodityAllowed + 1)"
                    (click)="openNewCommodityDialog($event)">{{'Add'|translate}}
            </button>
        </p>

        <!-- Remove one item when checking since the last commodity is the summary. -->
        <div class="commodities" *ngIf="commodities && (commodities.length - 1 !== 0)">
            <upsc-commodity-table #commodityTable
                                  [weightUnit]="weightUOM"
                                  [customsValueCurrency]="customsValueCurrency"
                                  [items]="commodities"
                                  [displayedColumns]="['Commodity', 'Quantity', 'Weight', 'CustomsValue', 'Actions']"
                                  (rowActionClicked)="onCommodityActionClicked($event)"></upsc-commodity-table>
        </div>
    </div>
</div>

<ng-template #standardTable>
    <table class="table table-striped black-font">
        <thead>
        <tr class="grid-head text-nowrap">
            <th>{{'Commodity'|translate}}</th>
            <th>{{'Quantity'|translate}}</th>
            <th>{{'Weight'|translate}}</th>
            <th>{{'CustomsValue'|translate}}</th>
            <th></th>
        </tr>
        </thead>
        <tfoot class="hide-me">
        <tr>
            <td></td>
            <td></td>
            <td>{{'Total'|translate}}</td>
            <td>{{ totalCustomsValue }} {{'USD'|translate}}</td>
            <td></td>
        </tr>
        </tfoot>
        <tbody>
        <tr *ngFor="let commodity of commodities; let i = index">
            <td>
                <span class="column-header">{{'Commodity'|translate}}</span>
                <span class="column-content">{{ commodity.CommodityDescription }}</span>
            </td>
            <td>
                <span class="column-header">{{'Quantity'|translate}}</span>
                <span class="column-content">{{ commodity.Quantity }}</span>
            </td>
            <td>
                <span class="column-header">{{'Weight'|translate}}</span>
                <span class="column-content">
                        {{ commodity.Weight }}
                        <span *ngIf="commodity.HarmonizedCode">&nbsp;lbs</span>
                    </span>
            </td>
            <td>
                <span class="column-header">{{'CustomsValue'|translate}}</span>
                <span class="column-content">
                        {{ commodity.CustomsValue }}
                        <span *ngIf="commodity.HarmonizedCode">&nbsp;{{'USD'|translate}}</span>
                    </span>
            </td>
            <td>
                <div class="actions" *ngIf="commodity.HarmonizedCode">
                    <button type="button" class="btn btn-default" (click)="openEditCommodityDialog($event, i)">
                        {{'Edit'|translate}}
                    </button>
                    <button type="button" class="btn btn-default" (click)="removeCommodity($event, i)">
                        {{'Remove'|translate}}
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</ng-template>
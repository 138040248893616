import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IPickupLocation } from '../pickup-locations/models/pickup-location.interface';

@Component({
    selector: 'upsc-pickup-location-info',
    templateUrl: './pickup-location-info.component.html',
    styleUrls: ['./pickup-location-info.component.scss'],
})
export class PickupLocationInfoComponent {
    @Input() location: IPickupLocation;
    @Output() editByLocationId = new EventEmitter<string>();
    @HostBinding('class') public hostClass = 'pickup-location-info';

    constructor() {
    }
}

<div class="address-book-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ 'AddressBook' | translate }}</h2>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <upsc-address-book-search-form [carrier]="carrier" [pageEvent]="pageEvent" [availableCountryCodes]="availableCountryCodes"
            [forbiddenCountryCodes]="forbiddenCountryCodes" (searchResultsChanged)="onSearchResultsChanged($event)" (isSearchResult)="isSearchResultDisplayed($event)"
            (isSearching)="onSearchingStatusChanged($event)"></upsc-address-book-search-form>
        <div class="table-wrapper">
            <ng-container [ngTemplateOutlet]="table"></ng-container>
            <upsc-element-blocker *ngIf="isSearching"></upsc-element-blocker>
        </div>
    </mat-dialog-content>
</div>

<ng-template #table>
    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="stripe" [hidden]="!searchResults">
            <thead>
                <tr>
                    <!-- <th class="all trigger-column"></th> -->
                    <th class="all"></th>
                    <th>{{ 'addressBookTable.headers.nickName' | translate }}</th>
                    <th>{{ 'addressBookTable.headers.contactName' | translate }}</th>
                    <th>{{ 'addressBookTable.headers.companyName' | translate }}</th>
                    <th>{{ 'addressBookTable.headers.streetAddress' | translate }}</th>
                    <th class="country">{{ 'addressBookTable.headers.country' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="searchResults">
                    <tr *ngFor="let item of searchResults | paginate:paginate">
                        <!-- <td class="trigger-cell"></td> -->
                        <td>
                            <div class="actions">
                                <button mat-raised-button type="button" (click)="onContactSelected(item)">
                                    {{'addressBookTable.actions.select'|translate}}
                                </button>
                            </div>
                        </td>
                        <td>
                            <span class="contact-name text-ellipsis">{{ item.NickName }}</span>
                        </td>
                        <td>
                            <span class="contact-name text-ellipsis">{{ item.FirstName }} {{ item.LastName }}</span>
                        </td>
                        <td>
                            <span class="company-name text-ellipsis">{{ item.CompanyName }}</span>
                        </td>
                        <td>
                            <span class="address text-ellipsis" matTooltip="{{ item.StreetAddress }}, {{ item.City }}, {{ item.State }}" [matTooltipPosition]="'above'">
                                {{ item.StreetAddress }}, {{ item.City }}, {{ item.State }}
                            </span>
                        </td>
                        <td>{{ item.Country }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <pagination-controls [directionLinks]="true" [previousLabel]="''" [nextLabel]="''" [autoHide]="true" (pageChange)="onPageChanged($event)"></pagination-controls>
    </div>
</ng-template>

import { Component, HostBinding, Input } from '@angular/core';
import { IPickupLocation } from '../../models/pickup-location.interface';

@Component({
    selector: 'upsc-pickup-location',
    templateUrl: './pickup-location.component.html',
    styleUrls: ['./pickup-location.component.scss'],
})
export class PickupLocationComponent {
    @Input() public origin: string;
    @Input() public location: IPickupLocation;
    @Input() public isEnabled = true;
    @HostBinding('class') public hostClass = 'pickup-location';
    public displayColumns = ['day', 'hours', 'dropOff'];

    constructor() {
    }

    @HostBinding('class.disabled')
    public get isDisabled(): boolean {
        return !this.isEnabled;
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import moment from 'moment';
import { nanoid } from 'nanoid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageService } from '../../core/services/storage/storage.service';
import { Carriers } from '../../shared/enum/general-enum';
import { ICountry } from '../../shared/models/country.interface';
import { ICustomFieldItem } from '../../shared/models/custom-field-item.interface';
import { ICustomField } from '../../shared/models/custom-field.interface';
import { Customer } from '../../shared/models/customer/customer.model';
import { NotificationType } from '../../shared/models/notification-type';
import { CustomerService } from '../../shared/services/customer/customer.service';
import { DateTimeService } from '../../shared/services/date-time/date-time.service';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { FormService } from '../../shared/services/form/form.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { SingleSignOnService } from '../../shared/services/single-sign-on/single-sign-on.service';
import { User } from '../../shared/services/user/models/user.model';
import { UserService } from '../../shared/services/user/user.service';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { ICarrierServiceRequestParam } from '../models/carrier-service-request-param.interface';
import { ICarrierService } from '../models/carrier-service.interface';
import { ICheckRestrictedResponse } from '../models/CheckRestrictedResponse.interface';
import { IPackageType } from '../models/package-type.interface';
import { Package } from '../models/package.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipConfig } from '../models/ship-config.model';
import { PickupLocationStateService } from '../pickup-locations/pickup-location-state.service';
import { PickupLocationService } from '../pickup-locations/pickup-location.service';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipmentService } from '../services/shipment.service';
import { EmailService } from '../../shared/services/email/email.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
    selector: 'upsc-ship-info',
    templateUrl: './ship-info.component.html',
    styleUrls: ['./ship-info.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: sessionStorage.getItem('lang') },
    
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
      ],
})
export class ShipInfoComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
    @Input() dateFormat = 'YYYY-MM-DD'; // 'MM/DD/YYYY';
    @Input() user: User;
    @Input() customer: Customer;
    @Input() carrier: Carriers;
    @Input() isDomestic = true;
    @Input() shipFromCountryCode = 'US';
    @Input() shipFromCurrencyCode = 'USD';
    @Input() shipToCountryCode = 'US';
    @Input() shipToCountry: ICountry;
    @Input() shipFromCountry: ICountry;
    @Input() shipFromZipCode: string;
    @Input() shipToZipCode: string;
    @Input() countryMaxCoverage: number;
    @Input() weightUOM = 'LBS';
    @Input() insuredValueCurrency = 'USD';
    @Input() maxCoverageCurrencyCode = 'USD';
    @Input() dimensionUnit = 'inches';
    @Input() totalWeight: number;
    @Input() isRestoreShipment = false;
    @Input() isReship = false;
    @Input() returnShipment = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() selectedServiceTypeChanged: EventEmitter<ICarrierService> = new EventEmitter<ICarrierService>();
    @Output() shipDateChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() insuredValueChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    public formGroup: UntypedFormGroup;
    public isServiceTypeLoading = true;
    public isPackageTypeLoading = false;
    public isShowCustomPackageDimension = false;
    public isEUUser: boolean = false;
    public shipmentReason: ICustomField;
    public serviceTypes: ICarrierService[];
    public displayedServiceTypes: ICarrierService[];
    public packageTypes: IPackageType[];
    public minWeight = this.weightUOM === 'KGS' ? 0 : 1;
    public maxWeight = 125;
    public maxInsuredValue = 0;
    public formattedShipDate = moment().format('MM/DD/YYYY');
    public config: ShipConfig;
    public ssoURL = 'https://parcelpro.zendesk.com';
    private shouldForceSetDefaultServiceType = false;
    private isDefaultServiceTypesLoaded = false;
    private serviceTypesSubject = new BehaviorSubject(null);
    private packageTypesSubject = new BehaviorSubject(null);
    private shipmentReasonsSubject = new BehaviorSubject<ICustomFieldItem[]>(null);
    // [MV3-2163] Allow/disallow insured value to be overwritten by a shipment-reason.
    private shouldReasonOverwriteUserInsuredValue = true;
    private shouldTryConstructShipmentReasons = true;
    private defaultMaxWeight = 125;
    private getShippingCarrierServicesSubscription: Subscription;
    private getShippingCarrierPackageTypesSubscription: Subscription;
    private getServicesByCountrySubscription: Subscription;
    private checkRestrictedSubscription: Subscription;
    private isRestrictedChecking = false;
    private isRestricted = false;
    private selectedServiceType: ICarrierService;
    private isDefaultValuesApplied = false;
    private isIgnoreCustomPackageWeight = true;
    private isServiceTypeRestored = false;
    private isPackageTypeRestored = false;
    private isShipmentRestored = false;
    private isServiceTypeSetFromCostEstimator = false;
    private isPackageTypeSetFromCostEstimator = false;
    private defaultShipDate = moment().day() === 0 ? moment().add(1, 'days').toDate() : moment().toDate();
    public sessionLanguage : string;

    constructor(private formBuild: UntypedFormBuilder,
                private validationService: ValidationService,
                private formService: FormService,
                private notificationService: NotificationService,
                private utilityService: UtilityService,
                private storageService: StorageService,
                private errorHandlerService: ErrorHandlerService,
                private dateTimeService: DateTimeService,
                private readonly userService: UserService,
                private readonly customerService: CustomerService,
                private dialogService: DialogService,
                private translateService: TranslateService,
                private shipConfigService: ShipConfigService,
                private shipmentService: ShipmentService,
                private singleSignOn: SingleSignOnService,
                private readonly pickupLocationService: PickupLocationService,
                private readonly pickupLocationStateService: PickupLocationStateService,
                private readonly snackBar: MatSnackBar,
                private emailService: EmailService,
                private adapter: DateAdapter<any>
    ) {
        this.translateService.onLangChange.subscribe((event) => {
            this.sessionLanguage = event.lang;
            this.adapter.setLocale(this.sessionLanguage)
          });
    }

    public ngOnInit() {
                this.formGroup = this.formBuild.group({
            shipDate: [this.defaultShipDate, Validators.compose([Validators.required, Validators.maxLength(15)])],
            insuredValue: [0, Validators.compose([Validators.required, Validators.min(0), this.validationService.isInteger()])],
            insuredValueCurrency: [this.insuredValueCurrency],
            maxCoverageCurrencyCode: [this.maxCoverageCurrencyCode],
            packageLength: [0],
            packageWidth: [0],
            packageHeight: [0],
            // weight: [1, Validators.compose([Validators.required, Validators.min(0.01), Validators.max(this.maxWeight)])],
            weight: [1, Validators.compose([this.validateWeight(0.01, this.maxWeight)])],
            weightUOM: [this.weightUOM],
            shipmentReason: [0, Validators.compose([Validators.required])],
            shipmentReasonText: [''],
            customField: [''],
            customFieldValue: [''],
            serviceType: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
            packageType: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
            referenceNumber: [''],
            invoiceNumber: [''],
        });
        this.sessionLanguage = sessionStorage.getItem('lang');
        this.monitorValueChanges();
        this.setFormValues();
        
        // Override the default ship date from the Package model.
        // The value will be picked up by the valueChanges monitoring and the Quote will consequently be updated.
        this.formGroup.controls.shipDate.setValue(this.defaultShipDate);

        this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;

                if (this.shouldTryConstructShipmentReasons) {
                    this.constructShipmentReasons();
                }

                if (this.config && this.config.isAPIDriven && !this.isDefaultValuesApplied) {
                    // this.formGroup.controls.insuredValue.setValue(config.DefaultInsuredValue || 0);
                    this.formGroup.patchValue({
                        insuredValue: config.DefaultInsuredValue || 0,
                        invoiceNumber: config.DefaultInvoiceNumber || 0,
                        referenceNumber: config.DefaultReferenceNumber || 0,
                    });

                    this.isDefaultValuesApplied = true;
                }

                if (this.config.customFields && this.config.customFields.length) {
                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.shipmentReason,
                        Validators.compose([Validators.required]),

                        // [MV3-2652] if editInsuredValue = true, allow user to edit the insured value
                        // i.e. don't reset user's insured value to shipment-reason insured value.
                        { emitEvent: !config.editInsuredValue },
                    );

                    this.formGroup.controls.customField.setValue(this.config.customFields[0].label);
                } else {
                    this.validationService.clearFormControlValidators([this.formGroup.controls.shipmentReason]);
                }
            },
        );
    }

    public resetForm() {
        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            shipDate: this.defaultShipDate,
            insuredValue: 0,
            packageLength: 0,
            packageWidth: 0,
            packageHeight: 0,
            weight: 0,
            weightUOM: this.weightUOM,
            shipmentReason: '',
            serviceType: '', // this.serviceTypes ? this.serviceTypes[0].ServiceCode : '', [MV3-3779] Package-Types API is called Twice as we are assigning the value.
            packageType: '',
            referenceNumber: '',
            invoiceNumber: '',
        });
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onCarrierChanged(changes['carrier']);
        this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
        this.onShipToCountryCodeChanged(changes['shipToCountryCode']);
        this.onShipFromZipCodeChanged(changes['shipFromZipCode']);
        this.onShipToZipCodeChanged(changes['shipToZipCode']);
        this.onIsDomesticChanged(changes['isDomestic']);
        this.onWeightUOMChanged(changes['weightUOM']);
        this.onTotalWeightChanged(changes['totalWeight']);
        this.onShipFromCurrencyCodeChanged(changes['shipFromCurrencyCode']);
        this.onCountryMaxCoverageChanged(changes['countryMaxCoverage']);
        this.onInsuredValueCurrencyChanged(changes['insuredValueCurrency']);
        this.onMaxCoverageCurrencyCodeChanged(changes['maxCoverageCurrencyCode']);
    }

    public ngOnDestroy() {
        this.clearShippingCarrierServicesSubscription();
        this.clearShippingCarrierPackageTypesSubscription();

        this.utilityService.clearSubscriptions([
            this.getServicesByCountrySubscription,
        ]);

        this.formGroup.reset(
            {
                shipDate: this.defaultShipDate,
                insuredValue: 0,
                packageLength: 0,
                packageWidth: 0,
                packageHeight: 0,
                weight: 0,
                weightUOM: this.weightUOM,
                shipmentReason: '',
                serviceType: '', // this.serviceTypes ? this.serviceTypes[0].ServiceCode : '', [MV3-3779] Package-Types API is called Twice as we are assigning the value.
                packageType: '',
                referenceNumber: '',
                invoiceNumber: '',
            },
            { emitEvent: false });
    }

    public loadCarrierServicesByCountries() {
        if (!this.shipConfigService.isUps(this.carrier)
            || !this.shipFromCountry
            || !this.shipFromCountryCode
            || !this.shipToCountry
            || !this.shipToCountryCode) {
            return;
        }

        // [MV3-2713] Saritha: "we no need to call this API for Domestic Shipmemt.
        // This API is applicable only for international shipments."
        // [MV3-2924] call another carrier service api for domestic.
        // [MV3-2932] US --> PR for UPS is considered a domestic shipment.
        // [MV3-5407] US --> PR for UPS is considered a both international and domestic shipment
        const isDomestic = this.shipFromCountryCode === this.shipToCountryCode
            || (this.shipConfigService.isUps(this.carrier) && (this.shipFromCountryCode === 'US' && this.shipToCountryCode === 'PR'));

        if (isDomestic) {
            this.loadCarrierServices(this.carrier, true, this.shipFromCountryCode);
            return;
        }

        if (this.shipToCountry.IsPostalCodeAware && !this.shipToZipCode) {
            return;
        }

        if (this.shipFromCountry.IsPostalCodeAware && !this.shipFromZipCode) {
            return;
        }

        const param: ICarrierServiceRequestParam = {
            shipFromCountry: this.shipFromCountryCode,
            shipToCountry: this.shipToCountryCode,
        };

        if (this.shipFromZipCode) {
            param.shipFromZip = this.shipFromZipCode;
        }

        if (this.shipToZipCode) {
            param.shipToZip = this.shipToZipCode;
        }

        this.utilityService.clearSubscriptions([this.getServicesByCountrySubscription]);
        this.getServicesByCountrySubscription = this.shipmentService.getServicesByCountry(param)
                                                    .subscribe(
                                                        serviceTypes => this.handleGetShippingCarrierServiceTypesSuccess(serviceTypes),
                                                        err => this.handleGetShippingCarrierServiceTypesFailure(err),
                                                    );
    }

    /**
     * The min date should be today.
     * If today is Sunday, the min date should be moved to the next day.
     *
     * @param {string} format
     * @returns {string | Date}
     */
    public getMinDate(format?: string) {
        let minDate = moment().startOf('day');
        if (minDate.day() === 0) {
            minDate = moment().startOf('day').add(1, 'days');
        }

        return format ? minDate.startOf('day').format(format) : minDate.startOf('day').toDate();
    }

    /**
     * The max date should be the next 5 days from the min date.
     * If any of the day between min and max dates fall into a Sunday,
     * move the max date one day beyond the original max date.
     *
     * @param {string} format
     * @returns {string | Date}
     */
    public getMaxDate(format?: string) {
        let minDate = moment().startOf('day');
        if (minDate.day() === 0) {
            minDate = moment().startOf('day').add(1, 'days');
        }

        let maxDate = minDate;
        let dayCount = 5;
        while (dayCount > 0) {
            maxDate = maxDate.endOf('day').add(1, 'days');
            if (maxDate.day() === 0) {
                continue;
            }

            dayCount = dayCount - 1;
        }

        return format ? maxDate.endOf('day').format(format) : maxDate.endOf('day').toDate();
    }

    public filterDates(date: Date) {
        return moment(date).day() !== 0;
    }

    public isMatDatepickerValid(dateControl: AbstractControl): boolean {
        if (!dateControl || !dateControl.errors) {
            return true;
        }

        return dateControl.errors.required && dateControl.errors.matDatepickerMin && dateControl.errors.matDatepickerMax;
    }

    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Shipping Information');
    }

    public isFormValid() {
        return this.formGroup.valid; // && this.isRestricted;
    }

    public clearShippingCarrierServicesSubscription() {
        if (!this.getShippingCarrierServicesSubscription) {
            return;
        }

        this.getShippingCarrierServicesSubscription.unsubscribe();
        this.getShippingCarrierServicesSubscription = null;
    }

    public clearShippingCarrierPackageTypesSubscription() {
        if (!this.getShippingCarrierPackageTypesSubscription) {
            return;
        }

        this.getShippingCarrierPackageTypesSubscription.unsubscribe();
        this.getShippingCarrierPackageTypesSubscription = null;
    }

    public formatMoney(value: number): string {
        return this.utilityService.formatMoney(value, this.maxCoverageCurrencyCode, 'en-US', 0);
    }

    public onWeightBlur(event) {
        event.preventDefault();

        if (!this.formGroup.controls.weight.errors || !this.formGroup.controls.weight.errors.expectedValue) {
            return;
        }

        this.translateService.get(['WeightDiscrepancyTitle', 'WeightDiscrepancyMessage'])
            .subscribe(
                (values) => {
                    this.dialogService.alert(values['WeightDiscrepancyTitle'], values['WeightDiscrepancyMessage']);
                },
            );
    }

    public validateWeight(minWeight: number, maxWeight: number): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value === '' || control.value === null || typeof control.value === 'undefined' || !this.user) {
                return null;
            }

            const weight = this.userService.standardizeLocalizedNumber(this.user, control.value);

            if (isNaN(weight)) {
                return {
                    notNumber: {
                        valid: false,
                    },
                };
            }

            if (+weight > maxWeight) {
                return {
                    max: {
                        valid: false,
                    },
                };
            }

            if (+weight >= minWeight && +weight <= maxWeight) {
                return null;
            }

            return {
                outOfRange: {
                    valid: false,
                },
            };
        };
    }

    public fillCostEstimateValues(cachedCostEstimate: any) {
        this.isServiceTypeSetFromCostEstimator = true;
        this.isPackageTypeSetFromCostEstimator = true;

        this.formGroup.patchValue({
            serviceType: cachedCostEstimate.SelectedServiceCode,
            insuredValue: cachedCostEstimate.InsuredValue,
            weight: cachedCostEstimate.Weight,
        });

        this.formGroup.controls.serviceType.markAsDirty();
        this.formGroup.controls.serviceType.markAsTouched();

        this.formGroup.controls.insuredValue.markAsDirty();
        this.formGroup.controls.insuredValue.markAsTouched();

        this.formGroup.controls.weight.markAsDirty();
        this.formGroup.controls.weight.markAsTouched();

        this.fillCostEstimateCustomPackaging(cachedCostEstimate);
    }

    public restoreShipment(shipment: Package) {
        this.serviceTypesSubject.subscribe(
            (serviceTypes) => {
                if (!serviceTypes || !serviceTypes.length) {
                    return;
                }

                if (this.isServiceTypeRestored) {
                    return;
                }

                this.isPackageTypeRestored = false;

                this.formGroup.patchValue(
                    {
                        serviceType: shipment.ServiceCode,
                    },
                    { emitEvent: true });

                this.formGroup.controls.serviceType.markAsDirty();
                this.formGroup.controls.serviceType.markAsTouched();

                this.isServiceTypeRestored = true;
                // this.isRestoreShipment = !(this.isServiceTypeRestored && this.isPackageTypeRestored);
            },
        );

        this.packageTypesSubject.subscribe(
            (packageTypes) => {
                if (!packageTypes || !packageTypes.length) {
                    return;
                }

                if (this.isPackageTypeRestored) {
                    return;
                }

                this.formGroup.patchValue(
                    {
                        packageType: shipment.PackageCode,
                    },
                    { emitEvent: true });

                this.formGroup.controls.packageType.markAsDirty();
                this.formGroup.controls.packageType.markAsTouched();

                this.isPackageTypeRestored = true;
                // this.isRestoreShipment = !(this.isServiceTypeRestored && this.isPackageTypeRestored);
            },
        );

        if (shipment.ShipmentReason) {
            this.shipmentReasonsSubject.subscribe(
                (shipmentReasons) => {
                    if (!shipmentReasons || !shipmentReasons.length) {
                        return;
                    }

                    const targetShipmentReason = shipmentReasons.find(reason => reason.label === shipment.ShipmentReason);
                    if (!targetShipmentReason) {
                        return;
                    }

                    this.formGroup.patchValue({
                        shipmentReason: targetShipmentReason._id,
                    });

                    this.formGroup.controls.shipmentReason.markAsDirty();
                    this.formGroup.controls.shipmentReason.markAsTouched();
                },
            );
        }

        this.formGroup.patchValue(
            {
                shipDate: moment(shipment.ShipDate).endOf('day').toDate(), // moment(shipment.ShipDate).format('YYYY-MM-DD'),
                insuredValueCurrency: shipment.CoverageCurrencyCode || 'USD',
                maxCoverageCurrencyCode: shipment.CoverageCurrencyCode || 'USD',
                packageLength: shipment.Length,
                packageWidth: shipment.Width,
                packageHeight: shipment.Height,
                weight: shipment.Weight,
                weightUOM: shipment.WeightUOM,
                shipmentReasonText: shipment.ShipmentReason,
                customField: shipment.CustomField,
                customFieldValue: shipment.CustomFieldValue,
                referenceNumber: shipment.ReferenceNumber,
                invoiceNumber: shipment.CustomerReferenceNumber,
            },
            { emitEvent: false });

        // [MV3-2779] Reset package dimensions on reship.
        if (this.isReship) {
            this.formGroup.patchValue({
                packageLength: 0,
                packageWidth: 0,
                packageHeight: 0,
            });
        }

        this.formGroup.markAsDirty();
        this.formGroup.markAsTouched();

        this.formGroup.controls.weight.markAsPristine();
        this.formGroup.controls.weight.markAsUntouched();

        this.formGroup.patchValue({
            insuredValue: shipment.InsuredValue,
        });

        this.formGroup.controls.insuredValue.markAsDirty();
        this.formGroup.controls.insuredValue.markAsTouched();

        const formValue = this.formGroup.value;
        formValue.weight = this.userService.standardizeLocalizedNumber(this.user, formValue.weight);
        this.shipmentService.saveShipmentInformation(formValue);
        this.isValid.emit(this.formGroup.valid);
        this.formValueChanged.emit(formValue);

        this.isShipmentRestored = true;
    }

    public generateZendeskLink() {


        this.singleSignOn.generateSSOToken().subscribe(
            response => this.returnZendeskURL(response),
            err => this.handleZendeskFailure(err),
        );

    }

    public setInsuredValue(value: number): void {
        this.formGroup.get('insuredValue').setValue(value);
    }

    private constructShipmentReasons() {
        this.shipmentReason = this.getShipmentReasonCustomField();
        if (!this.shipmentReason || !this.formGroup) {
            return;
        }

        this.shipmentReason.items = this.shipmentReason.items.map(
            (item) => {
                item._id = nanoid();
                return item;
            },
        );

        this.shipmentReason.items = _.sortBy(this.shipmentReason.items, ['order', 'label']);
        this.shipmentReasonsSubject.next(this.shipmentReason.items);

        // set the default shipment reason.
        this.formGroup.controls.shipmentReason.setValue('');

        this.shouldTryConstructShipmentReasons = false;
    }

    private getShipmentReasonCustomField(): ICustomField {
        if (!this.config || !this.config.customFields) {
            return null;
        }

        return this.config.customFields.find(customField => customField.label === 'Shipment Reason');
    }

    private resetComponents(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return true;
                }

                component.resetForm();
            },
        );
    }

    private onInsuredValueCurrencyChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.shipmentService.Quote.CoverageCurrencyCode = change.currentValue;
        this.shipmentService.cacheQuote();

        if (!this.formGroup) {
            return;
        }

        this.formGroup.patchValue({
            insuredValueCurrency: change.currentValue,
        });
    }

    private onMaxCoverageCurrencyCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue || !this.formGroup) {
            return;
        }

        this.formGroup.patchValue({
            maxCoverageCurrencyCode: change.currentValue,
        });
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges
            .subscribe(
                (value) => {
                    value.weight = this.userService.standardizeLocalizedNumber(this.user, value.weight);
                    //To ensure insuredValueCurrency has value for EU customers.
                    if(this.user && !value.insuredValueCurrency){
                        switch(this.user?.CountryCode.toUpperCase()) {
                            case 'DE':
                            case 'FR':
                            case 'IT':
                                value.insuredValueCurrency = 'EUR';
                              break;
                            case 'GB':
                                value.insuredValueCurrency = 'GBP';
                              break;
                        }
                    }
                    this.shipmentService.saveShipmentInformation(value);
                    this.isValid.emit(this.formGroup.valid);
                    this.formValueChanged.emit(value);
                },
            );

        // TODO: seems like monitoring valueChanges on a date is not a good idea since it seems like it takes milliseconds into account.
        let shipDate = moment(this.defaultShipDate).format('YYYYMMDD');
        this.formGroup.controls.shipDate.valueChanges
            .subscribe(
                (value) => {
                    const newValue = moment(value).startOf('day').format('YYYYMMDD');
                    this.formattedShipDate = moment(value).startOf('day').format(this.dateFormat);

                    if (shipDate === newValue) {
                        return;
                    }

                    shipDate = newValue;
                    this.shipConfigService.Config.shipDate = moment(value).startOf('day').format('YYYY-MM-DD');
                    this.shipDateChanged.emit(value);
                },
            );

        this.formGroup.controls.serviceType.valueChanges
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    this.checkRestricted();
                    this.loadPackageTypes(value);

                    if (this.serviceTypes) {
                        this.selectedServiceType = this.serviceTypes.find(serviceType => serviceType.ServiceCode === value);
                        this.selectedServiceTypeChanged.emit(this.selectedServiceType);

                        if (this.selectedServiceType) {
                            this.setMaxInsuredValue();
                        }
                    }
                },
            );

        this.formGroup.controls.packageType.valueChanges
            .subscribe(
                (value) => {
                    this.minWeight = this.weightUOM === 'KGS' ? 0 : 1;

                    this.validationService.clearFormControlValidators([
                        this.formGroup.controls.packageLength,
                        this.formGroup.controls.packageWidth,
                        this.formGroup.controls.packageHeight,
                        this.formGroup.controls.weight,
                    ]);

                    const isCustomPackage = value === '02';
                    if (!isCustomPackage) {
                        this.isShowCustomPackageDimension = false;
                        this.maxWeight = this.defaultMaxWeight;
                        this.setWeightValidation(this.maxWeight);
                        this.resetCustomPackageDimensions();

                        return;
                    }

                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.packageLength, Validators.compose([Validators.required]));
                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.packageWidth, Validators.compose([Validators.required]));
                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.packageHeight, Validators.compose([Validators.required]));

                    this.isShowCustomPackageDimension = true;
                    this.calculateWeight();
                },
            );

        this.formGroup.controls.packageLength.valueChanges
            .pipe(debounceTime(500),
                distinctUntilChanged())
            .subscribe(
                value => this.calculateWeight(),
            );

        this.formGroup.controls.packageWidth.valueChanges
            .pipe(debounceTime(500),
                distinctUntilChanged())
            .subscribe(
                value => this.calculateWeight(),
            );

        this.formGroup.controls.packageHeight.valueChanges
            .pipe(debounceTime(500),
                distinctUntilChanged())
            .subscribe(
                value => this.calculateWeight(),
            );

        this.formGroup.controls.insuredValue.valueChanges
            .pipe(debounceTime(500),
                distinctUntilChanged(),
            )
            .subscribe(
                (value) => {
                    const pickupLocation = this.pickupLocationStateService.selectedPickupLocation$();
                    if (pickupLocation) {
                        const isLocationValidInsuredValue = this.pickupLocationService.isLocationValidInsuredValue(
                            pickupLocation,
                            value,
                        );

                        if (!isLocationValidInsuredValue) {
                            this.snackBar.open(
                                'The provided insured value exceeds the pickup location limit. The location has been removed.',
                                '',
                                { duration: 5000 },
                            );
                            this.shipmentService.accessPointSelected$.next(false);
                            this.shipmentService.fedexHoldAtLocationSelected$.next(false);
                        }
                    }

                    this.insuredValueChanged.emit(value);
                    this.checkRestricted();
                    if(!this.customer.IsInsuranceOnly)
                        this.checkInsuredValueExceeds();

                    this.shipmentService.shipToZipRestrictionCheckRequested$.next(true);
                },
            );

        this.formGroup.controls.shipmentReason.valueChanges
            .subscribe(
                (value) => {
                    if (value === '') {
                        return;
                    }

                    if (!this.shipmentReason || !this.shipmentReason.items || !this.shipmentReason.items.length) {
                        return;
                    }

                    const selectedShipmentReason = this.shipmentReason.items.find(item => item._id === value);
                    if (selectedShipmentReason) {
                        this.formGroup.controls.shipmentReasonText.setValue(selectedShipmentReason.label);
                        this.formGroup.controls.customFieldValue.setValue(selectedShipmentReason.label);
                    }

                    this.setCustomFieldTargetValue(selectedShipmentReason, this.shouldReasonOverwriteUserInsuredValue);
                },
            );
    }

    private resetCustomPackageDimensions() {
        if (!this.formGroup) {
            return;
        }

        this.formGroup.patchValue({
            packageLength: 0,
            packageWidth: 0,
            packageHeight: 0,
        });
    }

    private setCustomFieldTargetValue(customFieldItem: ICustomFieldItem, isOverwriteUserValue = false) {
        const targetControl = this.formGroup.controls[_.lowerFirst(customFieldItem.target)];

        if (!targetControl) {
            return;
        }

        if (isOverwriteUserValue) {
            targetControl.setValue(customFieldItem.value);
            return;
        }

        // Prevent the user's insured value from being overwritten if a user has already changed it.
        if (!targetControl.dirty) {
            targetControl.setValue(customFieldItem.value);
        }
    }

    private setWeightValidation(maxWeight: number) {
        if (!this.formGroup) {
            return;
        }

        const weightValidators = [
            Validators.required,
            // Validators.min(this.minWeight),
            // Validators.max(maxWeight),
            this.validateWeight(this.minWeight, maxWeight),
        ];

        // if (['US', 'CA'].includes(this.shipFromCountryCode))
        if (this.weightUOM === 'LBS') {
            weightValidators.push(Validators.pattern('^[0-9]*$'));
        }

        // [MV3-1634] The user-input weight should match the total commodity weight IF a user does NOT use a custom package.
        const packageType = this.formGroup.controls.packageType.value;
        if (this.totalWeight > 0 && packageType !== '02') {
            weightValidators.push(this.validationService.isExpectedValue(+this.totalWeight));
        }

        this.validationService.setFormControlValidators(
            this.formGroup.controls.weight,
            Validators.compose(weightValidators),
        );
    }

    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        // TODO: package dimensions are not stored and thus cannot be restored.
        this.formGroup.patchValue({
            shipDate: this.shipmentService.Quote.ShipDate ? moment(this.shipmentService.Quote.ShipDate)
                .toDate() : this.defaultShipDate,
            insuredValue: this.shipmentService.Quote.InsuredValue,
            insuredValueCurrency: this.shipmentService.Quote.CoverageCurrencyCode,
            maxCoverageCurrencyCode: this.shipmentService.Quote.CoverageCurrencyCode,
            packageLength: this.shipmentService.Quote.Length,
            packageWidth: this.shipmentService.Quote.Width,
            packageHeight: this.shipmentService.Quote.Height,
            weight: this.shipmentService.Quote.Weight,
            serviceType: this.shipmentService.Quote.ServiceCode,
            packageType: this.shipmentService.Quote.PackageCode,
            referenceNumber: this.shipmentService.Quote.ReferenceNumber,
            invoiceNumber: this.shipmentService.Quote.CustomerReferenceNumber,
        });
        this.formService.markAllAsTouchedAndDirty(this.formGroup);
        this.isValid.emit(this.formGroup.valid);
    }

    //#endregion

    private setMaxInsuredValue() {
        if (!this.selectedServiceType || this.countryMaxCoverage === null || typeof this.countryMaxCoverage === 'undefined') {
            return;
        }

        // [MV3-1615] Limit the insured value "for US" to service-type's max coverage and ignore the country's max coverage.
        this.maxInsuredValue = this.shipToCountryCode === 'US'
            ? this.selectedServiceType.MaxCoverage
            : _.min([this.selectedServiceType.MaxCoverage, this.countryMaxCoverage]);

        // [MV3-1625] Limit the insured value for "DE/GB" users to country's max coverage and ignore the service-type's max coverage.
        if (this.userService.isDEGBUser(this.user)) {
            this.maxInsuredValue = this.countryMaxCoverage;
        }

        this.validationService.clearFormControlValidators([
            this.formGroup.controls.insuredValue,
        ]);

        this.validationService.setFormControlValidators(
            this.formGroup.controls.insuredValue,
            Validators.compose([
                Validators.required,
                Validators.min(0),
                Validators.max(this.maxInsuredValue),
                this.validationService.isInteger()]),
        );
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.isDefaultValuesApplied = false;
        this.shouldTryConstructShipmentReasons = true;
        this.isDefaultServiceTypesLoaded = false;

        this.loadCarrierServices(change.currentValue, this.isDomestic, this.shipFromCountryCode);
    }

    private onIsDomesticChanged(change: SimpleChange) {
        if (!change) {
            return;
        }

        this.loadCarrierServices(this.carrier, change.currentValue, this.shipFromCountryCode);
    }

    //#endregion

    private onShipFromCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.setWeightValidation(this.maxWeight);
        this.loadCarrierServices(this.carrier, this.isDomestic, change.currentValue);
        this.isEUUser = ['DE','GB','IT', 'FR'].includes(this.user?.CountryCode.toUpperCase());
        // if (this.isDomestic && this.shipFromCurrencyCode) {
        //     this.insuredValueCurrency = this.shipFromCurrencyCode;
        // } else {
        //     this.insuredValueCurrency = !this.isEUUser ? 'USD' : this.isGBUser(this.user) ? 'GBP' : 'EUR';
        // }
        const shouldUseUSD = ['8', '2'].includes(this.customer.BillingStationId) || !this.isEUUser;
        this.insuredValueCurrency = shouldUseUSD
            ? 'USD'
            : this.isGBUser(this.user)
                ? 'GBP'
                : 'EUR';
    }

    public isDEUser(user: User) {
        return this.shipConfigService.isDEUser(user);
    }

    public isGBUser(user: User) {
        return this.shipConfigService.isGBUser(user);
    }
    
    private onShipToCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        if (this.isRestoreShipment && this.isServiceTypeRestored) {
            this.shouldForceSetDefaultServiceType = true;
        }

        // [MV3-3043] Load carrier services instead of services by country on a country changed.
        // this.loadCarrierServicesByCountries();
        const shouldUseDomesticServiceTypes = this.isDomestic || (this.isWithinUSTerritories() && +Carriers[this.carrier] === Carriers.Ups);

        this.clearShippingCarrierServicesSubscription();
        this.getShippingCarrierServicesSubscription =
            this.shipmentService.getShippingCarrierServices(this.carrier, shouldUseDomesticServiceTypes, this.shipFromCountryCode)
                .subscribe(
                    serviceTypes => this.handleGetShippingCarrierServiceTypesSuccess(serviceTypes),
                    err => this.handleGetShippingCarrierServiceTypesFailure(err),
                );
    }

    private onShipFromZipCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.loadCarrierServicesByCountries();
    }

    //#endregion

    private onShipToZipCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.loadCarrierServicesByCountries();
    }

    private onWeightUOMChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.formGroup?.controls.weightUOM.setValue(this.weightUOM, { emitEvent: false });

        this.defaultMaxWeight = this.weightUOM === 'KGS' ? 70 : 125;
        this.maxWeight = this.defaultMaxWeight;
        this.setWeightValidation(this.maxWeight);
    }

    private onTotalWeightChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        // [MV3-1634] Max weight does not follow the commodity weight.
        // Other than having the input weight matches the commodity weight, the weight must still not exceed the max weight.
        this.setWeightValidation(this.maxWeight);
        this.formGroup.controls.weight.markAsDirty();
        this.formGroup.controls.weight.markAsTouched();
    }

    private onShipFromCurrencyCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.displayedServiceTypes = _.cloneDeep(this.serviceTypes);
    }

    private onCountryMaxCoverageChanged(change: SimpleChange) {
        if (!change || change.currentValue === null || (typeof change.currentValue === 'undefined')) {
            return;
        }

        this.setMaxInsuredValue();
    }

    private loadCarrierServices(carrier: Carriers, isDomestic: boolean, shipFromCountryCode: string) {
        if (!carrier || !shipFromCountryCode) {
            return;
        }
        //[MV3-5407 -> both international and domestic shipment implemented in ups]
        // if (this.shipConfigService.isUps(carrier) && !isDomestic && this.isDefaultServiceTypesLoaded) {
        //   this.loadCarrierServicesByCountries();
        //   return;
        // }

        // [MV3-1439] Use domestic service types for shipping to a US-territory country.
        // [MV3-1645] Shipping within the US territories should use domestic services only for UPS.
        const shouldUseDomesticServiceTypes = isDomestic || (this.isWithinUSTerritories() && +Carriers[this.carrier] === Carriers.Ups);

        this.clearShippingCarrierServicesSubscription();
        this.getShippingCarrierServicesSubscription =
            this.shipmentService.getShippingCarrierServices(carrier, shouldUseDomesticServiceTypes, shipFromCountryCode)
                .subscribe(
                    serviceTypes => this.handleGetShippingCarrierServiceTypesSuccess(serviceTypes),
                    err => this.handleGetShippingCarrierServiceTypesFailure(err),
                );
    }

    private isWithinUSTerritories() {
        return ['US', 'PR'].includes(this.shipFromCountryCode) && ['US', 'PR'].includes(this.shipToCountryCode);
    }

    //#region GetShippingCarrierServiceTypes handlers
    private handleGetShippingCarrierServiceTypesSuccess(serviceTypes) {
        this.serviceTypes = _.sortBy(serviceTypes, (service) => {
            return service.SortOrder;
        });

        this.serviceTypesSubject.next(this.serviceTypes);
        this.displayedServiceTypes = _.cloneDeep(this.serviceTypes);

        const shouldSetDefaultServiceType =
            (!this.isRestoreShipment && !this.isServiceTypeSetFromCostEstimator)
            || (this.isRestoreShipment && !this.isServiceTypeRestored)
            || this.shouldForceSetDefaultServiceType;

        if (this.serviceTypes
            && this.serviceTypes.length
            && this.formGroup
            && shouldSetDefaultServiceType) {
            // && !this.formGroup.controls.serviceType.dirty
            // && !this.formGroup.controls.serviceType.touched) {
            this.formGroup.patchValue({
                serviceType: this.serviceTypes[0].ServiceCode,
            });
        }

        this.isServiceTypeLoading = false;
        this.isServiceTypeSetFromCostEstimator = false;
        this.isDefaultServiceTypesLoaded = true;
    }

    private handleGetShippingCarrierServiceTypesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting service types',
            NotificationType.ERROR);
        this.isServiceTypeLoading = false;
    }

    private loadPackageTypes(serviceType: string) {
        this.isPackageTypeLoading = true;

        this.clearShippingCarrierPackageTypesSubscription();
        this.getShippingCarrierPackageTypesSubscription = this.shipmentService.getShippingCarrierPackageTypes(this.carrier, serviceType)
                                                              .subscribe(
                                                                  packageTypes => this.handleGetShippingCarrierPackageTypesSuccess(packageTypes),
                                                                  err => this.handleGetShippingCarrierPackageTypesFailure(err),
                                                              );
    }

    //#region GetShippingCarrierPackageTypes handlers
    private handleGetShippingCarrierPackageTypesSuccess(packageTypes) {
        this.packageTypes = packageTypes;
        this.packageTypesSubject.next(packageTypes);

        // if (this.formGroup.controls.packageType.dirty || this.formGroup.controls.packageType.touched) {
        //   this.isPackageTypeLoading = false;
        //   return;
        // }

        const shouldSetDefaultPackageType =
            (!this.isRestoreShipment && !this.isPackageTypeSetFromCostEstimator)
            || (this.isRestoreShipment && !this.isPackageTypeRestored);

        // select the first one as the default value.
        if (this.packageTypes && this.packageTypes.length && shouldSetDefaultPackageType) {
            this.formGroup.patchValue({
                packageType: this.packageTypes[0].PackageTypeCode,
            });

            if (Carriers[this.carrier].toString() === Carriers.Fedex.toString()) {
                this.formGroup.patchValue({
                    packageType: 'MEDIUM BOX',
                });
            }
        }

        this.isPackageTypeLoading = false;
        this.isPackageTypeSetFromCostEstimator = false;

        // if (!shouldSetDefaultPackageType && this.isRestoreShipment) {
        //   this.isRestoreShipment = false;
        // }
    }

    private handleGetShippingCarrierPackageTypesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting package types',
            NotificationType.ERROR);
        this.isPackageTypeLoading = false;
    }

    private calculateWeight() {
        const length = this.formGroup.controls.packageLength.value;
        const width = this.formGroup.controls.packageWidth.value;
        const height = this.formGroup.controls.packageHeight.value;

        if (!length || !width || !height) {
            return;
        }

        this.shipmentService.getPackageWeight(length, width, height, this.dimensionUnit, this.user)
            .subscribe(
                weight => this.handleGetPackageWeightSuccess(weight),
                err => this.handleGetPackageWeightFailure(err),
            );
    }

    //#region GetPackageWeight handlers
    private handleGetPackageWeightSuccess(weight) {
        this.maxWeight = this.defaultMaxWeight;


        // [MV3-1388] The system shall allow the user to input a weight equal to or greater than the calculated dimensional weight,
        // but not greater than the maximum allowed weight (125 LBS or 70 KGS).
        if (this.formGroup.controls.packageType.value !== '02') {
            this.minWeight = this.weightUOM === 'KGS' ? 0 : 1;
        } else {
            this.minWeight = weight;
        }

        this.setWeightValidation(this.maxWeight);

        this.formGroup.patchValue({
            weight,
        });

        this.formGroup.controls.weight.markAsDirty();
        this.formGroup.controls.weight.markAsTouched();
    }

    private handleGetPackageWeightFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting package weight',
            NotificationType.ERROR);
    }
    private checkInsuredValueExceeds() {
        if (!this.formGroup) {
          return;
        }
        let insuredValue = parseInt(this.formGroup.controls.insuredValue.value);
        const serviceType = this.formGroup.controls.serviceType.value;
        this.isEUUser = ['DE','GB','IT', 'FR'].includes(this.user?.CountryCode.toUpperCase());
        
        if ( this.isEUUser  && (serviceType === "07" || serviceType === "65" || serviceType === "74") 
            && typeof insuredValue === 'number' && typeof this.maxInsuredValue === 'number') {
            
          if (insuredValue > this.maxInsuredValue) {
            this.dialogService.confirm(this.translateService.instant('InsureValueExceedsTitle'), this.translateService.instant('InsureValueExceedsMessage'),'',this.translateService.instant('Confirm'), this.translateService.instant('Cancel'),'')
            .subscribe(
                (isConfirmed) => {
                  if (!isConfirmed) {
                    return;
                  }
                 this.sendHighInsuredValueEmail();
                 this.confirmationMessage();
                });
            this.formGroup.controls.insuredValue.setValue(0);
          }
        } 

        if ( this.isEUUser && serviceType === "11" && typeof insuredValue === 'number' && typeof this.maxInsuredValue === 'number') {
            if (insuredValue > this.maxInsuredValue) {
              this.notificationService.notify('', this.translateService.instant('UnInsuredStandaredErr_msg'), NotificationType.ERROR);
            }
            
          }
      }

      sendHighInsuredValueEmail() {
        this.emailService.sendHighInsuredValueEmail()
          .subscribe(
            (response) => {
              
            },
            (err) => {
                this.notificationService.notify(
                    this.errorHandlerService.getHttpErrorMessage(err),
                    'Error Sending Email',
                    NotificationType.ERROR
                  )
            })
      }

      confirmationMessage(){
        this.dialogService.alert(
            this.translateService.instant('ConfirmationMessageTitle'),
            this.translateService.instant('ConfirmationNoteMessage'));
      }

    private checkRestricted() {
        if (!this.formGroup) {
            return;
        }

        const zipCode = this.shipmentService.Quote.ShipTo.Zip;
        const insuredValue = this.formGroup.controls.insuredValue.value;
        const serviceType = this.formGroup.controls.serviceType.value;

        if (!this.carrier || !zipCode || !insuredValue || !serviceType) {
            return;
        }

        if (this.shipmentService.Quote.ShipTo.Country !== 'US') {
            return;
        }

        if (this.checkRestrictedSubscription) {
            this.checkRestrictedSubscription.unsubscribe();
            this.checkRestrictedSubscription = null;
        }

        this.isRestrictedChecking = true;
        this.checkRestrictedSubscription = this.shipmentService.checkRestricted(zipCode, this.carrier, insuredValue, serviceType)
                                               .subscribe(
                                                   result => this.handleCheckRestrictedSuccess(result),
                                                   err => this.handleCheckRestrictedFailure(err),
                                               );
    }

    private handleCheckRestrictedSuccess(result: ICheckRestrictedResponse) {
        if (result.IsRestricted) {
            const displayMessage = result.Messages.map(message => message.Message).join(',');
            this.notificationService.notify(
                displayMessage,
                'Restricted',
                NotificationType.ERROR);
        }

        this.isRestricted = result.IsRestricted;
        this.isRestrictedChecking = false;
    }

    private handleCheckRestrictedFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed checking restricted',
            NotificationType.ERROR);
        this.isRestrictedChecking = false;
    }

    private fillCostEstimateCustomPackaging(cachedCostEstimate: any) {
        if (!cachedCostEstimate.IsCustomPackaging) {
            return;
        }

        this.formGroup.patchValue({
            packageType: '02',
            packageLength: cachedCostEstimate.Length,
            packageWidth: cachedCostEstimate.Width,
            packageHeight: cachedCostEstimate.Height,
        });

        this.formGroup.controls.packageType.markAsDirty();
        this.formGroup.controls.packageType.markAsTouched();

        this.formGroup.controls.packageLength.markAsDirty();
        this.formGroup.controls.packageLength.markAsTouched();

        this.formGroup.controls.packageWidth.markAsDirty();
        this.formGroup.controls.packageWidth.markAsTouched();

        this.formGroup.controls.packageHeight.markAsDirty();
        this.formGroup.controls.packageHeight.markAsTouched();
    }

    private getFormattedTotalWeight(): string {
        if (this.customerService.isUSCustomer(this.customer)) {
            return Math.ceil(this.totalWeight).toString();
        }

        return this.totalWeight.toFixed(2).replace(/\.?0+$/, '');
    }

    private returnZendeskURL(res: any) {
        this.ssoURL = res.redirectURL +
            '&return_to=https://support.parcelpro.com/hc/en-us/articles/360001688228-Actual-Weight-vs-Dimensional-DIM-Weight';
    }

    private handleZendeskFailure(err: any) {
        this.ssoURL = 'https://www.parcelpro.com';
    }
}

<div class="dialog-header">
    <button class="close-icon"
            mat-dialog-close
            mat-icon-button
            tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="dialog-content">
    <div class="content">
        <upsc-pickup-locations (locationSelected)="pickupLocation = $event"
                               [carrier]="carrier"
                               [insuredValue]="insuredValue"
                               [isReadonly]="isReadonly"
                               [pickupLocationId]="locationId"
                               [request]="request"></upsc-pickup-locations>
    </div>

    @if (!isReadonly) {
        <div class="dialog-actions" #dialogFooter>
            @if (carrier === Carriers.Ups) {
                <div class="access-point-code-offer">
                    <div class="message-wrapper">
                        <img ngSrc="assets/images/locked-package.svg"
                             alt="Access Point Code"
                             height="40"
                             width="41">
                        <span class="message">{{ 'pickupLocationsDialog.accessPointCode.title' | translate }}</span>
                        <span class="material-icons" matTooltip="Use access code to enhance pickup security.">help_outline</span>
                    </div>
                    <div class="access-point-code-option">
                        <mat-checkbox [formControl]="useAccessPointCodeControl">
                            {{ 'pickupLocationsDialog.accessPointCode.optionMessage' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            }

            <div class="actions">
                <button (click)="continue($event)"
                        class="dialog-action"
                        color="primary"
                        mat-flat-button
                        [disabled]="!pickupLocation"
                        type="button">{{ 'pickupLocationsDialog.continue' | translate }}
                </button>
                <button class="dialog-action"
                        [mat-dialog-close]="null"
                        mat-stroked-button
                        type="button">{{ 'pickupLocationsDialog.cancel' | translate }}
                </button>
            </div>
        </div>
    }
</div>

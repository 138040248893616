<div class="ship-to-edit ups-form" [formGroup]="formGroup">
    <div class="form-body">
        <div class="select-wrapper">
            <upsc-ship-countries [formGroup]="formGroup"
                                 [countryFormControlName]="'country'"
                                 [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                                 [countries]="displayedCountries"></upsc-ship-countries>
            <upsc-element-blocker *ngIf="isCountriesLoading || isZipCodeValidating"></upsc-element-blocker>
        </div>

        <ng-container *ngIf="shouldDisplayEUFields">
            <mat-form-field>
                <mat-label>{{ 'vat' | translate }}</mat-label>
                <input matInput
                       type="text"
                       maxlength="20"
                       formControlName="vat">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'taxId' | translate }}</mat-label>
                <input matInput
                       type="text"
                       maxlength="20"
                       formControlName="taxId">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'eori' | translate }}</mat-label>
                <input matInput
                       type="text"
                       maxlength="20"
                       formControlName="eori">
                <a href="{{ 'eoriInfoLink' | translate}}"
                   target="_blank"
                   matSuffix>
                    <mat-icon matTooltip="{{ 'eoriInfo' | translate }}">help</mat-icon>
                </a>
            </mat-form-field>
        </ng-container>

        <div class="equal-space">
            <mat-form-field>
                <mat-label>{{'FirstName'|translate}}</mat-label>
                <input matInput
                       [required]="isFirstNameLastNameRequired ? '' : null"
                       maxlength="15"
                       formControlName="firstName">
                <mat-error *ngIf="formGroup.controls.firstName?.errors?.required">
                    {{'FirstNameRequired'|translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'LastName'|translate}}</mat-label>
                <input matInput
                       [required]="isFirstNameLastNameRequired ? '' : null"
                       maxlength="19"
                       formControlName="lastName">
                <mat-error *ngIf="formGroup.controls.lastName?.errors?.required">
                    {{'LastNameRequired'|translate}}
                </mat-error>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{'Company'|translate}}</mat-label>
            <input matInput
                   [required]="isCompanyNameRequired ? '' : null"
                   maxlength="35"
                   formControlName="company">
            <mat-error *ngIf="formGroup.controls.company?.errors?.required">
                {{'CompanyRequired'|translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'AddressLine1'|translate}}</mat-label>
            <input matInput
                   required
                   maxlength="35"
                   (blur)="validateAddress()"
                   formControlName="address1">
            <mat-error *ngIf="formGroup.controls.address1?.errors?.required">
                {{'Address1Required'|translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'AddressLine2'|translate}}</mat-label>
            <input matInput
                   maxlength="35"
                   formControlName="address2">
            <mat-error *ngIf="formGroup.controls.address2?.errors?.required">
                {{'Address2Required'|translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isFRITUser">
            <mat-label>{{'AddressLine3'|translate}}</mat-label>
            <input matInput
                   maxlength="35"
                   formControlName="address3">
           
        </mat-form-field>
        <mat-form-field [subscriptSizing]="selectedCountry?.IsPostalCodeAware && !!selectedCountry?.PostalCodePattern ? 'dynamic' : null">
            <mat-label>{{'ZipCode'|translate}}</mat-label>
            <input matInput
                   [required]="selectedCountry?.IsPostalCodeAware ? '' : null"
                   maxlength="10"
                   (blur)="validateAddress()"
                   formControlName="zipCode">
            <mat-hint *ngIf="selectedCountry?.IsPostalCodeAware && !!selectedCountry?.PostalCodePattern">
                <span>{{ 'inputHint.postalCodeExample' | translate }}: {{ selectedCountry.PostalCodePattern }}</span>
            </mat-hint>
            <mat-error *ngIf="formGroup.controls.zipCode?.errors?.required">
                {{'ZipCodeRequired'|translate}}
            </mat-error>
            <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
        </mat-form-field>

        <div class="equal-space">
            <mat-form-field>
                <mat-label>{{'City'|translate}}</mat-label>
                <input matInput
                       required
                       maxlength="35"
                       (blur)="validateAddress()"
                       formControlName="city">
                <mat-error *ngIf="formGroup.controls.city?.errors">
                    <span *ngIf="formGroup.controls.city?.errors?.required">{{'CityRequired'|translate}}</span>
                    <span *ngIf="formGroup.controls.city?.errors?.maxLength">{{'CityMaxLength'|translate}}</span>
                </mat-error>
                <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'StateOrProvince'|translate}}</mat-label>
                <!-- [MV3-1689] State/Province field should always be enabled even if it is not required. -->
                <input matInput
                       [attr.required]="shouldRequireStateProvince()"
                       maxlength="35"
                       (blur)="validateAddress()"
                       formControlName="state">
                <mat-error *ngIf="formGroup.controls.state?.errors?.required">
                    {{'StateProvinceRequired'|translate}}
                </mat-error>
                <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{'TelephoneNo'|translate}}</mat-label>
            <input matInput
                   required
                   minlength="10"
                   maxlength="15"
                   formControlName="phone">
            <mat-error *ngIf="formGroup.controls.phone?.errors">
                <span *ngIf="formGroup.controls.phone?.errors.required">{{'TelephoneNumberRequired'|translate}}</span>
                <span *ngIf="formGroup.controls.phone?.errors.pattern">{{'Telephone No can only be numeric'|translate}}</span>
                <span *ngIf="!formGroup.controls.phone?.errors.pattern && formGroup.controls.phone?.errors.minlength">{{'Telephone No must be at least 10 digits'|translate}}</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'EmailAddress'|translate}}</mat-label>
            <input matInput
                   maxlength="100"
                   formControlName="email">
            <mat-error *ngIf="formGroup.controls.email?.errors?.emailFormat">
                {{'EmailAddressInvalidFormat'|translate}}
            </mat-error>
        </mat-form-field>

        <div class="email-notifications" *ngIf="config?.showNotifyRecipient">
            <mat-checkbox formControlName="emailShipmentNotification">{{'ShipmentNotification'|translate}}
            </mat-checkbox>
            <div class="fedex-only" *ngIf="isFedEx()">
                <mat-checkbox formControlName="emailExceptionNotification">{{'ExceptionNotification'|translate}}
                </mat-checkbox>
                <mat-checkbox formControlName="emailDeliveryNotification">{{'DeliveryNotification'|translate}}
                </mat-checkbox>
            </div>
        </div>

        <!--<mat-checkbox formControlName="notifyRecipient">Notify Recipient</mat-checkbox>-->
        <mat-checkbox formControlName="updateAddress">{{'SaveMyAddressBook'|translate}}</mat-checkbox>
        <mat-checkbox formControlName="addExpress">{{'AddToExpressContacts'|translate}}</mat-checkbox>
        <mat-checkbox formControlName="residentialCheck">{{'CheckIsResidentialAddress'|translate}}</mat-checkbox>
        <div class="parcel-pro-notification">
            <mat-checkbox formControlName="emailParcelProNotification" *ngIf="emailTemplates.length">
                {{ 'ParcelProNotify' | translate }}
            </mat-checkbox>
            <div class="custom-email-options" *ngIf="isParcelProNotificationEnabled">
                <div class="select-wrapper">
                    <mat-form-field>
                        <mat-label>{{'SelectTemplate'|translate}}</mat-label>
                        <mat-select required
                                    formControlName="parcelProEmailTemplate">
                            <mat-option value="" disabled selected>{{'SelectAnEmailTemplate'|translate}}</mat-option>
                            <mat-option *ngFor="let template of emailTemplates" [value]="template.TemplateId">
                                {{ template.TemplateName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <upsc-element-blocker *ngIf="isTemplateLoading"></upsc-element-blocker>
                </div>
                <mat-form-field>
                    <mat-label>{{'PackageContent'|translate}}</mat-label>
                    <input matInput
                           required
                           maxlength="100"
                           formControlName="parcelProEmailPackageContent">
                    <mat-error *ngIf="formGroup.controls.parcelProEmailPackageContent?.errors?.required">
                        {{'PackageContentRequired'|translate}}
                    </mat-error>
                </mat-form-field>

                <div class="parcel-pro-notify-upload-photo" *ngIf="isParcelProNotificationEnabled">
                    <div class="message">
                        {{ "ParcelProNotifyUploadPhotoMessage" | translate }}
                        <a href="#" (click)="openTermsOfUseDialog($event)">{{ 'TermsOfUse' | translate }}</a>
                    </div>
                    <div class="actions">
                        <div class="spinner-wrapper">
                            <button mat-raised-button
                                    type="button"
                                    (click)="previewTemplate($event, formGroup.controls.parcelProEmailTemplate.value)"
                                    class="alternative-button">
                                {{ 'PreviewTemplate' | translate }}
                            </button>
                            <upsc-element-blocker *ngIf="isLoadingNotifyTemplate"></upsc-element-blocker>
                        </div>
                        <button *ngIf="false" mat-raised-button type="button" class="secondary-button">
                            {{ 'UploadTemplateBannerPhoto' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <a routerLink="/notify-templates" *ngIf="shouldShowCreateNotifyTemplate && !emailTemplates.length && false">
                {{ 'CreateNotifyTemplate' | translate }}</a>
        </div>
    </div>
</div>

<div class="shipping">
    <div class="shipping-content">
        <form class="ups-form" [formGroup]="parentForm" (ngSubmit)="onFormSubmitted($event, parentForm.value)">
            <ng-container [ngTemplateOutlet]="header"></ng-container>

            <div class="ship-sections-wrapper form-body">
                <div class="ship-sections">
                    <div class="ship-section">

                        <upsc-return-ship-from #shipFrom class="ship-section-item" [user]="user" [customer]="customer"
                                               [carrier]="carrier" [shipFromCountryCode]="shipFromCountryCode"
                                               [shipFromCurrencyCode]="shipFromCurrencyCode"
                                               [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                                               [restrictedCountryCodes]="restrictedShipToCountryCodes"
                                               [shouldDisplayEUFields]="shouldDisplayShipFromEUFields"
                                               (countryChanged)="onShipFromCountryChanged($event)"
                                               (countryCodeChanged)="onShipFromCountryCodeChanged($event)"
                                               (zipCodeChanged)="onShipFromZipCodeChanged($event)"
                                               (shipFromAddressChanged)="onShipFromAddressChanged($event)"
                                               (shipFromAddressChanges)="onShipFromAddressChanges($event)"
                                               (addressValidationChanged)="onAddressValidationChanged($event)"
                                               (isResidentialAddressChanged)="onIsResidentialAddressChanged($event)"
                                               (adultSignatureRequested)="onAdultSignatureRequested($event)"
                                               (residentialCheckChanged)="onResidentialCheckChanged($event)"
                                               (isValid)="setFormValidity('shipFrom', $event)"
                                               (formValueChanged)="onFormValueChanged($event)">
                        </upsc-return-ship-from>
                        <upsc-return-ship-to #shipTo class="ship-section-item" [user]="user" [customer]="customer"
                                             [carrier]="carrier" [shipToCountryCode]="shipToCountryCode"
                                             [shipFromCurrencyCode]="shipFromCurrencyCode"
                                             [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                                             [returnShipment]="returnShipment"
                                             [isRestoreShipment]="isRestoreShipment"
                                             [shouldDisplayEUFields]="shouldDisplayShipToEUFields"
                                             (countryChanged)="onShipToCountryChanged($event)"
                                             (countryCodeChanged)="onShipToCountryCodeChanged($event)"
                                             (zipCodeChanged)="onShipToZipCodeChanged($event)"
                                             (shipToAddressChanged)="onShipToAddressChanged($event)"
                                             (isValid)="setFormValidity('shipTo', $event)"
                                             (formValueChanged)="onFormValueChanged($event)">
                        </upsc-return-ship-to>

                    </div>
                    <div class="ship-section">
                        <upsc-ship-info #shipInfo class="ship-section-item" [user]="user" [customer]="customer"
                                        [carrier]="carrier" [isDomestic]="isDomestic"
                                        [shipFromCountryCode]="shipFromCountryCode"
                                        [shipFromCurrencyCode]="shipFromCurrencyCode"
                                        [shipToCountryCode]="shipToCountryCode"
                                        [shipToCountry]="shipToCountry" [shipFromCountry]="shipFromCountry"
                                        [shipFromZipCode]="shipFromZipCode" [shipToZipCode]="shipToZipCode"
                                        [countryMaxCoverage]="countryMaxCoverage"
                                        [insuredValueCurrency]="insuredValueCurrencyCode"
                                        [maxCoverageCurrencyCode]="maxCoverageCurrencyCode" [weightUOM]="weightUOM"
                                        [dimensionUnit]="dimensionUnit" [dateFormat]="dateFormat"
                                        [totalWeight]="totalWeight"
                                        [isRestoreShipment]="isRestoreShipment" [isReship]="isReship"
                                        [returnShipment]="returnShipment"
                                        (selectedServiceTypeChanged)="onServiceTypeChanged($event)"
                                        (shipDateChanged)="onShipDateChanged($event)"
                                        (insuredValueChanged)="onInsuredValueChanged($event)"
                                        (isValid)="setFormValidity('shipInfo', $event)"
                                        (formValueChanged)="onFormValueChanged($event)"></upsc-ship-info>
                        <div class="commodity-ship-info-section">
                            <upsc-commodity-ship-info #commodityShipInfo class="ship-section-item sub-section"
                                                      *ngIf="config?.showCommodities" [commodities]="commodities"
                                                      [customsValueCurrency]="customsValueCurrency"
                                                      [isRestoreShipment]="isRestoreShipment"
                                                      [cachedShipment]="cachedShipment"
                                                      (isValid)="setFormValidity('commodityShipInfo', $event)"
                                                      (formValueChanged)="onFormValueChanged($event)"></upsc-commodity-ship-info>
                        </div>
                        <upsc-ship-info-add #shipInfoAdd class="ship-section-item" [hidden]="true"
                                            [serviceType]="serviceType" [carrier]="carrier" [user]="user"
                                            [isDomestic]="isDomestic"
                                            [shipFromCountryCode]="shipFromCountryCode"
                                            [shipToCountryCode]="shipToCountryCode"
                                            [isRestoreShipment]="isRestoreShipment" [returnShipment]="returnShipment"
                                            [shouldForceClearAdultSignatureRequired]="shouldForceClearAdultSignatureRequired"
                                            (adultSignatureChanged)="onAdultSignatureChanged($event)"
                                            (isValid)="setFormValidity('shipInfoAdd', $event)"
                                            (formValueChanged)="onFormValueChanged($event)"></upsc-ship-info-add>
                        <upsc-ship-commodity #shipCommodity class="ship-section-item"
                                             [class.hide-me]="!config?.showCommodities"
                                             [isRestoreShipment]="isRestoreShipment"
                                             [user]="user" [customer]="customer"
                                             [shipFromCountryCode]="shipFromCountryCode"
                                             [shipToCountryCode]="shipToCountryCode" [maxCommodityAllowed]="5"
                                             [weightUOM]="weightUOM"
                                             [customsValueCurrency]="customsValueCurrency" [carrier]="carrier"
                                             (totalCustomsValueChanged)="onTotalCustomsValueChanged($event)"
                                             (totalWeightChanged)="onTotalWeightChanged($event)"
                                             (commoditiesChanged)="onCommoditiesChanged($event)"
                                             (isValid)="setFormValidity('shipCommodity', $event)"></upsc-ship-commodity>
                        <upsc-return-options #shipReturnOptions class="ship-section-item"
                                             (isValid)="setFormValidity('shipReturnOptions', $event)"
                                             (formValueChanged)="onFormValueChanged($event)"
                                             (returnOptionChanged)="onReturnOptionChanged($event)"></upsc-return-options>
                        <upsc-ship-options #shipOptions class="ship-section-item" [user]="user" [carrier]="carrier"
                                           [class.hide-me]="returnOption === '3' || returnOption === '5' || returnOption === '0'"
                                           [isExistingSchedulePickup]="isExistingSchedulePickup" [shipDate]="shipDate"
                                           [dateFormat]="dateFormat" [shipFromAddress]="shipFromAddress"
                                           [isRestoreShipment]="isRestoreShipment" [isReship]="isReship"
                                           (pickupTypeChanged)="onPickupTypeChanged($event)"
                                           [returnOption]="returnOption"
                                           [returnShipment]="returnShipment"
                                           (schedulePickupDateChanged)="onSchedulePickupDateChanged($event)"
                                           (isValid)="setFormValidity('shipOptions', $event)"
                                           (formValueChanged)="onFormValueChanged($event)"></upsc-ship-options>
                        <upsc-ship-billing-details #shipBillingDetails class="ship-section-item" [user]="user"
                                                   [carrier]="carrier" [isDomestic]="isDomestic"
                                                   (isValid)="setFormValidity('billingDetails', $event)"
                                                   (formValueChanged)="onFormValueChanged($event)"></upsc-ship-billing-details>
                        <upsc-ship-electronic-export #shipElectronicExport class="ship-section-item"
                                                     [class.hide-me]="true" [totalCustomsValue]="totalCustomsValue"
                                                     [shipFromCountryCode]="shipFromCountryCode" [customer]="customer"
                                                     [isEEIRequired]="isEEIRequired"
                                                     (eeiFilingOptionChanged)="onEeiFilingOptionChanged($event)"
                                                     (isValid)="setFormValidity('shipElectronicExport', $event)"
                                                     (formValueChanged)="onFormValueChanged($event)"></upsc-ship-electronic-export>
                        <upsc-import-information #shipImportInformation class="ship-section-item" *ngIf="showImportInformation"
                                                 (formValueChanged)="onFormValueChanged($event)">
                        </upsc-import-information>
                        <upsc-ship-customs-docs #shipCustomsDocs class="ship-section-item" *ngIf="!isDomestic"
                                                [shipFromCountryCode]="shipFromCountryCode"
                                                [shipToCountryCode]="shipToCountryCode"
                                                [carrier]="carrier" [carrierKey]="carrierKey" [customer]="customer"
                                                [customerImages]="customerImages"
                                                [validatePaperlessInvoiceCapabilityResult]="validatePaperlessInvoiceCapabilityResult"
                                                (isValid)="setFormValidity('shipCustomsDocs', $event)"
                                                (formValueChanged)="onFormValueChanged($event)"></upsc-ship-customs-docs>

                    </div>
                    <div class="ship-section">
                        <ng-container [ngTemplateOutlet]="notifications"
                                      [ngTemplateOutletContext]="{formGroup: parentForm}"></ng-container>

                        <ng-container [ngTemplateOutlet]="costEstimate"></ng-container>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #header>
    <div class="ups-page-header ship-page-header">
        <h1 class="page-title">{{ 'CreateReturnShipment' | translate }}</h1>
        <div class="required-field-indicator">{{ 'requiredFields' | translate }}</div>
    </div>
</ng-template>

<ng-template #costEstimate>
    <div class="ship-section-item sub-section cost-estimator-wrapper">
        <div class="cost-estimate">
            <div class="form-section-header">
                <h5 class="form-section-title">{{ (shouldShowCostEstimate ? 'CostEstimate' : 'Quote') | translate
                    }}</h5>
            </div>

            <div class="estimate-items" *ngIf="shouldShowCostEstimate">
                <div class="estimate-item">
                    <div class="key">{{'EstShippingandInsurance'|translate}}</div>
                    <div class="value">{{ estimatedCost }}</div>
                </div>
                <div class="estimate-item">
                    <div class="key">{{'SurchargeandServices'|translate}}</div>
                    <div class="value">{{ surcharge }}</div>
                </div>

                <div class="estimate-item total">
                    <div class="key">{{'YourTotal'|translate}}<sup>*</sup></div>
                    <div class="value">{{ totalCharges }}</div>
                </div>
            </div>
            <div class="estimate-items" *ngIf="!shouldShowCostEstimate">
                <div class="estimate-item">
                    <div class="key">{{'InsuranceCost'|translate}}</div>
                    <div class="value">{{ formattedInsuredValue }}</div>
                </div>
            </div>
            <div class="footnotes">
                <div class="footnote">
                    {{'FootNote'|translate}}
                </div>
            </div>

            <div class="form-actions">
                <button type="button" mat-raised-button class="form-action alternative-button"
                        (click)="saveQuote($event)" [disabled]="!isQuoteReady || !shouldEnableSaveQuote">
                    {{'SaveQuote'|translate}}
                </button>
                <button type="button" mat-raised-button class="form-action"
                        (click)="onFormSubmitted($event, parentForm.value)">
                    {{'GetQuote'|translate}}
                </button>
            </div>
        </div>

        <div class="confirm-shipment">
            <div class="terms-notice">
                {{'YouAreAgreeingTo'|translate}}
                <a href="#" (click)="openTermsAndConditionsDialog($event)">{{'TermsandConditions'|translate}}</a>.
            </div>

            <button *ngIf="!isAESShipment" type="button" mat-raised-button class="form-action"
                    (click)="getQuoteAndShip($event, parentForm.value)">
                {{'GetReturnLabel'|translate}}
            </button>

            <button *ngIf="isAESShipment" type="button" mat-raised-button class="form-action"
                    (click)="submitToACE($event, parentForm.value)">
                {{'SubmitToACE'|translate}}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #notifications let-formGroup="formGroup">
    <div class="notifications" [formGroup]="formGroup">
        <div class="notification alert reroute" *ngIf="shouldShowShipToReroute">
            <div class="notification-content">
                <h5>{{'DestinationZipCodeRestricted'|translate}}</h5>
                <p>
                    {{'DestinationZipCodeRestrictedNotification'|translate}}
                </p>
            </div>
        </div>
        <div class="notification alert residential-verification" *ngIf="shouldShowResidentialVerification">
            <div class="notification-content">
                <mat-checkbox formControlName="isResidentialVerificationAgreed">
                    {{'IAgreeToRESIDENTIALVERIFICATION'|translate}}
                </mat-checkbox>
                <p>
                    {{'IsResidentialVerificationAgreedParagraph'|translate}}
                    <a href="#" (click)="openAddressValidationInformationDialog($event)">
                        {{'InformationOnAddressValidationandResidentialDelivery'|translate}}
                    </a>
                    {{'ToSeeDetailedInformation'|translate}}
                </p>
                <mat-error *ngIf="parentForm.controls.isResidentialVerificationAgreed?.errors">
                    <span *ngIf="parentForm.controls.isResidentialVerificationAgreed?.errors.required">
                        {{ 'residentialVerificationAgreement' | translate }}
                    </span>
                </mat-error>
            </div>
        </div>
        <div class="notification alert high-value" *ngIf="isHighValueShipment">
            <div class="notification-content">
                <h5>{{ 'HighValueShipmentNotification.Title' | translate }}</h5>
                <p>{{ 'HighValueShipmentNotification.Message' | translate }}</p>
            </div>
        </div>
        <div class="notification alert aes" *ngIf="isAESShipment">
            <div class="notification-content">
                <h5>{{ 'AESShipmentNotification.Title' | translate }}</h5>
                <p>{{ 'AESShipmentNotification.Message' | translate }}</p>
            </div>
        </div>
        <div class="notification alert valuation-discrepancy" *ngIf="shouldShowValuationDiscrepancy">
            <div class="notification-content">
                <mat-checkbox formControlName="isValuationDiscrepancyAgreed">
                    {{'IAgreeToValuationDiscrepancy'|translate}}
                </mat-checkbox>
                <h5>{{'ValuationDiscrepancy.Title'|translate}}</h5>
                <p>
                    {{'ValuationDiscrepancy.Message'|translate}}
                </p>
                <p>
                    <strong>{{'ValuationDiscrepancy.Notice'|translate}}</strong>
                </p>
                <mat-error *ngIf="parentForm.controls.isValuationDiscrepancyAgreed?.errors">
                    <span *ngIf="parentForm.controls.isValuationDiscrepancyAgreed?.errors.required">
                        {{ 'valuationDiscrepancyAgreement' | translate }}
                    </span>
                </mat-error>
            </div>
        </div>
    </div>
</ng-template>

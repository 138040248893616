<div class="ship-customs-doc ups-form" [formGroup]="formGroup">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title customs-documentation" style="padding-left: 1.7rem;">{{'CustomsDocumentation'|translate}}</h5>
        </div>

        <div class="option-title">
            <span [innerHtml]="'shipCustomsDocumentation.optionTitle.label' | translate"></span>
            <img ngSrc="assets/icons/help.svg"
                 alt="Help"
                 class="help-icon"
                 matTooltip="{{ 'shipCustomsDocumentation.optionTitle.hint' | translate }}"
                 matTooltipPosition="right"
                 height="19"
                 width="19">
        </div>

        <mat-radio-group formControlName="invoiceOption">
            <mat-radio-button value="2" *ngIf="shouldAllowInvoiceUpload">
                <span [innerHtml]="'shipCustomsDocumentation.options.upload.label' | translate"></span>
                <img ngSrc="assets/icons/help.svg"
                     alt="Help"
                     class="help-icon"
                     matTooltip="{{ 'shipCustomsDocumentation.options.upload.hint' | translate }}"
                     matTooltipPosition="right"
                     height="19"
                     width="19">
            </mat-radio-button>
            <div class="invoice-uploader"
                 *ngIf="shouldAllowInvoiceUpload && formGroup.controls['invoiceOption'].value === '2' && !uploadedFile">
                <div class="uploader-message">
                    {{ 'shipCustomsDocumentation.options.upload.uploader.messagePre' | translate }}
                    <a href="https://www.ups.com/media/en/Commercial_Invoice_Guide.pdf" target="_blank">
                        {{ 'shipCustomsDocumentation.options.upload.uploader.messageLink' | translate }}
                    </a>
                    {{ 'shipCustomsDocumentation.options.upload.uploader.messagePost' | translate }}
                </div>
                <div class="uploader">
                    <!-- <label>
                        <input type="file"
                               accept=".bmp,.doc,.docx,.gif,.jpg,.jpeg,.pdf,.rtf,.tif,.txt,.xls,.xlsx"
                               (change)="uploadInvoice($event)">
                        <a mat-raised-button
                           class="secondary-button dense">
                            {{ 'shipCustomsDocumentation.options.upload.uploader.button' | translate }}
                        </a>
                    </label> -->
                    <!-- MV3-3890 - File Control not working in IE -->
                <upsc-file-selector [label]="'shipCustomsDocumentation.options.upload.uploader.button' | translate"
                                    [fileTypes]="'.bmp,.doc,.docx,.gif,.jpg,.jpeg,.pdf,.rtf,.tif,.txt,.xls,.xlsx'"
                                    [buttonClass]="'dense'"                                 
                                    [clearFileControl]="true"
                                    (fileSelectionChanged)="uploadInvoice($event)"></upsc-file-selector>
                    <div class="error-message" *ngIf="uploadErrorMessage">{{ uploadErrorMessage }}</div>
                </div>
                <div class="file-type-message">
                    {{ 'shipCustomsDocumentation.options.upload.uploader.fileTypeMessage' | translate: { sizeLimit: 8 } }}
                </div>
            </div>
            <div class="uploaded-invoice file-chip"
                 *ngIf="shouldAllowInvoiceUpload && formGroup.controls['invoiceOption'].value === '2' && !!uploadedFile">
                <mat-chip-listbox>
                    <mat-chip-option [selectable]="false"
                              [removable]="true"
                              (removed)="removeUploadedInvoice()">
                        {{ uploadedFile.name }}
                        <mat-icon matChipRemove
                                  (mouseover)="showRemoveFileHint = true"
                                  (mouseout)="showRemoveFileHint = false">close
                        </mat-icon>
                    </mat-chip-option>
                </mat-chip-listbox>
                <span class="remove-link" [hidden]="!showRemoveFileHint">
                    {{ 'shipCustomsDocumentation.options.upload.uploader.remove' | translate }}
                </span>
            </div>
            <mat-radio-button value="3" *ngIf="shouldAllowInvoiceCreation">
                <span [innerHtml]="'shipCustomsDocumentation.options.createAndEFile.label' | translate"></span>
                <img ngSrc="assets/icons/help.svg"
                     alt="Help"
                     class="help-icon"
                     matTooltip="{{ 'shipCustomsDocumentation.options.createAndEFile.hint' | translate }}"
                     matTooltipPosition="right"
                     height="19"
                     width="19">
            </mat-radio-button>
            <div class="uploaded-images file-chip"
                 *ngIf="!!shouldAllowInvoiceCreation && !!customerImages && !!customerImages.length && formGroup.controls['invoiceOption'].value === '3'">
                <mat-chip-listbox>
                    <mat-chip-option [selectable]="false"
                              [removable]="true"
                              (removed)="removeUploadedImages()" (click)="openPaperlessCommercialInvoiceSettingsDialog()" >
                        {{ 'shipCustomsDocumentation.options.createAndEFile.uploader.chipLabel' | translate }}
                        <mat-icon matChipRemove
                                  (mouseover)="showRemoveImageHint = true"
                                  (mouseout)="showRemoveImageHint = false">close
                        </mat-icon>
                    </mat-chip-option>
                </mat-chip-listbox>
                <span class="remove-link" [hidden]="!showRemoveImageHint">
                    {{ 'shipCustomsDocumentation.options.createAndEFile.uploader.remove' | translate }}
                </span>
            </div>
            <mat-radio-button value="1">
                <span [innerHtml]="'shipCustomsDocumentation.options.createAndPrint.label' | translate"></span>
                <img ngSrc="assets/icons/help.svg"
                     alt="Help"
                     class="help-icon"
                     matTooltip="{{ 'shipCustomsDocumentation.options.createAndPrint.hint' | translate }}"
                     matTooltipPosition="right"
                     height="19"
                     width="19">
            </mat-radio-button>
            <mat-radio-button value="0">
                <span [innerHtml]="'shipCustomsDocumentation.options.doNotCreate.label' | translate"></span>
                <img ngSrc="assets/icons/help.svg"
                     alt="Help"
                     class="help-icon hide-me"
                     matTooltip="{{ 'shipCustomsDocumentation.options.doNotCreate.hint' | translate }}"
                     matTooltipPosition="right"
                     height="19"
                     width="19">
            </mat-radio-button>
        </mat-radio-group>

        <mat-checkbox formControlName="isCreateCommercialInvoices" *ngIf="false">
            {{'CreateCommercialInvoices'|translate}}
            <img ngSrc="assets/icons/help.svg"
                 alt="Help"
                 class="help-icon"
                 matTooltip="{{ 'CreateCommercialInvoicesTooltip' | translate }}"
                 matTooltipPosition="above"
                 height="19"
                 width="19">
        </mat-checkbox>
        <mat-checkbox formControlName="isNAFTA" *ngIf="config?.showNAFTA">
            {{'SelectUSMCACertificateOfOrigin'|translate}}
            <img ngSrc="assets/icons/help.svg"
                 alt="Help"
                 class="help-icon"
                 matTooltip="{{ 'USMCATooltip' | translate }}"
                 matTooltipPosition="above"
                 height="19"
                 width="19">
        </mat-checkbox>
    </div>
</div>

import { Component, HostBinding, Input } from '@angular/core';
import { DeliveryDefenseService } from '../../../../delivery-defense/services/delivery-defense.service';

@Component({
    selector: 'upsc-dd-remaining-credits',
    templateUrl: './dd-remaining-credits.component.html',
    styleUrls: ['./dd-remaining-credits.component.scss'],
})
export class DDRemainingCreditsComponent {
    @Input() public remainingCredit = 0;
    @Input() public fullCredit = 50;
    @Input() public subTypeId: number;

    @HostBinding('class') public hostClass = 'dd-remaining-credits';

    public constructor(private readonly deliveryDefenseService: DeliveryDefenseService,
    ) {
    }

    public showDeliveryDefenseSignUpDialog(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        let dialogType: 'complimentary' | 'regular' | 'form' | 'upgrade' | 'downgrade';
        switch (this.subTypeId) {
            case 1: // paid customer
                // [MV3-6586] Show `Need More Scores` dialog instead of the paid subscription dialog.
                dialogType = 'form';
                break;
            case 5: // trial customer
                dialogType = 'complimentary';
                break;
            case 2: // high volume customer program 1
            case 3: // high volume customer program 2
            case 4: // high volume customer program 3
                dialogType = 'form';
                break;
            default:
                throw new Error(`Unsupported SubTypeID of ${ this.subTypeId }`);
        }

        this.deliveryDefenseService.showOfferDialog(dialogType);
    }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShipComponent } from './ship.component';
import { LayoutComponent } from '../layout/layout.component';
import { AuthGuardService } from '../shared/services/auth-guard/auth-guard.service';
import { ConfirmedShipmentDetailsComponent } from './confirmed-shipment-details/confirmed-shipment-details.component';
import { ShipResolver } from './ship.resolver';
import { ReturnShipmentComponent } from './return-shipment/return-shipment.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'ups',
      },
      {
        path: 'details',
        component: ConfirmedShipmentDetailsComponent,
      },
      {
        path: ':carrierCode',
        component: ShipComponent,
        data: {
          title: 'Ship',
        },
        resolve: { prefetch: ShipResolver },
      },
      {
        path: ':carrierCode/return-shipment',
        component: ReturnShipmentComponent,
        data: {
          title: 'ReturnShipment',
        },
        resolve: { prefetch: ShipResolver },
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShipRoutingModule {
}

export const routedComponents: ModuleWithProviders<any> = RouterModule.forChild(routes);

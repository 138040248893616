<div class="pickup-location-title">
    <div class="business-name" [attr.data-location-id]="location?.locationId">{{ location?.businessName }}</div>
    @if (location?.isPreviouslyUsed) {
        <div class="previously-used">
            <span class="material-symbols-rounded">beenhere</span>
            <span class="description" [matTooltip]="location?.lastUsed">{{ 'pickupLocations.previouslyUsed' | translate }}</span>
        </div>
    }
</div>
<div class="address-info">
    <div class="address">
        <span [innerHTML]="[location?.address, location?.city, location?.state, location?.zip] | concatAddress"></span>
    </div>
    <div class="distance">
        <span class="distance-value">{{ location?.distance }}</span>
        <span class="distance-unit">{{ location?.distanceUnit | lowercase }}</span>
    </div>
</div>
<div class="actions">
    <button mat-stroked-button
            color="primary"
            class="hours-toggle"
            (click)="hours.hidden = !hours.hidden">
        <span class="material-symbols-rounded">schedule</span>
        <span>{{ 'pickupLocations.hoursOfOperation' | translate }}</span>
        <span class="material-symbols-rounded"
              [class.flip]="!hours.hidden">expand_more</span>
    </button>
    <a mat-stroked-button
       color="primary"
       class="map-link"
       [attr.href]="[
            origin,
            [location?.address, location?.city, location?.state, location?.zip] | concatAddress
            ] | googleMapsUrl"
       target="_blank">
        <span class="material-symbols-rounded">map</span>
        <span>{{ 'pickupLocations.map' | translate }}</span>
    </a>
</div>
<div class="hours-of-operation-container"
     hidden
     #hours>
    <table mat-table
           class="hours-of-operation"
           [dataSource]="location?.hoursOfOperations">
        <ng-container matColumnDef="day">
            <th mat-header-cell
                *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let element">{{ element.day | dayOfWeek:'ccc' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="hours">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'pickupLocations.hours.hoursOfOperation' | translate }}
            </th>
            <td mat-cell
                *matCellDef="let element">
                @if (element.isCloseAllDay || (!element.startTime && !element.endTime)) {
                    <span class="closed">Closed</span>
                } @else {
                    <span>
                        {{ element.startTime | formatTime:'h:mm aaa' }}
                        -{{ element.endTime  | formatTime:'h:mm aaa' }}
                    </span>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="dropOff">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'pickupLocations.hours.latestDropOffTime' | translate }}
            </th>
            <td mat-cell
                *matCellDef="let element">
                <span [class.not-available]="!element.latestDropOffTime">{{ (element.latestDropOffTime | formatTime:'h:mm aaa') || 'N/A' }}</span>
            </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayColumns"></tr>
    </table>
</div>

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    UserTermsConditionsComponent,
} from 'app/account/user-settings/user-terms-conditions/user-terms-conditions.component';
import { SharedService } from 'app/core/services/shared.service';
import { SpinnerService } from 'app/core/services/spinner/spinner.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { CarrierPipe } from 'app/history/pipes/carrier.pipe';
import { SavedQuotesService } from 'app/history/services/saved-quotes.service';
import { INotifyTemplate } from 'app/notify-templates/models/notify-template.interface';
import { NotifyTemplateService } from 'app/notify-templates/services/notify-template.service';
import { ICustomerImage } from 'app/shared/components/paperless-commercial-invoice/models/customer-image.interface';
import {
    PaperlessCommercialInvoiceService,
} from 'app/shared/components/paperless-commercial-invoice/services/paperless-commercial-invoice.service';
import { ShipDetailsDialogComponent } from 'app/shared/components/ship-details-dialog/ship-details-dialog.component';
import { Carriers } from 'app/shared/enum/general-enum';
import { ICarrierKey } from 'app/shared/models/carrier-key.interface';
import { ICountry } from 'app/shared/models/country.interface';
import { Customer } from 'app/shared/models/customer/customer.model';
import { Location } from 'app/shared/models/location/location.model';
import { NotificationType } from 'app/shared/models/notification-type';
import { CarrierService } from 'app/shared/services/carrier/carrier.service';
import { CountryService } from 'app/shared/services/country/country.service';
import { DateTimeService } from 'app/shared/services/date-time/date-time.service';
import { DialogService } from 'app/shared/services/dialog/dialog.service';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { FormService } from 'app/shared/services/form/form.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';

import { User } from 'app/shared/services/user/models/user.model';
import { UserService } from 'app/shared/services/user/user.service';
import { UtilityService } from 'app/shared/services/utility/utility.service';
import { ValidationService } from 'app/shared/services/validation/validation.service';
import * as _ from 'lodash';
import moment from 'moment';
import * as diff from 'recursive-diff';
import { forkJoin, of, shareReplay, Subscription } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppState } from '../../app.state';
import { ImportInformationComponent } from '../components/import-information/import-information.component';
import { ReturnOptionsComponent } from '../components/return-options/return-options.component';
import { IAddressValidation } from '../models/address-validation.interface';

import { ICarrierService } from '../models/carrier-service.interface';
import { Commodity } from '../models/commodity.model';
import { Package } from '../models/package.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipConfig } from '../models/ship-config.model';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipmentStateService } from '../services/shipment-state.service';
import { ShipmentService } from '../services/shipment.service';

import { ShipBillingDetailsComponent } from '../ship-billing-details/ship-billing-details.component';
import { CommodityShipInfoComponent } from '../ship-commodity/commodity-ship-info/commodity-ship-info.component';
import { ShipCommodityComponent } from '../ship-commodity/ship-commodity.component';
import { ShipCustomsDocsComponent } from '../ship-customs-docs/ship-customs-docs.component';
import { ShipElectronicExportComponent } from '../ship-electronic-export/ship-electronic-export.component';
import { ShipFromComponent } from '../ship-from/ship-from.component';
import { ShipInfoAddComponent } from '../ship-info-add/ship-info-add.component';
import { ShipInfoComponent } from '../ship-info/ship-info.component';
import { ShipOptionsComponent } from '../ship-options/ship-options.component';
import { ShipToComponent } from '../ship-to/ship-to.component';

@Component({
    selector: 'upsc-return-shipment',
    templateUrl: './return-shipment.component.html',
    styleUrls: ['../ship.component.scss'],
    providers: [
        ShipmentStateService,
    ],
})
export class ReturnShipmentComponent implements OnInit {

    @Input('parentForm') public parentForm: UntypedFormGroup;

    @ViewChild('shipTo', { static: true }) public shipTo: ShipToComponent;
    @ViewChild('shipFrom', { static: true }) public shipFrom: ShipFromComponent;
    @ViewChild('shipInfo', { static: true }) public shipInfo: ShipInfoComponent;
    @ViewChild('shipInfoAdd', { static: true }) public shipInfoAdd: ShipInfoAddComponent;
    @ViewChild('shipOptions', { static: true }) public shipOptions: ShipOptionsComponent;
    @ViewChild('shipBillingDetails', { static: true }) public shipBillingDetails: ShipBillingDetailsComponent;
    @ViewChild('commodityShipInfo') public commodityShipInfo: CommodityShipInfoComponent;
    @ViewChild('shipElectronicExport', { static: true }) public shipElectronicExport: ShipElectronicExportComponent;
    @ViewChild('shipCustomsDocs') public shipCustomsDocs: ShipCustomsDocsComponent;
    @ViewChild('shipCommodity', { static: true }) public shipCommodity: ShipCommodityComponent;
    @ViewChild('shipReturnOptions', { static: true }) public shipReturnOptions: ReturnOptionsComponent;
    @ViewChild('shipImportInformation', { static: true }) public shipImportInformation: ImportInformationComponent;

    public user: User;
    public customer: Customer;
    public carrier: Carriers;
    public carrierCode: string;
    public isQuoteReady = false;
    public estimatedCost: string;
    public surcharge: string;
    public totalCharges: string;
    public shipFromCountryCode = 'US';
    public shipToCountryCode: string;
    public shipFromZipCode: string;
    public shipToZipCode: string;
    public isDomestic = true;
    public serviceType: ICarrierService;
    public totalCustomsValue = 0;
    public totalWeight = 0;
    public shipFromAddress: Location;
    public shipToAddress: Location;
    public shipToCountry: ICountry;
    public shipFromCountry: ICountry;
    public commodities: Commodity[];
    public restrictedShipToCountryCodes: any;
    public config: ShipConfig;
    public insuredValueCurrencyCode = 'USD';
    public weightUOM = 'LBS';
    public dimensionUnit = 'inches';
    public shouldShowCostEstimate = false;
    public shipDate: Date;
    public shipDateString: string;
    public countryMaxCoverage: number;
    public returnShipment = true;
    public returnOption = '0';
    public customsValueCurrency = 'USD';

    public isFormValid = false;
    public isShipFromValid = true;
    public isShipToValid = true;
    public isShipInfoValid = false;
    public isShipInfoAddValid = false;
    public isShipOptionsValid = false;
    public isBillingDetailsValid = false;
    public isCommodityShipInfoValid = false;
    public isElectronicExportValid = false;
    public isShipCustomsDocsValid = false;
    public isShipCommodityValid = false;
    public isShipReturnOptionsValid = false;
    public isShipImportInformationValid = false;

    public isFormSubmitting = false;
    public isQuoteHasLabel = false;
    public isForbidden = false;
    public isExistingSchedulePickup = false;
    public shouldShowResidentialVerification = false;
    public shouldShowValuationDiscrepancy = false;
    public shouldShowShipToReroute = false;
    public shouldShowEEI = false;
    public shipFromCurrencyCode = 'USD';
    public maxCoverageCurrencyCode = 'USD';
    public formattedInsuredValue = '';

    public isHighValueShipment = false;
    public isAESShipment = false;
    public isEEIRequired = false;
    public isNAFTA = false;
    public shipFromCountries: ICountry[];
    public shipToCountries: ICountry[];
    public dateFormat = 'MM/DD/YYYY';
    public isRestoreShipment = false;
    public isReship = false;
    public cachedShipment: Package;
    public shouldForceClearAdultSignatureRequired = false;
    public shouldEnableSaveQuote = false;

    public carrierKey: ICarrierKey;
    public customerImages: ICustomerImage[];
    public notifyTemplates: INotifyTemplate[];
    public shipFromLocations: Location[];
    public shipToLocations: Location[];
    public validatePaperlessInvoiceCapabilityResult: any;
    public shouldDisplayShipFromEUFields = false;
    public shouldDisplayShipToEUFields = false;
    public showImportInformation = false;

    private shouldClearCachedAfterRestored = true;
    private lastGetQuotePayload: Package;
    private responseQuote: Package;
    private carrierCountries: ICountry[];

    private isResidentialCheck = false;
    private isAdultSignature = false;
    private isResidentialAddress = false;
    private isAdultSignatureRequested = false;
    private insuredValue: number;
    private pickupType: string;

    private formValidationErrors = [];
    private hasPendingConfigUpdate = false;
    private hasHighValueCommodity = false;
    private isUseAESDirect = false;
    private isMobileWebView = false;

    private isInsuredValueChanged = false;
    private isPickupTypeChanged = false;

    private getUserConfigSubscription: Subscription;
    private checkSchedulePickupSubscription: Subscription;
    private createHighValueShipmentSubscription: Subscription;
    private confirmShipmentSubscription: Subscription;
    private getShipToCountriesSubscription: Subscription;

    constructor(private formBuild: UntypedFormBuilder,
        private carrierPipe: CarrierPipe,
        private userService: UserService,
        private notificationService: NotificationService,
        private validationService: ValidationService,
        private formService: FormService,
        private errorHandlerService: ErrorHandlerService,
        private utilityService: UtilityService,
        private dialogService: DialogService,
        private dateTimeService: DateTimeService,
        private dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private countryService: CountryService,
        private spinnerService: SpinnerService,
        private storageService: StorageService,
        private sharedService: SharedService,
        private readonly appState: AppState,
        private paperlessCommercialInvoiceService: PaperlessCommercialInvoiceService,
        private notifyTemplateService: NotifyTemplateService,
        private carrierService: CarrierService,
        private savedQuoteService: SavedQuotesService,
        private shipConfigService: ShipConfigService,
        private translateService: TranslateService,
        private shipmentService: ShipmentService,
    ) {
        const prefetch = this.route.snapshot.data.prefetch;
        if (prefetch) {
            this.carrierKey = prefetch.carrierKey;
            this.customerImages = prefetch.customerImages;
            this.notifyTemplates = prefetch.notifyTemplates;
            this.config = prefetch.shipConfig;
            this.shipFromLocations = prefetch.shipFromLocations;
            this.shipToLocations = prefetch.shipToLocations;
            this.shipFromCountries = prefetch.shipFromCountries;
            this.shipToCountries = prefetch.shipToCountries;
        }

        this.user = this.appState.user$();
        this.customer = this.appState.customer$();

        this.dateFormat = this.isDEGBUser(this.user) ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        this.handleGetUserSuccess(this.user);
        this.handleGetCustomerSuccess(this.customer);

        this.readRouteParams();
    }

    ngOnInit(): void {
        this.parentForm = this.formBuild.group({
            isResidentialVerificationAgreed: [false],
            isValuationDiscrepancyAgreed: [false],
        });

        this.parentForm.valueChanges.subscribe(
            (value) => {
                this.onFormValueChanged(value);
            });

        this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;
                this.shouldShowEEI = config.showExportInformation && (config.showEEI || config.showMRN);
            },
        );

        this.shipmentService.saveReturnService();

        // TEMP: [FI-162]
        //  By default, cost estimation should be hidden and we show quote instead.
        //  The task for FI-162 is to show cost estimation for EU users.
        //  However, we are temporarily hiding it for staging and production environments for 5/30/2024 deployment.
        //Update by Tejas - Enabling Cost estimate for EU users for next deployment.
        this.shouldShowCostEstimate = ['DE', 'GB', 'FR', 'IT'].includes(this.user?.CountryCode);
    }

    public onFormSubmitted(event, form) {
        event.preventDefault();

        const isStatementsNotAgreed = this.notifyIfStatementsNotAgreed();
        if (isStatementsNotAgreed) {
            return;
        }

        this.validateAllForms();
        const isValidCommodity = this.isValidCommodity();
        this.isFormValid = this.isFormValid && isValidCommodity;

        if (!this.isFormValid) {
            // this.showFormValidity(null);
            this.showFormErrors(null);
            this.forceValidation();

            return;
        }

        this.isFormSubmitting = true;
        this.spinnerService.show();

        this.getQuote();

    }

    public onFormValueChanged(value: any) {
        // [MV3-1675] If a form value is changed, reset the quote ID and tell a user to get a new quote when he tries to get a label.
        const clonedQuote = _.cloneDeep(this.shipmentService.Quote);
        clonedQuote.QuoteId = 'NOID';
        const delta = diff.getDiff(this.lastGetQuotePayload, clonedQuote);

        // Ignore non-user-input value changes.
        delete delta['/IsSavedQuote'];
        delete delta['/QuoteId'];

        // console.log(delta);
        if (!this.lastGetQuotePayload || !Object.keys(delta).length) {
            return;
        }

        this.isQuoteReady = false;
        this.shipmentService.resetQuoteId();
    }


    //#region Load

    //#region Dialog
    public openTermsAndConditionsDialog(event) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '70%',
            data: {},
        };

        let dialogRef: MatDialogRef<UserTermsConditionsComponent>;
        dialogRef = this.dialog.open(UserTermsConditionsComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    public showShipmentDetailsDialog($event) {
        event.preventDefault();

        this.openShipmentDetailsDialog(this.shipmentService.Quote);
    }

    //#endregion


    //#region Handlers

    //#region GetUser handlers

    //#region Form validation
    public setFormValidity(formName: string, isValid: boolean) {
        switch (formName) {
            case 'shipTo':
                this.isShipToValid = isValid;
                break;
            case 'shipFrom':
                this.isShipFromValid = isValid;
                break;
            case 'shipInfo':
                this.isShipInfoValid = isValid;
                break;
            case 'shipInfoAdd':
                this.isShipInfoAddValid = isValid;
                break;
            case 'shipOptions':
                this.isShipOptionsValid = isValid;
                break;
            case 'billingDetails':
                this.isBillingDetailsValid = isValid;
                break;
            case 'commodityShipInfo':
                this.isCommodityShipInfoValid = isValid;
                break;
            case 'shipElectronicExport':
                this.isElectronicExportValid = isValid;
                break;
            case 'shipCustomsDocs':
                this.isShipCustomsDocsValid = isValid;
                break;
            case 'shipCommodity':
                this.isShipCommodityValid = isValid;
                break;
            case 'shipReturnOptions':
                this.isShipReturnOptionsValid = isValid;
            case 'importInformation':
                this.isShipImportInformationValid = isValid;
            default:
                break;
        }

        this.isFormValid = this.parentForm.valid &&
            this.isShipFromValid &&
            this.isShipToValid &&
            this.isShipInfoValid &&
            this.isShipInfoAddValid &&
            this.isShipOptionsValid &&
            this.isBillingDetailsValid;

        if (this.commodityShipInfo) {
            this.isFormValid = this.isFormValid && this.isCommodityShipInfoValid;
        }

        if (this.shipElectronicExport) {
            this.isFormValid = this.isFormValid && this.isElectronicExportValid;
        }

        if (this.shipCustomsDocs) {
            this.isFormValid = this.isFormValid && this.isShipCustomsDocsValid;
        }

        if (this.shipCommodity) {
            this.isFormValid = this.isFormValid && this.isShipCommodityValid;
        }

        if (this.isShipReturnOptionsValid) {
            this.isFormValid = this.isFormValid && this.isShipReturnOptionsValid;
        }

        if (this.isShipImportInformationValid) {
            this.isFormValid = this.isFormValid && this.isShipImportInformationValid;
        }
    }

    public showFormErrors(event) {
        if (event) {
            event.preventDefault();
        }

        this.formValidationErrors = [];

        const parentFormErrors = this.validationService.getFormControlValidationErrors(this.parentForm.controls, 'Ship');
        if (parentFormErrors) {
            this.formValidationErrors.push(...parentFormErrors);
        }

        const shipToErrors = this.shipTo.getFormErrors();
        if (shipToErrors) {
            this.formValidationErrors.push(...shipToErrors);
        }

        const shipFromErrors = this.shipFrom.getFormErrors();
        if (shipFromErrors) {
            this.formValidationErrors.push(...shipFromErrors);
        }

        const shipInfoErrors = this.shipInfo.getFormErrors();
        if (shipInfoErrors) {
            this.formValidationErrors.push(...shipInfoErrors);
        }

        const shipInfoAddErrors = this.shipInfoAdd.getFormErrors();
        if (shipInfoAddErrors) {
            this.formValidationErrors.push(...shipInfoErrors);
        }

        const shipOptionsErrors = this.shipOptions.getFormErrors();
        if (shipOptionsErrors) {
            this.formValidationErrors.push(...shipOptionsErrors);
        }

        const shipBillingDetailsErrors = this.shipBillingDetails.getFormErrors();
        if (shipBillingDetailsErrors) {
            this.formValidationErrors.push(...shipBillingDetailsErrors);
        }

        if (this.commodityShipInfo) {
            const commodityShipInfoErrors = this.commodityShipInfo.getFormErrors();
            if (commodityShipInfoErrors) {
                this.formValidationErrors.push(...commodityShipInfoErrors);
            }
        }

        if (this.shipElectronicExport) {
            const electronicExportErrors = this.shipElectronicExport.getFormErrors();
            if (electronicExportErrors) {
                this.formValidationErrors.push(...electronicExportErrors);
            }
        }

        if (this.shipCustomsDocs) {
            const shipCustomsDocsErrors = this.shipCustomsDocs.getFormErrors();
            if (shipCustomsDocsErrors) {
                this.formValidationErrors.push(...shipCustomsDocsErrors);
            }
        }

        if (this.shipCommodity) {
            const shipCommodityErrors = this.shipCommodity.getFormErrors();
            if (shipCommodityErrors) {
                this.formValidationErrors.push(...shipCommodityErrors);
            }
        }

        if (this.shipReturnOptions) {
            const shipReturnOptionsError = this.shipReturnOptions.getFormErrors();
            if (shipReturnOptionsError) {
                this.formValidationErrors.push(...shipReturnOptionsError);
            }
        }

        if (this.shipImportInformation) {
            const shipImportInformationError = this.shipImportInformation.getFormErrors();
            if (shipImportInformationError) {
                this.formValidationErrors.push(...shipImportInformationError);
            }
        }

        if (this.formValidationErrors.length) {
            console.error(this.formValidationErrors);
        }
    }

    public showFormValidity(event) {
        if (event) {
            event.preventDefault();
        }

        console.log('ship-to valid: ', this.shipTo.isFormValid());
        console.log('ship-from valid: ', this.shipFrom.isFormValid());
        console.log('ship-info valid: ', this.shipInfo.isFormValid());
        console.log('ship-info-add valid: ', this.shipInfoAdd.isFormValid());
        console.log('ship-options valid: ', this.shipOptions.isFormValid());
        console.log('ship-billing-details valid: ', this.shipBillingDetails.isFormValid());

        if (this.commodityShipInfo) {
            console.log('commodity-ship-info valid: ', this.commodityShipInfo.isFormValid());
        }

        if (this.shipElectronicExport) {
            console.log('electronic-export valid: ', this.shipElectronicExport.isFormValid());
        }

        if (this.shipCustomsDocs) {
            console.log('ship-customs-docs valid: ', this.shipCustomsDocs.isFormValid());
        }

        if (this.shipCommodity) {
            console.log('ship-commodity valid: ', this.shipCommodity.isFormValid());
        }
    }

    public validateAllForms(): boolean {
        const formValidity = {
            //MV3-3167 removed these lines
            shipTo: this.shipTo.isFormValid(),
            shipFrom: this.shipFrom.isFormValid(),
            shipInfo: this.shipInfo.isFormValid(),
            shipInfoAdd: this.shipInfoAdd.isFormValid(),
            shipOptions: this.shipOptions.isFormValid(),
            shipBillingDetails: this.shipBillingDetails.isFormValid(),
        };

        if (this.commodityShipInfo) {
            formValidity['commodityShipInfo'] = this.commodityShipInfo.isFormValid();
        }

        if (this.shipElectronicExport) {
            formValidity['shipElectronicExport'] = this.shipElectronicExport.isFormValid();
        }

        if (this.shipCustomsDocs) {
            formValidity['shipCustomsDocs'] = this.shipCustomsDocs.isFormValid();
        }

        if (this.shipCommodity) {
            formValidity['shipCommodity'] = this.shipCommodity.isFormValid();
        }

        if (this.shipReturnOptions) {
            formValidity['shipReturnOptions'] = this.shipReturnOptions.isFormValid();
        }

        if (this.shipImportInformation) {
            formValidity['shipImportInformation'] = this.shipImportInformation.isFormValid();
        }

        this.isFormValid = Object.keys(formValidity).every(key => !!formValidity[key]);

        if (!this.isFormValid) {
            Object.keys(formValidity).forEach(
                (key) => {
                    if (!!formValidity[key]) {
                        return true;
                    }

                    console.warn(`${_.lowerCase(key)} is invalid.`);

                    this.notificationService.notify(`${_.lowerCase(key)} is invalid. Please check ${_.lowerCase(key)} values.`, 'Validation Error',
                        NotificationType.ERROR);
                },
            );
        }

        return this.isFormValid;

    }

    //#endregion

    //#region GetContactAddress handlers

    public onShipFromAddressChanges(shipFromAddress: Location) {
        if (!shipFromAddress) {
            return;
        }
        this.shipFromAddress = this.shipmentService.Quote.ShipFrom;
    }

    public onShipFromAddressChanged(shipFromAddress: Location) {
        if (!shipFromAddress) {
            return;
        }

        this.shipFromAddress = _.cloneDeep(shipFromAddress);
        this.saveShipFromData();

        // this.shouldShowCostEstimate = ['US', 'CA'].includes(this.shipFromAddress.Country); - MV3-4791 hide quote

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
            this.shipFromAddress.UPSPickUpType,
        );

        this.shipConfigService.setUPSPickupType(this.carrier, this.shipFromAddress);
        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);

        if (!this.shipFromCountries) {
            this.shipmentService.getShipFromCountries(this.carrier)
                .subscribe(
                    (countries) => {
                        this.shipFromCountries = countries;
                        this.setShipFromCurrency(shipFromAddress.Country);
                    },
                );
        } else {
            this.setShipFromCurrency(shipFromAddress.Country);
        }

        this.checkSchedulePickupDate();
    }

    //#endregion

    public onShipToAddressChanged(shipToAddress: Location) {

        this.shipToAddress = shipToAddress;
        this.saveShipToData();

        //  this.shipFromAddress = shipToAddress;
        //  this.saveShipFromData();
        // ReturnShipment
        // this.shouldShowCostEstimate = ['US', 'CA'].includes(this.shipFromAddress.Country);

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
        );

        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
    }

    public onShipFromCountryChanged(country: ICountry) {
        this.shipFromCountry = country;
        this.setEUFieldsVisibility();
    }

    //#endregion

    //#region CheckSchedulePickup handlers

    public onShipFromCountryCodeChanged(countryCode: string) {
        this.resetCommodityRelatedComponents();

        this.shipFromCountryCode = countryCode;
        this.setEUFieldsVisibility();

        this.isNAFTA = this.shipConfigService.isNAFTA(this.shipFromCountryCode, this.shipToCountryCode);
        // this.weightUOM = ['US', 'CA'].includes(countryCode) ? 'LBS' : 'KGS';
        // this.dimensionUnit = ['US', 'CA'].includes(countryCode) ? 'inches' : 'cm';
        this.setShipFromCurrency(countryCode);

        this.restrictedShipToCountryCodes =
            this.shipmentService.getRestrictedShipToCountries(this.user, this.carrier, this.shipFromCountryCode);

        this.isDomestic = this.shipConfigService.isDomestic(this.shipFromCountryCode, this.shipToCountryCode);
        if (this.isDomestic) {
            this.shipmentService.resetElectronicExportInformation();
            this.customsValueCurrency = this.shipFromCurrencyCode;
        } else {
            if (this.userService.isEURUser(this.user.CountryCode))
                this.customsValueCurrency = 'EUR';
            else if (this.userService.isGBUser(this.user))
                this.customsValueCurrency = 'GBP';
            else
                this.customsValueCurrency = 'USD';
        }

        if (!this.shipFromAddress) {
            this.hasPendingConfigUpdate = true;
            return;
        }

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
            this.shipFromAddress.UPSPickUpType,
        );

        //this.monitorCountryChanges();

        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
        this.checkValuationDiscrepancy();

        // if (!this.shipConfigService.isUps(this.carrier)) {
        //   this.loadCarrierServices(this.carrier, this.shipFromCountryCode, this.shipToCountryCode);
        // } else {
        //   this.loadCarrierServicesByCountries();
        // }

        if (this.shipToCountryCode) {
            this.validatePaperlessInvoiceCapacity(this.shipFromCountryCode, this.shipToCountryCode);
        }
    }

    public onShipFromZipCodeChanged(zipCode: string) {
        this.shipFromZipCode = zipCode;
    }


    //#endregion

    public onShipToCountryChanged(country: ICountry) {
        this.shipToCountry = country;
        this.setEUFieldsVisibility();
    }

    public onShipToCountryCodeChanged(countryCode: string) {
        this.resetCommodityRelatedComponents();

        this.shipToCountryCode = countryCode;
        this.setEUFieldsVisibility();

        this.isDomestic = this.shipConfigService.isDomestic(this.shipFromCountryCode, this.shipToCountryCode);
        this.isNAFTA = this.shipConfigService.isNAFTA(this.shipFromCountryCode, this.shipToCountryCode);

        this.weightUOM = ['US', 'CA'].includes(countryCode) ? 'LBS' : 'KGS';
        this.dimensionUnit = ['US', 'CA'].includes(countryCode) ? 'inches' : 'cm';

        if (this.isDomestic) {
            this.shipmentService.resetElectronicExportInformation();
            this.customsValueCurrency = this.shipFromCurrencyCode;
        } else {
            if (this.userService.isEURUser(this.user.CountryCode))
                this.customsValueCurrency = 'EUR';
            else if (this.userService.isGBUser(this.user))
                this.customsValueCurrency = 'GBP';
            else
                this.customsValueCurrency = 'USD';
        }

        if (!this.shipFromAddress) {
            this.hasPendingConfigUpdate = true;
            return;
        }

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
            this.shipFromAddress.UPSPickUpType,
        );

        // this.monitorCountryChanges();

        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
        this.checkValuationDiscrepancy();
        this.setCountryMaxCoverage(countryCode);

        // if (!this.shipConfigService.isUps(this.carrier)) {
        //   this.loadCarrierServices(this.carrier, this.shipFromCountryCode, this.shipToCountryCode);
        // } else {
        //   this.loadCarrierServicesByCountries();
        // }

        if (this.shipFromCountryCode) {
            this.validatePaperlessInvoiceCapacity(this.shipFromCountryCode, this.shipToCountryCode);
        }
    }

    private validatePaperlessInvoiceCapacity(shipFromCountryCode: string, shipToCountryCode: string): void {
        if (!this.shipConfigService.isUps(this.carrier) || !(this.carrierKey !== undefined && this.carrierKey.IsDocUpload)) {
            return;
        }

        this.paperlessCommercialInvoiceService.validatePaperlessInvoiceCapability(shipFromCountryCode, shipToCountryCode)
            .subscribe(
                (result) => {
                    this.validatePaperlessInvoiceCapabilityResult = result;
                },
            );
    }

    //#endregion

    //#region Save Quote handlers

    public onShipToZipCodeChanged(zipCode: string) {
        this.shipToZipCode = zipCode;
    }

    public onServiceTypeChanged(serviceType: ICarrierService) {
        this.serviceType = serviceType;
        this.checkSchedulePickupDate();
    }

    //#endregion

    //#region Create high value shipment handlers

    public onTotalCustomsValueChanged(customsValue: number) {
        this.totalCustomsValue = customsValue;

        this.setAESStatus();

        if (this.shipFromAddress) {
            this.shipConfigService.setConfig(
                this.shipFromCountryCode,
                this.shipToCountryCode,
                this.carrier,
                this.user,
                this.customer,
                this.insuredValue,
                this.totalCustomsValue,
                this.shipDateString,
                this.shipFromAddress.UPSPickUpType,
            );

            this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
            this.checkValuationDiscrepancy();
        }
    }

    public onTotalWeightChanged(weight: number) {
        this.totalWeight = weight;
    }

    //#endregion

    //#region Submit to ACE handlers

    public onShipDateChanged(dateString: string): void {
        this.shipDate = moment(dateString).toDate();
        this.shipDateString = dateString;

        if (!this.shipFromAddress) {
            this.hasPendingConfigUpdate = true;
            return;
        }

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
            this.shipFromAddress.UPSPickUpType,
        );

        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
    }

    public onSchedulePickupDateChanged(pickupDate: Date) {
        this.shipmentService.Quote.PickUpDate = this.dateTimeService.toServerDateTimeString(pickupDate);
        this.checkSchedulePickupDate();
    }

    //#endregion

    //#endregion


    //#region populate

    public onAddressValidationChanged(addressValidation: IAddressValidation) {
        const isResidentialAddress = addressValidation.AddressType === 1;
        this.shouldShowResidentialVerification = isResidentialAddress && this.isResidentialCheck && !this.isAdultSignature;

        if (!isResidentialAddress) {
            this.parentForm.patchValue({
                isResidentialVerificationAgreed: false,
            });
        }

        this.validationService.clearFormControlValidators([
            this.parentForm.controls.isResidentialVerificationAgreed,
        ]);

        if (this.shouldShowResidentialVerification) {
            this.validationService.setFormControlValidators(
                this.parentForm.controls.isResidentialVerificationAgreed,
                Validators.compose([Validators.requiredTrue]),
            );
        }
    }

    public onEeiFilingOptionChanged(eeiOption: string) {
        this.isUseAESDirect = eeiOption === '1';

        this.setAESStatus();
    }

    public onForceClearAdultSignatureRequiredChanged(isForce: boolean) {
        this.shouldForceClearAdultSignatureRequired = isForce;
    }

    //#endregion

    public onAdultSignatureRequested(isRequested: boolean) {
        this.isAdultSignatureRequested = isRequested;

        if (!isRequested) {
            return;
        }

        this.shipInfoAdd.enableAdultSignature();
    }

    public onIsResidentialAddressChanged(isRequired: boolean) {
        this.isResidentialAddress = isRequired;
        this.showResidentialVerification();
    }

    public onResidentialCheckChanged(isChecked: boolean) {
        this.isResidentialCheck = isChecked;
        this.showResidentialVerification();
    }

    //#endregion


    //#region  Cache

    public onAdultSignatureChanged(isChecked: boolean) {
        this.isAdultSignature = isChecked;
        this.showResidentialVerification();
    }

    public onReturnOptionChanged(option: string) {
        this.returnOption = option;
    }


    //#endregion

    public onInsuredValueChanged(insuredValue: number) {
        this.insuredValue = insuredValue;
        this.isInsuredValueChanged = true;
        this.showInsuredValueAlert();
        this.formattedInsuredValue = this.utilityService.formatMoney(this.insuredValue, this.insuredValueCurrencyCode);

        if (this.shipFromAddress) {
            this.shipConfigService.setConfig(
                this.shipFromCountryCode,
                this.shipToCountryCode,
                this.carrier,
                this.user,
                this.customer,
                this.insuredValue,
                this.totalCustomsValue,
                this.shipDateString,
                this.shipFromAddress.UPSPickUpType,
            );

            this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
            this.checkValuationDiscrepancy();
        }
    }

    public showFormValue(event) {
        event.preventDefault();

        console.log(this.shipmentService.Quote);
    }

    public onPickupTypeChanged(pickupType: string) {
        this.pickupType = pickupType;
        this.isPickupTypeChanged = true;
        this.showInsuredValueAlert();
    }

    public openAddressValidationInformationDialog(event) {
        event.preventDefault();

        const title = 'Address Validation Information';
        const message = '<p>' +
            'Our website provides an address validation tool for the address of the recipient you identify. ' +
            'Subject to the accuracy of the United State Postal Service\'s database, the validation tool is designed to ' +
            'distinguish criteria such as whether the address provided is a known U.S. address, ' +
            'whether the address is a business or a commercial address and whether the city, state and zip code are a match. ' +
            'This address validation is for reference only and is not intended to replace your responsibility ' +
            'for determining the accuracy and validity of the recipient\'s address. ' +
            'When the address verification tool identifies an address as residential, ' +
            'a message will pop up giving you the option of selecting an Adult Signature Required (ASR) delivery. ' +
            'If you elect not to select the (ASR) delivery, if the package is delivered to a residential address ' +
            'and is left unattended and a signature is not obtained, you hereby agree that the delivery is a valid delivery. ' +
            'Please see Parcel Pro\'s Terms and Conditions for more details and coverage requirements for residential deliveries.' +
            '</p>';

        this.dialogService.alert(title, message);
    }

    //#endregion


    //#region Read Route Params

    public onCommoditiesChanged(commodities: Commodity[]) {
        this.onFormValueChanged(this.commodities);
        this.commodities = commodities;
        this.shipmentService.setCommodities(this.commodities);
        this.hasHighValueCommodity = this.shipmentService.hasHighValueCommodity();

        if (this.shipFromAddress) {
            this.shipConfigService.setConfig(
                this.shipFromCountryCode,
                this.shipToCountryCode,
                this.carrier,
                this.user,
                this.customer,
                this.insuredValue,
                this.totalCustomsValue,
                this.shipDateString,
                this.shipFromAddress.UPSPickUpType,
            );

            this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
            this.checkValuationDiscrepancy();
        }
    }

    //#endregion

    public getQuote(shouldConfirmShipment: boolean = false) {
        this.setReturnShipmentSpecificValues();
        this.lastGetQuotePayload = _.cloneDeep(this.shipmentService.Quote);
        const data: Package = _.cloneDeep(this.shipmentService.Quote);

        if (data.Commodities) {
            data.Commodities = data.Commodities.filter(
                (commodity) => {
                    return !!commodity.HarmonizedCode;
                },
            );
        }

        // Remove extra fields from shipping addresses. They cause validation errors on API.
        delete data.ShipFrom?.Vat;
        delete data.ShipFrom?.TaxID;
        delete data.ShipFrom?.EORI;
        delete data.ShipTo?.Vat;
        delete data.ShipTo?.TaxID;
        delete data.ShipTo?.EORI;
        delete data.ShipFromRerouting?.Vat;
        delete data.ShipFromRerouting?.TaxID;
        delete data.ShipFromRerouting?.EORI;
        delete data.ShipToRerouting?.Vat;
        delete data.ShipToRerouting?.TaxID;
        delete data.ShipToRerouting?.EORI;

        if (this.user.CountryCode !== 'US') {
            delete data.DDSearch;
            delete data.DDScore;
            delete data.DDSearchStatus;
            delete data.UseDeliveryDefense;
            delete data.SkipDeliveryDefense;
        }

        this.shipmentService.add(data)
            .subscribe(
                quote => this.handleSuccessfulQuoteAdd(quote, shouldConfirmShipment),
                err => this.handleFailedQuoteAdd(err),
            );
    }

    public saveQuote(event) {
        event.preventDefault();

        if (!this.responseQuote) {
            this.notificationService.notify(
                'No quote exists. Please get a quote first.',
                'No quote to save',
                NotificationType.WARNING);

            return;
        }

        this.shipmentService.Quote.IsSavedQuote = true;
        this.shipmentService.saveQuote(this.responseQuote.QuoteId)
            .subscribe(
                res => this.handleSaveQuoteSuccess(res),
                err => this.handleSaveQuoteFailure(err),
            );
    }

    public async submitToACE(event, form) {
        event.preventDefault();
        this.setReturnShipmentSpecificValues();
        const data: Package = _.cloneDeep(this.shipmentService.Quote);

        if (data.Commodities) {
            data.Commodities = data.Commodities.filter(
                (commodity) => {
                    return !!commodity.HarmonizedCode;
                },
            );
        }

        const isStatementsNotAgreed = this.notifyIfStatementsNotAgreed();
        if (isStatementsNotAgreed) {
            return;
        }

        this.validateAllForms();
        const isValidCommodity = this.isValidCommodity();
        this.isFormValid = this.isFormValid && isValidCommodity;

        if (!this.isFormValid) {
            this.showFormErrors(null);
            this.forceValidation();
            return;
        }

        this.spinnerService.show();

        if (!this.responseQuote) {
            this.shipmentService.add(data)
                .pipe(flatMap(quote => this.shipmentService.submitToACE(quote?.QuoteId, 12)))
                .subscribe(
                    res => this.handleSubmitToACESuccess(res),
                    err => this.handleSubmitToACEFailure(err),
                );

            return;
        }

        this.shipmentService.submitToACE(this.responseQuote?.QuoteId, 12)
            .subscribe(
                res => this.handleSubmitToACESuccess(res),
                err => this.handleSubmitToACEFailure(err),
            );
    }

    public getQuoteAndShip(event, form) {
        if (event) {
            event.preventDefault();
        }

        const isStatementsNotAgreed = this.notifyIfStatementsNotAgreed();
        if (isStatementsNotAgreed) {
            return;
        }

        this.validateAllForms();
        const isValidCommodity = this.isValidCommodity();
        this.isFormValid = this.isFormValid && isValidCommodity;

        if (!this.isFormValid) {
            this.showFormErrors(null);
            this.forceValidation();

            return;
        }
        //this.saveShipData();

        this.isFormSubmitting = true;
        this.spinnerService.show();

        if (this.isQuoteReady) {
            this.confirmShipment(null);
            return;
        }

        this.getQuote(true);
    }

    public confirmShipment(event) {
        if (event) {
            event.preventDefault();
        }

        if (this.isAESShipment) {
            this.goToShipmentDetails();
            return;
        }

        if (!this.responseQuote || !this.shipmentService.Quote.QuoteId || this.shipmentService.Quote.QuoteId === 'NOID') {
            // Instead of having a user manually clicking GET QUOTE, get a quote for him and proceed forwards.
            this.getQuoteAndShip(null, null);
            // this.notificationService.notify('Data may have been changed. Please get a new quote.', 'No Quote', NotificationType.WARNING);
            // this.spinnerService.hide();
            return;
        }

        if (this.isHighValueShipment) {
            this.storageService.set('shipment-details', this.responseQuote);
            this.spinnerService.show();
            this.utilityService.clearSubscriptions([this.createHighValueShipmentSubscription]);
            this.createHighValueShipmentSubscription = this.shipmentService.createHighValueShipment(this.responseQuote.QuoteId)
                .subscribe(
                    res => this.handleCreateHighValueShipmentSuccess(res),
                    err => this.handleCreateHighValueShipmentFailure(err),
                );

            return;
        }

        this.isFormSubmitting = true;
        this.spinnerService.show();
        this.utilityService.clearSubscriptions([this.confirmShipmentSubscription]);
        this.confirmShipmentSubscription = this.shipmentService.confirmShipment(this.responseQuote.QuoteId)
            .subscribe(
                quote => this.handleConfirmShipmentSuccess(quote),
                err => this.handleConfirmShipmentFailure(err),
            );
    }

    public isDEGBUser(user: User) {
        // return user.UserName.startsWith('DE') || user.UserName.startsWith('GB');
        return this.shipConfigService.isDEGBUser(user);
    }

    public isDEUser(user: User) {
        // return user.UserName.startsWith('DE');
        return this.shipConfigService.isDEUser(user);
    }

    public isGBUser(user: User) {
        // return user.UserName.startsWith('GB');
        return this.shipConfigService.isGBUser(user);
    }

    public isEUUser(user: User) {
        return this.shipConfigService.isEUUser(user);
    }

    public ngOnDestroy() {
        this.shipmentService.clearCache();
        this.shipmentService.clearQuote();
        this.shipConfigService.clearConfig();
        this.notifyTemplateService.clearCache();
        this.carrierService.clearCache();
        this.config = null;
        this.responseQuote = null;
        this.lastGetQuotePayload = null;

        this.utilityService.clearSubscriptions([
            this.getUserConfigSubscription,
            this.checkSchedulePickupSubscription,
            this.createHighValueShipmentSubscription,
            this.confirmShipmentSubscription,
            this.getShipToCountriesSubscription,
        ]);

        this.storageService.remove('shipment-edit');
        this.storageService.remove('reship');
    }

    public ngDoCheck() {
        this.cdRef.detectChanges();
    }

    public ngAfterViewInit() {
        // this.sharedService.userSubject.subscribe(
        //     (user) => {
        //         if (!user) {
        //             return;
        //         }
        //
        //         const delta = diff.getDiff(this.user, user);
        //         if (!Object.keys(delta).length) {
        //             return;
        //         }
        //
        //         this.user = user;
        //         this.dateFormat = this.isDEGBUser(user) ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        //         this.handleGetUserSuccess(user);
        //     },
        // );

        // This is required for mobile ship page as the user object is not requested anywhere else in that mode.
        // if (!this.user) {
        //     this.userService.getUser().subscribe(
        //         (user) => {
        //             this.sharedService.userSubject.next(user);
        //         },
        //     );
        // }
    }

    public resetForms() {

        this.serviceType = undefined;

        this.resetComponents([
            this.shipTo,
            this.shipFrom,
            this.shipInfo,
            this.commodityShipInfo,
            this.shipInfoAdd,
            this.shipCommodity,
            this.shipOptions,
            this.shipBillingDetails,
            this.shipElectronicExport,
            this.shipCustomsDocs,
        ]);

        if (this.parentForm) {
            this.parentForm.reset({
                isResidentialVerificationAgreed: false,
            });

            this.shouldShowShipToReroute = false;
            this.shouldShowResidentialVerification = false;
            this.isHighValueShipment = false;
            this.shipmentService.resetElectronicExportInformation();
            this.isAESShipment = false;

            if (this.shipFromAddress) {
                // this.shouldShowCostEstimate = ['US', 'CA'].includes(this.shipFromAddress.Country); - MV3-4791 hide quote
            }

            this.estimatedCost = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
            this.surcharge = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
            this.totalCharges = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
            this.formattedInsuredValue = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
        }
    }

    private loadInitialValues() {
        this.estimatedCost = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
        this.surcharge = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
        this.totalCharges = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
        this.formattedInsuredValue = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
    }

    private loadCostEstimateValues() {
        const cachedCostEstimate = this.storageService.get('cost-estimate');
        if (!cachedCostEstimate || !Object.keys(cachedCostEstimate).length) {
            return;
        }

        this.shipFrom.fillCostEstimateValues(cachedCostEstimate);
        this.shipTo.fillCostEstimateValues(cachedCostEstimate);
        this.shipInfo.fillCostEstimateValues(cachedCostEstimate);
        this.shipInfoAdd.fillCostEstimateValues(cachedCostEstimate);
        this.shipOptions.fillCostEstimateValues(cachedCostEstimate);

        this.storageService.remove('cost-estimate');
    }

    private handleGetUserSuccess(user: User) {
        // this.user = user;
        this.dateFormat = this.isDEGBUser(this.user) ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        this.shipToCountryCode = user.DefaultShipToCountry ? user.DefaultShipToCountry.toUpperCase() : null;
        this.shipmentService.Quote.ShipTo.Country = this.shipToCountryCode;
        this.shipmentService.Quote.CustomerId = user.CustomerId;

        this.checkCarrierForbidden();
        if (this.isForbidden) {
            return;
        }

        const isDEGBUser = this.isDEGBUser(user);
        this.insuredValueCurrencyCode = this.isGBUser(user) ? 'GBP' : (this.isEUUser(user) ? 'EUR' : 'USD');
        this.maxCoverageCurrencyCode = this.insuredValueCurrencyCode;

        forkJoin([
            this.userService.getCustomer(),
            this.shipmentService.getContactAddress(user.DefaultLocation),
        ]).subscribe({
            next: (data) => {
                const customer = data[0];
                this.handleGetCustomerSuccess(customer);

                const shipFromAddress = data[1];
                // this.handleGetContactAddressSuccess(shipFromAddress); No need to fill ship from address as this is return shipment

                this.shipConfigService.restoreConfig(this.carrier, user, customer, shipFromAddress);

                if (this.hasPendingConfigUpdate) {
                    this.shipConfigService.setConfig(
                        this.shipFromCountryCode,
                        this.shipToCountryCode,
                        this.carrier,
                        this.user,
                        this.customer,
                        this.insuredValue,
                        this.totalCustomsValue,
                        this.shipDateString,
                        this.shipFromAddress.UPSPickUpType,
                    );

                    this.hasPendingConfigUpdate = false;

                    this.shipConfigService.loadUserConfig(this.carrierCode, user);
                }

                this.loadCostEstimateValues();

                setTimeout(
                    () => {
                        this.loadCachedShipment();
                    },
                    1000);
            },
            error: (err) => {
                this.notificationService.notify(
                    this.errorHandlerService.getHttpErrorMessage(err),
                    'Failed getting customer or address',
                    NotificationType.ERROR);
            },
        });
    }

    private handleGetUserFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting current user',
            NotificationType.ERROR);
    }

    private handleGetCustomerSuccess(customer: Customer) {
        this.customer = customer;
        this.shipmentService.setCustomerId(customer.CustomerId);
    }

    private handleGetCustomerFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting customer',
            NotificationType.ERROR);
    }

    private handleGetContactAddressSuccess(location: Location) {
        this.shipFromAddress = _.cloneDeep(location);
        this.saveShipFromData();
    }

    private handleGetContactAddressFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting contact address',
            NotificationType.ERROR);
    }

    //#region Get ShipTo Countries handlers
    private handleGetShipToCountriesSuccess(countries: ICountry[], shipToCountryCode: string) {
        this.carrierCountries = countries;
        // this.storageService.set('carrier-countries', countries);

        this.shipToCountry = countries.find(item => item.CountryCode === shipToCountryCode);
        if (this.shipToCountry) {
            this.countryMaxCoverage = this.shipToCountry.MaxCoverage;
        }
    }

    private handleGetShipToCountriesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Getting Carrier Countries',
            NotificationType.ERROR);
    }

    private handleCheckSchedulePickupSuccess(result) {
        this.isExistingSchedulePickup = !!result;

        if (this.isExistingSchedulePickup) {
            this.notificationService.notify(
                'You already have a pickup scheduled for this location. Please select a different pickup option.',
                'Pickup Already Scheduled',
                NotificationType.WARNING);
        }
    }

    private handleCheckSchedulePickupFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed checking schedule pickup.',
            NotificationType.ERROR);
    }

    //#region ConfirmShipment handlers
    private handleConfirmShipmentSuccess(quote: Package) {
        quote.ShipmentReason = this.shipmentService.Quote.ShipmentReason;
        this.storageService.set('shipment-details', quote);
        this.notificationService.notify('Shipment has been confirmed', 'Success', NotificationType.SUCCESS);

        // // Extract required properties from the shipment package for use with the shipment details.
        // const requiredProperties = [
        //   // 'BillToAccount', // Use the one from the form instead of from the returned shipment.
        //   'ConfirmationNumber',
        //   'LabelImage',
        //   'LabelImageFull',
        //   'InternalTrackingNumber',
        //   'ShipmentId',
        //   'ShipFromRerouting',
        //   'ShipToRerouting',
        //   'TrackingNumber'];
        // requiredProperties.forEach(
        //   (property) => {
        //     this.responseQuote[property] = quote[property];
        //   },
        // );

        this.responseQuote = quote;

        // [MV3-2221] Delete the saved quote after it has been confirmed.
        // [MV3-2229] Remove the call to delete the saved quote after confirming a shipment.
        // this.savedQuoteService.deleteQuote(quote.QuoteId).subscribe();

        this.goToShipmentDetails();
    }

    private handleConfirmShipmentFailure(err) {
        this.spinnerService.hide();
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed confirming shipment',
            NotificationType.ERROR);
        this.isFormSubmitting = false;
        this.isQuoteReady = false;
        this.isQuoteHasLabel = true;
    }

    private handleSaveQuoteSuccess(res) {
        this.notificationService.notify('The quote has been successfully saved.', 'Quote Saved', NotificationType.SUCCESS);
        this.isQuoteReady = true;
        this.shouldEnableSaveQuote = false;
    }

    private handleSaveQuoteFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed saving quote',
            NotificationType.ERROR);
    }

    private handleCreateHighValueShipmentSuccess(res) {
        this.goToShipmentDetails();
        this.spinnerService.hide();
    }

    private handleCreateHighValueShipmentFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Creating High-Value Shipment',
            NotificationType.ERROR);
        this.spinnerService.hide();
    }

    private handleSubmitToACESuccess(res) {
        this.notificationService.notify('The quote has been submitted to AES.', 'SUCCESS!', NotificationType.SUCCESS);
        this.spinnerService.hide();

        if (this.isMobileWebView) {
            window.location.href = `/pending-shipments`;
            return;
        }

        this.router.navigate(['/pending-shipments']);
    }

    private handleSubmitToACEFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed submitting a quote to AES.',
            NotificationType.ERROR);
        this.spinnerService.hide();
    }

    private saveShipFromData() {
        if (!this.shipFromAddress) {
            return;
        }

        const data = {
            contactId: this.shipFromAddress.ContactId,
            firstName: this.shipFromAddress.FirstName,
            lastName: this.shipFromAddress.LastName,
            company: this.shipFromAddress.CompanyName,
            zipCode: this.shipFromAddress.Zip,
            city: this.shipFromAddress.City,
            state: this.shipFromAddress.State,
            country: this.shipFromAddress.Country,
            address1: this.shipFromAddress.StreetAddress,
            address2: this.shipFromAddress.ApartmentSuite,
            address3: this.shipFromAddress.AdditionalAddressInformation,
            email: this.shipFromAddress.Email,
            phone: this.shipFromAddress.TelephoneNo,
            pickupType: this.shipFromAddress.UPSPickUpType,
        };
        this.shipmentService.saveShipFrom(data);
    }

    private saveShipToData() {
        const data = {
            contactId: this.shipToAddress.ContactId,
            firstName: this.shipToAddress.FirstName,
            lastName: this.shipToAddress.LastName,
            company: this.shipToAddress.CompanyName,
            zipCode: this.shipToAddress.Zip,
            city: this.shipToAddress.City,
            state: this.shipToAddress.State,
            country: this.shipToAddress.Country,
            address1: this.shipToAddress.StreetAddress,
            address2: this.shipToAddress.ApartmentSuite,
            address3: this.shipToAddress.AdditionalAddressInformation,
            email: this.shipToAddress.Email,
            phone: this.shipToAddress.TelephoneNo,
            pickupType: this.shipToAddress.UPSPickUpType,
        };

        this.shipmentService.saveShipTo(data);
    }

    private saveShipData() {
        this.saveShipFromData();
        this.saveShipToData();
    }

    private openShipmentDetailsDialog(quote: Package) {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            // width: '80%',
            data: {
                quote,
            },
        };

        const dialogRef: MatDialogRef<ShipDetailsDialogComponent> = this.dialog.open(ShipDetailsDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (result) => {
                if (!result) {
                    return;
                }
            },
        );
    }

    private loadCachedShipment() {
        const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
        if (!cachedShipment || !Object.keys(cachedShipment).length) {
            return;
        }

        this.cleanUpCachedShipment(cachedShipment);
        this.cachedShipment = cachedShipment;

        this.shipmentService.Quote = _.cloneDeep(Object.assign(new Package(), cachedShipment));
        this.shipFrom.restoreShipment(cachedShipment);
        this.shipTo.restoreShipment(cachedShipment);

        setTimeout(
            () => {
                this.shipInfo.restoreShipment(cachedShipment);
            },
            2000);

        this.shipInfoAdd.restoreShipment(cachedShipment);
        this.shipOptions.restoreShipment(cachedShipment);
        this.shipBillingDetails.restoreShipment(cachedShipment);

        if (this.commodityShipInfo) {
            this.commodityShipInfo.restoreShipment(cachedShipment);
        }

        if (this.shipCommodity) {
            this.shipCommodity.restoreShipment(cachedShipment);
        }

        if (this.shipElectronicExport) {
            this.shipElectronicExport.restoreShipment(cachedShipment);
        }

        if (this.shipCustomsDocs) {
            this.shipCustomsDocs.restoreShipment(cachedShipment);
        }

        if (this.shouldClearCachedAfterRestored) {
            setTimeout(
                () => {
                    this.storageService.remove('shipment-edit');
                    this.storageService.remove('reship');
                },
                10000);
        }

        // this.isRestoreShipment = false;
        // this.showFormErrors(null);
    }

    private cleanUpCachedShipment(cachedShipment: Package) {
        delete cachedShipment['InputType'];
        delete cachedShipment['IsMaxCoverageExceeded'];
        delete cachedShipment['SaveQuote'];
        delete cachedShipment['TotalShipments'];
        delete cachedShipment['BillToCustomerID'];
        delete cachedShipment.Estimator;
        cachedShipment.EstimatedCost = null;
        cachedShipment.LabelImage = null;
        cachedShipment.LabelImageFull = null;
        cachedShipment.ConfirmationNumber = '';
        cachedShipment.InternalTrackingNumber = '';
        cachedShipment.TrackingNumber = '';
        cachedShipment.Status = 0;
        cachedShipment.TotalCharges = null;
        cachedShipment.PhysicalStatus = 0;
        cachedShipment.QuoteId = 'NOID';
        cachedShipment.ShipmentId = 'NOID';
        cachedShipment.ShipTo.UserId = this.user.UserId;
        cachedShipment.WeightUOM = ['US', 'CA'].includes(cachedShipment.ShipTo.Country) ? 'LBS' : 'KGS';

        if (cachedShipment.CustomerReferenceNumber) {
            cachedShipment.CustomerReferenceNumber = cachedShipment.CustomerReferenceNumber.replace(/\*Return Label\*/ig, '')
                .trim();
        }
    }

    private readRouteParams() {
        this.route.params.subscribe(
            (params) => {

                const carrierCode = params['carrierCode'];
                if (!carrierCode) {
                    return;
                }

                const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
                if (cachedShipment) {
                    const cachedCarrier = this.carrierPipe.transform(cachedShipment.CarrierCode);
                    this.isRestoreShipment = !!cachedShipment && cachedCarrier.toUpperCase() === carrierCode.toUpperCase();
                } else {
                    this.isRestoreShipment = false;
                }

                const reship = this.storageService.get<any>('reship');
                if (reship) {
                    this.isReship = !!reship.isReship;
                }

                this.handleGetUserSuccess(this.user);
                this.shipmentService.Quote = new Package();
                this.isMobileWebView = !!sessionStorage.getItem('is-mobile-web-view') || false;
                this.loadInitialValues();

                const carrierEnumValue = Carriers[_.capitalize(carrierCode)];
                const carrierEnum = Carriers[carrierEnumValue];
                this.shipmentService.Quote.CarrierCode = +carrierEnumValue;
                this.carrierCode = carrierCode.toUpperCase();
                this.carrier = <any>carrierEnum;

                this.resetForms();
                this.onCarrierChanged();
            });
    }

    private setShipFromCurrency(countryCode: string) {
        if (!this.shipFromCountries) {
            return;
        }

        const targetCountry = this.shipFromCountries.find(country => country.CountryCode === countryCode);

        if (targetCountry && targetCountry.CurrencyCode) {
            this.shipFromCurrencyCode = targetCountry.CurrencyCode;
            return;
        }

        this.shipFromCurrencyCode = this.countryService.getCurrency(this.shipFromAddress.Country);
    }

    private isValidCommodity(): boolean {
        const isInternationalShipping = this.shipFromCountryCode !== this.shipToCountryCode;
        if (!isInternationalShipping) {
            return true;
        }

        const hasCommodity = !!this.commodities && this.commodities.length > 0;
        if (hasCommodity) {
            return true;
        }

        this.notificationService.notify(
            'At least one commodity is required for an international shipping.',
            'Commodity Required',
            NotificationType.ERROR);

        return false;
    }

    private setCountryMaxCoverage(countryCode: string) {
        // this.carrierCountries = this.storageService.get('carrier-countries');
        // if (this.carrierCountries) {
        //   this.shipToCountry = this.carrierCountries.find(item => item.CountryCode === countryCode);
        //   if (this.shipToCountry) {
        //     this.countryMaxCoverage = this.shipToCountry.MaxCoverage;
        //   }
        //
        //   return;
        // }

        this.utilityService.clearSubscriptions([this.getShipToCountriesSubscription]);
        this.getShipToCountriesSubscription
            = this.shipmentService.getShipToCountries(this.carrier)
                .pipe(
                    catchError((err) => {
                        this.handleGetShipToCountriesFailure(err);
                        return of(null);
                    }),
                )
                .subscribe(
                    countries => this.handleGetShipToCountriesSuccess(countries, countryCode),
                );
    }

    private checkValuationDiscrepancy() {
        this.validationService.clearFormControlValidators([
            this.parentForm.controls.isValuationDiscrepancyAgreed,
        ]);

        const isShipFromUSToInternational = this.shipFromCountryCode === 'US' && this.shipToCountryCode !== 'US';
        if (!isShipFromUSToInternational) {
            this.shouldShowValuationDiscrepancy = false;
            return;
        }

        // [MV3-1334] Show the discrepancy message only if it's shipped from US
        // and there is 100+ USD difference between the insured value and the customs value.
        // [MV3-1548] added condition: AND only if there is at least one commodity.
        this.shouldShowValuationDiscrepancy = this.shipFromCountryCode === 'US'
            && (Math.abs(this.insuredValue - this.totalCustomsValue) > 99)
            && (!!this.commodities && !!this.commodities.length);

        if (this.shouldShowValuationDiscrepancy) {
            this.validationService.setFormControlValidators(
                this.parentForm.controls.isValuationDiscrepancyAgreed,
                Validators.compose([Validators.requiredTrue]),
            );
        }
    }

    private checkSchedulePickupDate() {
        if (!this.shipFromAddress ||
            this.shipFromAddress.ContactId === 'NOID' ||
            !this.shipFromAddress.ContactId ||
            !this.carrier ||
            !this.shipmentService.Quote ||
            !this.shipmentService.Quote.IsPickUpRequested ||
            !this.serviceType) {
            return;
        }

        if (this.checkSchedulePickupSubscription) {
            this.checkSchedulePickupSubscription.unsubscribe();
            this.checkSchedulePickupSubscription = null;
        }

        this.checkSchedulePickupSubscription = this.shipmentService.checkSchedulePickup(
            this.shipFromAddress.ContactId,
            this.shipmentService.Quote.PickUpDate,
            this.carrier,
            this.serviceType.ServiceCode, // this.shipmentService.Quote.ServiceCode,
        )
            .subscribe(
                result => this.handleCheckSchedulePickupSuccess(result),
                err => this.handleCheckSchedulePickupFailure(err),
            );
    }

    private onCarrierChanged() {
        this.shipConfigService.Config = new ShipConfig();
        this.shipConfigService.isDefaultUserConfigApplied = false;

        this.checkCarrierForbidden();
        if (this.isForbidden) {
            return;
        }

        if (this.shipFromAddress) {
            this.shipConfigService.setConfig(
                this.shipFromCountryCode,
                this.shipToCountryCode,
                this.carrier,
                this.user,
                this.customer,
                this.insuredValue,
                this.totalCustomsValue,
                this.shipDateString,
                this.shipFromAddress.UPSPickUpType,
            );

            this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
        }

        if (this.user && this.carrier && this.shipFromCountryCode) {
            this.restrictedShipToCountryCodes =
                this.shipmentService.getRestrictedShipToCountries(this.user, this.carrier, this.shipFromCountryCode);
        }
    }

    private checkCarrierForbidden() {
        if (!this.user) {
            return;
        }

        switch (+Carriers[this.carrier]) {
            case Carriers.Ups:
                this.isForbidden = !this.user.IsUPSDomestic && !this.user.IsUPSInternational;
                break;
            case Carriers.Fedex:
                this.isForbidden = !this.user.IsFedExDomestic && !this.user.IsFedExInternational;
                break;
            case Carriers.Usps:
                this.isForbidden = !this.user.IsUSPSDomestic && !this.user.IsUSPSInternational;
                break;
            default:
                break;
        }
    }

    // [MV3-2785] adjusted logic to set config.highValueCommodity to check if it should override country logic
    private setAESStatus() {
        this.isAESShipment = this.shipmentService.hasHighValueCommodity() && this.isUseAESDirect && ['US', 'VI', 'PR'].includes(this.shipFromCountryCode);
        try {
            this.hasHighValueCommodity = this.config.highValueCommodity = this.shipmentService.hasAESCommodity(this.commodities);
        } catch (e) {
            this.hasHighValueCommodity = false;
        }

        if (this.isAESShipment && !this.responseQuote) {
            this.shipmentService.resetElectronicExportInformation();
        }

        this.isEEIRequired = (this.isAESShipment && !this.isNAFTA);
    }

    private showInsuredValueAlert() {
        if (!this.isInsuredValueChanged || !this.isPickupTypeChanged) {
            return;
        }

        const isUps = +Carriers[this.carrier] === +Carriers.Ups;
        if (this.insuredValue <= 50000 || !isUps || this.pickupType !== '02') {
            return;
        }

        this.dialogService.alert(
            '',
            'Shipments valued $50,001 and greater may not be tendered to The UPS Store. Select another option for tender.')
            .subscribe(
                (result) => {
                    this.shipOptions.setPickupType('01');
                },
            );
    }

    private showResidentialVerification() {
        if (!this.isResidentialAddress) {
            return;
        }

        // [MV3-3060] Remove the Adult Signature Required condition from showing/hiding the residential verification message.
        if (this.shipConfigService.isUps(this.carrier) && this.config && !this.config.showAdultSignatureMessage) {
            this.shouldShowResidentialVerification = false;
        } else {
            this.shouldShowResidentialVerification = !this.isResidentialCheck || !this.isAdultSignature;
        }

        this.validationService.clearFormControlValidators([
            this.parentForm.controls.isResidentialVerificationAgreed,
        ]);

        if (this.shouldShowResidentialVerification) {
            this.validationService.setFormControlValidators(
                this.parentForm.controls.isResidentialVerificationAgreed,
                Validators.compose([Validators.requiredTrue]),
            );
        }
    }

    private notifyIfStatementsNotAgreed(): boolean {
        const isResidentialVerificationNotAgreed = this.parentForm.controls.isResidentialVerificationAgreed.errors
            && this.parentForm.controls.isResidentialVerificationAgreed.errors.requiredTrue !== null;
        const isValuationDiscrepancyNotAgreed = this.parentForm.controls.isValuationDiscrepancyAgreed.errors
            && this.parentForm.controls.isValuationDiscrepancyAgreed.errors.requiredTrue !== null;

        const isStatementsNotAgreed = isResidentialVerificationNotAgreed || isValuationDiscrepancyNotAgreed;

        if (isStatementsNotAgreed) {
            this.notificationService.notify('All agreement statements must be agreed.', 'Statements Not Agreed', NotificationType.ERROR);
        }

        return isStatementsNotAgreed;
    }

    private setReturnShipmentSpecificValues() {
        this.shipmentService.Quote.IsDDO = false;
        this.shipmentService.Quote.IsDirectSignature = false;
        this.shipmentService.Quote.IsDeliveryConfirmation = false;
        this.shipmentService.Quote.IsReturnService = true;
        this.shipmentService.Quote.IsThermal = (this.shipmentService.Quote.ReturnServiceCode == '9') ? this.shipmentService.Quote.IsThermal : false; // MV3-4862
    }

    private handleSuccessfulQuoteAdd(quote: Package, shouldConfirmShipment = false) {
        this.responseQuote = quote;

        this.shipmentService.Quote.QuoteId = quote.QuoteId;
        this.isQuoteReady = true;
        this.isQuoteHasLabel = false;
        this.estimatedCost = quote.Estimator.length ?
            this.utilityService.formatMoney(
                quote.Estimator[0].ShippingCost + quote.Estimator[0].InsuranceCost,
                this.insuredValueCurrencyCode) :
            this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
        this.surcharge = quote.Estimator.length ?
            this.utilityService.formatMoney(quote.Estimator[0].AccessorialsCost, this.insuredValueCurrencyCode) :
            this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
        this.totalCharges = quote.Estimator.length ?
            this.utilityService.formatMoney(quote.Estimator[0].TotalCharges, this.insuredValueCurrencyCode) :
            this.utilityService.formatMoney(0, this.insuredValueCurrencyCode);
        this.shouldShowShipToReroute = quote.IsShipToRestrictedZip;

        this.isHighValueShipment = quote.IsHighValueShipment;

        // TODO: the EeiFilingOption is missing from the API. Therefore, we temporarily use the value from the package.
        this.responseQuote.EeiFilingOption = this.shipmentService.Quote.EeiFilingOption;
        this.setAESStatus();

        // Overwrite BillToAccount with the one in the form.
        this.responseQuote.BillToAccount = this.shipmentService.Quote.BillToAccount.toUpperCase();

        if (shouldConfirmShipment) {
            this.confirmShipment(null);
        } else {
            this.notificationService.notify('Shipment quote has successfully been created.', 'Success', NotificationType.SUCCESS);
            this.isFormSubmitting = false;
            this.spinnerService.hide();
        }

        this.validateAllForms();
        this.shouldEnableSaveQuote = true;
        // this.openShipmentDetailsDialog(this.shipmentService.Quote);
    }

    private handleFailedQuoteAdd(err) {
        this.spinnerService.hide();
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed creating shipment',
            NotificationType.ERROR);
        this.isFormSubmitting = false;
    }

    //#region other Built-in events

    private goToShipmentDetails() {
        if (!this.shipmentService.DetailsQuote) {
            this.shipmentService.DetailsQuote = new Package();
        }

        Object.assign(this.shipmentService.DetailsQuote, this.responseQuote);
        this.shipmentService.clearQuote();

        this.isFormSubmitting = false;
        this.spinnerService.hide();
        this.isQuoteReady = false;
        this.isQuoteHasLabel = true;

        const queryParams = this.getQuoteQueryParameters(this.responseQuote);

        if (this.isMobileWebView) {
            const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
            window.location.href = `/ship/details?${queryString}`;
            return;
        }

        this.router.navigate(['/ship/details'], { queryParams });
    }

    private getQuoteQueryParameters(quote: Package): any {
        const shipmentType = quote.IsHighValueShipment ? 'highvalue' : 'normal';
        return {
            type: shipmentType,
            shipmentid: quote.ShipmentId,
            trackingnumber: quote.TrackingNumber,
            carriercode: this.carrierCode.toLowerCase(),
        };
    }

    private forceValidation() {
        this.markComponentFormAsDirty([
            this.shipTo,
            this.shipFrom,
            this.shipInfo,
            this.commodityShipInfo,
            this.shipInfoAdd,
            this.shipCommodity,
            this.shipOptions,
            this.shipBillingDetails,
            this.shipElectronicExport,
            this.shipCustomsDocs,
            this.shipReturnOptions,
            this.shipImportInformation,
        ]);

        this.formService.markAllAsTouchedAndDirty(this.parentForm, false);
    }

    //#endregion


    //#region Reset

    private markComponentFormAsDirty(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return;
                }

                component.forceValidation();
            },
        );
    }

    private resetComponents(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return true;
                }

                component.resetForm();
            },
        );
    }

    private resetCommodityRelatedComponents() {
        this.resetComponents([
            this.commodityShipInfo,
            this.shipCommodity,
        ]);
    }

    //#endregion

    private setEUFieldsVisibility(): void {
        if (!this.shipFromCountryCode || !this.shipToCountryCode) {
            this.shouldDisplayShipFromEUFields = false;
            return;
        }

        this.shouldDisplayShipFromEUFields =
            (this.shipConfigService.isEuToNonEu(this.shipFromCountryCode, this.shipToCountryCode) ||
                this.shipConfigService.isInterEU(this.shipFromCountryCode, this.shipToCountryCode)) &&
            !this.shipConfigService.isEUDomestic(this.shipFromCountryCode, this.shipToCountryCode) &&
            !this.shipConfigService.isNonEuToNonEu(this.shipFromCountryCode, this.shipToCountryCode);
        this.shouldDisplayShipToEUFields =
            (this.shipConfigService.isNonEuToEu(this.shipFromCountryCode, this.shipToCountryCode) ||
                this.shipConfigService.isInterEU(this.shipFromCountryCode, this.shipToCountryCode)) &&
            !this.shipConfigService.isEUDomestic(this.shipFromCountryCode, this.shipToCountryCode) &&
            !this.shipConfigService.isNonEuToNonEu(this.shipFromCountryCode, this.shipToCountryCode);

        if (this.shipConfigService.isNonEuToEu(this.shipFromCountryCode, this.shipToCountryCode)) {
            this.showImportInformation = true;
        } else {
            this.showImportInformation = false;
        }
    }
}

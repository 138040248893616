<div class="ups-dialog">
    <div class="dialog-header clear-header" *ngIf="!isFormSubmitted">
        <h2 mat-dialog-title [innerHtml]="'requestQuote.title' | translate"></h2>
        <mat-dialog-actions>
            <!--<button mat-raised-button class="close-button-medium" mat-dialog-close>{{'Close'|translate}}</button>-->
        </mat-dialog-actions>
        <button type="button"
                mat-raised-button
                class="icon-button close-button"
                tabindex="-1"
                mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="main-content" *ngIf="!isFormSubmitted">
            <div class="message" [innerHtml]="'requestQuote.message' | translate"></div>
            <ul class="benefits">
                <li class="benefit">
                    <mat-icon>check</mat-icon>
                    {{'requestQuote.benefits.0' | translate}}
                </li>
                <li class="benefit">
                    <mat-icon>check</mat-icon>
                    {{'requestQuote.benefits.1' | translate}}
                </li>
                <li class="benefit">
                    <mat-icon>check</mat-icon>
                    {{'requestQuote.benefits.2' | translate}}
                </li>
            </ul>
            <form [formGroup]="formGroup" (ngSubmit)="onFormSubmitted($event, formGroup.value)">
                <div class="name">
                    <mat-form-field>
                        <input matInput
                               required
                               placeholder="{{ 'requestQuote.form.firstName.label' | translate }}"
                               type="text"
                               maxlength="50"
                               formControlName="firstName">
                        <mat-error *ngIf="formGroup.controls.firstName?.errors">
                    <span *ngIf="formGroup.controls.firstName?.errors?.required"
                          [innerHtml]="'requestQuote.form.firstName.errors.required' | translate">
                    </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput
                               required
                               placeholder="{{ 'requestQuote.form.lastName.label' | translate }}"
                               type="text"
                               maxlength="50"
                               formControlName="lastName">
                        <mat-error *ngIf="formGroup.controls.lastName?.errors">
                    <span *ngIf="formGroup.controls.lastName?.errors?.required"
                          [innerHtml]="'requestQuote.form.lastName.errors.required' | translate">
                    </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field>
                    <input matInput
                           required
                           placeholder="{{ 'requestQuote.form.phoneNumber.label' | translate }}"
                           type="text"
                           maxlength="15"
                           pattern="^[0-9]{1,15}$"
                           formControlName="phone">
                    <mat-error *ngIf="formGroup.controls.phone?.errors">
                        <span *ngIf="formGroup.controls.phone?.errors?.required"
                              [innerHtml]="'requestQuote.form.phoneNumber.errors.required' | translate">
                        </span>
                        <span *ngIf="formGroup.controls.phone?.errors?.pattern"
                              [innerHtml]="'requestQuote.form.phoneNumber.errors.pattern' | translate">
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input matInput
                           required
                           placeholder="{{ 'requestQuote.form.email.label' | translate }}"
                           type="text"
                           formControlName="email">
                    <mat-error *ngIf="formGroup.controls.email?.errors">
                        <span *ngIf="formGroup.controls.email?.errors?.required"
                              [innerHtml]="'requestQuote.form.email.errors.required' | translate">
                        </span>
                        <span *ngIf="formGroup.controls.email?.errors?.emailFormat"
                              [innerHtml]="'requestQuote.form.email.errors.emailFormat' | translate">
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-error class="error-message" *ngIf="formSubmitErrorMessage">{{ formSubmitErrorMessage }}</mat-error>
                <div class="form-actions">
                    <button type="submit"
                            mat-raised-button
                            class="form-action secondary-button"
                            tabindex="0"
                            [disabled]="!formGroup.valid">
                        {{ 'requestQuote.form.submit' | translate }}
                    </button>
                </div>
                <upsc-element-blocker *ngIf="isFormSubmitting"></upsc-element-blocker>
            </form>
            <div class="agreement">
                <span>{{ 'requestQuote.agreement.0' | translate }}</span>
                <span>{{ 'requestQuote.agreement.1' | translate }}</span>
                <span>{{ 'requestQuote.agreement.2' | translate }}</span>
                <span>{{ 'requestQuote.agreement.3' | translate }}</span>
            </div>
        </div>
        <div class="notification" *ngIf="isFormSubmitted">
            <h2 class="title" [innerHtml]="'requestQuote.notification.title' | translate"></h2>
            <div class="message" [innerHtml]="'requestQuote.notification.message' | translate"></div>
            <button type="button"
                    class="secondary-button"
                    mat-raised-button
                    mat-dialog-close="true">
                {{ 'requestQuote.notification.close' | translate }}
            </button>
        </div>
    </mat-dialog-content>
</div>
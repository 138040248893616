<div class="ship-from-edit ups-form" [formGroup]="formGroup">
    <div class="form-body">
        <div class="select-wrapper" *ngIf="!isDomesticOnly">
            <upsc-ship-countries [formGroup]="formGroup"
                                 [countryFormControlName]="'country'"
                                 [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                                 [countries]="displayedCountries"></upsc-ship-countries>
            <upsc-element-blocker *ngIf="isCountriesLoading || isZipCodeValidating"></upsc-element-blocker>
        </div>

        <ng-container *ngIf="shouldDisplayEUFields">
            <mat-form-field>
                <mat-label>{{ 'vat' | translate }}</mat-label>
                <input matInput
                       type="text"
                       maxlength="20"
                       formControlName="vat">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'taxId' | translate }}</mat-label>
                <input matInput
                       type="text"
                       maxlength="20"
                       formControlName="taxId">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'eori' | translate }}</mat-label>
                <input matInput
                       type="text"
                       maxlength="20"
                       formControlName="eori">
                <a href="{{ 'eoriInfoLink' | translate}}"
                   target="_blank"
                   matSuffix>
                    <mat-icon matTooltip="{{ 'eoriInfo' | translate }}">help</mat-icon>
                </a>
            </mat-form-field>
        </ng-container>

        <mat-form-field *ngIf="isDomesticOnly">
            <mat-label>{{'Country'|translate}}</mat-label>
            <input matInput
                   required
                   readonly
                   maxlength="2"
                   formControlName="country">
        </mat-form-field>

        <div class="equal-space">
            <mat-form-field>
                <mat-label>{{'FirstName'|translate}}</mat-label>
                <input matInput
                       [required]="isFirstNameLastNameRequired ? '' : null"
                       maxlength="15"
                       formControlName="firstName">
                <mat-error *ngIf="formGroup.controls.firstName?.errors?.required">
                    {{'FirstNameRequired'|translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'LastName'|translate}}</mat-label>
                <input matInput
                       [required]="isFirstNameLastNameRequired ? '' : null"
                       maxlength="19"
                       formControlName="lastName">
                <mat-error *ngIf="formGroup.controls.lastName?.errors?.required">
                    {{'LastNameRequired'|translate}}
                </mat-error>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{'Company'|translate}}</mat-label>
            <input matInput
                   [required]="isCompanyNameRequired ? '' : null"
                   maxlength="35"
                   formControlName="company">
            <mat-error *ngIf="formGroup.controls.company?.errors?.required">
                {{'CompanyRequired'|translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'AddressLine1'|translate}}</mat-label>
            <input matInput
                   required
                   maxlength="35"
                   formControlName="address1">
            <mat-error *ngIf="formGroup.controls.address1?.errors?.required">
                {{'Address1Required'|translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'AddressLine2'|translate}}</mat-label>
            <input matInput
                   maxlength="35"
                   formControlName="address2">
            <mat-error *ngIf="formGroup.controls.address2?.errors?.required">
                {{'Address2Required'|translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isFRITUser">
            <mat-label>{{'AddressLine3'|translate}}</mat-label>
            <input matInput
                   maxlength="35"
                   formControlName="address3"></mat-form-field>
        <mat-form-field>
            <mat-label>{{'ZipCode'|translate}}</mat-label>
            <input matInput
                   [required]="selectedCountry?.IsPostalCodeAware ? '' : null"
                   maxlength="10"
                   formControlName="zipCode">
            <mat-hint *ngIf="selectedCountry?.IsPostalCodeAware && !!selectedCountry?.PostalCodePattern">
                <span>{{ 'inputHint.postalCodeExample' | translate }}: {{ selectedCountry.PostalCodePattern }}</span>
            </mat-hint>
            <mat-error *ngIf="formGroup.controls.zipCode?.errors?.required">
                {{'ZipCodeRequired'|translate}}
            </mat-error>
            <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
        </mat-form-field>

        <div class="equal-space">
            <mat-form-field>
                <mat-label>{{'City'|translate}}</mat-label>
                <input matInput
                       required
                       maxlength="35"
                       formControlName="city">
                <mat-error *ngIf="formGroup.controls.city?.errors">
                    <span *ngIf="formGroup.controls.city?.errors?.required">{{'CityRequired'|translate}}</span>
                    <span *ngIf="formGroup.controls.city?.errors?.maxLength">{{'CityMaxLength'|translate}}</span>
                </mat-error>
                <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'StateOrProvince'|translate}}</mat-label>
                <input matInput
                       [attr.required]="shouldRequireStateProvince()"
                       maxlength="35"
                       formControlName="state">
                <mat-error *ngIf="formGroup.controls.state?.errors?.required">
                    {{'StateProvinceRequired'|translate}}
                </mat-error>
                <upsc-element-blocker *ngIf="isZipCodeValidating"></upsc-element-blocker>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{'TelephoneNo'|translate}}</mat-label>
            <input matInput
                   required
                   minlength="10"
                   maxlength="15"
                   formControlName="phone">
            <mat-error *ngIf="formGroup.controls.phone?.errors">
                <span *ngIf="formGroup.controls.phone?.errors?.required">{{'TelephoneNumberRequired'|translate}}</span>
                <span *ngIf="formGroup.controls.phone?.errors?.pattern">{{'Telephone No can only be numeric'|translate}}</span>
                <span *ngIf="!formGroup.controls.phone?.errors?.pattern && formGroup.controls.phone?.errors?.minlength">{{'Telephone No must be at least 10 digits'|translate}}</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'EmailAddress'|translate}}</mat-label>
            <input matInput
                   required
                   maxlength="100"
                   formControlName="email">
            <mat-error *ngIf="formGroup.controls.email?.errors">
                <span *ngIf="formGroup.controls.email?.errors?.emailFormat">{{'EmailAddressInvalidFormat'|translate}}</span>
                <span *ngIf="formGroup.controls.email?.errors?.required">{{ 'validation.emailRequired' | translate }}</span>
            </mat-error>
        </mat-form-field>
    </div>
</div>

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';

import { Location } from 'app/shared/models/location/location.model';
import { IShipComponent } from '../../../models/ship-component.interface';
import { ICountry } from 'app/shared/models/country.interface';
import { Carriers } from 'app/shared/enum/general-enum';
import { Subscription } from 'rxjs';
import { StorageService } from 'app/core/services/storage/storage.service';
import { ShipConfigService } from '../../../services/ship-config.service';
import { ShipmentService } from '../../../services/shipment.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { NotificationType } from 'app/shared/models/notification-type';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { UtilityService } from 'app/shared/services/utility/utility.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'upsc-return-ship-from-static',
    templateUrl: './return-ship-from-static.component.html',
    styleUrls: ['./return-ship-from-static.component.scss']
})
export class ReturnShipFromStaticComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {

    @Input() location: Location;
    @Input() carrier: Carriers;
    @Input() shipFromCountryCode: string;
    @Input() shouldDisplayEUFields = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

    public formGroup: UntypedFormGroup;
    public countries: ICountry[];
    private getShipFromCountriesSubscription: Subscription;

    constructor(private storageService: StorageService,
                private shipConfigService: ShipConfigService,
                private notificationService: NotificationService,
                private utilityService: UtilityService,
                private errorHandlerService: ErrorHandlerService,
                private shipmentService: ShipmentService,
                private formBuilder: UntypedFormBuilder,
    ) {
        this.isValid.emit(true);

        this.formGroup = this.formBuilder.group({
            vat: [''],
            taxId: [''],
            eori: [''],
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onCarrierChanged(changes['carrier']);
        this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
    }

    private onShipFromCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }
        if (this.countries) {
            const selectedCountry = this.countries.find(country => country.CountryCode === change.currentValue);
            this.formGroup?.patchValue({
                vat: '',
                taxId: '',
                eori: '',
            });
            // TODO - update this in ship To component
            // this.shipConfigService.setPostalCodeAware(selectedCountry);
        }
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.carrier = this.utilityService.getEnumValue(Carriers, change.currentValue);
        this.loadCarrierCountries(this.carrier);
    }

    private loadCarrierCountries(carrier: Carriers) {
        if (!carrier) {
            return;
        }

        if (this.getShipFromCountriesSubscription) {
            this.getShipFromCountriesSubscription.unsubscribe();
            this.getShipFromCountriesSubscription = null;
        }

        this.getShipFromCountriesSubscription = this.shipmentService.getShipToCountries(carrier)
            .subscribe(
                countries => this.handleGetShipFromCountriesSuccess(countries),
                err => this.handleGetShipFromCountriesFailure(err),
            );
    }

    private handleGetShipFromCountriesSuccess(countries) {
        this.countries = countries;
    }

    private handleGetShipFromCountriesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed loading country list',
            NotificationType.ERROR);
        // this.utilityService.delay(() => {
        //   this.isCountriesLoading = false;
        // });
    }

    public ngOnDestroy() {
        this.utilityService.clearSubscriptions([this.getShipFromCountriesSubscription,])
    }

    public ngOnInit() {
    }

    public resetForm() {
    }

    public forceValidation() {
    }
}

<button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
    <mat-icon>close</mat-icon>
</button>

<div class="dialog-header">
    <div [innerHTML]="'getShippingUpdates.title' | translate" class="title"></div>
    @switch (carrier) {
        @case (Carriers.Ups) {
            <p [innerHTML]="'getShippingUpdates.p1.ups' | translate"></p>
        }
        @case (Carriers.Fedex) {
            <p [innerHTML]="'getShippingUpdates.p1.fedex' | translate"></p>
        }
        @default {
            <p [innerHTML]="'getShippingUpdates.p1.ups' | translate"></p>
        }
    }
</div>
<div class="dialog-content">
    <p [innerHTML]="'getShippingUpdates.form.title' | translate"></p>
    <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
        <div class="recipients" formArrayName="recipients">
            <div class="recipient" *ngFor="let recipient of recipientFormArray.controls; let i=index"
                 [formGroupName]="i">
                <mat-form-field class="form-field--short">
                    <mat-label>{{ 'getShippingUpdates.form.firstName' | translate }}</mat-label>
                    <input type="text"
                           matInput
                           formControlName="firstName"
                           [readonly]="recipient.get('firstName')?.['_isReadonly']"
                           required>
                    <mat-error>First name is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="form-field--short">
                    <mat-label>{{ 'getShippingUpdates.form.lastName' | translate }}</mat-label>
                    <input type="text"
                           matInput
                           formControlName="lastName"
                           [readonly]="recipient.get('lastName')?.['_isReadonly']"
                           required>
                    <mat-error>Last name is required.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'getShippingUpdates.form.email' | translate }}</mat-label>
                    <input type="email"
                           matInput
                           formControlName="email"
                           [readonly]="recipient.get('email')?.['_isReadonly']"
                           required>
                    <mat-error>Email address is required.</mat-error>
                </mat-form-field>
                <div class="recipient-actions">
                    <button mat-flat-button
                            type="button"
                            color="warn"
                            class="recipient-action remove"
                            (click)="removeRecipient($event, i)"
                            [innerHTML]="'getShippingUpdates.form.removeEmail' | translate"></button>
                </div>
            </div>
        </div>
        <div class="person-info-actions">
            @if (shouldAllowMultipleRecipients) {
                <a href="#"
                   mat-flat-button
                   class="person-info-action"
                   (click)="addRecipient($event)"
                   [innerHTML]="'getShippingUpdates.form.addEmail' | translate"></a>
            }
        </div>
        <div class="field-container">
            <mat-checkbox class="readonly" formControlName="shouldSendToSender">
                <span [innerHTML]="'getShippingUpdates.form.sendToSender' | translate:{ email: formGroup?.get('sender')?.get('email')?.value }"></span>
            </mat-checkbox>
        </div>

        <div class="form-actions">
            <button class="form-action"
                    color="primary"
                    (click)="closeDialog($event)"
                    [disabled]="!formGroup.valid"
                    mat-flat-button
                    type="submit">
                <span [innerHTML]="'getShippingUpdates.form.confirm' | translate"></span>
            </button>
        </div>
    </form>
</div>

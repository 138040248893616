import { Component, Input, OnInit } from '@angular/core';
import { DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { ICarrierLocation } from '../models/carrier-location.interface';

@Component({
  selector: 'upsc-location-search-table',
  templateUrl: './location-search-table.component.html',
  styleUrls: ['./location-search-table.component.scss'],
})
export class LocationSearchTableComponent extends DataTableComponent<ICarrierLocation> implements OnInit {
  @Input() isActionsAllowed = false;

  public ngOnInit() {
    super.ngOnInit();

    this.displayedColumns = ['AddressDisplay', 'Distance', 'Hours', 'LatestExpressDropOff'];

    if (this.isActionsAllowed) {
      this.displayedColumns.push('Actions');
    }
  }

  public selectLocation(event, location: ICarrierLocation) {
    event.preventDefault();

    this.rowActionClicked.emit({
      action: 'select',
      data: location,
    });
  }
}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Carriers } from 'app/shared/enum/general-enum';
import { ICountry } from 'app/shared/models/country.interface';
import { Customer } from 'app/shared/models/customer/customer.model';
import { Location } from 'app/shared/models/location/location.model';
import { NotificationType } from 'app/shared/models/notification-type';
import { PostalCode } from 'app/shared/models/postal-code.model';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { FormService } from 'app/shared/services/form/form.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { User } from 'app/shared/services/user/models/user.model';
import { UserService } from 'app/shared/services/user/user.service';
import { UtilityService } from 'app/shared/services/utility/utility.service';
import { ValidationService } from 'app/shared/services/validation/validation.service';
import { ZipCodeService } from 'app/shared/services/zip-code/zip-code.service';
import { Package } from '../../../models/package.model';
import { IShipComponent } from '../../../models/ship-component.interface';
import { ShipmentService } from '../../../services/shipment.service';

@Component({
  selector: 'upsc-return-ship-to-edit',
  templateUrl: './return-ship-to-edit.component.html',
  styleUrls: ['./return-ship-to-edit.component.scss']
})
export class ReturnShipToEditComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {

  @Input() user: User;
  @Input() customer: Customer;
  @Input() location: Location;
  @Input() carrier: Carriers;
  @Input() shipToCountryCode: string;
  @Input() shipFromCurrencyCode = 'USD';
  @Input() maxCoverageCurrencyCode = 'USD';
  @Input() isRestoreShipment = false;
  @Input() shouldDisplayEUFields = false;
  @Input() isFRITUser: boolean;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() countryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();
  @Output() countryCodeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();
  

  public formGroup: UntypedFormGroup;

  public countries: ICountry[];
  public displayedCountries: ICountry[];
  public selectedCountry: ICountry;
  public isCountriesLoading = true;
  public isZipCodeValidating = false;
  public isDomesticOnly = false;
  public isFirstNameLastNameRequired = true;
  public isCompanyNameRequired = true;

  private NACountries = ['CA', 'MX', 'US'];
  private shouldResetZip = true;

  private getShipToCountriesSubscription: Subscription;
  private getAddressByZipCodeSubscription: Subscription;

  constructor(private validationService: ValidationService,
    private formService: FormService,
    private zipCodeService: ZipCodeService,
    private utilityService: UtilityService,
    private errorHandlerService: ErrorHandlerService,
    private notificationService: NotificationService,
    private userService: UserService,
    private formBuild: UntypedFormBuilder,
    private shipmentService: ShipmentService) {
    this.loadInitialValues();
  }

  public ngOnDestroy() {
    if (this.getShipToCountriesSubscription) {
      this.getShipToCountriesSubscription.unsubscribe();
      this.getShipToCountriesSubscription = null;
    }

    if (this.getAddressByZipCodeSubscription) {
      this.getAddressByZipCodeSubscription.unsubscribe();
      this.getAddressByZipCodeSubscription = null;
    }
  }

  public ngOnInit() {
    this.formGroup = this.formBuild.group({
      contactId: ['NOID'],
      firstName: ['', Validators.compose([Validators.required, Validators.maxLength(15)])],
      lastName: ['', Validators.compose([Validators.required, Validators.maxLength(19)])],
      company: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
      zipCode: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
      city: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
      state: ['', Validators.compose([Validators.maxLength(35)])],
      country: [this.shipToCountryCode, Validators.compose([Validators.required, Validators.maxLength(2)])],
      address1: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
      address2: ['', Validators.compose([Validators.maxLength(35)])],
      address3: ['', Validators.compose([Validators.maxLength(35)])],
      email: ['', Validators.compose([Validators.required, this.validationService.emailFormatValidator(), Validators.maxLength(100)])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10)])],
      vat: [''],
      taxId: [''],
      eori: [''],
    });

    this.monitorValueChanges();
    this.setFormValues();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.onUserChanged(changes['user']);
    this.onLocationChanged(changes['location']);
    // this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
    this.onCarrierChanged(changes['carrier']);
    this.onShipFromCurrencyCodeChanged(changes['shipFromCurrencyCode']); // TODO check
    this.onCustomerChanged(changes['customer']);
  }

  private onUserChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    // [MV3-1212]: Trigger the domestic-only flag to show/hide a readonly country input.
    this.isDomesticOnly = this.userService.isDomesticOnly(change.currentValue);
  }

  private onCustomerChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }
  }

  private onLocationChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    // MV3-1211: Do not populate location for a guest user. Just set the country.
    if (this.user && this.user.IsGuest) {
      if (this.formGroup) {
        this.formGroup.controls.country.setValue(change.currentValue.Country);
      }

      return;
    }

    this.updateLocation(change.currentValue);
  }

  // private onShipFromCountryCodeChanged(change: SimpleChange) {
  //   if (!change || !change.currentValue) {
  //     return;
  //   }
  //
  //   if (this.formGroup) {
  //     this.formGroup.controls.country.setValue(change.currentValue);
  //   }
  // }

  private updateLocation(location: Location) {
    if (!this.formGroup) {
      return;
    }

    this.shouldResetZip = false;
    this.formGroup.patchValue(
      {
        contactId: location.ContactId,
        firstName: location.FirstName,
        lastName: location.LastName,
        company: location.CompanyName,
        zipCode: location.Zip,
        city: location.City,
        state: location.State,
        country: location.Country,
        address1: location.StreetAddress,
        address2: location.ApartmentSuite,
        address3: location.AdditionalAddressInformation,
        email: location.Email || this.user.Email,
        phone: location.TelephoneNo,

        vat: '',
        taxId: '',
        eori: '',
      },
      { onlySelf: true, emitEvent: true });

    if (this.countries) {
      this.selectedCountry = this.countries.find(country => country.CountryCode === location.Country);
    }

    this.countryCodeChanged.emit(location.Country);
    this.zipCodeChanged.emit(location.Zip);
  }

  private onCarrierChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.loadCarrierCountries(change.currentValue);
  }

  private onShipFromCurrencyCodeChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.displayedCountries = _.cloneDeep(this.countries);
  }

  private loadCarrierCountries(carrier: Carriers) {
    if (!carrier) {
      return;
    }

    if (this.getShipToCountriesSubscription) {
      this.getShipToCountriesSubscription.unsubscribe();
      this.getShipToCountriesSubscription = null;
    }

    this.getShipToCountriesSubscription = this.shipmentService.getShipFromCountries(this.carrier)
      .subscribe(
        countries => this.handleGetShipToCountriesSuccess(countries),
        err => this.handleGetShipToCountriesFailure(err),
      );
  }

  private loadInitialValues() {
  }

  private monitorValueChanges() {
    this.formGroup.valueChanges
      .subscribe(
        (form) => {
          this.isValid.emit(this.formGroup.valid);
          this.shipmentService.saveShipTo(form);
          this.formValueChanged.emit(form);
        });

    this.formGroup.controls.firstName.valueChanges
      .subscribe(
        (value) => {
          this.updateAlternateValidators();
        },
      );

    this.formGroup.controls.lastName.valueChanges
      .subscribe(
        (value) => {
          this.updateAlternateValidators();
        },
      );

    this.formGroup.controls.company.valueChanges
      .subscribe(
        (value) => {
          this.updateAlternateValidators();
        },
      );

    this.formGroup.controls.country.valueChanges
      .subscribe(
        (value) => {
          if (this.shouldResetZip) {
            this.formGroup.patchValue(
              {
                zipCode: '',
                city: '',
                state: '',
                vat: '',
                taxId: '',
                eori: '',
              },
              { onlySelf: true });
          }

          this.shouldResetZip = true;

          this.validationService.clearFormControlValidators([
            this.formGroup.controls.state,
          ]);

          if (this.NACountries.includes(value)) {
            this.validationService.setFormControlValidators(
              this.formGroup.controls.state,
              Validators.compose([Validators.required, Validators.maxLength(35)]),
            );
          }

          if (!this.countries) {
            return;
          }

          this.selectedCountry = this.countries.find(country => country.CountryCode === value);
          if (this.selectedCountry && this.selectedCountry.IsPostalCodeAware) {
            this.formGroup.controls.zipCode.enable();
            this.validationService.setFormControlValidators(
              this.formGroup.controls.zipCode,
              Validators.compose([Validators.required, Validators.maxLength(10)]),
            );

            this.countryChanged.emit(this.selectedCountry);
          } else {
            // this.formGroup.controls.zipCode.disable();
            this.validationService.setFormControlValidators(
              this.formGroup.controls.zipCode,
              Validators.compose([Validators.maxLength(10)]),
            );
          }

          this.countryCodeChanged.emit(value);
        },
      );

    this.formGroup.controls.zipCode.valueChanges
      .pipe(debounceTime(500),
        distinctUntilChanged())
      .subscribe(
        (value) => {
          if (!value || (value && !this.isZipCodeValid(value))) {
            return;
          }

          this.zipCodeChanged.emit(value);

          const addressLookupCountries = ['US', 'CA', 'PR', 'VI', 'GU'];
          if (!this.selectedCountry || !addressLookupCountries.includes(this.selectedCountry.CountryCode)) {
            return;
          }

          if (this.getAddressByZipCodeSubscription) {
            this.getAddressByZipCodeSubscription.unsubscribe();
            this.getAddressByZipCodeSubscription = null;
          }

          this.isZipCodeValidating = true;
          this.getAddressByZipCodeSubscription = this.zipCodeService.getAddressByZipCode(value)
            .subscribe(
              result => this.handleZipCodeValidationSuccess(result),
              err => this.handleZipCodeValidationFailure(err),
            );
        },
      );
  }

  private updateAlternateValidators() {
    const firstName = this.formGroup.controls.firstName.value;
    const lastName = this.formGroup.controls.lastName.value;
    const companyName = this.formGroup.controls.company.value;

    // [MV3-1427] A user can enter either (First Name and Last Name) or Company Name.
    // Company Name is not required if both First Name and Last Name are present.
    this.isFirstNameLastNameRequired = !companyName || (!!firstName && !!lastName && !!companyName);
    this.isCompanyNameRequired = (!firstName && !lastName) || (!!companyName && (!firstName || !lastName));

    if (this.isFirstNameLastNameRequired) {
      this.validationService.setFormControlValidators(
        this.formGroup.controls.firstName,
        Validators.compose([Validators.required, Validators.maxLength(15)]),
        { emitEvent: false });
      this.validationService.setFormControlValidators(
        this.formGroup.controls.lastName,
        Validators.compose([Validators.required, Validators.maxLength(20)]),
        { emitEvent: false });
    } else {
      this.validationService.setFormControlValidators(
        this.formGroup.controls.firstName,
        Validators.compose([Validators.maxLength(15)]),
        { emitEvent: false });
      this.validationService.setFormControlValidators(
        this.formGroup.controls.lastName,
        Validators.compose([Validators.maxLength(20)]),
        { emitEvent: false });
    }

    if (this.isCompanyNameRequired) {
      this.validationService.setFormControlValidators(
        this.formGroup.controls.company,
        Validators.compose([Validators.required, Validators.maxLength(35)]),
        { emitEvent: false });
    } else {
      this.validationService.setFormControlValidators(
        this.formGroup.controls.company,
        Validators.compose([Validators.maxLength(35)]),
        { emitEvent: false });
    }
  }

  private setFormValues() {
    if (!this.shipmentService.Quote) {
      return;
    }

    this.formGroup.patchValue({
      firstName: this.shipmentService.Quote.ShipTo.FirstName,
      lastName: this.shipmentService.Quote.ShipTo.LastName,
      company: this.shipmentService.Quote.ShipTo.CompanyName,
      zipCode: this.shipmentService.Quote.ShipTo.Zip,
      city: this.shipmentService.Quote.ShipTo.City,
      state: this.shipmentService.Quote.ShipTo.State,
      country: this.shipmentService.Quote.ShipTo.Country,
      address1: this.shipmentService.Quote.ShipTo.StreetAddress,
      address2: this.shipmentService.Quote.ShipTo.ApartmentSuite,
      address3: this.shipmentService.Quote.ShipTo.AdditionalAddressInformation,
      email: this.shipmentService.Quote.ShipTo.Email,
      phone: this.shipmentService.Quote.ShipTo.TelephoneNo,
      vat: this.shipmentService.Quote.ShipToVAT,
      taxId: this.shipmentService.Quote.ShipToTaxID,
      eori: this.shipmentService.Quote.ShipToEORI,
    });

    this.formService.markAllAsTouchedAndDirty(this.formGroup);
    this.isValid.emit(this.formGroup.valid);
  }

  private handleGetShipToCountriesSuccess(countries) {
    this.countries = _.sortBy(countries, ['CountryName', 'MaxCoverage']);
    this.displayedCountries = _.cloneDeep(this.countries);
    this.utilityService.delay(() => {
      this.isCountriesLoading = false;
    });

    if (this.shipmentService.Quote) {
      this.selectedCountry = this.countries.find(country => country.CountryCode === this.shipmentService.Quote.ShipTo.Country);
    }
  }

  private handleGetShipToCountriesFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed loading country list',
      NotificationType.ERROR);
    this.utilityService.delay(() => {
      this.isCountriesLoading = false;
    });
  }

  /**
   * Client-side validation of a zip code.
   * @returns {boolean}
   */
  private isZipCodeValid(zipCode: string): boolean {
    if (!this.selectedCountry) {
      return false;
    }

    // TODO: validate zip code pattern too!
    const zipCodeRegExp = new RegExp(`^[a-z0-9]\{${this.selectedCountry.PostalCodeDigits}\}$`, 'i');

    return zipCodeRegExp.test(zipCode);
  }

  private handleZipCodeValidationSuccess(zipCode: PostalCode) {
    this.formGroup.patchValue({
      city: zipCode.City,
      state: zipCode.State,
      // country: zipCode.Country,
    });

    this.utilityService.delay(() => {
      this.isZipCodeValidating = false;
    });
  }

  private handleZipCodeValidationFailure(err) {
    // this.notificationService.notify(err.statusText, 'Failed validating a zip code', NotificationType.ERROR);
    console.warn(err.statusText);
    this.utilityService.delay(() => {
      this.isZipCodeValidating = false;
    });
  }

  public getFormErrors() {
    return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Ship To');
  }

  public isFormValid() {
    return this.formGroup.valid;
  }

  public shouldRequireStateProvince() {
    const country = this.formGroup.controls.country.value;
    if (!country) {
      return false;
    }

    return this.NACountries.includes(country);
  }

  public resetForm() {
    if (!this.formGroup) {
      return;
    }

    this.formGroup.reset({
      country: this.shipToCountryCode,
    });
  }

  public forceValidation() {
    this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
  }

  public formatMoney(value: number): string {
    return this.utilityService.formatMoney(value, this.maxCoverageCurrencyCode, 'en-US', 0);
  }

  public trackCountry(index, country: ICountry) {
    return country.CountryCode;
  }

  public restoreShipment(shipment: Package) {
    this.formGroup.patchValue(
      {
        country: shipment.ShipTo.Country,
        zipCode: shipment.ShipTo.Zip,
        city: shipment.ShipTo.City,
        state: shipment.ShipTo.State,
        address1: shipment.ShipTo.StreetAddress,
        address2: shipment.ShipTo.ApartmentSuite,
        address3: shipment.ShipTo.AdditionalAddressInformation,
        phone: shipment.ShipTo.TelephoneNo,
        email: shipment.ShipTo.Email,
      },
      { emitEvent: false });

    // [MV3-2629] Excludes email from being restored if shipment's NotifySender is false and user's APIDevStatus is 0
    // since ship-from email address will be set from the system in this case
    // and it can be different from what user has input when creating a shipment/label.
    // [MV3-2629] New decision: use the email address from the user object in this case.
    if (!shipment.NotifySender && (this.user && this.user.APIDevStatus === 0)) {
      this.formGroup.patchValue(
        {
          email: this.user.Email,
        },
        { emitEvent: false });
    }

    this.formGroup.patchValue({
      firstName: shipment.ShipTo.FirstName,
      lastName: shipment.ShipTo.LastName,
      company: shipment.ShipTo.CompanyName,
    });

    this.formGroup.markAsDirty();
    this.formGroup.markAsTouched();

    const formValue = this.formGroup.value;
    this.isValid.emit(this.formGroup.valid);
    this.shipmentService.saveShipTo(formValue);
    this.formValueChanged.emit(formValue);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../app.state';
import { NotifyTemplateService } from '../notify-templates/services/notify-template.service';
import {
    PaperlessCommercialInvoiceService,
} from '../shared/components/paperless-commercial-invoice/services/paperless-commercial-invoice.service';
import { CarrierService } from '../shared/services/carrier/carrier.service';
import { ShipConfigService } from './services/ship-config.service';
import { ShipmentService } from './services/shipment.service';

@Injectable()
export class ShipResolver {
    public constructor(private carrierService: CarrierService,
                       private shipmentService: ShipmentService,
                       private shipConfigService: ShipConfigService,
                       private notifyTemplateService: NotifyTemplateService,
                       private paperlessCommercialInvoiceService: PaperlessCommercialInvoiceService,
                       private readonly appState: AppState,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const carrierCode = route.paramMap.get('carrierCode');

        const requests = [
            of({
                user: this.appState.user$(),
                customer: this.appState.customer$(),
            }),
            this.carrierService.getCarrierKey(carrierCode),
            this.paperlessCommercialInvoiceService.getCustomerImages(),
            this.notifyTemplateService.getNotifyTemplates(),
            this.shipConfigService.getUserConfig(carrierCode),
            this.shipmentService.getShipmentLocations(true, carrierCode),
            this.shipmentService.getShipmentLocations(false, carrierCode),
            this.shipmentService.getShipFromCountries(carrierCode),
            this.shipmentService.getShipToCountries(carrierCode),
        ];

        const cachedShipment = localStorage.getItem('shipment-edit') || localStorage.getItem('reship');
        if (cachedShipment) {
            requests.push(of(JSON.parse(cachedShipment)));
        }

        return forkJoin(requests).pipe(
            map(
                responses => ({
                    user: (responses[0] as any).user,
                    customer: (responses[0] as any).customer,
                    carrierKey: responses[1][0],
                    customerImages: responses[2],
                    notifyTemplates: responses[3],
                    shipConfig: responses[4],
                    shipFromLocations: responses[5],
                    shipToLocations: responses[6],
                    shipFromCountries: responses[7],
                    shipToCountries: responses[8],
                    cachedShipment: responses[9],
                }),
            ),
        );
    }
}

<div class="location-search-table">
    <mat-table #table [dataSource]="dataSource">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="AddressDisplay">
            <mat-header-cell *matHeaderCellDef>{{'Address'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let location" [innerHtml]="location.AddressDisplay"></mat-cell>
        </ng-container>

        <ng-container matColumnDef="Distance">
            <mat-header-cell *matHeaderCellDef>{{'Distance'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let location">{{ location.Distance }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Hours">
            <mat-header-cell *matHeaderCellDef>{{'HoursOfOperation'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let location">
                {{ location.Hours }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LatestExpressDropOff">
            <mat-header-cell *matHeaderCellDef>{{'LatestExpressDropoff'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let location">
                {{location.LatestExpressDropOff}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let location">
                <div class="row-actions">
                    <button mat-raised-button type="button" (click)="selectLocation($event, location)">{{'Select'|translate}}</button>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppState } from '../../../app.state';
import { ICountry } from '../../../shared/models/country.interface';
import { UtilityService } from '../../../shared/services/utility/utility.service';

@Component({
    selector: 'upsc-ship-countries',
    templateUrl: './ship-countries.component.html',
    styleUrls: ['./ship-countries.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipCountriesComponent {
    @Input() public formGroup: UntypedFormGroup;
    @Input() public countryFormControlName: string;
    @Input() public countries: ICountry[];
    @Input() public maxCoverageCurrencyCode = 'USD';

    public constructor(private utilityService: UtilityService,
                       public readonly appState: AppState,
    ) {
    }
}

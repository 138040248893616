<div [formGroup]="formGroup" class="ship-info ups-form">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{ 'ShipmentInformation'|translate }}</h5>
        </div>

        <mat-form-field class="date-input">
            <mat-label>{{ 'ShipmentDate'|translate }}</mat-label>
            <input matInput
                   required
                   [matDatepicker]="picker"
                   [min]="getMinDate()"
                   [max]="getMaxDate()"
                   [matDatepickerFilter]="filterDates"
                   formControlName="shipDate">
            <span class="formatted-date" (click)="picker.open()">{{ formattedShipDate }}</span>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="!isMatDatepickerValid(formGroup.controls.shipDate)">
                <span *ngIf="formGroup.controls.shipDate?.errors?.required">{{ 'ShipmentDateRequired'|translate }}</span>
                <span *ngIf="formGroup.controls.shipDate?.errors?.matDatepickerMin">{{ 'MinimumShipmentDate'|translate }} {{ getMinDate('LL') }}</span>
                <span *ngIf="formGroup.controls.shipDate?.errors?.matDatepickerMax">{{ 'MaximumShipmentDate'|translate }} {{ getMaxDate('LL') }}</span>
            </mat-error>
        </mat-form-field>

        <div class="select-wrapper" *ngIf="shipmentReason && shipmentReason.show">
            <mat-form-field>
                <mat-label>{{ shipmentReason?.label }}</mat-label>
                <mat-select formControlName="shipmentReason" required>
                    <mat-option value="" disabled>{{ 'SelectShipmentReason' | translate }}</mat-option>
                    <mat-option *ngFor="let reason of shipmentReason?.items" [value]="reason._id">
                        {{ reason.label }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="shipmentReason && formGroup.controls.shipmentReason?.errors">
                    <span *ngIf="formGroup.controls.shipmentReason?.errors?.required">Shipment reason is required.</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="select-wrapper">
            <mat-form-field>
                <mat-label>{{ 'ServiceType'|translate }}</mat-label>
                <mat-select required formControlName="serviceType">
                    <mat-option value="" disabled selected>{{ 'SelectYourServiceType'|translate }}</mat-option>
                    <mat-option *ngFor="let serviceType of displayedServiceTypes" [value]="serviceType.ServiceCode">
                        {{ serviceType.ServiceCodeDesc }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="serviceTypes && formGroup.controls.serviceType?.errors?.required">
                    <span *ngIf="formGroup.controls.serviceType?.errors?.required">{{ 'ServiceTypeRequired'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <upsc-element-blocker *ngIf="isServiceTypeLoading"></upsc-element-blocker>
        </div>

        <div class="select-wrapper" [attr.disabled]="!serviceTypes || !serviceTypes.length">
            <mat-form-field>
                <mat-label>{{ 'PackageType'|translate }}</mat-label>
                <mat-select required formControlName="packageType">
                    <mat-option value="" disabled selected>{{ 'SelectPackageType'|translate }}</mat-option>
                    <mat-option *ngFor="let packageType of packageTypes" [value]="packageType.PackageTypeCode">
                        {{ packageType.PackageTypeDesc | translate}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="packageTypes && formGroup.controls.packageType?.errors?.required">
                    <span *ngIf="formGroup.controls.packageType?.errors?.required">{{ 'PackageTypeRequired'|translate }}</span>
                </mat-error>
            </mat-form-field>
            <upsc-element-blocker *ngIf="isPackageTypeLoading"></upsc-element-blocker>
        </div>

        <div class="package-dimension" *ngIf="isShowCustomPackageDimension">
            <label class="package-dimension-label mat-input-placeholder mat-form-field-placeholder">
                {{ 'PackageDimensions'|translate }}
                <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker">*</span>
            </label>
            <div class="dimensions">
                <mat-form-field>
                    <mat-label>{{ 'Length'|translate }}</mat-label>
                    <input matInput
                           required
                           type="text"
                           min="0"
                           step="1"
                           pattern="[0-9]+"
                           onfocus="this.select();"
                           formControlName="packageLength">
                    <mat-error *ngIf="formGroup.controls.packageLength?.errors">
                        <span *ngIf="formGroup.controls.packageLength?.errors?.required">{{ 'PackageLengthRequired'|translate }}</span>
                        <span *ngIf="formGroup.controls.packageLength?.errors?.pattern">{{ 'PackageLengthPattern'|translate }}</span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'Width'|translate }}</mat-label>
                    <input matInput
                           required
                           type="text"
                           min="0"
                           step="1"
                           pattern="[0-9]+"
                           onfocus="this.select();"
                           formControlName="packageWidth">
                    <mat-error *ngIf="formGroup.controls.packageWidth?.errors">
                        <span *ngIf="formGroup.controls.packageWidth?.errors?.required">{{ 'PackageWidthRequired'|translate }}</span>
                        <span *ngIf="formGroup.controls.packageWidth?.errors?.pattern">{{ 'PackageWidthPattern'|translate }}</span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'Height'|translate }}</mat-label>
                    <input matInput
                           required
                           type="text"
                           min="0"
                           step="1"
                           pattern="[0-9]+"
                           onfocus="this.select();"
                           formControlName="packageHeight">
                    <mat-error *ngIf="formGroup.controls.packageHeight?.errors">
                        <span *ngIf="formGroup.controls.packageHeight?.errors?.required">{{ 'PackageHeightRequired'|translate }}</span>
                        <span *ngIf="formGroup.controls.packageHeight?.errors?.pattern">{{ 'PackageHeightPattern'|translate }}</span>
                    </mat-error>
                </mat-form-field>
                <span>{{ dimensionUnit }}</span>
            </div>
            <p>{{ 'PackagesGreaterThan18x18x7p1'|translate }} <a href={{ssoURL}} (click)="generateZendeskLink()"
                                                                 target="_blank">
                {{ 'DimensionalWeight'|translate }}
            </a>{{ 'PackagesGreaterThan18x18x7p2'|translate }}</p>
        </div>

        <mat-form-field
                [subscriptSizing]="formGroup.controls.weight?.dirty && formGroup.controls.weight?.errors ? 'dynamic' : 'fixed'">
            <mat-label>{{ 'Weight'|translate }}</mat-label>
            <input matInput
                   required
                   type="text"
                   min="1"
                   max="{{ maxWeight }}"
                   pattern="[0-9]+"
                   onfocus="this.select();"
                   (blur)="onWeightBlur($event)"
                   autocomplete="off"
                   formControlName="weight">
            <span matSuffix>{{ weightUOM }}</span>
            <mat-error *ngIf="formGroup.controls.weight?.errors">
                <span *ngIf="formGroup.controls.weight?.errors?.required">{{ 'WeightRequired'|translate }}</span>
                <span *ngIf="formGroup.controls.weight?.errors?.min">{{ 'WeightMin' | translate: { weight: minWeight } }}</span>
                <span *ngIf="formGroup.controls.weight?.errors?.max">{{ 'WeightCannotExceed'|translate }}
                    <strong>{{ maxWeight }}.</strong></span>
                <span *ngIf="formGroup.controls.weight?.errors?.pattern">{{ 'WeightPattern'|translate }}</span>
                <span *ngIf="formGroup.controls.weight?.errors?.expectedValue">{{ 'WeightDiscrepancy'|translate: { weight: getFormattedTotalWeight() + ' ' + weightUOM } }}</span>
                <span *ngIf="formGroup.controls.weight?.errors?.notNumber">{{ 'WeightNotNumber' | translate }}</span>
                <span *ngIf="formGroup.controls.weight?.errors?.outOfRange">{{ 'WeightOutOfRange' | translate:{
                    min: minWeight,
                    max: maxWeight
                } }}</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ReferenceNo'|translate }}</mat-label>
            <input matInput
                   maxlength="35"
                   formControlName="referenceNumber">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'InvoiceNo'|translate }}</mat-label>
            <input matInput
                   maxlength="256"
                   formControlName="invoiceNumber">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'InsuredValue'|translate }}</mat-label>
            <input matInput
                   required
                   class="insurnce-value"
                   type="text"
                   min="0"
                   pattern="[0-9]+"
                   onfocus="this.select();"
                   autocomplete="off"
                   formControlName="insuredValue">
            <span matSuffix>{{ insuredValueCurrency }}</span>
            <img src="../../../assets/icons/help.svg"
                 alt="Help"
                 class="help-icon"
                 *ngIf="returnShipment"
                 [matTooltip]="'insuranceValueHelpText' | translate"
                 matTooltipPosition="left">
            <mat-error *ngIf="formGroup.controls.insuredValue?.errors">
                <span *ngIf="formGroup.controls.insuredValue?.errors?.required">{{ 'InsuredValueRequired'|translate }}</span>
                <span *ngIf="formGroup.controls.insuredValue?.errors?.min">{{ 'InsuredValueNegativeNotAllowed'|translate }}</span>
                <span *ngIf="formGroup.controls.insuredValue?.errors?.max">{{ 'InsuredValueCannotExceed'|translate }} {{ maxInsuredValue }}
                    .</span>
                <span *ngIf="formGroup.controls.insuredValue?.errors?.notInteger">{{ 'InsuredValueNotInteger'|translate }}</span>
            </mat-error>
        </mat-form-field>
    </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { PickupLocationMapComponent } from './components/map/pickup-location-map.component';
import { PickupLocationComponent } from './components/pickup-location/pickup-location.component';
import { PickupLocationSearchComponent } from './components/search/pickup-location-search.component';
import { PickupLocationService } from './pickup-location.service';
import { PickupLocationsComponent } from './pickup-locations.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        SharedModule,
        MatSliderModule,
    ],
    declarations: [
        PickupLocationsComponent,
        PickupLocationComponent,
        PickupLocationSearchComponent,
        PickupLocationMapComponent,
    ],
    providers: [
        PickupLocationService,
    ],
    exports: [PickupLocationsComponent, PickupLocationSearchComponent, PickupLocationComponent],
})
export class PickupLocationsModule {
}

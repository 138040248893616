import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UserTermsUseComponent } from '../../../account/user-settings/user-terms-use/user-terms-use.component';
import { StorageService } from '../../../core/services/storage/storage.service';
import { INotifyTemplate } from '../../../notify-templates/models/notify-template.interface';
import {
    NotifyTemplatePreviewDialogComponent,
} from '../../../notify-templates/notify-template-preview-dialog/notify-template-preview-dialog.component';
import { NotifyTemplateService } from '../../../notify-templates/services/notify-template.service';
import { Carriers } from '../../../shared/enum/general-enum';
import { ICountry } from '../../../shared/models/country.interface';
import { Customer } from '../../../shared/models/customer/customer.model';
import { Location } from '../../../shared/models/location/location.model';
import { NotificationType } from '../../../shared/models/notification-type';
import { PostalCode } from '../../../shared/models/postal-code.model';
import { DialogService } from '../../../shared/services/dialog/dialog.service';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { FormService } from '../../../shared/services/form/form.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { User } from '../../../shared/services/user/models/user.model';
import { UserService } from '../../../shared/services/user/user.service';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { ZipCodeService } from '../../../shared/services/zip-code/zip-code.service';
import {
    ResidentialAccessPointSuggestionDialogComponent,
} from '../../address-dialogs/residential-access-point-suggestion-dialog/residential-access-point-suggestion-dialog.component';
import { IAddressValidation } from '../../models/address-validation.interface';
import { ICheckRestrictedResponse } from '../../models/CheckRestrictedResponse.interface';
import { IEmailNotificationTemplate } from '../../models/email-notification-template.interface';
import { Package } from '../../models/package.model';
import { IShipComponent } from '../../models/ship-component.interface';
import { ShipConfig } from '../../models/ship-config.model';
import { ShipConfigService } from '../../services/ship-config.service';
import { ShipmentStateService } from '../../services/shipment-state.service';
import { ShipmentService } from '../../services/shipment.service';

@Component({
    selector: 'upsc-ship-to-edit',
    templateUrl: './ship-to-edit.component.html',
    styleUrls: ['./ship-to-edit.component.scss'],
})
export class ShipToEditComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
    @Input() location: Location;
    @Input() carrier: Carriers;
    @Input() user: User;
    @Input() customer: Customer;
    @Input() shipToCountryCode: string;
    @Input() shipFromCurrencyCode = 'USD';
    @Input() maxCoverageCurrencyCode = 'USD';
    @Input() restrictedCountryCodes: any;
    @Input() shouldDisplayEUFields = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() countryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();
    @Output() countryCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() isResidentialAddressChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() adultSignatureRequested: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() residentialCheckChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() addressValidationChanged: EventEmitter<IAddressValidation> = new EventEmitter<IAddressValidation>();
    @Output() forceClearAdultSignatureRequiredChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly environment = environment;
    public formGroup: UntypedFormGroup;
    public config: ShipConfig;
    public countries: ICountry[];
    public displayedCountries: ICountry[];
    public allCountries: ICountry[];
    public selectedCountry: ICountry;
    public isCountriesLoading = true;
    public isZipCodeValidating = false;
    public isTemplateLoading = false;
    public isFRITUser: boolean;
    public isParcelProNotificationEnabled = false;
    public emailTemplates: IEmailNotificationTemplate[] = [];
    public isFirstNameLastNameRequired = true;
    public isCompanyNameRequired = true;
    public isLoadingNotifyTemplate = false;
    public zipCodeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public shouldShowCreateNotifyTemplate = false;
    public residentialCheckTooltip: string;

    private NACountries = ['CA', 'MX', 'US'];

    private configSubscription: Subscription;

    private getShipToCountriesSubscription: Subscription;
    private getAddressByZipCodeSubscription: Subscription;
    private getEmailNotificationTemplatesSubscription: Subscription;

    private checkDoNotShipSubscription: Subscription;
    private isDoNotShipChecking = false;
    private isDoNotShipValid = false;

    private checkRestrictedSubscription: Subscription;
    private isRestrictedChecking = false;
    private isRestricted = false;

    private validateAddressSubscription: Subscription;
    private isValidatingAddress = false;
    private isResidentialAddress = false;
    private isInvalidAddress = false;
    private isRestrictedAddress = false;
    private shouldResetZip = true;
    private lastCheckAddress: any;
    private addressPayload: any;
    private isPickupLocationSelected = false;

    private getNotifyTemplateSubscription: Subscription;

    private term: any;
    private ngDestroyed$ = new Subject<void>();
    private isResidentialAccessPointSuggestionDialogOpen = false;
    private isAdultSignatureRequiredForResidentialAddressDialogOpen = false;

    constructor(private formBuild: UntypedFormBuilder,
                private notificationService: NotificationService,
                private utilityService: UtilityService,
                private errorHandlerService: ErrorHandlerService,
                private validationService: ValidationService,
                private dialogService: DialogService,
                private formService: FormService,
                private zipCodeService: ZipCodeService,
                private shipConfigService: ShipConfigService,
                private dialog: MatDialog,
                private userService: UserService,
                private notifyTemplateService: NotifyTemplateService,
                private storageService: StorageService,
                private shipmentService: ShipmentService,
                private shipState: ShipmentStateService,
                private route: ActivatedRoute,
    ) {
        this.route.params.subscribe((params) => {
            this.shipmentService.Quote.ShipTo.IsResidential = false;
            this.shipmentService.Quote.ShipToResidential = false;
        });

    
    }

    public ngOnDestroy() {
        this.ngDestroyed$.next(null);
        this.ngDestroyed$.complete();

        this.utilityService.clearSubscriptions([
            this.configSubscription,
            this.checkDoNotShipSubscription,
            this.checkRestrictedSubscription,
            this.getShipToCountriesSubscription,
            this.checkDoNotShipSubscription,
            this.getAddressByZipCodeSubscription,
            this.getEmailNotificationTemplatesSubscription,
            this.getNotifyTemplateSubscription,
        ]);
    }

    public ngOnInit() {
        this.formGroup = this.formBuild.group({
            contactId: [''],
            firstName: ['', Validators.compose([Validators.required, Validators.maxLength(15)])],
            lastName: ['', Validators.compose([Validators.required, Validators.maxLength(19)])],
            company: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
            zipCode: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
            city: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
            state: ['', Validators.compose([Validators.maxLength(35)])],
            country: [this.shipToCountryCode, Validators.compose([Validators.required, Validators.maxLength(2)])],
            address1: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
            address2: ['', Validators.compose([Validators.maxLength(35)])],
            address3:['', Validators.compose([Validators.maxLength(35)])],            
            email: ['', Validators.compose([this.validationService.emailFormatValidator(), Validators.maxLength(100)])],
            emailShipmentNotification: [false],
            emailExceptionNotification: [false],
            emailDeliveryNotification: [false],
            emailParcelProNotification: [false],
            parcelProEmailTemplate: [''],
            parcelProEmailPackageContent: [''],
            parcelProEmailPhoto: [''],
            phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10)])],
            // notifyRecipient: [false],
            residentialCheck: [false],
            updateAddress: [false],
            addExpress: [false],
            vat: [''],
            taxId: [''],
            eori: [''],
        });

        this.monitorValueChanges();
        this.setFormValues();

        this.utilityService.clearSubscriptions([this.configSubscription]);
        this.configSubscription = this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }
                this.config = config;
                // console.log(this.config.isSendCustomEmailToRecipient);
                // TODO: BUG: Find out why calling without a delay (even if it's 0ms) breaks the express contact selection!
                this.utilityService.delay(
                    () => {
                        this.setValuesFromConfig();
                    },
                    0);
            },
        );

        this.userService.getTermsSigned()
            .pipe(takeWhile(() => !this.term))
            .subscribe(
                (term) => {
                    this.term = term;
                },
                (err) => {
                    this.notificationService.notify(
                        this.errorHandlerService.getHttpErrorMessage(err),
                        'Error Loading Terms',
                        NotificationType.ERROR);
                });

        this.shipmentService.accessPointSelected$.subscribe(
            (isSelected) => {
                this.isPickupLocationSelected = isSelected;

                if (!isSelected) {
                    this.formGroup?.get('residentialCheck').enable();

                    this.formGroup.patchValue({
                        residentialCheck: this.isResidentialAddress,
                    });

                    return;
                }

                this.formGroup.patchValue({
                    residentialCheck: false,
                });

                this.formGroup?.get('residentialCheck').disable();
            },
        );

        this.shipmentService.fedexHoldAtLocationSelected$.subscribe(
            (isSelected) => {
                this.isPickupLocationSelected = isSelected;

                if (!isSelected) {
                    this.formGroup?.get('residentialCheck').enable();

                    this.formGroup.patchValue({
                        residentialCheck: this.isResidentialAddress,
                    });

                    return;
                }

                this.formGroup.patchValue({
                    residentialCheck: false,
                });

                this.formGroup?.get('residentialCheck').disable();
            },
        );

        this.shipmentService.requestShipToAddressValidation$
            .pipe(
                takeUntil(this.ngDestroyed$),
            )
            .subscribe(
                (shouldValidate) => {
                    if (!shouldValidate) {
                        return;
                    }

                    if (this.formGroup?.get('country')?.value === 'US' && !!this.formGroup?.get('zipCode')?.value) {
                        this.isValidatingAddress = true;
                        this.validateAddressSubscription = this.shipmentService.validateAddress(this.addressPayload, this.carrier)
                                                               .subscribe(
                                                                   addressValidation => this.validateAddressSuccess(addressValidation),
                                                                   err => this.validateAddressFailure(err),
                                                               );
                    }
                },
            );

        this.shipmentService.shippingUpdateInformationChanged$
            .pipe(
                takeUntil(this.ngDestroyed$),
            )
            .subscribe(
                (data) => {
                    if (!data || !data?.recipients?.length) {
                        this.formGroup.patchValue({
                            emailShipmentNotification: false,
                        });

                        this.shipmentService.saveShipTo(this.formGroup.value);
                        return;
                    }

                    const recipient = data.recipients[0];

                    this.formGroup.patchValue({
                        firstName: recipient.firstName,
                        lastName: recipient.lastName,
                        email: recipient.email,
                        emailShipmentNotification: true,
                    });

                    this.shipmentService.saveShipTo(this.formGroup.value);
                },
            );
            
            this.isFRITUser =  this.userService.isFRITUser(this.user)
            
        this.getEmailNotificationTemplates();
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onLocationChanged(changes['location']);
        this.onCarrierChanged(changes['carrier']);
        this.onUserChanged(changes['user']);
        this.onShipToCountryCodeChanged(changes['shipToCountryCode']);
        this.onRestrictedShipToCountryCodesChanged(changes['restrictedCountryCodes']);
        this.onShipFromCurrencyCodeChanged(changes['shipFromCurrencyCode']);
        this.onCustomerChanged(changes['customer']);
    }

    public isFedEx() {
        return +Carriers[this.carrier] === Carriers.Fedex;
    }

    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Ship To');
    }

    public isFormValid() {
        if (!this.formGroup) {
            return true;
        }

        return this.formGroup.valid && this.isDoNotShipValid; // && this.isRestricted;
    }

    public shouldRequireStateProvince() {
        const country = this.formGroup.controls.country.value;
        if (!country) {
            return false;
        }

        return this.NACountries.includes(country);
    }

    public validateAddress() {
        if (!this.formGroup) {
            return;
        }

        this.shipmentService.shipToZipRestrictionCheckRequested$.next(true);

        const city = this.formGroup.controls.city.value;
        const company = this.formGroup.controls.company.value;
        const country = this.formGroup.controls.country.value;
        const state = this.formGroup.controls.state.value;
        const streetAddress = this.formGroup.controls.address1.value;
        const apartmentSuite = this.formGroup.controls.address2.value;
        const additionalAddressInformation = this.formGroup.controls.address3.value;
        const zipCode = this.formGroup.controls.zipCode.value;

        const address = {
            City: city,
            Country: country,
            State: state,
            StreetAddress: streetAddress,
            ApartmentSuite: apartmentSuite,
            Zip: zipCode,
            additionalAddressInformation: additionalAddressInformation
        };

        if (!this.utilityService.isObjectsDifferent(this.lastCheckAddress, address)) {
            return;
        }

        this.lastCheckAddress = address;

        const isInvalidAddress = [city, country, state, streetAddress, zipCode].some(x => ['', null, undefined].includes(x));
        if (isInvalidAddress) {
            return;
        }

        if (country !== 'US') {
            return;
        }

        if (this.validateAddressSubscription) {
            this.validateAddressSubscription.unsubscribe();
            this.validateAddressSubscription = null;
        }

        this.isValidatingAddress = true;
        this.addressPayload = {
            City: city,
            Country: country,
            State: state,
            StreetAddress: streetAddress,
            CompanyName: company,
            ApartmentSuite: apartmentSuite === null ? '' : apartmentSuite,
            Zip: zipCode,
        };

        this.validateAddressSubscription = this.shipmentService.validateAddress(this.addressPayload, this.carrier)
                                               .subscribe(
                                                   addressValidation => this.validateAddressSuccess(addressValidation),
                                                   err => this.validateAddressFailure(err),
                                               );
    }

    public resetForm() {
        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            country: this.shipToCountryCode,
            emailShipmentNotification: false,
            emailExceptionNotification: false,
            emailDeliveryNotification: false,
            emailParcelProNotification: false,
            parcelProEmailTemplate: '',
            parcelProEmailPackageContent: '',
            parcelProEmailPhoto: '',
            residentialCheck: false,
            updateAddress: false,
            addExpress: false,
        });
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public formatMoney(value: number): string {
        return this.utilityService.formatMoney(value, this.maxCoverageCurrencyCode, 'en-US', 0);
    }

    public trackCountry(index, country: ICountry) {
        return country.CountryCode;
    }

    public openTermsOfUseDialog(event) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '70%',
            data: {
                termsofUse: this.term.TermsUsage,
            },
        };

        const dialogRef = this.dialog.open(UserTermsUseComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    public previewTemplate(event, templateId: string) {
        if (event) {
            event.preventDefault();
        }

        if (!templateId) {
            return;
        }

        if (!this.emailTemplates) {
            this.isLoadingNotifyTemplate = true;
            this.utilityService.clearSubscriptions([this.getNotifyTemplateSubscription]);
            this.getNotifyTemplateSubscription = this.notifyTemplateService.getNotifyTemplate(templateId)
                                                     .subscribe(
                                                         template => this.handleGetNotifyTemplateSuccess(template),
                                                         err => this.handleGetNotifyTemplateFailure(err),
                                                     );

            return;
        }

        const targetTemplate = this.emailTemplates.find(item => item.TemplateId === templateId);
        this.showPreview(targetTemplate);
    }

    //#endregion

    public fillCostEstimateValues(cachedCostEstimate: any) {
        this.formGroup.patchValue({
            country: cachedCostEstimate.ShipToCountry,
            zipCode: cachedCostEstimate.PostalCode,
            residentialCheck: cachedCostEstimate.ShipToResidential,
        });

        this.formGroup.controls.country.markAsDirty();
        this.formGroup.controls.country.markAsTouched();

        this.formGroup.controls.zipCode.markAsDirty();
        this.formGroup.controls.zipCode.markAsTouched();

        this.formGroup.controls.residentialCheck.markAsDirty();
        this.formGroup.controls.residentialCheck.markAsTouched();
    }

    public restoreShipment(shipment: Package) {
        const shipTo = shipment.ShipTo;
        this.formGroup.patchValue(
            {
                contactId: shipTo.ContactId,
                zipCode: shipTo.Zip,
                city: shipTo.City,
                state: shipTo.State || shipTo.ProvinceRegion,
                address1: shipTo.StreetAddress,
                address2: shipTo.ApartmentSuite,
                address3: shipTo.AdditionalAddressInformation,
                email: shipTo.Email,
                phone: shipTo.TelephoneNo,
                emailShipmentNotification: shipment.NotifyRecipient,
                emailExceptionNotification: shipment.NotifyExceptionRecipient,
                emailDeliveryNotification: shipment.NotifyDeliveryRecipient,
                // parcelProEmailPhoto: shipment.Photo,
                residentialCheck: shipment.ShipToResidential,
                updateAddress: shipment.UpdateAddressBook,
                addExpress: shipment.AddToExpress,
            },
            { emitEvent: false });

        this.formService.markAllAsTouchedAndDirty(this.formGroup);

        this.formGroup.patchValue({
            firstName: shipTo.FirstName,
            lastName: shipTo.LastName,
            company: shipTo.CompanyName,
            country: shipTo.Country,
            emailParcelProNotification: shipment.NotifyCustomShipmentRecipient,
            parcelProEmailTemplate: shipment.TemplateID,
            parcelProEmailPackageContent: shipment.PackageContent,
        },
        { emitEvent: false });

        this.formGroup.controls.emailParcelProNotification.markAsTouched();
        this.formGroup.controls.emailParcelProNotification.markAsDirty();

        const formValue = this.formGroup.value;
        this.isValid.emit(this.formGroup.valid);
        this.shipmentService.saveShipTo(formValue);
        this.formValueChanged.emit(formValue);

        this.shipState.shipToCountryCode.set(shipment?.ShipTo?.Country?.toUpperCase());

        if (this.countries?.length) {
            this.handleShipToCountry(shipment?.ShipTo?.Country);
        } else {
            this.getShipToCountries(this.carrier).subscribe(
                () => {
                    this.handleShipToCountry(shipment?.ShipTo?.Country);
                },
            );
        }
    }

    private handleShipToCountry(countryCode: string): void {
        if (!countryCode) {
            return;
        }

        this.selectedCountry = this.countries?.find(country => country.CountryCode === countryCode);
        if (this.selectedCountry) {
            if (this.selectedCountry.IsPostalCodeAware) {
                this.formGroup.controls.zipCode.enable();
                this.validationService.setFormControlValidators(
                    this.formGroup.controls.zipCode,
                    Validators.compose([Validators.required, Validators.maxLength(10)]),
                );

                this.countryChanged.emit(this.selectedCountry);
            } else {
                // this.formGroup.controls.zipCode.disable();
                this.validationService.setFormControlValidators(
                    this.formGroup.controls.zipCode,
                    Validators.compose([Validators.maxLength(10)]),
                );

                this.isRestricted = false;
                this.isDoNotShipValid = true;
            }
            this.shipConfigService.setPostalCodeAware(this.selectedCountry);
        }
    }

    public openAdultSignatureRequiredForResidentialAddressDialog(): void {
        if (this.isAdultSignatureRequiredForResidentialAddressDialogOpen) {
            return;
        }

        this.isAdultSignatureRequiredForResidentialAddressDialogOpen = true;
        this.dialogService.confirm(
            'Adult Signature is required',
            'For a residential address, an adult signature is required.<br>' +
            'Would you like us to select this option for you?',
        )
            .pipe(
                finalize(() => {
                    this.isAdultSignatureRequiredForResidentialAddressDialogOpen = false;
                }),
            )
            .subscribe(
                (isAutoChecked) => {
                    if (isAutoChecked) {
                        this.adultSignatureRequested.emit(true);
                    }
                },
            );
    }

    public setIsResidential(isResidential: boolean): void {
        if (this.isPickupLocationSelected) {
            this.formGroup.get('residentialCheck')?.setValue(false);
            return;
        }

        this.formGroup.get('residentialCheck')?.setValue(isResidential);
    }

    public openResidentialAccessPointSuggestionDialog(): void {
        if (this.isResidentialAccessPointSuggestionDialogOpen) {
            return;
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            panelClass: 'mobile-fullscreen-dialog',
            // data: {},
        };

        const dialogRef = this.dialog.open(ResidentialAccessPointSuggestionDialogComponent, dialogConfig);
        this.isResidentialAccessPointSuggestionDialogOpen = true;
        dialogRef.afterClosed()
                 .pipe(
                     finalize(() => {
                         this.isResidentialAccessPointSuggestionDialogOpen = false;
                     }),
                 )
                 .subscribe((option) => {
                     if (!option) {
                         return;
                     }

                     switch (option) {
                         case 'accessPoint':
                             this.shipmentService.accessPointSelectorRequested$.next(true);
                             break;
                         case 'residential':
                             this.openAdultSignatureRequiredForResidentialAddressDialog();
                             break;
                         default:
                             break;
                     }
                 });
    }

    private setValuesFromConfig() {
        // [MV3-1958][MV3-1959] Disable setting Parcel Pro Notify value from config if the field is dirty.
        if (!this.formGroup.controls.emailParcelProNotification.dirty || !this.formGroup.controls.emailParcelProNotification.touched) {
            this.formGroup.controls.emailParcelProNotification.setValue(this.config.isSendCustomEmailToRecipient);
        }

        // [MV3-1959] Uncheck email notification checkboxes upon hidden.
        if (!this.config.showNotifyRecipient) {
            this.formGroup.patchValue({
                emailShipmentNotification: false,
                emailExceptionNotification: false,
                emailDeliveryNotification: false,
            });
        }
    }

    private onLocationChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.updateLocation(change.currentValue);
        this.validateAddress();
    }

    private onUserChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.shouldShowCreateNotifyTemplate = !this.userService.isDEGBUser(change.currentValue);
    }

    private onCustomerChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }
    }

    private onShipToCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        if (this.formGroup) {
            this.formGroup.controls.country.setValue(change.currentValue);
        }
    }

    private onShipFromCurrencyCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.displayedCountries = _.cloneDeep(this.countries);
    }

    private updateLocation(location: Location) {
        if (!this.formGroup) {
            return;
        }

        this.shouldResetZip = false;
        this.formGroup.patchValue(
            {
                contactId: location.ContactId,
                firstName: location.FirstName,
                lastName: location.LastName,
                company: location.CompanyName,
                zipCode: location.Zip,
                city: location.City,
                state: location.State,
                country: location.Country,
                address1: location.StreetAddress,
                address2: location.ApartmentSuite,
                address3: location.AdditionalAddressInformation,
                email: location.Email,
                phone: location.TelephoneNo,

                vat: '',
                taxId: '',
                eori: '',
            },
            { onlySelf: true, emitEvent: true });

        this.selectedCountry = this.countries.find(country => country.CountryCode === location.Country);
        this.countryCodeChanged.emit(location.Country);
        this.zipCodeChanged.emit(location.Zip);
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.carrier = this.utilityService.getEnumValue(Carriers, change.currentValue);
        this.loadCarrierCountries(this.carrier);
    }

    private loadCarrierCountries(carrier: Carriers) {
        if (!carrier) {
            return;
        }

        // const carrierCountries = this.storageService.get('carrier-countries');
        // if (carrierCountries) {
        //     this.handleGetShipToCountriesSuccess(carrierCountries);
        //     return;
        // }

        if (this.getShipToCountriesSubscription) {
            this.getShipToCountriesSubscription.unsubscribe();
            this.getShipToCountriesSubscription = null;
        }

        this.getShipToCountries(carrier).subscribe();
    }

    private getShipToCountries(carrier: Carriers | string): Observable<ICountry[]> {
        this.isCountriesLoading = true;
        return this.shipmentService.getShipToCountries(carrier)
                   .pipe(
                       takeUntil(this.ngDestroyed$),
                       catchError((err) => {
                           this.handleGetShipToCountriesFailure(err);
                           return of(null);
                       }),
                       finalize(() => {
                           this.isCountriesLoading = false;
                       }),
                       tap(countries => this.handleGetShipToCountriesSuccess(countries)),
                   );
    }

    //#region GetShipToCountries handlers
    private handleGetShipToCountriesSuccess(countries: ICountry[]) {
        if (!countries?.length) {
            return;
        }

        this.countries = _.sortBy(countries, ['CountryName', 'MaxCoverage']);
        this.allCountries = this.countries;
        // this.storageService.set('carrier-countries', this.countries);

        this.displayedCountries = _.cloneDeep(this.countries);

        this.utilityService.delay(() => {
            this.isCountriesLoading = false;
        });

        if (this.shipmentService.Quote) {
            this.selectedCountry = this.countries.find(country => country.CountryCode === this.shipmentService.Quote.ShipTo.Country);

            if (this.formGroup) {
                this.formGroup.controls.country.setValue(this.shipmentService.Quote.ShipTo.Country);
            }
        }

        this.filterCountries();
    }

    private handleGetShipToCountriesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed loading country list',
            NotificationType.ERROR);
        this.utilityService.delay(() => {
            this.isCountriesLoading = false;
        });
    }

    private onRestrictedShipToCountryCodesChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.shouldResetZip = false;
        this.filterCountries();
    }

    private filterCountries() {
        // If no restriction, allow all countries.
        if (!this.countries ||
            !this.restrictedCountryCodes ||
            (!this.restrictedCountryCodes.availableCountryCodes.length && !this.restrictedCountryCodes.forbiddenCountryCodes.length)) {
            return;
        }

        this.countries = this.allCountries.filter(
            (country) => {
                const isNoRestriction = !this.restrictedCountryCodes.availableCountryCodes ||
                    !this.restrictedCountryCodes.availableCountryCodes.length;
                if (!this.restrictedCountryCodes || isNoRestriction) {
                    return true;
                }

                return this.restrictedCountryCodes.availableCountryCodes.includes(country.CountryCode);
            },
        );

        this.countries = this.countries.filter(
            (country) => {
                const isNoRestriction = !this.restrictedCountryCodes.forbiddenCountryCodes ||
                    !this.restrictedCountryCodes.forbiddenCountryCodes.length;
                if (!this.restrictedCountryCodes || isNoRestriction) {
                    return true;
                }

                return !this.restrictedCountryCodes.forbiddenCountryCodes.includes(country.CountryCode);
            },
        );

        this.displayedCountries = _.cloneDeep(this.countries);
        if (this.countries && this.countries.length) {
            this.formGroup.patchValue({
                country: this.shipToCountryCode || this.countries[0].CountryCode,
            });
        }
    }

    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        this.formGroup.patchValue({
            contactId: this.shipmentService.Quote.ShipTo.ContactId,
            firstName: this.shipmentService.Quote.ShipTo.FirstName,
            lastName: this.shipmentService.Quote.ShipTo.LastName,
            company: this.shipmentService.Quote.ShipTo.CompanyName,
            zipCode: this.shipmentService.Quote.ShipTo.Zip,
            city: this.shipmentService.Quote.ShipTo.City,
            state: this.shipmentService.Quote.ShipTo.State,
            country: this.shipmentService.Quote.ShipTo.Country || this.shipToCountryCode,
            address1: this.shipmentService.Quote.ShipTo.StreetAddress,
            address2: this.shipmentService.Quote.ShipTo.ApartmentSuite,
            address3: this.shipmentService.Quote.ShipTo.AdditionalAddressInformation,
            email: this.shipmentService.Quote.ShipTo.Email,
            emailShipmentNotification: this.shipmentService.Quote.NotifyRecipient,
            emailExceptionNotification: this.shipmentService.Quote.NotifyExceptionRecipient,
            emailDeliveryNotification: this.shipmentService.Quote.NotifyDeliveryRecipient,
            emailParcelProNotification: this.shipmentService.Quote.NotifyCustomShipmentRecipient,
            parcelProEmailTemplate: this.shipmentService.Quote.TemplateID,
            parcelProEmailPackageContent: this.shipmentService.Quote.PackageContent,
            // parcelProEmailPhoto: this.shipmentService.Quote.Photo,
            phone: this.shipmentService.Quote.ShipTo.TelephoneNo,
            // notifyRecipient: this.shipmentService.Quote.NotifyRecipient,
            residentialCheck: this.shipmentService.Quote.ShipTo.IsResidential || this.shipmentService.Quote.ShipToResidential,
            updateAddress: this.shipmentService.Quote.UpdateAddressBook,
            addExpress: this.shipmentService.Quote.AddToExpress,

            vat: this.shipmentService.Quote.ShipToVAT,
            taxId: this.shipmentService.Quote.ShipToTaxID,
            eori: this.shipmentService.Quote.ShipToEORI,
        });

        this.formService.markAllAsTouchedAndDirty(this.formGroup);
        this.isValid.emit(this.formGroup.valid);
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges.subscribe((form) => {
            this.isValid.emit(this.formGroup.valid);
            this.shipmentService.saveShipTo(form);
            this.formValueChanged.emit(form);
        });

        this.formGroup.controls.firstName.valueChanges
            .subscribe(
                (value) => {
                    this.updateAlternateValidators();
                },
            );

        this.formGroup.controls.lastName.valueChanges
            .subscribe(
                (value) => {
                    this.updateAlternateValidators();
                },
            );

        this.formGroup.controls.company.valueChanges
            .subscribe(
                (value) => {
                    this.updateAlternateValidators();
                },
            );

        this.formGroup.controls.country.valueChanges
            .subscribe(
                (value) => {
                    this.shipState.shipToCountryCode.set(value);
                    if (this.shouldResetZip) {
                        this.formGroup.patchValue(
                            {
                                zipCode: '',
                                city: '',
                                state: '',
                                vat: '',
                                taxId: '',
                                eori: '',
                            },
                            { onlySelf: true });
                    }

                    this.zipCodeChanged.emit('');

                    this.shouldResetZip = true;

                    this.checkDoNotShip();
                    this.validateAddress();

                    this.validationService.clearFormControlValidators([
                        this.formGroup.controls.state,
                    ]);

                    if (this.NACountries.includes(value)) {
                        this.validationService.setFormControlValidators(
                            this.formGroup.controls.state,
                            Validators.compose([Validators.required, Validators.maxLength(35)]),
                        );
                    }

                    if (!this.allCountries) {
                        return;
                    }

                    this.selectedCountry = this.allCountries.find(country => country.CountryCode === value);
                    if (this.selectedCountry) {
                        if (this.selectedCountry.IsPostalCodeAware) {
                            this.formGroup.controls.zipCode.enable();
                            this.validationService.setFormControlValidators(
                                this.formGroup.controls.zipCode,
                                Validators.compose([Validators.required, Validators.maxLength(10)]),
                            );

                            this.countryChanged.emit(this.selectedCountry);
                        } else {
                            // this.formGroup.controls.zipCode.disable();
                            this.validationService.setFormControlValidators(
                                this.formGroup.controls.zipCode,
                                Validators.compose([Validators.maxLength(10)]),
                            );

                            this.isRestricted = false;
                            this.isDoNotShipValid = true;
                        }
                        this.shipConfigService.setPostalCodeAware(this.selectedCountry);
                    }

                    // console.log(this.formGroup.controls.zipCode.validator({} as AbstractControl));
                    this.ifInternationalOrDomesticShipment();
                    this.countryCodeChanged.emit(value);
                },
            );

        this.formGroup.controls.zipCode.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged())
            .subscribe(
                (value) => {
                    if (value?.length < 5) {
                        return;
                    }

                    this.checkDoNotShip();
                    this.checkRestricted();

                    if (!value || (value && !this.isZipCodeValid(value))) {
                        return;
                    }

                    this.zipCodeChanged.emit(value);

                    const addressLookupCountries = ['US', 'CA', 'PR', 'VI', 'GU'];
                    if (!this.selectedCountry || !addressLookupCountries.includes(this.selectedCountry.CountryCode)) {
                        return;
                    }

                    if (this.getAddressByZipCodeSubscription) {
                        this.getAddressByZipCodeSubscription.unsubscribe();
                        this.getAddressByZipCodeSubscription = null;
                    }

                    this.isZipCodeValidating = true;
                    this.getAddressByZipCodeSubscription = this.zipCodeService.getAddressByZipCode(value)
                                                               .subscribe(
                                                                   result => this.handleZipCodeValidationSuccess(result),
                                                                   err => this.handleZipCodeValidationFailure(err),
                                                               );
                },
            );

        this.formGroup.controls.emailParcelProNotification.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
            )
            .subscribe(
                (value) => {
                    this.isParcelProNotificationEnabled = value;

                    this.validationService.clearFormControlValidators([
                        this.formGroup.controls.parcelProEmailTemplate,
                        this.formGroup.controls.parcelProEmailPackageContent,
                        this.formGroup.controls.parcelProEmailPhoto,
                    ]);

                    this.formGroup.controls.parcelProEmailTemplate.markAsUntouched();
                    this.formGroup.controls.parcelProEmailPackageContent.markAsUntouched();
                    this.formGroup.controls.parcelProEmailPhoto.markAsUntouched();

                    if (!value) {
                        return;
                    }

                    this.getEmailNotificationTemplates();

                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.parcelProEmailTemplate,
                        Validators.compose([Validators.required]),
                    );

                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.parcelProEmailPackageContent,
                        Validators.compose([Validators.required]),
                    );
                },
            );

        this.formGroup.controls.residentialCheck.valueChanges
            .subscribe(
                (value) => {
                    this.shipConfigService.Config.isCheckResidential = value;
                    this.residentialCheckChanged.emit(value);
                },
            );

        this.formGroup.controls.updateAddress.valueChanges
            .subscribe(
                (value) => {
                    if (value) {
                        return;
                    }

                    this.formGroup.controls.addExpress.setValue(false);
                },
            );

        this.formGroup.controls.addExpress.valueChanges
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    this.formGroup.controls.updateAddress.setValue(true);
                },
            );
    }

    private getEmailNotificationTemplates(): void {
        this.utilityService.clearSubscriptions([this.getEmailNotificationTemplatesSubscription]);
        this.isTemplateLoading = true;
        this.getEmailNotificationTemplatesSubscription = this.notifyTemplateService.getNotifyTemplates()
                                                             .pipe(
                                                                 catchError((err) => {
                                                                     this.handleGetEmailNotificationTemplateFailure(err);
                                                                     return of(null);
                                                                 }),
                                                             )
                                                             .subscribe(
                                                                 templates => this.handleGetEmailNotificationTemplateSuccess(templates),
                                                             );
    }

    private ifInternationalOrDomesticShipment(): void {
        const firstName = this.formGroup.controls.firstName.value;
        const lastName = this.formGroup.controls.lastName.value;
        const companyName = this.formGroup.controls.company.value;

        let shipToCountry = this.shipmentService.Quote.ShipTo.Country;
        let shipFromCountry = this.shipmentService.Quote.ShipFrom.Country

        if (shipToCountry == shipFromCountry) { // Domestic
            this.isCompanyNameRequired = (!firstName && !lastName) || (!!companyName && (!firstName || !lastName));    
            this.isFirstNameLastNameRequired = !companyName || (!!firstName && !!lastName && !!companyName);
        }

        else { // International
            this.isCompanyNameRequired = true;
            this.isFirstNameLastNameRequired = true;
        }
    }

    private updateAlternateValidators() {
        // [MV3-1427] A user can enter either (First Name and Last Name) or Company Name.
        // Company Name is not required if both First Name and Last Name are present. 
        // (MV3-6320) Only applies to Domestic Shipments. InternationalShipments require both
        this.ifInternationalOrDomesticShipment();

        if (this.isFirstNameLastNameRequired) {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.firstName,
                Validators.compose([Validators.required, Validators.maxLength(15)]),
                { emitEvent: false });
            this.validationService.setFormControlValidators(
                this.formGroup.controls.lastName,
                Validators.compose([Validators.required, Validators.maxLength(20)]),
                { emitEvent: false });
        } else {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.firstName,
                Validators.compose([Validators.maxLength(15)]),
                { emitEvent: false });

            this.validationService.setFormControlValidators(
                this.formGroup.controls.lastName,
                Validators.compose([Validators.maxLength(20)]),
                { emitEvent: false });
        }

        if (this.isCompanyNameRequired) {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.company,
                Validators.compose([Validators.required, Validators.maxLength(35)]),
                { emitEvent: false });
        } else {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.company,
                Validators.compose([Validators.maxLength(35)]),
                { emitEvent: false });
        }
    }

    private checkDoNotShip() {
        if (!this.formGroup) {
            return;
        }

        const zipCode = this.formGroup.controls.zipCode.value;
        const countryCode = this.formGroup.controls.country.value;

        if (!this.carrier || !zipCode || !countryCode) {
            return;
        }

        if (this.checkDoNotShipSubscription) {
            this.checkDoNotShipSubscription.unsubscribe();
            this.checkDoNotShipSubscription = null;
        }

        this.isDoNotShipChecking = true;
        this.checkDoNotShipSubscription = this.shipmentService.checkDoNotShip(zipCode, this.carrier, countryCode)
                                              .subscribe(
                                                  isInDoNotShipList => this.handleCheckDoNotShipSuccess(isInDoNotShipList),
                                                  err => this.handleCheckDoNotShipFailure(err),
                                              );
    }

    private handleCheckDoNotShipSuccess(isInDoNotShipList: boolean) {
        if (isInDoNotShipList) {
            this.notificationService.notify(
                'Shipments are not allowed to this zip code.',
                'Do Not Ship',
                NotificationType.ERROR);
        }

        this.isDoNotShipValid = !isInDoNotShipList;
        this.isDoNotShipChecking = false;
    }

    private handleCheckDoNotShipFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed checking do-not-ship',
            NotificationType.ERROR);
        this.isDoNotShipChecking = false;
    }

    private checkRestricted() {
        if (!this.formGroup) {
            return;
        }

        const zipCode = this.formGroup.controls.zipCode.value;
        const insuredValue = this.shipmentService.Quote.InsuredValue;
        const serviceType = this.shipmentService.Quote.ServiceCode;

        if (!this.carrier || !zipCode || !insuredValue || !serviceType) {
            return;
        }

        if (this.shipmentService.Quote.ShipTo.Country !== 'US') {
            return;
        }

        if (this.checkRestrictedSubscription) {
            this.checkRestrictedSubscription.unsubscribe();
            this.checkRestrictedSubscription = null;
        }

        this.isRestrictedChecking = true;
        this.checkRestrictedSubscription = this.shipmentService.checkRestricted(zipCode, this.carrier, insuredValue, serviceType)
                                               .subscribe(
                                                   result => this.handleCheckRestrictedSuccess(result),
                                                   err => this.handleCheckRestrictedFailure(err),
                                               );
    }

    private handleCheckRestrictedSuccess(result: ICheckRestrictedResponse) {
        if (result.IsRestricted) {
            const displayMessage = result.Messages.map(message => message.Message).join(',');
            this.notificationService.notify(
                displayMessage,
                'Restricted',
                NotificationType.ERROR);
        }

        this.isRestricted = result.IsRestricted;
        this.isRestrictedChecking = false;
    }

    private handleCheckRestrictedFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed checking restricted',
            NotificationType.ERROR);
        this.isRestrictedChecking = false;
    }

    private handleGetEmailNotificationTemplateSuccess(templates: INotifyTemplate[]) {
        this.emailTemplates = <any>_.sortBy(templates, ['TemplateName', 'TemplateId']);
        this.utilityService.delay(() => {
            this.isTemplateLoading = false;
        });
    }

    private handleGetEmailNotificationTemplateFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting email notification templates',
            NotificationType.ERROR,
        );
        this.utilityService.delay(() => {
            this.isTemplateLoading = false;
        });
    }

    /**
     * Client-side validation of a zip code.
     * @returns {boolean}
     */
    private isZipCodeValid(zipCode: string): boolean {
        if (!this.selectedCountry) {
            return false;
        }

        // TODO: set validation for zip code regexp. Look out for the postal prefix. The digit number doesn't include it.
        const zipCodeRegExp = new RegExp(`^[a-z0-9]\{${ this.selectedCountry.PostalCodeDigits }\}$`, 'i');

        return zipCodeRegExp.test(zipCode);
    }

    private handleZipCodeValidationSuccess(zipCode: PostalCode) {
        this.formGroup.patchValue({
            city: zipCode.City,
            state: zipCode.State,
            // country: zipCode.Country,
        });

        this.utilityService.delay(() => {
            this.isZipCodeValidating = false;
        });

        this.validateAddress();
    }

    private handleZipCodeValidationFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed validating a zip code',
            NotificationType.ERROR);
        this.utilityService.delay(() => {
            this.isZipCodeValidating = false;
        });
    }

    private validateAddressSuccess(addressValidation: IAddressValidation) {
        this.addressValidationChanged.emit(addressValidation);
        this.isValidatingAddress = false;

        // TODO: If the AddressType is 1 (residential), tell the user that "Check if this is residential address"
        //  is going to be selected and ask him that if he wants the system to select "adult signature" for him.
        this.isResidentialAddress = addressValidation.AddressType === 1;
        this.isInvalidAddress = addressValidation.AddressType === 0;
        this.isRestrictedAddress = addressValidation.IsRestricted;

        this.isResidentialAddressChanged.emit(this.isResidentialAddress);

        const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
        const isEditMode = !!cachedShipment;

        // Set this flag to `true` if we want to just restore a shipment without showing any confirmation dialogs (just fill data as-is).
        const shouldSkipConfirmationInEditMode = false;

        if (!isEditMode || !shouldSkipConfirmationInEditMode) {
            this.handleAddressType(addressValidation.AddressType);
        }
    }

    private handleAddressType(addressType: number) {
        this.shipmentService.shipToAddressType$.next(addressType);
    }

    private validateAddressFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed validating address',
            NotificationType.ERROR);
        this.isValidatingAddress = false;
    }

    private handleGetNotifyTemplateSuccess(template: INotifyTemplate) {
        this.showPreview(template);
        this.isLoadingNotifyTemplate = false;
    }

    private handleGetNotifyTemplateFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Getting Notify Template',
            NotificationType.ERROR);
        this.isLoadingNotifyTemplate = false;
    }

    private showPreview(template: INotifyTemplate) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            data: {
                template,
            },
        };

        const dialogRef = this.dialog.open(NotifyTemplatePreviewDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            (result) => {
                if (!result) {
                    return;
                }
            },
        );
    }
}

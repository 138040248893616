import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AccessPointsLearnMoreDialogComponent } from './access-points-learn-more-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class AccessPointsLearnMoreDialogService<TRequest = unknown, TResponse = unknown> {
    private dialogRef: MatDialogRef<AccessPointsLearnMoreDialogComponent>;

    constructor(private dialog: MatDialog) {
    }

    public open(data?: TRequest): Observable<TResponse> {
        const config: MatDialogConfig<TRequest> = {
            data,
            disableClose: true,
            maxWidth: '100%',
            width: '34.625rem',
            panelClass: ['mobile-fullscreen-dialog'],
        };

        this.dialogRef = this.dialog.open(AccessPointsLearnMoreDialogComponent, config);
        return this.dialogRef.afterClosed();
    }

    public close(result?: never): void {
        if (!this.dialogRef) {
            return;
        }

        this.dialogRef.close(result);
    }
}

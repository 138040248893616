import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/shared/services/user/models/user.model';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import * as diff from 'recursive-diff';
import { filter, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserTermsConditionsComponent } from '../account/user-settings/user-terms-conditions/user-terms-conditions.component';
import { AppState } from '../app.state';
import { SharedService } from '../core/services/shared.service';
import { SpinnerService } from '../core/services/spinner/spinner.service';
import { StorageService } from '../core/services/storage/storage.service';
import { DeliveryDefenseService } from '../delivery-defense/services/delivery-defense.service';
import { CarrierPipe } from '../history/pipes/carrier.pipe';
import { SavedQuotesService } from '../history/services/saved-quotes.service';
import { INotifyTemplate } from '../notify-templates/models/notify-template.interface';
import { NotifyTemplateService } from '../notify-templates/services/notify-template.service';
import { AutoCloseDialogComponent } from '../shared/components/auto-close-dialog/auto-close-dialog.component';
import { ICustomerImage } from '../shared/components/paperless-commercial-invoice/models/customer-image.interface';
import { PaperlessCommercialInvoiceService } from '../shared/components/paperless-commercial-invoice/services/paperless-commercial-invoice.service';
import { ShipDetailsDialogComponent } from '../shared/components/ship-details-dialog/ship-details-dialog.component';
import { Carriers } from '../shared/enum/general-enum';
import { ICarrierKey } from '../shared/models/carrier-key.interface';
import { ICountry } from '../shared/models/country.interface';
import { Customer } from '../shared/models/customer/customer.model';
import { Location } from '../shared/models/location/location.model';
import { NotificationType } from '../shared/models/notification-type';
import { CarrierService } from '../shared/services/carrier/carrier.service';
import { CountryService } from '../shared/services/country/country.service';
import { DateTimeService } from '../shared/services/date-time/date-time.service';
import { DialogService } from '../shared/services/dialog/dialog.service';
import { ErrorHandlerService } from '../shared/services/error-handler/error-handler.service';
import { FormService } from '../shared/services/form/form.service';
import { NavigationService } from '../shared/services/navigation.service';
import { NotificationService } from '../shared/services/notification/notification.service';
import { UserService } from '../shared/services/user/user.service';
import { UtilityService } from '../shared/services/utility/utility.service';
import { ValidationService } from '../shared/services/validation/validation.service';
import { AddressConfidenceComponent } from './address-confidence/address-confidence.component';
import { IAddressValidation } from './models/address-validation.interface';
import { ICarrierService } from './models/carrier-service.interface';
import { Commodity } from './models/commodity.model';
import { Contact } from './models/contact.model';
import { IDDCredit } from './models/dd-credit.interface';
import { IDDSubscription } from './models/dd-subscription.interface';
import { IDeliveryDefenseScore } from './models/delivery-defense-score.interface';
import { Package } from './models/package.model';
import { IShipComponent } from './models/ship-component.interface';
import { ShipConfig } from './models/ship-config.model';
import { ShipConfigService } from './services/ship-config.service';
import { ShipmentStateService } from './services/shipment-state.service';
import { ShipmentService } from './services/shipment.service';
import { ShipBillingDetailsComponent } from './ship-billing-details/ship-billing-details.component';
import { CommodityShipInfoComponent } from './ship-commodity/commodity-ship-info/commodity-ship-info.component';
import { ShipCommodityComponent } from './ship-commodity/ship-commodity.component';
import { ShipCustomsDocsComponent } from './ship-customs-docs/ship-customs-docs.component';
import { ShipElectronicExportComponent } from './ship-electronic-export/ship-electronic-export.component';
import { ShipFromComponent } from './ship-from/ship-from.component';
import { ShipInfoAddComponent } from './ship-info-add/ship-info-add.component';
import { ShipInfoComponent } from './ship-info/ship-info.component';
import { ShipOptionsComponent } from './ship-options/ship-options.component';
import { ShipToComponent } from './ship-to/ship-to.component';

export interface IShipRouteState {
    quoteId?: string;
    orderId?: string;
    isECommerceEdit?: boolean;
}

/**
 * DDEnabled
 * NULL;    dd-ineligible customer: a customer with no DD features (cannot see, cannot subscribe)
 * false;   potential subscriber: a customer who can subscribe but has not yet subscribed
 * true;    subscriber: a customer who has subscribed for DD
 *
 * MonthlyTierType
 * NULL 0r 1 -  Paid customer
 * 2            High volume program 1 - 200 free scores
 * 3            High volume program 2 - 300 free scores
 * 4            High volume program 3 - 1500 scores
 *
 * SubscriptionType (SubTypeID): SubscriptionTypes table.
 * 1    50 credits (general paid customer)
 * 2    200 credits (eligible for customers with 100-199 shipments in the previous month)
 * 3    300 credits (eligible for customers with 200-399 shipments in the previous month)
 * 4    1500 credits (eligible for customers with 400+ shipments in the previous month)
 * 5    5 credits (trial)
 * -- A customer pays $19.99 when he first subscribes and the system assign SubscriptionType 1 to him.
 * -- On a subsequent month, if the customer makes enough shipments during the previous month, he will be eligible for higher type/tier.
 * -- The system will offer him to upgrade with no additional costs.
 * -- He does not get to choose which type to upgrade to though.
 * -- If he falls into type 3, the system offers type 3; no option for type 2.
 */
@Component({
    selector: 'upsc-ship',
    templateUrl: './ship.component.html',
    styleUrls: ['./ship.component.scss'],
    providers: [
        ShipmentStateService,
    ],
})
export class ShipComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
    @ViewChild('shipTo', { static: true }) public shipTo: ShipToComponent;
    @ViewChild('shipFrom', { static: true }) public shipFrom: ShipFromComponent;
    @ViewChild('shipInfo', { static: true }) public shipInfo: ShipInfoComponent;
    @ViewChild('shipInfoAdd', { static: true }) public shipInfoAdd: ShipInfoAddComponent;
    @ViewChild('shipOptions', { static: true }) public shipOptions: ShipOptionsComponent;
    @ViewChild('shipBillingDetails', { static: true }) public shipBillingDetails: ShipBillingDetailsComponent;
    @ViewChild('commodityShipInfo') public commodityShipInfo: CommodityShipInfoComponent;
    @ViewChild('shipElectronicExport', { static: true }) public shipElectronicExport: ShipElectronicExportComponent;
    @ViewChild('shipCustomsDocs') public shipCustomsDocs: ShipCustomsDocsComponent;
    @ViewChild('shipCommodity', { static: true }) public shipCommodity: ShipCommodityComponent;

    public readonly environment = environment;
    public parentForm: UntypedFormGroup;
    public user: User;
    public customer: Customer;
    public carrier: Carriers;
    public carrierCode: string;
    public isQuoteReady = false;
    public estimatedCost: string;
    public surcharge: string;
    public totalCharges: string;
    public shipFromCountryCode: string;
    public shipToCountryCode: string;
    public shipFromZipCode: string;
    public shipToZipCode: string;
    public isDomestic = true;
    public serviceType: ICarrierService;
    public totalCustomsValue = 0;
    public totalWeight = 0;
    public shipFromAddress: Location;
    public shipToAddress: Location;
    public shipToCountry: ICountry;
    public shipFromCountry: ICountry;
    public commodities: Commodity[];
    public restrictedShipToCountryCodes: any;
    public config: ShipConfig;
    public insuredValueCurrencyCode = 'USD';
    public weightUOM = 'LBS';
    public dimensionUnit = 'inches';
    public shouldShowCostEstimate = false;
    public shipDate: Date;
    public shipDateString: string;
    public countryMaxCoverage: number;
    public isFormValid = false;
    public isShipFromValid = true;
    public isShipToValid = true;
    public isShipInfoValid = false;
    public isShipInfoAddValid = false;
    public isShipOptionsValid = false;
    public isBillingDetailsValid = false;
    public isCommodityShipInfoValid = false;
    public isElectronicExportValid = false;
    public isShipCustomsDocsValid = false;
    public isShipCommodityValid = false;
    public isFormSubmitting = false;
    public isQuoteHasLabel = false;
    public isForbidden = false;
    public isExistingSchedulePickup = false;
    public shouldShowResidentialVerification = false;
    public shouldShowValuationDiscrepancy = false;
    public shouldShowShipToReroute = false;
    public shouldShowEEI = false;
    public shipFromCurrencyCode = 'USD';
    public maxCoverageCurrencyCode = 'USD';
    public formattedInsuredValue = '';
    public isHighValueShipment = false;
    public isAESShipment = false;
    public isEEIRequired = false;
    public isNAFTA = false;
    public shipFromCountries: ICountry[];
    public shipToCountries: ICountry[];
    public dateFormat = 'MM/DD/YYYY';
    public isRestoreShipment = false;
    public isReship = false;
    public cachedShipment: Package;
    public shouldForceClearAdultSignatureRequired = false;
    public shouldEnableSaveQuote = false;
    public carrierKey: ICarrierKey;
    public customerImages: ICustomerImage[];
    public notifyTemplates: INotifyTemplate[];
    public shipFromLocations: Location[];
    public shipToLocations: Location[];
    public validatePaperlessInvoiceCapabilityResult: any;
    public shouldDisplayShipFromEUFields = false;
    public shouldDisplayShipToEUFields = false;
    public ddCredit: IDDCredit;
    public ddFullCredit: number;
    public ddRemainingCredit: number;
    public ddSubTypeID: number;
    public insuredValue: number;

    private routeState: IShipRouteState;
    private cachedCarrierCode: string;
    private locale = 'en-US';
    private shouldClearCachedAfterRestored = true;
    private lastGetQuotePayload: Package;
    private responseQuote: Package;
    private carrierCountries: ICountry[];

    private isResidentialCheck = false;
    private isAdultSignature = false;
    private isResidentialAddress = false;
    private isAdultSignatureRequested = false;
    private pickupType: string;

    private formValidationErrors = [];
    private hasPendingConfigUpdate = false;
    private hasHighValueCommodity = false;
    private isUseAESDirect = false;
    private isMobileWebView = false;

    private isInsuredValueChanged = false;
    private isPickupTypeChanged = false;

    private cachedSkipDeliveryDefenseFlag = false;
    private isActionFromDDScoreDialog = false;

    private isUsePickupLocation = false;

    private ddSubscription: IDDSubscription;

    private routeSubscription: Subscription;
    private getUserConfigSubscription: Subscription;
    private checkSchedulePickupSubscription: Subscription;
    private createHighValueShipmentSubscription: Subscription;
    private confirmShipmentSubscription: Subscription;
    private getShipToCountriesSubscription: Subscription;

    private ngDestroyed$ = new Subject<void>();

    public constructor(
        private formBuild: UntypedFormBuilder,
        private carrierPipe: CarrierPipe,
        private userService: UserService,
        private notificationService: NotificationService,
        private validationService: ValidationService,
        private formService: FormService,
        private errorHandlerService: ErrorHandlerService,
        private utilityService: UtilityService,
        private dialogService: DialogService,
        private dateTimeService: DateTimeService,
        private dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private countryService: CountryService,
        private spinnerService: SpinnerService,
        private storageService: StorageService,
        private sharedService: SharedService,
        private readonly appState: AppState,
        private paperlessCommercialInvoiceService: PaperlessCommercialInvoiceService,
        private notifyTemplateService: NotifyTemplateService,
        private carrierService: CarrierService,
        private savedQuoteService: SavedQuotesService,
        private shipConfigService: ShipConfigService,
        private translateService: TranslateService,
        private shipmentService: ShipmentService,
        private snackBar: MatSnackBar,
        private deliveryDefenseService: DeliveryDefenseService,
        public readonly shipmentStateService: ShipmentStateService,
        private navigationService: NavigationService,
    ) {
        const prefetch = this.route.snapshot.data.prefetch;
        if (prefetch) {
            this.carrierKey = prefetch.carrierKey;
            this.customerImages = prefetch.customerImages;
            this.notifyTemplates = prefetch.notifyTemplates;
            this.config = prefetch.shipConfig;
            this.shipFromLocations = prefetch.shipFromLocations;
            this.shipToLocations = prefetch.shipToLocations;
            this.shipFromCountries = prefetch.shipFromCountries;
            this.shipToCountries = prefetch.shipToCountries;
        }

        this.user = this.appState.user$();
        this.customer = this.appState.customer$();

        this.dateFormat = this.isDEGBUser(this.user) ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        this.locale = this.isDEUser(this.user) ? 'de-DE' : this.isGBUser(this.user) ? 'en-GB' : 'en-US';
        // this.handleGetUserSuccess(this.user);
        this.handleGetCustomerSuccess(this.customer);

        // this.shipmentService.restoreQuote();
        // this.restoreValuesFromQuote();

        this.route.params.subscribe(
            (params) => {
                const carrierCode = params['carrierCode'];
                if (!carrierCode) {
                    return;
                }

                if (!this.cachedCarrierCode) {
                    this.cachedCarrierCode = carrierCode;
                }

                const isCarrierCodeChanged = this.cachedCarrierCode && carrierCode !== this.cachedCarrierCode;
                this.cachedCarrierCode = carrierCode;

                this.shipmentStateService.isShipToZipRestricted.set(false);

                const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
                if (cachedShipment) {
                    const cachedCarrier = this.carrierPipe.transform(cachedShipment.CarrierCode);
                    this.isRestoreShipment = !!cachedShipment && cachedCarrier.toUpperCase() === carrierCode.toUpperCase();
                } else {
                    this.isRestoreShipment = false;
                }

                const reship = this.storageService.get<any>('reship');
                if (reship) {
                    this.isReship = !!reship.isReship;
                }

                const shouldRestoreShipment = !isCarrierCodeChanged;
                this.handleGetUserSuccess(this.user, shouldRestoreShipment);
                this.shipmentService.Quote = new Package();
                this.isMobileWebView = !!sessionStorage.getItem('is-mobile-web-view') || false;
                this.loadInitialValues();

                const carrierEnumValue = Carriers[_.capitalize(carrierCode)];
                const carrierEnum = Carriers[carrierEnumValue];
                this.shipmentService.Quote.CarrierCode = +carrierEnumValue;
                this.shipmentService.Quote.DDSearch = null;
                this.carrierCode = carrierCode.toUpperCase();
                this.carrier = <any>carrierEnum;

                this.resetForms();
                this.onCarrierChanged();
            },
        );
    }

    public get useAccessPoint(): boolean {
        return !!this.shipInfoAdd?.accessPoint?.UPSAccessPointID;
    }

    public get useFedExHoldAtLocation(): boolean {
        return !!this.shipInfoAdd?.useFedExHoldAtLocation;
    }

    public get shouldShowDDScoreDialog(): boolean {
        return this.customer.DDEnabled === true
            && !this.parentForm?.get('skipDeliveryDefense')?.value
            && !this.isActionFromDDScoreDialog;
    }

    public get isUSDomestic(): boolean {
        return this.shipConfigService.isUSDomestic(this.shipFromCountryCode, this.shipToCountryCode);
    }

    public get shouldShowDeliveryDefenseBanner(): boolean {
        return environment.feature?.deliveryDefense
            && this.customer?.DDEnabled !== null
            && this.user?.CountryCode === 'US';
    }

    public get canGetComplimentaryScores(): boolean {
        // console.log(this.ddSubscription);
        return this.customer.DDEnabled === false
            && (this.customer.MonthlyTierType === null || this.customer.MonthlyTierType === 1)
            && (!this.ddSubscription || this.ddSubscription?.CurrentSubType === null);
    }

    public ngOnInit(): void {
        if (this.navigationService.isRefresh()) {
            this.storageService.remove('shipment-edit');
            this.storageService.remove('reship');
            this.isRestoreShipment = false;
        }

        this.setupFormGroup();
        this.monitorFormValueChanges();

        this.monitorShipConfigChanges();
        this.monitorDeliveryDefenseCreditDeductionEvent();
        this.monitorUPSAccessPointSelectorRequest();
        this.monitorUPSAccessPointSelection();
        this.monitorFedExHoldAtLocationSelectorRequest();
        this.monitorFedExHoldAtLocationSelection();
        this.monitorSkipDeliveryDefenseFlagChange();
        this.monitorDeliveryDefenseCreditsUpdate();
        this.monitorShipToAddressTypeChanged();
        this.monitorDeliveryDefenseOfferDialogClosed();
        this.monitorShipToZipRestrictionCheckChanged();

        // This will run any code upon navigating to/from the same route with different route parameters.
        this.routeSubscription = this.route.params.subscribe(
            (params) => {
                this.handleDDCustomer();
            },
        );

        this.routeState = history.state;
        const quoteId = this.routeState?.quoteId;

        // Load saved quote if a quote ID is found in the route state.
        if (quoteId) {
            this.savedQuoteService.getSavedQuote(quoteId)
                .pipe(
                    takeUntil(this.ngDestroyed$),
                    catchError((err) => {
                        this.errorHandlerService.logHttpError(err);
                        return of(null);
                    }),
                )
                .subscribe(
                    (quote) => {
                        if (!quote) {
                            return;
                        }

                        this.storageService.set('shipment-edit', quote);
                        const cachedCarrier = this.carrierPipe.transform(quote.CarrierCode);
                        this.isRestoreShipment = !!quote && cachedCarrier.toUpperCase() === this.carrierCode.toUpperCase();

                        this.restoreShipment();

                        this.handleSuccessfulQuoteAdd(quote, false);
                    },
                );
        }
    }

    public ngOnDestroy() {
        this.routeSubscription?.unsubscribe();
        this.shipmentService.clearCache();
        this.shipmentService.clearQuote();
        this.shipConfigService.clearConfig();
        this.notifyTemplateService.clearCache();
        this.carrierService.clearCache();
        this.config = null;
        this.responseQuote = null;
        this.lastGetQuotePayload = null;

        this.utilityService.clearSubscriptions([
            this.getUserConfigSubscription,
            this.checkSchedulePickupSubscription,
            this.createHighValueShipmentSubscription,
            this.confirmShipmentSubscription,
            this.getShipToCountriesSubscription,
        ]);

        // Any active subscriptions that are using `takeUntil(this.ngUnsubscribe)` will see this value
        // and automatically complete, unsubscribing themselves.
        // Note that the unsubscription works because of how it's used in conjunction with the `takeUntil` operator.
        // The takeUntil RxJS operator listens for the first emission from the observable you provide to it
        // (in this case, this.ngUnsubscribe).
        // When that observable emits a value, takeUntil automatically completes the source observable, effectively unsubscribing from it.
        this.ngDestroyed$.next();

        // This marks the ngUnsubscribe Subject as complete, which ensures that
        // no more values can be emitted and no more subscriptions can be made.
        // This is a cleanup step that effectively "seals" the Subject.
        this.ngDestroyed$.complete();

        this.storageService.remove('shipment-edit');
        this.storageService.remove('reship');
    }

    public ngDoCheck() {
        this.cdRef.detectChanges();
    }

    public ngAfterViewInit(): void {
        this.resetForms();
    }

    public isDEGBUser(user: User) {
        // return user.UserName?.toUpperCase().startsWith('DE') || user.UserName?.toUpperCase().startsWith('GB');
        return ['DE', 'GB'].includes(user.CountryCode?.toUpperCase());
    }

    public isDEUser(user: User) {
        // return user.UserName?.toUpperCase().startsWith('DE');
        return user.CountryCode?.toUpperCase() === 'DE';
    }

    public isITFRUser(user: User) {
        // return user.UserName?.toUpperCase().startsWith('DE');
        return ['FR', 'IT'].includes(user.CountryCode?.toUpperCase());
    }

    public isEuUser(user: User) {
        // return user.UserName?.toUpperCase().startsWith('GB');
        return ['DE', 'GB', 'FR', 'IT'].includes(user.CountryCode?.toUpperCase());
    }

    public isGBUser(user: User) {
        // return user.UserName?.toUpperCase().startsWith('GB');
        return user.CountryCode?.toUpperCase() === 'GB';
    }

    public onFormSubmitted(event, form) {
        event.preventDefault();

        const isStatementsNotAgreed = this.notifyIfStatementsNotAgreed();
        if (isStatementsNotAgreed) {
            return;
        }

        this.validateAllForms();
        const isValidCommodity = this.isValidCommodity();
        this.isFormValid = this.isFormValid && isValidCommodity;

        if (!this.isFormValid) {
            // this.showFormValidity(null);
            this.showFormErrors(null);
            this.forceValidation();
            if (this.isDEGBUser(this.user) && this.isHVExceeded()) {
                this.handleHVExceed();
                return;
            }
            return;
        }
        if (this.isDEGBUser(this.user) && this.isHVExceeded()) {
            this.handleHVExceed();
            return;
        }

        this.isFormSubmitting = true;
        this.spinnerService.show();

        this.getQuote();
    }

    public getQuote(shouldConfirmShipment: boolean = false, isSubsequentGetQuote = false) {
        // this.lastGetQuotePayload = structuredClone(this.shipmentService.Quote);
        this.lastGetQuotePayload = _.cloneDeep(this.shipmentService.Quote);
        const data: Package = _.cloneDeep(this.shipmentService.Quote);

        if (data.Commodities) {
            data.Commodities = data.Commodities.filter(
                (commodity) => {
                    return !!commodity.HarmonizedCode;
                },
            );
        }

        this.sanitizeShipTo(data.ShipTo);

        // Remove extra fields from shipping addresses. They cause validation errors on API.
        delete data.ShipFrom?.Vat;
        delete data.ShipFrom?.TaxID;
        delete data.ShipFrom?.EORI;
        delete data.ShipTo?.Vat;
        delete data.ShipTo?.TaxID;
        delete data.ShipTo?.EORI;
        delete data.ShipFromRerouting?.Vat;
        delete data.ShipFromRerouting?.TaxID;
        delete data.ShipFromRerouting?.EORI;
        delete data.ShipToRerouting?.Vat;
        delete data.ShipToRerouting?.TaxID;
        delete data.ShipToRerouting?.EORI;

        // TODO: Check if we still need this.
        // Copy UPS Access Point information to HoldAt fields.
        if (data.UseAccessPoint && data.AccessPoint) {
            data.IsHoldAtLocation = data.IsHoldAtLocation || data.UseAccessPoint;
            data.HoldAtAddress1 = data.HoldAtAddress1
                || [data.AccessPoint.StreetAddress, data.AccessPoint.ApartmentSuite]
                    .filter(Boolean)
                    .join(', ');
            data.HoldAtAddress2 = data.HoldAtAddress2 || data.AccessPoint.Consignee;
            data.HoldAtCity = data.HoldAtCity || data.AccessPoint.City;
            data.HoldAtState = data.HoldAtState || data.AccessPoint.State;
            data.HoldAtZip = data.HoldAtZip || data.AccessPoint.Zip;
            data.HoldAtCountry = data.HoldAtCountry || data.AccessPoint.Country;
            data.HoldAtLocationID = data.HoldAtLocationID || data.AccessPoint.UPSAccessPointID;
        }
        delete data.UseAccessPoint;
        delete data.AccessPoint;

        if (!data.ShipFrom.CompanyName) {
            data.ShipFrom.CompanyName = '';
        }

        if (!data.ShipTo.CompanyName) {
            data.ShipTo.CompanyName = '';
        }

        if (!this.isUSDomestic) {
            // TODO: while this ensures that DD is skipped for a non-US domestic shipment,
            //  it should also be handled upon ship-from/to country code changed.
            this.shipmentService.skipDeliveryDefenseFlagChanged$.next(true);
        }

        if (this.shipmentService.Quote.DDSearch === null) {
            data.DDSearch = this.environment.feature?.deliveryDefense
                && this.customer.DDEnabled === true
                && this.user.CountryCode === 'US'
                && this.isUSDomestic
                && !this.parentForm.get('skipDeliveryDefense')?.value
                && !this.useAccessPoint;

            // TODO: Potential change:
            //  Only get DD score if a user tries to confirm shipment.
            //  Getting a quote alone should not make any DD search.
            // && shouldConfirmShipment;

            // this.shipmentService.Quote.DDSearch = data.DDSearch;
        } else {
            data.DDSearch = this.shipmentService.Quote.DDSearch;
        }

        const shouldRestrictDDSearch = data.DDSearch && this.ddRemainingCredit <= 0;
        if (shouldRestrictDDSearch && !isSubsequentGetQuote) {
            this.deliveryDefenseService.handleZeroCredit(this.ddCredit);

            this.spinnerService.hide();
            return;
        }

        delete data.DDScore;

        let request: Observable<Package>;
        if (data.DDSearch) {
            request = this.deliveryDefenseService.getRemainingDDCredit()
                          .pipe(
                              switchMap((ddCredit) => {
                                  this.ddCredit = ddCredit;
                                  this.ddRemainingCredit = +(ddCredit?.ScoresRemaining ?? 0);
                                  this.ddFullCredit = +(ddCredit?.TotalScores ?? 0);
                                  this.ddSubTypeID = +(ddCredit?.SubTypeID ?? 0);

                                  if (this.ddRemainingCredit <= 0) {
                                      this.notificationService.notify(
                                          'Need more score for a DeliveryDefense&#8482; Address Confidence search.',
                                          'Insufficient search score',
                                          NotificationType.WARNING,
                                      );

                                      return of(null);
                                  }

                                  return this.shipmentService.add(data);
                              }),
                          );
        } else {
            request = this.shipmentService.add(data);
        }

        request
            .pipe(
                catchError((err) => {
                    this.handleFailedQuoteAdd(err);
                    return of(null);
                }),
            )
            .subscribe(
                quote => this.handleSuccessfulQuoteAdd(quote, shouldConfirmShipment),
            );
        // this.shipmentService.add(data)
        //     .subscribe(
        //         quote => this.handleSuccessfulQuoteAdd(quote, shouldConfirmShipment),
        //         err => this.handleFailedQuoteAdd(err),
        //     );
    }

    public saveQuote(event) {
        event.preventDefault();

        if (!this.responseQuote) {
            this.notificationService.notify(
                'No quote exists. Please get a quote first.',
                'No quote to save',
                NotificationType.WARNING);

            return;
        }

        this.shipmentService.Quote.IsSavedQuote = true;
        this.shipmentService.saveQuote(this.responseQuote.QuoteId)
            .subscribe(
                res => this.handleSaveQuoteSuccess(),
                err => this.handleSaveQuoteFailure(err),
            );
    }

    public async submitToACE(event, form) {
        event.preventDefault();

        const data: Package = _.cloneDeep(this.shipmentService.Quote);

        if (data.Commodities) {
            data.Commodities = data.Commodities.filter(
                (commodity) => {
                    return !!commodity.HarmonizedCode;
                },
            );
        }

        const isStatementsNotAgreed = this.notifyIfStatementsNotAgreed();
        if (isStatementsNotAgreed) {
            return;
        }

        this.validateAllForms();
        const isValidCommodity = this.isValidCommodity();
        this.isFormValid = this.isFormValid && isValidCommodity;

        if (!this.isFormValid) {
            this.showFormErrors(null);
            this.forceValidation();
            return;
        }

        this.spinnerService.show();

        // ACE (AES) is always an international shipment which DD is always disabled.
        this.shipmentService.Quote.DDSearch = false;
        data.DDSearch = false;

        // MV3-6288, MV3-6737: ITN (AESCitation) value should not be sent to the API ever for a restored shipment.
        if (this.isRestoreShipment) {
            data.AESCitation = null;
        }

        if (!this.responseQuote) {
            if (data.DDSearch === null || data.DDSearch === undefined) {
                data.DDSearch = false;
            }

            this.sanitizeShipTo(data.ShipTo);

            this.shipmentService.add(data)
                .pipe(mergeMap(quote => this.shipmentService.submitToACE(quote?.QuoteId, 12)))
                .subscribe(
                    res => this.handleSubmitToACESuccess(),
                    err => this.handleSubmitToACEFailure(err),
                );

            return;
        }

        this.shipmentService.submitToACE(this.responseQuote?.QuoteId, 12)
            .subscribe(
                res => this.handleSubmitToACESuccess(),
                err => this.handleSubmitToACEFailure(err),
            );
    }

    public getQuoteAndShip(event, form, isSubsequentGetQuote = false) {
        if (event) {
            event.preventDefault();
        }

        const isStatementsNotAgreed = this.notifyIfStatementsNotAgreed();
        if (isStatementsNotAgreed) {
            return;
        }

        this.validateAllForms();
        const isValidCommodity = this.isValidCommodity();
        this.isFormValid = this.isFormValid && isValidCommodity;

        if (!this.isFormValid) {
            this.showFormErrors(null);
            this.forceValidation();
            if (this.isDEGBUser(this.user) && this.isHVExceeded()) {
                this.handleHVExceed();
                return;
            }
            return;
        }
        if (this.isDEGBUser(this.user) && this.isHVExceeded()) {
            this.handleHVExceed();
            return;
        }

        this.isFormSubmitting = true;
        this.spinnerService.show();

        // TODO: Potential change:
        //  If DDEnabled, we should always get quote with DD search once.
        //  Depending on the selected option, we might need to get quote again.
        //  However, if not DDEnabled, we skip getting quote if quote is already there (a user already gets a quote).
        // const shouldSkipGetQuote = this.customer.DDEnabled
        //     ? !this.isActionFromDDScoreDialog
        //     : this.isQuoteReady;
        const shouldSkipGetQuote = this.isQuoteReady;
        if (shouldSkipGetQuote) {
            this.confirmShipment(null);
            return;
        }

        this.getQuote(true, isSubsequentGetQuote);
    }

    public confirmShipment(event) {
        if (event) {
            event.preventDefault();
        }

        if (this.isAESShipment) {
            this.isActionFromDDScoreDialog = false;
            this.goToShipmentDetails();
            return;
        }

        if (!this.responseQuote || !this.shipmentService.Quote.QuoteId || this.shipmentService.Quote.QuoteId === 'NOID') {
            // Instead of having a user manually clicking GET QUOTE, get a quote for him and proceed forwards.
            this.getQuoteAndShip(null, null);
            // this.notificationService.notify('Data may have been changed. Please get a new quote.', 'No Quote', NotificationType.WARNING);
            // this.spinnerService.hide();
            return;
        }

        if (this.isHighValueShipment) {
            this.storageService.set('shipment-details', this.responseQuote);
            this.spinnerService.show();
            this.utilityService.clearSubscriptions([this.createHighValueShipmentSubscription]);
            this.createHighValueShipmentSubscription = this.shipmentService.createHighValueShipment(this.responseQuote.QuoteId)
                                                           .subscribe(
                                                               () => this.handleCreateHighValueShipmentSuccess(),
                                                               err => this.handleCreateHighValueShipmentFailure(err),
                                                           );

            return;
        }

        this.isFormSubmitting = true;
        this.spinnerService.show();
        this.utilityService.clearSubscriptions([this.confirmShipmentSubscription]);
        this.confirmShipmentSubscription = this.shipmentService.confirmShipment(this.responseQuote.QuoteId)
                                               .pipe(
                                                   tap(() => {
                                                       this.isActionFromDDScoreDialog = false;
                                                   }),
                                               )
                                               .subscribe(
                                                   quote => this.handleConfirmShipmentSuccess(quote),
                                                   err => this.handleConfirmShipmentFailure(err),
                                               );
    }

    public onShipFromAddressChanged(shipFromAddress: Location) {
        if (!shipFromAddress) {
            return;
        }

        this.shipFromAddress = _.cloneDeep(shipFromAddress);
        this.saveShipFromData();

        this.shouldShowCostEstimate = ['US', 'CA'].includes(this.shipFromAddress.Country);

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
            this.shipFromAddress.UPSPickUpType,
        );

        this.shipConfigService.setUPSPickupType(this.carrier, this.shipFromAddress);
        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);

        if (!this.shipFromCountries) {
            this.shipmentService.getShipFromCountries(this.carrier)
                .subscribe(
                    (countries) => {
                        this.shipFromCountries = countries;
                        this.setShipFromCurrency(shipFromAddress.Country);
                    },
                );
        } else {
            this.setShipFromCurrency(shipFromAddress.Country);
        }

        this.checkSchedulePickupDate();
    }

    public onShipToAddressChanged(shipToAddress: Location) {
        this.shipToAddress = shipToAddress;
        this.saveShipToData();

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
        );

        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
    }

    public onShipFromCountryChanged(country: ICountry) {
        this.shipFromCountry = country;
        this.setEUFieldsVisibility();
    }

    public onShipFromCountryCodeChanged(countryCode: string) {
        this.resetCommodityRelatedComponents();

        this.shipFromCountryCode = countryCode;
        this.setEUFieldsVisibility();

        this.isNAFTA = this.shipConfigService.isNAFTA(this.shipFromCountryCode, this.shipToCountryCode);
        this.weightUOM = ['US', 'CA'].includes(countryCode) ? 'LBS' : 'KGS';
        this.dimensionUnit = ['US', 'CA'].includes(countryCode) ? 'inches' : 'cm';
        this.setShipFromCurrency(countryCode);

        this.restrictedShipToCountryCodes =
            this.shipmentService.getRestrictedShipToCountries(this.user, this.carrier, this.shipFromCountryCode);

        this.isDomestic = this.shipConfigService.isDomestic(this.shipFromCountryCode, this.shipToCountryCode);
        if (this.isDomestic) {
            this.shipmentService.resetElectronicExportInformation();
        }

        if (!this.shipFromAddress) {
            this.hasPendingConfigUpdate = true;
            return;
        }

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
            this.shipFromAddress.UPSPickUpType,
        );

        // this.monitorCountryChanges();

        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
        this.checkValuationDiscrepancy();

        // if (!this.shipConfigService.isUps(this.carrier)) {
        //   this.loadCarrierServices(this.carrier, this.shipFromCountryCode, this.shipToCountryCode);
        // } else {
        //   this.loadCarrierServicesByCountries();
        // }

        if (this.shipToCountryCode) {
            this.validatePaperlessInvoiceCapacity(this.shipFromCountryCode, this.shipToCountryCode);
        }
    }

    public onShipFromZipCodeChanged(zipCode: string) {
        this.shipFromZipCode = zipCode;
    }

    public onShipToCountryChanged(country: ICountry) {
        this.shipToCountry = country;
        this.setEUFieldsVisibility();
    }

    public onShipToCountryCodeChanged(countryCode: string) {
        this.resetCommodityRelatedComponents();

        this.shipToCountryCode = countryCode;
        this.setEUFieldsVisibility();

        this.isDomestic = this.shipConfigService.isDomestic(this.shipFromCountryCode, this.shipToCountryCode);
        this.isNAFTA = this.shipConfigService.isNAFTA(this.shipFromCountryCode, this.shipToCountryCode);
        if (this.isDomestic) {
            this.shipmentService.resetElectronicExportInformation();
        }

        if (!this.shipFromAddress) {
            this.hasPendingConfigUpdate = true;
            return;
        }

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
            this.shipFromAddress.UPSPickUpType,
        );

        // this.monitorCountryChanges();

        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
        this.checkValuationDiscrepancy();
        this.setCountryMaxCoverage(countryCode);

        // if (!this.shipConfigService.isUps(this.carrier)) {
        //   this.loadCarrierServices(this.carrier, this.shipFromCountryCode, this.shipToCountryCode);
        // } else {
        //   this.loadCarrierServicesByCountries();
        // }

        if (this.shipFromCountryCode) {
            this.validatePaperlessInvoiceCapacity(this.shipFromCountryCode, this.shipToCountryCode);
        }
    }

    public onShipToZipCodeChanged(zipCode: string) {
        this.shipToZipCode = zipCode;
        this.shipmentService.shipToZip$.next(zipCode);
    }

    public onServiceTypeChanged(serviceType: ICarrierService) {
        this.serviceType = serviceType;
        this.checkSchedulePickupDate();
    }

    public onTotalCustomsValueChanged(customsValue: number) {
        this.totalCustomsValue = customsValue;

        this.setAESStatus();

        if (this.shipFromAddress) {
            this.shipConfigService.setConfig(
                this.shipFromCountryCode,
                this.shipToCountryCode,
                this.carrier,
                this.user,
                this.customer,
                this.insuredValue,
                this.totalCustomsValue,
                this.shipDateString,
                this.shipFromAddress.UPSPickUpType,
            );

            this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
            this.checkValuationDiscrepancy();
        }
    }

    public onTotalWeightChanged(weight: number) {
        this.totalWeight = weight;
    }

    public resetForm(event) {
        event.preventDefault();

        this.shipmentService.clearQuote();
    }

    public isShowEei() {
        const isExceptionCountry = ['CA', 'PR'].includes(this.shipToCountryCode.toUpperCase()) &&
            this.shipFromCountryCode.trim().toUpperCase() === 'US';
        if (isExceptionCountry) {
            return false;
        }

        return !this.isDomestic;
    }

    //#endregion

    //#region Form validation
    public setFormValidity(formName: string, isValid: boolean) {
        switch (formName) {
            case 'shipTo':
                this.isShipToValid = isValid;
                break;
            case 'shipFrom':
                this.isShipFromValid = isValid;
                break;
            case 'shipInfo':
                this.isShipInfoValid = isValid;
                break;
            case 'shipInfoAdd':
                this.isShipInfoAddValid = isValid;
                break;
            case 'shipOptions':
                this.isShipOptionsValid = isValid;
                break;
            case 'billingDetails':
                this.isBillingDetailsValid = isValid;
                break;
            case 'commodityShipInfo':
                this.isCommodityShipInfoValid = isValid;
                break;
            case 'shipElectronicExport':
                this.isElectronicExportValid = isValid;
                break;
            case 'shipCustomsDocs':
                this.isShipCustomsDocsValid = isValid;
                break;
            case 'shipCommodity':
                this.isShipCommodityValid = isValid;
                break;
            default:
                break;
        }

        this.isFormValid = this.parentForm.valid &&
            this.isShipFromValid &&
            this.isShipToValid &&
            this.isShipInfoValid &&
            this.isShipInfoAddValid &&
            this.isShipOptionsValid &&
            this.isBillingDetailsValid;

        if (this.commodityShipInfo) {
            this.isFormValid = this.isFormValid && this.isCommodityShipInfoValid;
        }

        if (this.shipElectronicExport) {
            this.isFormValid = this.isFormValid && this.isElectronicExportValid;
        }

        if (this.shipCustomsDocs) {
            this.isFormValid = this.isFormValid && this.isShipCustomsDocsValid;
        }

        if (this.shipCommodity) {
            this.isFormValid = this.isFormValid && this.isShipCommodityValid;
        }
    }

    public showFormErrors(event) {
        if (event) {
            event.preventDefault();
        }

        this.formValidationErrors = [];

        const parentFormErrors = this.validationService.getFormControlValidationErrors(this.parentForm.controls, 'Ship');
        if (parentFormErrors) {
            this.formValidationErrors.push(...parentFormErrors);
        }

        const shipToErrors = this.shipTo.getFormErrors();
        if (shipToErrors) {
            this.formValidationErrors.push(...shipToErrors);
        }

        const shipFromErrors = this.shipFrom.getFormErrors();
        if (shipFromErrors) {
            this.formValidationErrors.push(...shipFromErrors);
        }

        const shipInfoErrors = this.shipInfo.getFormErrors();
        if (shipInfoErrors) {
            this.formValidationErrors.push(...shipInfoErrors);
        }

        const shipInfoAddErrors = this.shipInfoAdd.getFormErrors();
        if (shipInfoAddErrors) {
            this.formValidationErrors.push(...shipInfoErrors);
        }

        const shipOptionsErrors = this.shipOptions.getFormErrors();
        if (shipOptionsErrors) {
            this.formValidationErrors.push(...shipOptionsErrors);
        }

        const shipBillingDetailsErrors = this.shipBillingDetails.getFormErrors();
        if (shipBillingDetailsErrors) {
            this.formValidationErrors.push(...shipBillingDetailsErrors);
        }

        if (this.commodityShipInfo) {
            const commodityShipInfoErrors = this.commodityShipInfo.getFormErrors();
            if (commodityShipInfoErrors) {
                this.formValidationErrors.push(...commodityShipInfoErrors);
            }
        }

        if (this.shipElectronicExport) {
            const electronicExportErrors = this.shipElectronicExport.getFormErrors();
            if (electronicExportErrors) {
                this.formValidationErrors.push(...electronicExportErrors);
            }
        }

        if (this.shipCustomsDocs) {
            const shipCustomsDocsErrors = this.shipCustomsDocs.getFormErrors();
            if (shipCustomsDocsErrors) {
                this.formValidationErrors.push(...shipCustomsDocsErrors);
            }
        }

        if (this.shipCommodity) {
            const shipCommodityErrors = this.shipCommodity.getFormErrors();
            if (shipCommodityErrors) {
                this.formValidationErrors.push(...shipCommodityErrors);
            }
        }

        if (this.formValidationErrors.length) {
            console.error(this.formValidationErrors);
        }
    }

    public showFormValidity(event) {
        if (event) {
            event.preventDefault();
        }

        console.log('ship-to valid: ', this.shipTo.isFormValid());
        console.log('ship-from valid: ', this.shipFrom.isFormValid());
        console.log('ship-info valid: ', this.shipInfo.isFormValid());
        console.log('ship-info-add valid: ', this.shipInfoAdd.isFormValid());
        console.log('ship-options valid: ', this.shipOptions.isFormValid());
        console.log('ship-billing-details valid: ', this.shipBillingDetails.isFormValid());

        if (this.commodityShipInfo) {
            console.log('commodity-ship-info valid: ', this.commodityShipInfo.isFormValid());
        }

        if (this.shipElectronicExport) {
            console.log('electronic-export valid: ', this.shipElectronicExport.isFormValid());
        }

        if (this.shipCustomsDocs) {
            console.log('ship-customs-docs valid: ', this.shipCustomsDocs.isFormValid());
        }

        if (this.shipCommodity) {
            console.log('ship-commodity valid: ', this.shipCommodity.isFormValid());
        }
    }

    public validateAllForms(): boolean {
        const formValidity = {
            // MV3-3167 removed these lines
            // this.shipTo.isFormValid(),
            // shipFrom: this.shipFrom.isFormValid(),
            shipInfo: this.shipInfo.isFormValid(),
            shipInfoAdd: this.shipInfoAdd.isFormValid(),
            shipOptions: this.shipOptions.isFormValid(),
            shipBillingDetails: this.shipBillingDetails.isFormValid(),
        };

        if (this.commodityShipInfo) {
            formValidity['commodityShipInfo'] = this.commodityShipInfo.isFormValid();
        }

        if (this.shipElectronicExport) {
            formValidity['shipElectronicExport'] = this.shipElectronicExport.isFormValid();
        }

        if (this.shipCustomsDocs) {
            formValidity['shipCustomsDocs'] = this.shipCustomsDocs.isFormValid();
        }

        if (this.shipCommodity) {
            formValidity['shipCommodity'] = this.shipCommodity.isFormValid();
        }

        this.isFormValid = Object.keys(formValidity).every(key => !!formValidity[key]);

        if (!this.isFormValid) {
            Object.keys(formValidity).forEach(
                (key) => {
                    if (formValidity[key]) {
                        return true;
                    }

                    // console.warn(`${ _.lowerCase(key) } is invalid.`);
                },
            );
        }

        return this.isFormValid;

        // this.isFormValid = this.shipTo.isFormValid()
        // && this.shipFrom.isFormValid()
        // && this.shipInfo.isFormValid()
        // && this.shipInfoAdd.isFormValid()
        // && this.shipOptions.isFormValid()
        // && this.shipBillingDetails.isFormValid();
        //
        // if (this.commodityShipInfo) {
        //   this.isFormValid = this.isFormValid && this.commodityShipInfo.isFormValid();
        // }
        //
        // if (this.shipElectronicExport) {
        //   this.isFormValid = this.isFormValid && this.shipElectronicExport.isFormValid();
        // }
        //
        // if (this.shipCustomsDocs) {
        //   this.isFormValid = this.isFormValid && this.shipCustomsDocs.isFormValid();
        // }
        //
        // if (this.shipCommodity) {
        //   this.isFormValid = this.isFormValid && this.shipCommodity.isFormValid();
        // }
    }

    public showShipmentDetailsDialog($event) {
        event.preventDefault();

        this.openShipmentDetailsDialog(this.shipmentService.Quote);
    }

    //#endregion

    public onShipDateChanged(dateString: string): void {
        this.shipDate = dayjs(dateString).toDate();
        this.shipDateString = dateString;

        if (!this.shipFromAddress) {
            this.hasPendingConfigUpdate = true;
            return;
        }

        this.shipConfigService.setConfig(
            this.shipFromCountryCode,
            this.shipToCountryCode,
            this.carrier,
            this.user,
            this.customer,
            this.insuredValue,
            this.totalCustomsValue,
            this.shipDateString,
            this.shipFromAddress.UPSPickUpType,
        );

        this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
    }

    public onSchedulePickupDateChanged(pickupDate: Date) {
        this.shipmentService.Quote.PickUpDate = this.dateTimeService.toServerDateTimeString(pickupDate);
        this.checkSchedulePickupDate();
    }

    public onAdultSignatureRequested(isRequested: boolean) {
        this.isAdultSignatureRequested = isRequested;

        if (!isRequested) {
            return;
        }

        this.shipInfoAdd.enableAdultSignature();
    }

    public onIsResidentialAddressChanged(isRequired: boolean) {
        this.isResidentialAddress = isRequired;
        this.showResidentialVerification();
    }

    public onResidentialCheckChanged(isChecked: boolean) {
        this.isResidentialCheck = isChecked;
        this.showResidentialVerification();
    }

    public onAdultSignatureChanged(isChecked: boolean) {
        this.isAdultSignature = isChecked;
        this.showResidentialVerification();
    }

    public onInsuredValueChanged(insuredValue: number) {
        this.insuredValue = insuredValue;
        this.isInsuredValueChanged = true;
        this.showInsuredValueAlert();
        this.formattedInsuredValue = this.utilityService.formatMoney(this.insuredValue, this.insuredValueCurrencyCode, this.locale);

        if (this.shipFromAddress) {
            this.shipConfigService.setConfig(
                this.shipFromCountryCode,
                this.shipToCountryCode,
                this.carrier,
                this.user,
                this.customer,
                this.insuredValue,
                this.totalCustomsValue,
                this.shipDateString,
                this.shipFromAddress.UPSPickUpType,
            );

            this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
            this.checkValuationDiscrepancy();
        }
    }

    public openAddressValidationInformationDialog(event) {
        event.preventDefault();

        const title = 'Address Validation Information';
        const message = '<p>' +
            'Our website provides an address validation tool for the address of the recipient you identify. ' +
            'Subject to the accuracy of the United State Postal Service\'s database, the validation tool is designed to ' +
            'distinguish criteria such as whether the address provided is a known U.S. address, ' +
            'whether the address is a business or a commercial address and whether the city, state and zip code are a match. ' +
            'This address validation is for reference only and is not intended to replace your responsibility ' +
            'for determining the accuracy and validity of the recipient\'s address. ' +
            'When the address verification tool identifies an address as residential, ' +
            'a message will pop up giving you the option of selecting an Adult Signature Required (ASR) delivery. ' +
            'If you elect not to select the (ASR) delivery, if the package is delivered to a residential address ' +
            'and is left unattended and a signature is not obtained, you hereby agree that the delivery is a valid delivery. ' +
            'Please see Parcel Pro\'s Terms and Conditions for more details and coverage requirements for residential deliveries.' +
            '</p>';

        this.dialogService.alert(title, message);
    }

    public onCommoditiesChanged(commodities: Commodity[]) {
        this.onFormValueChanged(this.commodities);
        this.commodities = commodities;
        this.shipmentService.setCommodities(this.commodities);
        this.hasHighValueCommodity = this.shipmentService.hasHighValueCommodity();

        if (this.shipFromAddress) {
            this.shipConfigService.setConfig(
                this.shipFromCountryCode,
                this.shipToCountryCode,
                this.carrier,
                this.user,
                this.customer,
                this.insuredValue,
                this.totalCustomsValue,
                this.shipDateString,
                this.shipFromAddress.UPSPickUpType,
            );

            this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
            this.checkValuationDiscrepancy();
        }
    }

    public resetForms() {
        this.serviceType = undefined;

        this.resetComponents([
            this.shipTo,
            this.shipFrom,
            this.shipInfo,
            this.commodityShipInfo,
            this.shipInfoAdd,
            this.shipCommodity,
            this.shipOptions,
            this.shipBillingDetails,
            this.shipElectronicExport,
            this.shipCustomsDocs,
        ]);

        if (this.parentForm) {
            this.parentForm.reset({
                isResidentialVerificationAgreed: false,
                isValuationDiscrepancyAgreed: false,
                skipDeliveryDefense: this.customer?.DDEnabled !== true || this.user?.CountryCode !== 'US',
            });

            this.shouldShowShipToReroute = false;
            this.shouldShowResidentialVerification = false;
            this.isHighValueShipment = false;
            this.shipmentService.resetElectronicExportInformation();
            this.isAESShipment = false;

            if (this.shipFromAddress) {
                this.shouldShowCostEstimate = ['US', 'CA'].includes(this.shipFromAddress.Country);
            }

            this.estimatedCost = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
            this.surcharge = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
            this.totalCharges = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
            this.formattedInsuredValue = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
        }
    }

    public showFormValue(event) {
        event.preventDefault();

        console.log(this.shipmentService.Quote);
    }

    public onPickupTypeChanged(pickupType: string) {
        this.pickupType = pickupType;
        this.isPickupTypeChanged = true;
        this.showInsuredValueAlert();
    }

    public onFormValueChanged(value: any) {
        // [MV3-5989]
        // Checking the type makes sure that it doesn't call on an array which leads to an unexpected behavior.
        // In this case, calling `Object.prototype.hasOwnProperty.call` on an array of commodities somehow
        // makes any statements following the calling statement not be executed.
        if (typeof value === 'object'
            && value !== null
            && Object.prototype.hasOwnProperty.call(value, 'skipDeliveryDefense')) {
            this.shipmentService.Quote.SkipDeliveryDefense = value?.skipDeliveryDefense;
            this.shipmentService.Quote.DDSearch = !this.shipmentService.Quote.SkipDeliveryDefense;
        }

        // [MV3-1675] If a form value is changed, reset the quote ID and tell a user to get a new quote when he tries to get a label.
        const clonedQuote = _.cloneDeep(this.shipmentService.Quote);
        clonedQuote.QuoteId = 'NOID';
        const delta = diff.getDiff(this.lastGetQuotePayload, clonedQuote);

        // Ignore non-user-input value changes.
        delete delta['/IsSavedQuote'];
        delete delta['/QuoteId'];

        // console.log(delta);
        if (!this.lastGetQuotePayload || !Object.keys(delta).length) {
            return;
        }

        this.isQuoteReady = false;
        this.shipmentService.resetQuoteId();
    }

    public onAddressValidationChanged(addressValidation: IAddressValidation) {
        this.isResidentialAddress = addressValidation.AddressType === 1;
        this.shouldShowResidentialVerification = this.isResidentialAddress
            && this.isResidentialCheck
            && !this.isAdultSignature;

        if (!this.isResidentialAddress) {
            this.parentForm.patchValue({
                isResidentialVerificationAgreed: false,
            });
        }

        this.validationService.clearFormControlValidators([
            this.parentForm.controls.isResidentialVerificationAgreed,
        ]);

        if (this.shouldShowResidentialVerification) {
            this.validationService.setFormControlValidators(
                this.parentForm.controls.isResidentialVerificationAgreed,
                Validators.compose([Validators.requiredTrue]),
            );
        }
    }

    public onEeiFilingOptionChanged(eeiOption: string | number) {
        this.isUseAESDirect = eeiOption === '1';

        this.setAESStatus();
    }

    public onForceClearAdultSignatureRequiredChanged(isForce: boolean) {
        this.shouldForceClearAdultSignatureRequired = isForce;
    }

    public openTermsAndConditionsDialog(event) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '70%',
            data: {},
        };

        const dialogRef = this.dialog.open(UserTermsConditionsComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
        });
    }

    public async signUpForDeliveryDefense(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        await this.router.navigate(['/delivery-defense-sign-up']);
    }

    /**
     * We use this function to capture the checkbox change from a user instead of using
     * this.parentForm.get('skipDeliveryDefense')?.valueChanges.
     * That valueChanges approach cannot differentiate the value changes between
     * a user change or a programmatic change.
     */
    public onUserChangeSkipDeliveryDefense(event: MatCheckboxChange): void {
        if (event.checked || this.ddRemainingCredit > 0) {
            return;
        }

        this.deliveryDefenseService.handleZeroCredit(this.ddCredit);
    }

    public isLangFrench(): boolean {
        return sessionStorage.getItem('lang') === 'FR';
    }

    private validatePaperlessInvoiceCapacity(shipFromCountryCode: string, shipToCountryCode: string): void {

        if (!this.shipConfigService.isUps(this.carrier) || !this.carrierKey?.IsDocUpload) {
            return;
        }

        this.paperlessCommercialInvoiceService.validatePaperlessInvoiceCapability(shipFromCountryCode, shipToCountryCode)
            .subscribe(
                (result) => {
                    this.validatePaperlessInvoiceCapabilityResult = result;
                },
            );
    }

    private monitorDeliveryDefenseCreditsUpdate(): void {
        this.deliveryDefenseService.deliveryDefenseCreditsUpdated$.subscribe(
            (ddCredit) => {
                this.ddFullCredit = +ddCredit.TotalScores;
                this.ddRemainingCredit = +ddCredit.ScoresRemaining;
            },
        );
    }

    private monitorShipToAddressTypeChanged(): void {
        this.shipmentService.shipToAddressType$
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(
                (addressType) => {
                    switch (addressType) {
                        // Invalid address
                        case 0:
                            this.shipInfoAdd.setAdultSignature(false);
                            this.confirmInvalidAddress();

                            break;

                        // Residential address
                        case 1: {
                            this.shipInfoAdd.setAdultSignature(false);
                            this.shipTo.shipToEdit.setIsResidential(true);
                            // residentialCheckControl.disable();

                            // [MV3-3060] Do not ask for Adult Signature Required confirmation if config.showAdultSignatureMessage is false.
                            if (this.shipConfigService.isUps(this.carrier) && !this.config?.showAdultSignatureMessage) {
                                return;
                            }

                            const isAccessPointEnabled = this.user.CountryCode === 'US'
                                && this.shipToCountryCode === 'US'

                                // TEMP: [DD-307]
                                // Only show UPS Access Point option for DD subscribers and potential DD subscribers.
                                && this.customer.DDEnabled !== null

                                && this.shipConfigService.isUps(this.carrier);
                            const isFeatureAndAccessPointEnabled = this.environment.feature?.upsAccessPoint && isAccessPointEnabled;

                            if (isFeatureAndAccessPointEnabled && this.customer?.DDEnabled !== true) {
                                if (!this.isRestoreShipment) {
                                    this.shipTo.shipToEdit.openResidentialAccessPointSuggestionDialog();
                                }
                            } else {
                                this.shipTo.shipToEdit.openAdultSignatureRequiredForResidentialAddressDialog();
                            }

                            break;
                        }
                        case 2: // Commercial address
                            this.shipInfoAdd.setAdultSignature(false);
                            this.shipTo.shipToEdit.setIsResidential(false);

                            break;
                        default:
                            break;
                    }
                },
            );
    }

    private monitorDeliveryDefenseOfferDialogClosed(): void {
        this.deliveryDefenseService.offerDialogClosed$.subscribe(
            () => {
                if (this.ddCredit?.ScoresRemaining > 0) {
                    return;
                }

                this.shipmentService.skipDeliveryDefenseFlagChanged$.next(true);
            },
        );
    }

    private monitorShipToZipRestrictionCheckChanged(): void {
        this.shipmentService.shipToZipRestrictionCheckRequested$.subscribe(
            (isRequested) => {
                if (!isRequested) {
                    return;
                }

                const shipToAddress = { ...this.shipmentService.Quote.ShipTo };
                const coverage = this.shipmentService.Quote.InsuredValue;
                const isDataReady = !!shipToAddress?.Zip && coverage > 0;

                if (!isDataReady) {
                    return;
                }

                this.shipmentService.validateShipToZipRestriction({
                    coverage,
                    zip: shipToAddress?.Zip,
                    carrierCode: this.utilityService.getEnumKeyByValue(Carriers, this.carrier)?.toLowerCase(),
                }).subscribe(
                    (res) => {
                        this.shipmentStateService.isShipToZipRestricted.set(res?.isRestricted);
                        this.shipInfoAdd.handleRestrictedShipToZip(res?.isRestricted);
                    },
                );
            },
        );
    }

    private confirmInvalidAddress(): void {
        this.dialogService.confirm(
            'Address Not Recognized',
            'You have entered an invalid address. Please select OK to correct or Cancel to proceed with conditions.',
            '',
            'OK',
            'Cancel',
        ).subscribe(
            (isToFix) => {
                if (isToFix) {
                    return;
                }

                const shouldConfirmAdultSignatureRequired =
                    // this.shipConfigService.isUps(this.carrier)
                    this.config?.showAdultSignatureMessage;
                if (!shouldConfirmAdultSignatureRequired) {
                    return;
                }

                this.dialogService.confirm(
                    'Adult Signature is required',
                    'As a precaution, please note that coverage may be compromised due to an incorrect or invalid address.<br>' +
                    'If this is a residential address, click OK to have the Signature option added to your shipment, ' +
                    'or click Cancel to Not add the Signature option.<br>' +
                    'Please see Parcel Pro\'s Terms and Conditions for more details and coverage requirements for residential deliveries.',
                    '',
                    'OK',
                    'Cancel',
                ).subscribe(
                    (isAutoChecked) => {
                        if (isAutoChecked) {
                            this.shipInfoAdd.setAdultSignature(true);
                        }
                    },
                );
            },
        );
    }

    private setupFormGroup(): void {
        this.parentForm = this.formBuild.group({
            isResidentialVerificationAgreed: [false],
            isValuationDiscrepancyAgreed: [false],
            skipDeliveryDefense: [this.customer?.DDEnabled !== true || this.user?.CountryCode !== 'US'],
        });
    }

    private monitorFormValueChanges(): void {
        this.parentForm.valueChanges.subscribe(
            (value) => {
                this.onFormValueChanged(value);
            });
    }

    private monitorShipConfigChanges(): void {
        this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;
                this.shouldShowEEI = config.showExportInformation && (config.showEEI || config.showMRN);
            },
        );
    }

    private monitorDeliveryDefenseCreditDeductionEvent(): void {
        this.shipmentService.deliveryDefenseCreditDeducted$.subscribe(
            (isDeducted) => {
                if (!isDeducted) {
                    return;
                }

                if (this.ddRemainingCredit > 0) {
                    this.ddRemainingCredit--;
                    this.ddCredit.ScoresRemaining--;

                    // If the remaining credit is 0, we default quote to skip DeliveryDefense.
                    if (this.ddRemainingCredit <= 0) {
                        this.shipmentService.skipDeliveryDefenseFlagChanged$.next(true);
                    }
                }

                this.updateDDCredit(false);
            },
        );
    }

    //#endregion

    private monitorUPSAccessPointSelectorRequest(): void {
        this.shipmentService.accessPointSelectorRequested$.subscribe(
            () => {
                this.cachedSkipDeliveryDefenseFlag = !!this.parentForm?.get('skipDeliveryDefense').value;
            },
        );
    }

    private monitorUPSAccessPointSelection(): void {
        this.shipmentService.accessPointSelected$?.subscribe(
            (isSelected) => {
                this.isUsePickupLocation = isSelected;

                if (isSelected) {
                    this.parentForm?.get('isResidentialVerificationAgreed').setValue(true);
                    this.shipmentService.skipDeliveryDefenseFlagChanged$.next(true);

                    this.shouldShowResidentialVerification = false;
                } else {
                    this.shouldShowResidentialVerification = this.isResidentialAddress && (!this.isResidentialCheck || !this.isAdultSignature);
                }

                this.notifyOptionChanges(isSelected);
            },
        );
    }

    private monitorFedExHoldAtLocationSelectorRequest(): void {
        this.shipmentService.fedexHoldAtLocationRequested$.subscribe(
            () => {
                this.cachedSkipDeliveryDefenseFlag = !!this.parentForm?.get('skipDeliveryDefense').value;
            },
        );
    }

    private monitorFedExHoldAtLocationSelection(): void {
        this.shipmentService.fedexHoldAtLocationSelected$.subscribe(
            (isSelected) => {
                this.isUsePickupLocation = isSelected;

                if (isSelected) {
                    this.parentForm?.get('isResidentialVerificationAgreed').setValue(true);
                    this.shipmentService.skipDeliveryDefenseFlagChanged$.next(true);

                    this.shouldShowResidentialVerification = false;
                } else {
                    this.shouldShowResidentialVerification = this.isResidentialAddress && (!this.isResidentialCheck || !this.isAdultSignature);
                }

                this.notifyOptionChanges(isSelected);
            },
        );
    }

    private monitorSkipDeliveryDefenseFlagChange(): void {
        this.shipmentService.skipDeliveryDefenseFlagChanged$.subscribe(
            (isSelected) => {
                this.parentForm?.get('skipDeliveryDefense')?.setValue(isSelected);

                if (!isSelected) {
                    this.deliveryDefenseService.getRemainingDDCredit()
                        .subscribe(
                            (ddCredit) => {
                                this.ddRemainingCredit = ddCredit?.ScoresRemaining;
                                if (this.ddRemainingCredit <= 0) {
                                    this.deliveryDefenseService.handleZeroCredit(this.ddCredit);
                                }
                            },
                        );
                }

                this.shipmentService.Quote.DDSearch = !isSelected;
            },
        );
    }

    private handleDDCustomer(): void {
        const isUSUser = this.user?.CountryCode === 'US';
        const isDDCustomer = this.customer?.DDEnabled === true;

        if (!isUSUser || !isDDCustomer) {
            return;
        }

        this.getDDCustomerSubscription();
        this.updateDDCredit(false);
    }

    private getDDCustomerSubscription(): void {
        forkJoin({
            ddCredit: this.deliveryDefenseService.getRemainingDDCredit(),
            subscription: this.deliveryDefenseService.getCustomerSubscription(),
        }).subscribe((res) => {
            this.ddRemainingCredit = res?.ddCredit.ScoresRemaining;
            this.ddSubscription = res?.subscription;

            if (!this.ddRemainingCredit || !this.ddSubscription) {
                return;
            }

            const oldSubscriptionTier = this.ddSubscription.CurrentSubType;
            const newSubscriptionTier = this.customer?.MonthlyTierType;
            const isDowngraded = oldSubscriptionTier > newSubscriptionTier && oldSubscriptionTier !== 5;

            // [MV3-6720] Do not show the DD offer dialog if it's a DD downgrade but the user still has some remaining scores under his account.
            if (isDowngraded && this.ddRemainingCredit > 0) {
                return;
            }

            this.deliveryDefenseService.handleSubscriptionTierChanged(oldSubscriptionTier, newSubscriptionTier);
        });
    }

    private updateDDCredit(shouldHandleZeroCredit = true): void {
        this.deliveryDefenseService.getRemainingDDCredit()
            .subscribe(
                (ddCredit) => {
                    if (!ddCredit) {
                        return;
                    }

                    this.ddCredit = ddCredit;
                    this.ddRemainingCredit = +(ddCredit?.ScoresRemaining ?? 0);
                    this.ddFullCredit = +(ddCredit?.TotalScores ?? 0);
                    this.ddSubTypeID = +(ddCredit?.SubTypeID ?? 0);

                    if (!shouldHandleZeroCredit && this.ddRemainingCredit <= 0) {
                        this.shipmentService.skipDeliveryDefenseFlagChanged$.next(true);
                    }

                    if (shouldHandleZeroCredit) {
                        // [MV3-6586]: Ignore 0 DD credit.
                        // this.deliveryDefenseService.handleZeroCredit(ddCredit);
                    }
                },
            );
    }

    private notifyOptionChanges(isSelected: boolean): void {
        if (!isSelected) {
            return;
        }

        const isAdultSignatureChecked = this.shipmentService.Quote.IsDeliveryConfirmation;
        const isShipToResidentialChecked = this.shipmentService.Quote.ShipTo.IsResidential;
        const isCODChecked = this.shipmentService.Quote.IsCod;

        const messages = [
            isAdultSignatureChecked ? 'Adult Signature Required' : null,
            isShipToResidentialChecked ? 'ShipTo Residential Address' : null,
            isCODChecked ? 'COD' : null,
        ].filter(Boolean);

        if (!messages?.length) {
            return;
        }

        this.snackBar.open(
            `${ messages.join(', ') } options have been deselected.`,
            'OK',
            { duration: 5000 });
    }

    private checkCarrierForbidden() {
        if (!this.user) {
            return;
        }

        switch (+Carriers[this.carrier]) {
            case Carriers.Ups:
                this.isForbidden = !this.user.IsUPSDomestic && !this.user.IsUPSInternational;
                break;
            case Carriers.Fedex:
                this.isForbidden = !this.user.IsFedExDomestic && !this.user.IsFedExInternational;
                break;
            case Carriers.Usps:
                this.isForbidden = !this.user.IsUSPSDomestic && !this.user.IsUSPSInternational;
                break;
            default:
                break;
        }
    }

    private loadInitialValues() {
        this.estimatedCost = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
        this.surcharge = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
        this.totalCharges = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
        this.formattedInsuredValue = this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
    }

    private loadCostEstimateValues() {
        const cachedCostEstimate = this.storageService.get('cost-estimate');
        if (!cachedCostEstimate || !Object.keys(cachedCostEstimate).length) {
            return;
        }

        this.shipFrom.fillCostEstimateValues(cachedCostEstimate);
        this.shipTo.fillCostEstimateValues(cachedCostEstimate);
        this.shipInfo.fillCostEstimateValues(cachedCostEstimate);
        this.shipInfoAdd.fillCostEstimateValues(cachedCostEstimate);
        this.shipOptions.fillCostEstimateValues(cachedCostEstimate);

        this.storageService.remove('cost-estimate');
    }

    //#region GetUser handlers
    private handleGetUserSuccess(user: User, shouldRestoreShipment = true) {
        this.user = user;
        this.dateFormat = this.isDEGBUser(this.user) ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        this.shipToCountryCode = user.DefaultShipToCountry ? user.DefaultShipToCountry.toUpperCase() : null;
        this.shipmentService.Quote.ShipTo.Country = this.shipToCountryCode;
        this.shipmentService.Quote.CustomerId = user.CustomerId;

        this.checkCarrierForbidden();
        if (this.isForbidden) {
            return;
        }

        const isDEGBUser = this.isDEGBUser(user);
        const isEuUser = this.isEuUser(user);
        this.insuredValueCurrencyCode = !isEuUser ? 'USD' : (this.isDEUser(user) || this.isITFRUser(user) ? 'EUR' : 'GBP');

        if (this.shipmentService.isDEUser(user)) {
            this.maxCoverageCurrencyCode = 'EUR';
        }
        if (this.shipmentService.isFRITUser(user)) {
            this.maxCoverageCurrencyCode = 'EUR';
        }
        if (this.shipmentService.isGBUser(user)) {
            this.maxCoverageCurrencyCode = 'GBP';
        }

        forkJoin(
            this.userService.getCustomer(),
            this.shipmentService.getContactAddress(user.DefaultLocation),
        ).subscribe(
            (data) => {
                const customer = data[0];
                const shipFromAddress = data[1];

                this.handleGetCustomerSuccess(customer);
                this.handleGetContactAddressSuccess(shipFromAddress);

                this.shipConfigService.restoreConfig(this.carrier, user, customer, shipFromAddress);

                if (this.hasPendingConfigUpdate) {
                    this.shipConfigService.setConfig(
                        this.shipFromCountryCode,
                        this.shipToCountryCode,
                        this.carrier,
                        this.user,
                        this.customer,
                        this.insuredValue,
                        this.totalCustomsValue,
                        this.shipDateString,
                        this.shipFromAddress.UPSPickUpType,
                    );

                    this.hasPendingConfigUpdate = false;

                    this.shipConfigService.loadUserConfig(this.carrierCode, user);
                }

                this.loadCostEstimateValues();

                if (shouldRestoreShipment) {
                    setTimeout(
                        () => {
                            this.restoreShipment();
                        },
                        1000);
                } else {
                    this.resetForms();
                }
            },
            (err) => {
                this.notificationService.notify(
                    this.errorHandlerService.getHttpErrorMessage(err),
                    'Failed getting customer or address',
                    NotificationType.ERROR);
            },
        );
    }

    private handleGetUserFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting current user',
            NotificationType.ERROR);
    }

    private handleGetCustomerSuccess(customer: Customer) {
        this.customer = customer;
        this.shipmentService.setCustomerId(customer.CustomerId);
    }

    private handleGetCustomerFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting customer',
            NotificationType.ERROR);
    }

    //#region GetContactAddress handlers
    private handleGetContactAddressSuccess(location: Location) {
        this.shipFromAddress = _.cloneDeep(location);
        this.saveShipFromData();
    }

    private handleGetContactAddressFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting contact address',
            NotificationType.ERROR);
    }

    private onCarrierChanged() {
        this.shipConfigService.Config = new ShipConfig();
        this.shipConfigService.isDefaultUserConfigApplied = false;

        this.checkCarrierForbidden();
        if (this.isForbidden) {
            return;
        }

        if (this.shipFromAddress) {
            this.shipConfigService.setConfig(
                this.shipFromCountryCode,
                this.shipToCountryCode,
                this.carrier,
                this.user,
                this.customer,
                this.insuredValue,
                this.totalCustomsValue,
                this.shipDateString,
                this.shipFromAddress.UPSPickUpType,
            );

            this.shipConfigService.loadUserConfig(this.carrierCode, this.user);
        }

        if (this.user && this.carrier && this.shipFromCountryCode) {
            this.restrictedShipToCountryCodes =
                this.shipmentService.getRestrictedShipToCountries(this.user, this.carrier, this.shipFromCountryCode);
        }
    }

    private saveShipFromData() {
        if (!this.shipFromAddress) {
            return;
        }

        const data = {
            contactId: this.shipFromAddress.ContactId,
            firstName: this.shipFromAddress.FirstName,
            lastName: this.shipFromAddress.LastName,
            company: this.shipFromAddress.CompanyName,
            zipCode: this.shipFromAddress.Zip,
            city: this.shipFromAddress.City,
            state: this.shipFromAddress.State,
            country: this.shipFromAddress.Country,
            address1: this.shipFromAddress.StreetAddress,
            address2: this.shipFromAddress.ApartmentSuite,
            email: this.shipFromAddress.Email,
            phone: this.shipFromAddress.TelephoneNo,
            pickupType: this.shipFromAddress.UPSPickUpType,
        };

        this.shipmentService.saveShipFrom(data);
    }

    private saveShipToData() {
        const data = {
            contactId: this.shipToAddress.ContactId,
            firstName: this.shipToAddress.FirstName,
            lastName: this.shipToAddress.LastName,
            company: this.shipToAddress.CompanyName,
            zipCode: this.shipToAddress.Zip,
            city: this.shipToAddress.City,
            state: this.shipToAddress.State,
            country: this.shipToAddress.Country,
            address1: this.shipToAddress.StreetAddress,
            address2: this.shipToAddress.ApartmentSuite,
            email: this.shipToAddress.Email,
            phone: this.shipToAddress.TelephoneNo,
            pickupType: this.shipToAddress.UPSPickUpType,
        };

        this.shipmentService.saveShipTo(data);
    }

    private restoreValuesFromQuote() {
        if (!this.shipmentService.Quote) {
            return;
        }

        this.isQuoteReady = !!this.shipmentService.Quote && (this.shipmentService.Quote.QuoteId !== 'NOID');
        this.estimatedCost = this.shipmentService.Quote.Estimator && this.shipmentService.Quote.Estimator.length ?
            this.shipmentService.Quote.Estimator[0].ShippingCost + this.shipmentService.Quote.Estimator[0].InsuranceCost :
            0;
        this.surcharge = this.shipmentService.Quote.Estimator && this.shipmentService.Quote.Estimator.length ?
            this.shipmentService.Quote.Estimator[0].AccessorialsCost :
            0;
        this.totalCharges = this.shipmentService.Quote.Estimator && this.shipmentService.Quote.Estimator.length ?
            this.shipmentService.Quote.Estimator[0].TotalCharges :
            0;

        this.isQuoteHasLabel = !!this.shipmentService.Quote.LabelImageFull;

        this.shipFromCountryCode = this.shipmentService.Quote.ShipFrom.Country.toUpperCase();
        this.shipToCountryCode = this.shipmentService.Quote.ShipTo.Country;
        this.isDomestic = this.shipConfigService.isDomestic(this.shipFromCountryCode, this.shipToCountryCode);
    }

    private notifyIfStatementsNotAgreed(): boolean {
        const isResidentialVerificationNotAgreed = this.parentForm.controls.isResidentialVerificationAgreed.errors
            && this.parentForm.controls.isResidentialVerificationAgreed.errors.requiredTrue !== null;
        const isValuationDiscrepancyNotAgreed = this.parentForm.controls.isValuationDiscrepancyAgreed.errors
            && this.parentForm.controls.isValuationDiscrepancyAgreed.errors.requiredTrue !== null;

        const isStatementsNotAgreed = isResidentialVerificationNotAgreed || isValuationDiscrepancyNotAgreed;

        if (isStatementsNotAgreed) {
            this.notificationService.notify('All agreement statements must be agreed.', 'Statements Not Agreed', NotificationType.ERROR);
        }

        return isStatementsNotAgreed;
    }

    private isHVExceeded() { // MV3-4394 - more conditions added
        if ((this.formValidationErrors.filter(x => x.keyControl === 'insuredValue' && x.keyError === 'max').length > 0) ||
            // (+this.serviceType.DefaultMaxValue != 0 && +this.insuredValue > +this.serviceType.DefaultMaxValue) ||
            (+this.serviceType.CustomerServiceHighValue !== 0 && +this.insuredValue > +this.serviceType.CustomerServiceHighValue)) {
            return true;
        }
    }

    //#endregion

    private handleHVExceed() {
        const phone = this.isDEUser(this.user) ? '+49 69 6630 8330' : '+44 20 7949 0144';
        const msg = this.translateService.instant('highValueExceedMsg') + phone;
        this.dialogService.confirm(
            this.translateService.instant('highValueExceedTitle'),
            msg, '', this.translateService.instant('Confirm'),
            this.translateService.instant('hvCancel')).subscribe((isConfirmed) => {
            if (isConfirmed) {
                this.requestHighValueincrease(this.shipmentService.Quote);
            }
        });
    }

    private requestHighValueincrease(quote: Package) {
        const val = {
            InsuredValue: quote.InsuredValue,
            ServiceCode: quote.ServiceCode,
            CarrierCode: Carriers[quote.CarrierCode],
            IsReportOnly: 0,
        };
        this.shipmentService.requestHighValueIncrease(val).subscribe(
            (x) => {
                // eslint-disable-next-line no-self-assign
                window.location = window.location;
            },
            (err) => {
                this.notificationService.notify(
                    this.errorHandlerService.getHttpErrorMessage(err),
                    'High Value Increase Request Failed',
                    NotificationType.ERROR);
            });
    }

    private handleSaveQuoteSuccess() {
        this.notificationService.notify('The quote has been successfully saved.', 'Quote Saved', NotificationType.SUCCESS);
        this.isQuoteReady = true;
        this.shouldEnableSaveQuote = false;
    }

    private handleSaveQuoteFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed saving quote',
            NotificationType.ERROR);
    }

    private handleSubmitToACESuccess() {
        this.notificationService.notify('The quote has been submitted to AES.', 'SUCCESS!', NotificationType.SUCCESS);
        this.spinnerService.hide();

        if (this.isMobileWebView) {
            window.location.href = `/pending-shipments`;
            return;
        }

        this.router.navigate(['/pending-shipments']);
    }

    private handleSubmitToACEFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed submitting a quote to AES.',
            NotificationType.ERROR);
        this.spinnerService.hide();
    }

    private handleSuccessfulQuoteAdd(quote: Package, shouldConfirmShipment = false) {
        this.responseQuote = quote;

        if (!quote) {
            return;
        }

        this.shipmentService.Quote.QuoteId = quote.QuoteId;
        this.isQuoteReady = true;
        this.isQuoteHasLabel = false;
        this.estimatedCost = quote.Estimator.length ?
            this.utilityService.formatMoney(
                quote.Estimator[0].ShippingCost + quote.Estimator[0].InsuranceCost,
                this.insuredValueCurrencyCode, this.locale) :
            this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
        this.surcharge = quote.Estimator.length ?
            this.utilityService.formatMoney(quote.Estimator[0].AccessorialsCost, this.insuredValueCurrencyCode, this.locale) :
            this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
        this.totalCharges = quote.Estimator.length ?
            this.utilityService.formatMoney(quote.Estimator[0].TotalCharges, this.insuredValueCurrencyCode, this.locale) :
            this.utilityService.formatMoney(0, this.insuredValueCurrencyCode, this.locale);
        this.shouldShowShipToReroute = quote.IsShipToRestrictedZip;

        this.isHighValueShipment = quote.IsHighValueShipment;

        // TODO: the EeiFilingOption is missing from the API. Therefore, we temporarily use the value from the package.
        this.responseQuote.EeiFilingOption = this.shipmentService.Quote.EeiFilingOption;
        this.setAESStatus();

        // Overwrite BillToAccount with the one in the form.
        this.responseQuote.BillToAccount = this.shipmentService.Quote.BillToAccount.toUpperCase();

        const confidenceLevel = this.shipmentService.getAddressConfidenceLevel(quote.DDScore?.score);
        const isHighConfidenceAddress = quote.DDSearch && confidenceLevel === 'high';
        const isAverageCommercialAddress = quote.DDSearch && confidenceLevel === 'average' && quote.DDScore?.resiComFlag === 'Commercial';
        const canConfirmShipment = !this.shouldShowDDScoreDialog || isHighConfidenceAddress;

        if (shouldConfirmShipment && canConfirmShipment) {
            this.confirmShipment(null);
        } else {
            const isDDUser = this.customer?.DDEnabled !== null;
            const isUsingDD = quote.DDSearch;
            const isTrustedAddress = isHighConfidenceAddress || isAverageCommercialAddress;

            const shouldShowSuccessMessage = !shouldConfirmShipment
                && (!isDDUser
                    || !isUsingDD
                    || !isTrustedAddress);

            if (shouldShowSuccessMessage) {
                this.notificationService.notify('Shipment quote has successfully been created.', 'Success', NotificationType.SUCCESS);
            }

            this.isFormSubmitting = false;
            this.spinnerService.hide();
        }

        if (quote.DDSearch) {
            this.shipmentService.Quote.UseDeliveryDefense = true;
            quote.DDScore.isCached = +quote.DDSearchStatus === 2;

            if (+quote.DDSearchStatus === 1) {
                // this.shipmentService.deliveryDefenseCreditDeducted$.next(true);
                if (this.ddRemainingCredit > 0) {
                    this.ddRemainingCredit--;
                    this.ddCredit.ScoresRemaining--;
                }
            }

            this.shipmentService.Quote.DDScore = quote.DDScore;

            if (quote.DDScore?.address === null) {
                this.shipmentService.ddNoScoreAttemptCount++;
            } else {
                this.shipmentService.ddNoScoreAttemptCount = 0;
            }

            // Set to `true` to skip the congrats dialog for high confidence addresses.
            const shouldSkipCongratsDialog = false;

            if (isHighConfidenceAddress && shouldSkipCongratsDialog) {
                this.notificationService.notify(
                    `This ${ quote?.DDScore?.resiComFlag?.toLowerCase() } address has a high confidence score associated with it.`,
                    this.translateService.instant('addressConfidence.high.congratulations'),
                    NotificationType.SUCCESS,
                );
            } else {
                if (this.shouldShowDDScoreDialog) {
                    this.openDeliveryDefenseScoreDialog(quote.ShipTo, quote.DDScore, shouldConfirmShipment);
                }
            }
        }

        this.shipmentStateService.isShipToZipRestricted.set(quote?.IsShipToRestrictedZip);
        this.validateAllForms();
        this.shouldEnableSaveQuote = true;
        // this.openShipmentDetailsDialog(this.shipmentService.Quote);

        this.shipmentService.requestShipToAddressValidation$.next(false);
    }

    private handleFailedQuoteAdd(err) {
        this.spinnerService.hide();
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed creating shipment',
            NotificationType.ERROR);
        this.isFormSubmitting = false;
    }

    private handleCreateHighValueShipmentSuccess() {
        this.goToShipmentDetails();
        this.spinnerService.hide();
    }

    private handleCreateHighValueShipmentFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Creating High-Value Shipment',
            NotificationType.ERROR);
        this.spinnerService.hide();
    }

    private goToShipmentDetails() {
        if (!this.shipmentService.DetailsQuote) {
            this.shipmentService.DetailsQuote = new Package();
        }

        Object.assign(this.shipmentService.DetailsQuote, this.responseQuote);

        if (this.shipmentService.Quote.UseAccessPoint) {
            this.shipmentService.DetailsQuote.ShipToResidential = false;
        }

        this.storageService.set('shipment-details', this.shipmentService.DetailsQuote);
        this.shipmentService.clearQuote();

        this.isFormSubmitting = false;
        this.spinnerService.hide();
        this.isQuoteReady = false;
        this.isQuoteHasLabel = true;

        const queryParams = this.getQuoteQueryParameters(this.responseQuote);

        if (this.isMobileWebView) {
            const queryString = Object.keys(queryParams).map(key => `${ key }=${ queryParams[key] }`).join('&');
            window.location.href = `/ship/details?${ queryString }`;
            return;
        }

        this.router.navigate(['/ship/details'], { queryParams });
    }

    private getQuoteQueryParameters(quote: Package): any {
        const shipmentType = quote.IsHighValueShipment ? 'highvalue' : 'normal';
        return {
            type: shipmentType,
            shipmentid: quote.ShipmentId,
            trackingnumber: quote.TrackingNumber,
            carriercode: this.carrierCode.toLowerCase(),
        };
    }

    //#region ConfirmShipment handlers
    private handleConfirmShipmentSuccess(quote: Package) {
        const contactIdChange = this.shipmentService.Quote.ShipFrom.ContactId;
        quote.ShipmentReason = this.shipmentService.Quote.ShipmentReason;
        quote.ShipFrom.ContactId = contactIdChange;
        this.storageService.set('shipment-details', quote);
        // this.notificationService.notify('Shipment has been confirmed', 'Success', NotificationType.SUCCESS);

        // // Extract required properties from the shipment package for use with the shipment details.
        // const requiredProperties = [
        //   // 'BillToAccount', // Use the one from the form instead of from the returned shipment.
        //   'ConfirmationNumber',
        //   'LabelImage',
        //   'LabelImageFull',
        //   'InternalTrackingNumber',
        //   'ShipmentId',
        //   'ShipFromRerouting',
        //   'ShipToRerouting',
        //   'TrackingNumber'];
        // requiredProperties.forEach(
        //   (property) => {
        //     this.responseQuote[property] = quote[property];
        //   },
        // );

        this.responseQuote = quote;

        // [MV3-2221] Delete the saved quote after it has been confirmed.
        // [MV3-2229] Remove the call to delete the saved quote after confirming a shipment.
        // this.savedQuoteService.deleteQuote(quote.QuoteId).subscribe();

        this.shipTo?.shipToEdit?.resetForm();
        this.shipFrom?.shipFromEdit?.resetForm();

        this.goToShipmentDetails();
    }

    private handleConfirmShipmentFailure(err) {
        this.spinnerService.hide();
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed confirming shipment',
            NotificationType.ERROR);
        this.isFormSubmitting = false;
        this.isQuoteReady = false;
        this.isQuoteHasLabel = true;
    }

    private setShipFromCurrency(countryCode: string) {
        if (!this.shipFromCountries) {
            return;
        }

        const targetCountry = this.shipFromCountries.find(country => country.CountryCode === countryCode);

        if (targetCountry && targetCountry.CurrencyCode) {
            this.shipFromCurrencyCode = targetCountry.CurrencyCode;
            return;
        }

        this.shipFromCurrencyCode = this.countryService.getCurrency(this.shipFromAddress.Country);
    }

    private isValidCommodity(): boolean {
        const isInternationalShipping = this.shipFromCountryCode !== this.shipToCountryCode;
        if (!isInternationalShipping) {
            return true;
        }

        const hasCommodity = !!this.commodities && this.commodities.length > 0;
        if (hasCommodity) {
            return true;
        }

        this.notificationService.notify(
            'At least one commodity is required for an international shipping.',
            'Commodity Required',
            NotificationType.ERROR);

        return false;
    }

    private setCountryMaxCoverage(countryCode: string) {
        // this.carrierCountries = this.storageService.get('carrier-countries');
        // if (this.carrierCountries) {
        //     this.shipToCountry = this.carrierCountries.find(item => item.CountryCode === countryCode);
        //     if (this.shipToCountry) {
        //         this.countryMaxCoverage = this.shipToCountry.MaxCoverage;
        //     }
        //
        //     return;
        // }

        this.utilityService.clearSubscriptions([this.getShipToCountriesSubscription]);
        this.getShipToCountriesSubscription =
            this.shipmentService.getShipToCountries(this.carrier)
                .pipe(
                    catchError((err) => {
                        this.handleGetShipToCountriesFailure(err);
                        return of(null);
                    }),
                )
                .subscribe(
                    countries => this.handleGetShipToCountriesSuccess(countries, countryCode),
                );
    }

    private handleGetShipToCountriesSuccess(countries: ICountry[], shipToCountryCode: string) {
        this.carrierCountries = countries;
        // this.storageService.set('carrier-countries', countries);

        this.shipToCountry = countries.find(item => item.CountryCode === shipToCountryCode);
        if (this.shipToCountry) {
            this.countryMaxCoverage = this.shipToCountry.MaxCoverage;
        }
    }

    private handleGetShipToCountriesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Getting Carrier Countries',
            NotificationType.ERROR);
    }

    private checkValuationDiscrepancy() {
        this.validationService.clearFormControlValidators([
            this.parentForm.controls.isValuationDiscrepancyAgreed,
        ]);

        const isShipFromUSToInternational = this.shipFromCountryCode === 'US' && this.shipToCountryCode !== 'US';
        if (!isShipFromUSToInternational) {
            this.shouldShowValuationDiscrepancy = false;
            return;
        }

        // [MV3-1334] Show the discrepancy message only if it's shipped from US
        // and there is 100+ USD difference between the insured value and the customs value.
        // [MV3-1548] added condition: AND only if there is at least one commodity.
        this.shouldShowValuationDiscrepancy = this.shipFromCountryCode === 'US'
            && (Math.abs(this.insuredValue - this.totalCustomsValue) > 99)
            && (!!this.commodities && !!this.commodities.length);

        if (this.shouldShowValuationDiscrepancy) {
            this.validationService.setFormControlValidators(
                this.parentForm.controls.isValuationDiscrepancyAgreed,
                Validators.compose([Validators.requiredTrue]),
            );
        }
    }

    // [MV3-2785] adjusted logic to set config.highValueCommodity to check if it should override country logic
    // [MV3-4487][MV3-4787] if ShipFrom is US and Ship To is any of these countries(CN, VE, RU, HK),
    // then it should be considered as AES regardless of value

    private openShipmentDetailsDialog(quote: Package) {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            // width: '80%',
            data: {
                quote,
            },
        };

        const dialogRef: MatDialogRef<ShipDetailsDialogComponent> = this.dialog.open(ShipDetailsDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (result) => {
                if (!result) {
                    return;
                }
            },
        );
    }

    private checkSchedulePickupDate() {
        if (!this.shipFromAddress ||
            this.shipFromAddress.ContactId === 'NOID' ||
            !this.shipFromAddress.ContactId ||
            !this.carrier ||
            !this.shipmentService.Quote ||
            !this.shipmentService.Quote.IsPickUpRequested ||
            !this.serviceType) {
            return;
        }

        if (this.checkSchedulePickupSubscription) {
            this.checkSchedulePickupSubscription.unsubscribe();
            this.checkSchedulePickupSubscription = null;
        }

        this.checkSchedulePickupSubscription = this.shipmentService.checkSchedulePickup(
            this.shipFromAddress.ContactId,
            this.shipmentService.Quote.PickUpDate,
            this.carrier,
            this.serviceType.ServiceCode, // this.shipmentService.Quote.ServiceCode,
        )
                                                   .subscribe(
                                                       result => this.handleCheckSchedulePickupSuccess(result),
                                                       err => this.handleCheckSchedulePickupFailure(err),
                                                   );
    }

    private handleCheckSchedulePickupSuccess(result) {
        this.isExistingSchedulePickup = !!result;

        if (this.isExistingSchedulePickup) {
            this.notificationService.notify(
                'You already have a pickup scheduled for this location. Please select a different pickup option.',
                'Pickup Already Scheduled',
                NotificationType.WARNING);
        }
    }

    private handleCheckSchedulePickupFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed checking schedule pickup.',
            NotificationType.ERROR);
    }

    private showResidentialVerification() {
        if (!this.isResidentialAddress) {
            return;
        }

        // [MV3-3060] Remove the Adult Signature Required condition from showing/hiding the residential verification message.
        if ((this.shipConfigService.isUps(this.carrier)
                && !this.config?.showAdultSignatureMessage)
            || this.isUsePickupLocation) {
            this.shouldShowResidentialVerification = false;
        } else {
            this.shouldShowResidentialVerification = (!this.isResidentialCheck || !this.isAdultSignature);
        }

        this.validationService.clearFormControlValidators([
            this.parentForm.controls.isResidentialVerificationAgreed,
        ]);

        if (this.shouldShowResidentialVerification) {
            this.validationService.setFormControlValidators(
                this.parentForm.controls.isResidentialVerificationAgreed,
                Validators.compose([Validators.requiredTrue]),
            );
        }
    }

    private resetComponents(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return true;
                }

                component.resetForm();
            },
        );
    }

    private resetCommodityRelatedComponents() {
        this.resetComponents([
            this.commodityShipInfo,
            this.shipCommodity,
        ]);
    }

    // private shouldShowEEI() {
    //   return this.config.showExportInformation && (this.config.showEEI || this.config.showMRN);
    // }

    private forceValidation() {
        this.markComponentFormAsDirty([
            this.shipTo,
            this.shipFrom,
            this.shipInfo,
            this.commodityShipInfo,
            this.shipInfoAdd,
            this.shipCommodity,
            this.shipOptions,
            this.shipBillingDetails,
            this.shipElectronicExport,
            this.shipCustomsDocs,
        ]);

        this.formService.markAllAsTouchedAndDirty(this.parentForm, false);
    }

    private markComponentFormAsDirty(components: IShipComponent[]) {
        components.forEach(
            (component) => {
                if (!component) {
                    return;
                }

                component.forceValidation();
            },
        );
    }

    // MV3-4802 - remove HK for AES
    private setAESStatus() {
        this.isAESShipment = ((this.shipmentService.hasHighValueCommodity() &&
                this.isUseAESDirect && ['US', 'VI', 'PR'].includes(this.shipFromCountryCode)) ||
            ((this.shipFromCountryCode === 'US'
                    && (this.shipToCountryCode === 'CN'
                        || this.shipToCountryCode === 'VE'
                        || this.shipToCountryCode === 'RU'))
                && this.isUseAESDirect));
        try {
            this.hasHighValueCommodity = this.config.highValueCommodity = this.shipmentService.hasAESCommodity(this.commodities);
        } catch (e) {
            this.hasHighValueCommodity = false;
        }

        if (this.isAESShipment && !this.responseQuote) {
            this.shipmentService.resetElectronicExportInformation();
        }

        this.isEEIRequired = (this.isAESShipment && !this.isNAFTA);
    }

    private showInsuredValueAlert() {
        if (!this.isInsuredValueChanged || !this.isPickupTypeChanged) {
            return;
        }

        const isUps = +Carriers[this.carrier] === +Carriers.Ups;
        if (this.insuredValue <= 50000 || !isUps || this.pickupType !== '02') {
            return;
        }

        this.dialogService.alert(
            '',
            'Shipments valued $50,001 and greater may not be tendered to The UPS Store. Select another option for tender.')
            .subscribe(
                () => {
                    this.shipOptions.setPickupType('01');
                },
            );
    }

    private restoreShipment(): void {
        const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
        if (!cachedShipment || !Object.keys(cachedShipment).length) {
            return;
        }

        this.spinnerService.show();
        this.cleanUpCachedShipment(cachedShipment);
        this.cachedShipment = cachedShipment;

        this.shipmentService.Quote = _.cloneDeep(Object.assign(new Package(), cachedShipment));
        this.shipFrom.restoreShipment(cachedShipment);
        this.shipTo.restoreShipment(cachedShipment);

        setTimeout(
            () => {
                this.shipInfo.restoreShipment(cachedShipment);
            },
            2000);

        this.shipInfoAdd.restoreShipment(cachedShipment);
        this.shipOptions.restoreShipment(cachedShipment);
        this.shipBillingDetails.restoreShipment(cachedShipment);

        if (this.commodityShipInfo) {
            this.commodityShipInfo.restoreShipment(cachedShipment);
        }

        if (this.shipCommodity) {
            this.shipCommodity.restoreShipment(cachedShipment);
        }

        if (this.shipElectronicExport) {
            this.shipElectronicExport.restoreShipment(cachedShipment);
        }

        if (this.shipCustomsDocs) {
            this.shipCustomsDocs.restoreShipment(cachedShipment);
        }

        if (this.shouldClearCachedAfterRestored) {
            setTimeout(
                () => {
                    this.storageService.remove('shipment-edit');
                    this.storageService.remove('reship');
                    this.isRestoreShipment = false;
                },
                10000);
        }

        // this.isRestoreShipment = false;
        // this.showFormErrors(null);

        setTimeout(() => { this.spinnerService.hide(); });
    }

    private cleanUpCachedShipment(cachedShipment: Package) {
        delete cachedShipment['InputType'];
        delete cachedShipment['IsMaxCoverageExceeded'];
        delete cachedShipment['SaveQuote'];
        delete cachedShipment['TotalShipments'];
        delete cachedShipment['BillToCustomerID'];
        delete cachedShipment.Estimator;
        cachedShipment.EstimatedCost = null;
        cachedShipment.LabelImage = null;
        cachedShipment.LabelImageFull = null;
        cachedShipment.ConfirmationNumber = '';
        cachedShipment.InternalTrackingNumber = '';
        cachedShipment.TrackingNumber = '';
        cachedShipment.Status = 0;
        cachedShipment.TotalCharges = null;
        cachedShipment.PhysicalStatus = 0;
        cachedShipment.QuoteId = 'NOID';
        cachedShipment.ShipmentId = 'NOID';
        cachedShipment.ShipTo.UserId = this.user.UserId;
        cachedShipment.WeightUOM = ['US', 'CA'].includes(cachedShipment.ShipFrom.Country) ? 'LBS' : 'KGS';

        if (cachedShipment.CustomerReferenceNumber) {
            cachedShipment.CustomerReferenceNumber = cachedShipment.CustomerReferenceNumber.replace(/\*Return Label\*/ig, '')
                                                                   .trim();
        }
    }

    private setEUFieldsVisibility(): void {
        if (!this.shipFromCountryCode || !this.shipToCountryCode) {
            this.shouldDisplayShipFromEUFields = false;
            return;
        }

        this.shouldDisplayShipFromEUFields =
            (this.shipConfigService.isEuToNonEu(this.shipFromCountryCode, this.shipToCountryCode) ||
                this.shipConfigService.isInterEU(this.shipFromCountryCode, this.shipToCountryCode)) &&
            !this.shipConfigService.isEUDomestic(this.shipFromCountryCode, this.shipToCountryCode) &&
            !this.shipConfigService.isNonEuToNonEu(this.shipFromCountryCode, this.shipToCountryCode);
        this.shouldDisplayShipToEUFields =
            (this.shipConfigService.isNonEuToEu(this.shipFromCountryCode, this.shipToCountryCode) ||
                this.shipConfigService.isInterEU(this.shipFromCountryCode, this.shipToCountryCode)) &&
            !this.shipConfigService.isEUDomestic(this.shipFromCountryCode, this.shipToCountryCode) &&
            !this.shipConfigService.isNonEuToNonEu(this.shipFromCountryCode, this.shipToCountryCode);
    }

    private openDeliveryDefenseScoreDialog(address: Partial<Contact>,
                                           ddScore: IDeliveryDefenseScore,
                                           shouldConfirmShipment: boolean,
    ): void {
        this.shipmentService.cacheQuote();

        const score = ddScore?.score;
        const addressType = ddScore?.resiComFlag?.toLowerCase();

        const inputs = {
            address,
            addressType,
            value: score,
            carrier: this.carrier,
            isCachedScore: ddScore?.isCached,
        };

        const outputs = {
            // output1: (value: any) => { console.log(value); },
            // output2: (value: any) => { console.log(value); },
        };

        const confidenceLevel = this.shipmentService.getAddressConfidenceLevel(score);
        const shouldAutoClose = confidenceLevel === 'high'
            || (confidenceLevel === 'average' && addressType === 'commercial' && shouldConfirmShipment);

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '66%',
            panelClass: 'mobile-fullscreen-dialog',
            data: {
                inputs,
                outputs,
                duration: 5000,
                disableAutoClose: !shouldAutoClose,
                component: AddressConfidenceComponent,
            },
        };

        const dialogRef = this.dialog.open(AutoCloseDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((action) => {
            this.isActionFromDDScoreDialog = true;

            if (confidenceLevel === 'average' && addressType === 'commercial') {
                action = 'average';

                // For this case of an auto close dialog (average-confidence commercial address),
                // we'll want to show the dialog again if a user gets quote again.
                this.isActionFromDDScoreDialog = false;
            }

            // If a user cancels the dialog (no offered option is selected),
            // the next get-quote call (with DDSearch is true) should show the DD score dialog upon getting its response.
            // this.shouldShowDDScoreDialog = !action;

            if (!action) {
                this.isActionFromDDScoreDialog = false;
                this.shipmentService.accessPointSelected$.next(false);
                this.shipmentService.fedexHoldAtLocationSelected$.next(false);
                this.shipmentService.skipDeliveryDefenseFlagChanged$.next(
                    this.cachedSkipDeliveryDefenseFlag
                    || this.ddCredit.ScoresRemaining === 0,
                );

                return;
            }

            /**
             * Setting DDSearch to false tells the API not to make a DD search for the subsequent get-quote calls.
             * This option is disabled for now since we agreed to always make a DD search
             * so that the newly created quote is inserted into DDLookups table.
             * Since it's a search on the same address, the user will not be charged for this additional search anyway.
             * This will also make the DD Score Card be displayed on the shipment details page.
             */
            this.shipmentService.Quote.DDSearch = true;

            switch (action) {
                case 'pickupLocation':
                    // this.parentForm.get('skipDeliveryDefense')?.setValue(false, { emitEvent: false });

                    // Get quote with the selected Access Point or HoldAt location, then create shipment.
                    this.getQuoteAndShip(null, null, true);
                    break;
                case 'low':
                    // [MV3-6642]: Cancel setting insured value to 0 for low-confidence addresses.
                    // this.shipInfo.setInsuredValue(0);

                    // create shipment with the current quote.
                    this.getQuoteAndShip(null, null, true);
                    break;

                case 'average':
                    if (addressType === 'residential') {
                        this.shipInfoAdd.setAdultSignature(true);
                        this.getQuoteAndShip(null, null, true);
                    }

                    // Average commercial address is the only case that we check if we want to create a shipment.
                    // This is due to the setup that automatically closes the score card dialog without an input from a user.
                    if (addressType === 'commercial' && shouldConfirmShipment) {
                        // create shipment with the current quote.
                        this.confirmShipment(null);
                    }

                    break;
                case 'direct-signature':
                    // set direct signature to true, get quote, then get shipment.
                    this.shipInfoAdd.forceSelectDirectSignature();
                    this.getQuoteAndShip(null, null, true);
                    break;
                case 'no-signature':
                    this.shipInfoAdd.setDirectSignature(false);

                    // [MV3-6642]: Cancel setting insured value to 0 for low-confidence addresses.
                    // this.shipInfo.setInsuredValue(0);

                    this.getQuoteAndShip(null, null, true);
                    break;
                case 'none':
                    // create shipment with the current quote.
                    this.confirmShipment(null);
                    break;
                case 'cancel':
                    this.isActionFromDDScoreDialog = false;
                    break;
                default:
                    break;
            }
        });
    }

    private sanitizeShipTo(shipTo: Partial<Contact>): void {
        if (!shipTo) {
            return;
        }

        // obj: { [key: string]: any }
        // for (const key in obj) {
        //     if (obj.hasOwnProperty(key)) {
        //         obj[key] = obj[key] ?? '';
        //     }
        // }

        shipTo.CompanyName = shipTo.CompanyName ?? '';
        shipTo.FirstName = shipTo.FirstName ?? '';
        shipTo.LastName = shipTo.LastName ?? '';
        shipTo.ApartmentSuite = shipTo.ApartmentSuite ?? '';
        shipTo.StreetAddress = shipTo.StreetAddress ?? '';
        shipTo.City = shipTo.City ?? '';
        shipTo.State = shipTo.State ?? '';
        shipTo.Zip = shipTo.Zip ?? '';
    }
}

import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { StorageService } from '../../core/services/storage/storage.service';
import {
    PaperlessCommercialInvoiceSettingsDialogComponent,
} from '../../shared/components/paperless-commercial-invoice/components/paperless-commercial-invoice-settings-dialog/paperless-commercial-invoice-settings-dialog.component';
import {
    PaperlessCommercialInvoiceTermsDialogComponent,
} from '../../shared/components/paperless-commercial-invoice/components/paperless-commercial-invoice-terms-dialog/paperless-commercial-invoice-terms-dialog.component';
import { ICustomerImage } from '../../shared/components/paperless-commercial-invoice/models/customer-image.interface';
import {
    IPaperlessCommercialInvoiceCapability,
} from '../../shared/components/paperless-commercial-invoice/models/paperless-commercial-invoice-capability.interface';
import {
    PaperlessCommercialInvoiceService,
} from '../../shared/components/paperless-commercial-invoice/services/paperless-commercial-invoice.service';
import { Carriers } from '../../shared/enum/general-enum';
import { ICarrierKey } from '../../shared/models/carrier-key.interface';
import { Customer } from '../../shared/models/customer/customer.model';
import { CustomerService } from '../../shared/services/customer/customer.service';
import { FormService } from '../../shared/services/form/form.service';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { Package } from '../models/package.model';
import { IShipComponent } from '../models/ship-component.interface';
import { ShipConfig } from '../models/ship-config.model';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipmentService } from '../services/shipment.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'upsc-ship-customs-docs',
    templateUrl: './ship-customs-docs.component.html',
    styleUrls: ['./ship-customs-docs.component.scss'],
})
export class ShipCustomsDocsComponent implements OnInit, OnDestroy, OnChanges, IShipComponent {
    @Input() shipFromCountryCode: string;
    @Input() shipToCountryCode: string;
    @Input() carrier: Carriers;
    @Input() carrierKey: ICarrierKey;
    @Input() customer: Customer;
    @Input() customerImages: ICustomerImage[];
    @Input() validatePaperlessInvoiceCapabilityResult: any;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

    public formGroup: UntypedFormGroup;
    public isNAFTA = false;
    public config: ShipConfig;
    public shouldAllowInvoiceUpload = false;
    public shouldAllowInvoiceCreation = false;
    public uploadErrorMessage: string;
    public uploadedFile: File;
    public showRemoveFileHint = false;
    public showRemoveImageHint = false;
    public isPageLoad = false;

    private isUps = false;
    private isEnrolledForUpsPaperlessCommercialInvoice = false;
    private isEnrolledForParcelProPaperlessCommercialInvoice = false;
    private isUSCustomer = false;
    private isPaperlessInvoiceApplicable = false;
    private fileSizeLimit = 8000000;

    private getCustomerImagesSubscription: Subscription;
    private deleteCustomerDocumentsSubscription: Subscription;

    public isPaperlessCommercialInvoiceDialogOpened: boolean = false;
    
    public constructor(private formBuilder: UntypedFormBuilder,
                       private validationService: ValidationService,
                       private formService: FormService,
                       private readonly customerService: CustomerService,
                       private paperlessCommercialInvoiceService: PaperlessCommercialInvoiceService,
                       private utilityService: UtilityService,
                       private dialog: MatDialog,
                       private cdRef: ChangeDetectorRef,
                       private shipConfigService: ShipConfigService,
                       private shipmentService: ShipmentService,
                       private readonly storageService: StorageService,
                       private route: ActivatedRoute,
    ) {
        this.formGroup = this.formBuilder.group({
            isCreateCommercialInvoices: [false],
            isNAFTA: [false],
            invoiceOption: [''],
            uploadedInvoice: [null],
            invoiceFileType: [''],
            invoiceMimeType: [''],
        });

        this.monitorValueChanges();

        this.config = this.shipConfigService.Config;
    }

    public ngOnInit() {        
        const prefetch = this.route.snapshot.data?.prefetch;
        this.customer = prefetch?.customer;
        this.setFormValues();
        const cachedShipment: Package = this.storageService.get<Package>('shipment-edit');
        if (cachedShipment) {
            this.restoreShipment(cachedShipment);
        }
    }

    public ngOnDestroy() {
        this.utilityService.clearSubscriptions([
            this.getCustomerImagesSubscription,
            this.deleteCustomerDocumentsSubscription,
        ]);

        this.formGroup.patchValue({
            invoiceOption: '',
            uploadedInvoice: null,
            invoiceFileType: '',
            invoiceMimeType: '',
        });

        this.shipmentService.saveCustomsDocumentation(this.formGroup.value);
    }

    public resetForm() {
        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            isCreateCommercialInvoices: false,
            isNAFTA: false,
            invoiceOption: '',
            uploadedInvoice: null,
        });
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
        this.onShipToCountryCodeChanged(changes['shipToCountryCode']);
        this.onCarrierChanged(changes['carrier']);
        this.onCarrierKeyChanged(changes['carrierKey']);
        this.onCustomerChanged(changes['customer']);
        this.onCustomerImagesChanged(changes['customerImages']);
        this.onValidatePaperlessInvoiceCapabilityResultChanged(changes['validatePaperlessInvoiceCapabilityResult']);
    }

    public openPaperlessCommercialInvoiceSettingsDialog() {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '670px',
            data: {},
        };

        if (this.customerImages) {
            dialogConfig.data = {
                letterhead: this.customerImages.find(image => image.FileType === '2'),
                signature: this.customerImages.find(image => image.FileType === '1'),
            };
        }

        if (!this.isPageLoad) {
            const dialogRef: MatDialogRef<PaperlessCommercialInvoiceSettingsDialogComponent> =
                this.dialog.open(PaperlessCommercialInvoiceSettingsDialogComponent, dialogConfig);


            dialogRef.afterClosed().subscribe(
                (dialogResult) => {

                    // [MV3-6810] If no option has been selected, select "Do not create or print commercial invoice for this shipment" option
                    //  upon the dialog cancellation or closed before the enrollment is completed.
                    if (!dialogResult) {
                        const selectedInvoiceOption = this.formGroup.controls.invoiceOption.value;
                        this.formGroup.controls.invoiceOption.setValue(selectedInvoiceOption || '0');
                        return;
                    }

                    this.getCustomerImagesSubscription = this.paperlessCommercialInvoiceService.getCustomerImages()
                                                             .subscribe(
                                                                 result => this.handleGetCustomerImagesSuccess(result),
                                                             );
                },
            );
        } else { this.isPageLoad = false; }
    }

    public removeUploadedImages() {
        this.utilityService.clearSubscriptions([this.deleteCustomerDocumentsSubscription]);
        const data: { LinkID: number }[] = this.customerImages.map(image => ({ LinkID: image.LinkId }));
        this.deleteCustomerDocumentsSubscription = this.paperlessCommercialInvoiceService.deleteCustomerDocuments(data)
                                                       .subscribe(
                                                           result => this.handleDeleteCustomerDocumentsSuccess(result),
                                                       );
    }

    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Customs Documentation');
    }

    public isFormValid() {
        return this.formGroup.valid;
    }

    public restoreShipment(shipment: Package) {
        this.formGroup.patchValue({
            isCreateCommercialInvoices: shipment.IsCommercialInvoice,
            isNAFTA: shipment.IsNAFTACertificate,
            invoiceOption: shipment.CommercialInvoiceType.toString(),
            uploadedInvoice: shipment.CommercialInvoice,
            invoiceFileType: shipment.CommercialInvoiceFileType,
            invoiceMimeType: shipment.CommercialInvoiceMimeType,
        });

        this.formGroup.markAsDirty();
        this.formGroup.markAsTouched();
    }

    public uploadInvoice(files: FileList) {

        if (!files || files.length === 0) {
            this.uploadedFile = null;
            return;
        }

        const reader = new FileReader();
        const file = files[0];
        if (file.size > this.fileSizeLimit) {
            this.uploadErrorMessage = 'File size exceeded. Please try again.';
            this.uploadedFile = null;
            return;
        }

        this.uploadErrorMessage = null;

        this.uploadedFile = file;
        reader.readAsDataURL(file);

        reader.onload = () => {
            const [fileExtension] = file.name.split('.').slice(-1);
            const [mimeType, fileContent] = (reader.result as string).split(',');

            this.formGroup.patchValue({
                invoiceFileType: fileExtension,
                invoiceMimeType: mimeType,
                uploadedInvoice: fileContent,
            });

            // need to run CD since file load runs outside of zone
            this.cdRef.markForCheck();
        };
    }

    public removeUploadedInvoice() {
        this.formGroup.patchValue({
            invoiceOption: '',
            uploadedInvoice: null,
            invoiceFileType: '',
            invoiceMimeType: '',
        });

        this.uploadedFile = null;
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges
            .subscribe(
                (form) => {
                    this.isValid.emit(this.formGroup.valid);

                    this.shipmentService.saveCustomsDocumentation(form);
                    this.formValueChanged.emit(form);
                },
            );

        this.formGroup.controls.invoiceOption.valueChanges
            .pipe(
                distinctUntilChanged(),
            )
            .subscribe(
            (value) => {
                if (!value) {
                    return;
                }

                this.formGroup.controls.isCreateCommercialInvoices.setValue(value !== '0');

                if (value !== '2') {
                    this.validationService.clearFormControlValidators([this.formGroup.controls.uploadedInvoice]);
                    this.formGroup.patchValue({
                        uploadedInvoice: null,
                        invoiceFileType: '',
                        invoiceMimeType: '',
                    });
                    this.uploadedFile = null;
                } else {
                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.uploadedInvoice,
                        Validators.compose([Validators.required]));
                }

                if (value === '3') {
                    if (!this.isEnrolledForUpsPaperlessCommercialInvoice) {
                        return;
                    }

                    if (!this.isEnrolledForParcelProPaperlessCommercialInvoice) {
                        this.openPaperlessCommercialInvoiceTermsDialog();
                        this.isPageLoad = false;
                        return;
                    }

                    this.openPaperlessCommercialInvoiceSettingsDialog();
                }
            },
        );
    }

    private openPaperlessCommercialInvoiceTermsDialog() {
        if (this.isPaperlessCommercialInvoiceDialogOpened || (this.customerImages && !!this.customerImages.length)) {
            return;
        }
        this.isPaperlessCommercialInvoiceDialogOpened = true;

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '550px',
            data: {},
        };

        setTimeout(() => {
            const dialogRef: MatDialogRef<PaperlessCommercialInvoiceTermsDialogComponent> =
                this.dialog.open(PaperlessCommercialInvoiceTermsDialogComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(
                (result) => {
                    this.isPaperlessCommercialInvoiceDialogOpened = false;
                    if (!result) {
                        this.formGroup.controls.invoiceOption.setValue('0');
                        return;
                    }

                    this.openPaperlessCommercialInvoiceSettingsDialog();
                },
            );
        }, 1000);

    }

    private handleGetCustomerImagesSuccess(result) {
        this.customerImages = result;
        this.isEnrolledForParcelProPaperlessCommercialInvoice = this.customerImages && !!this.customerImages.length;

        if (!this.customerImages || !this.customerImages.length) {
            this.formGroup.controls.invoiceOption.setValue(null);
        }
    }

    private handleDeleteCustomerDocumentsSuccess(result) {
        this.customerImages = null;
        this.isEnrolledForParcelProPaperlessCommercialInvoice = false;
        this.formGroup.controls.invoiceOption.setValue(null);
    }

    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        this.formGroup.patchValue({
            isCreateCommercialInvoices: false, // TODO: restore from Quote.
        });

        this.formService.markAllAsTouchedAndDirty(this.formGroup);
        this.isValid.emit(this.formGroup.valid);
    }

    private onShipFromCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.checkIfNAFTA();
        this.setDefaultInvoiceOption();
    }

    private onShipToCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.checkIfNAFTA();
        this.setDefaultInvoiceOption();
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.isUps = this.shipConfigService.isUps(change.currentValue);
        this.setVisibility();
        this.setDefaultInvoiceOption();
    }

    private onCarrierKeyChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.isEnrolledForUpsPaperlessCommercialInvoice = !!(<ICarrierKey>(change.currentValue)).IsDocUpload;
        this.setVisibility();
    }

    private onCustomerChanged(change: SimpleChange): void {
        if (!change || !change.currentValue) {
            return;
        }

        this.isUSCustomer = this.customerService.isUSCustomer(change.currentValue);
        this.setVisibility();
    }

    private onCustomerImagesChanged(change: SimpleChange): void {
        if (!change || !change.currentValue) {
            return;
        }

        this.isEnrolledForParcelProPaperlessCommercialInvoice = this.customerImages && !!this.customerImages.length;
    }

    private onValidatePaperlessInvoiceCapabilityResultChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        const validationResult = (<IPaperlessCommercialInvoiceCapability>(change.currentValue));
        this.isPaperlessInvoiceApplicable = validationResult.AccountSettings && validationResult.CountryPair;
        this.setVisibility();
    }

    private checkIfNAFTA() {
        this.isNAFTA = this.shipConfigService.isNAFTA(this.shipFromCountryCode, this.shipToCountryCode);
    }

    private setVisibility() {
        this.shouldAllowInvoiceUpload = this.shouldAllowInvoiceCreation =
            this.isUps
            && this.isEnrolledForUpsPaperlessCommercialInvoice
            && this.isPaperlessInvoiceApplicable;

            // [MV3-6777] Allow paperless invoices for everyone as long as it's an international shipment.
            // && this.isUSCustomer
            // && (this.shipFromCountryCode?.toUpperCase() === 'US')

        if (this.shouldAllowInvoiceCreation && this.shouldAllowInvoiceUpload && !(this.formGroup.controls.invoiceOption.value === '3')) {
            this.isPageLoad = true;
            this.formGroup.controls.invoiceOption.setValue('3');
        }

        this.setDefaultInvoiceOption();
    }

    private setDefaultInvoiceOption(): void {
        
        if (!this.formGroup) {
            return;
        }

        if (this.shipFromCountryCode === this.shipToCountryCode) {
            this.formGroup.controls.invoiceOption.setValue('');
            return;
        }

        // [MV3-3053] Remove US-export condition.
        // const isUSExport = !!this.shipFromCountryCode
        //   && !!this.shipToCountryCode
        //   && this.shipFromCountryCode === 'US'
        //   && this.shipToCountryCode !== 'US';
        // if (!isUSExport) {
        //   this.formGroup.controls.invoiceOption.setValue('');
        //   return;
        // }

        const selectInvoiceOption = this.formGroup.controls.invoiceOption.value;
        if (selectInvoiceOption !== '') {
            switch (selectInvoiceOption) {
                case '1':
                    break;
                case '2':
                    if (!this.shouldAllowInvoiceUpload) {
                        this.formGroup.controls.invoiceOption.setValue('0');
                    }

                    break;
                case '3':
                    if (!this.shouldAllowInvoiceCreation) {
                        this.formGroup.controls.invoiceOption.setValue('0');
                    }

                    break;
                default:
                    break;
            }
            
            if (this.isUps) {
                this.formGroup.controls.invoiceOption.setValue(this.customer.CommercialInvoiceTypes.toString());    
            }
            return;
        }

        // if (!this.isUps) {
        //   this.formGroup.controls.invoiceOption.setValue('0');
        //   return;
        // }
        
        this.formGroup.controls.invoiceOption.setValue('0');
    }

}

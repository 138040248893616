import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { Customer } from '../../../shared/models/customer/customer.model';
import { NotificationType } from '../../../shared/models/notification-type';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { LookupService } from '../../../shared/services/lookup/lookup.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { User } from '../../../shared/services/user/models/user.model';
import { UserService } from '../../../shared/services/user/user.service';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { Commodity } from '../../models/commodity.model';
import { HarmonizeCommodity } from '../../models/harmonize-commodity.model';
import { ShipmentService } from '../../services/shipment.service';

@Component({
    selector: 'upsc-commodity-form-dialog',
    templateUrl: './commodity-form-dialog.component.html',
    styleUrls: ['./commodity-form-dialog.component.scss'],
})
export class CommodityFormDialogComponent implements OnInit, OnDestroy {
    public commodity: Commodity;
    public user: User;
    public customer: Customer;

    public formGroup: UntypedFormGroup;
    public harmonizedCommodities: HarmonizeCommodity[];
    public filteredHarmonizedCommodities: HarmonizeCommodity[];
    public allHarmonizedCommodities: HarmonizeCommodity[];

    public commodities: Commodity[];
    public isCommoditiesLoading = false;

    public manufacturerCountries: any[];
    public unitExpressions = [];
    public weightUnit = 'LBS';
    public maxWeight = this.weightUnit === 'KGS' ? 70 : 125;
    public customsValueCurrency = 'USD';
    public quantityUOM = '';
    minValue = 0.00001;
    minQuantityValue = 1;
    public isSaveToProfile = false;
    public translatedUnitExpressions = [];
    private guestCommodityManufacturerCountries = ['AE', 'BQ', 'CW', 'DO', 'EH', 'MP', 'TT', 'WS'];
    private getCommoditiesSubscription: Subscription;
    private ngDestroyed$ = new Subject<void>();

    public constructor(private validationService: ValidationService,
                       private notificationService: NotificationService,
                       private formBuild: UntypedFormBuilder,
                       private lookupService: LookupService,
                       private utilityService: UtilityService,
                       private errorHandlerService: ErrorHandlerService,
                       private shipmentService: ShipmentService,
                       private userService: UserService,
                       public dialogRef: MatDialogRef<CommodityFormDialogComponent>,
                       @Inject(MAT_DIALOG_DATA) public data: any,
                       private translate: TranslateService,
    ) {
        this.commodity = data.commodity;
        this.user = data.user;
        this.customer = data.customer;
        this.harmonizedCommodities = data.harmonizedCommodities;

        if (this.harmonizedCommodities) {
            const sortByDescription = (a: HarmonizeCommodity,
                                       b: HarmonizeCommodity,
            ): number => a.CommodityDescription.localeCompare(b.CommodityDescription);

            const [alphabetCommodities, nonAlphabetCommodities] = this.harmonizedCommodities.reduce(
                ([alphabets, nonAlphabets], item) => {
                    item.HarmonizedCode = item.HarmonizedCode?.trim();
                    item.CommodityDescription = item.CommodityDescription?.trim();
                    item.AESHarmonizedCode = item.AESHarmonizedCode?.trim();
                    item.AESCommodityDescription = item.AESCommodityDescription?.trim();
                    item.AESUOM = item.AESUOM?.trim();

                    if (item.CommodityDescription.match(/^[a-z]/i)) {
                        alphabets.push(item);
                    } else {
                        nonAlphabets.push(item);
                    }
                    return [alphabets, nonAlphabets];
                },
                [[], []],
            );

            alphabetCommodities.sort(sortByDescription);
            nonAlphabetCommodities.sort(sortByDescription);

            this.filteredHarmonizedCommodities = [...alphabetCommodities, ...nonAlphabetCommodities];
            this.allHarmonizedCommodities = [...this.filteredHarmonizedCommodities];
        }

        this.weightUnit = data.weightUOM;
        this.maxWeight = data.maxWeight;
        this.customsValueCurrency = data.customsValueCurrency;

        this.loadInitialValues();
    }

    public ngOnInit() {
        // Setup a form group here.
        this.formGroup = this.formBuild.group({
            commodityId: [''],
            savedCommodity: [''],
            harmonizedCode: ['', Validators.compose([Validators.required])],
            harmonizedCodeText: [''],
            description: [''],
            quantity: [1, Validators.compose([Validators.required, Validators.pattern(/\d*/), Validators.min(this.minQuantityValue)])],
            weight: [0, Validators.compose([Validators.required, Validators.min(this.minValue)])],
            weightUnit: [this.weightUnit, Validators.compose([Validators.required])],
            weightUnitExpression: ['', Validators.compose([Validators.required])],
            customsValue: [0, Validators.compose([Validators.required])],
            customsValueCurrency: [this.customsValueCurrency, Validators.compose([Validators.required])],
            customsValueCurrencyExpression: ['', Validators.compose([Validators.required])],
            manufacturerCountry: ['', Validators.compose([Validators.required])],
            exportLicenseNumber: ['C33', Validators.compose([Validators.required])],
            isSaveCommodity: [false],
            commodityName: [''],
        });

        this.monitorValueChanges();
        this.restoreFormValues();
        this.translate.get('CommodityUnitExpressions').subscribe((res: any[]) => {
            this.unitExpressions = res;
            this.unitExpressions = this.unitExpressions.flatMap(expression => [expression.PerUnit, expression.Total]);
        });
    }

    public ngOnDestroy() {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();

        if (this.getCommoditiesSubscription) {
            this.getCommoditiesSubscription.unsubscribe();
            this.getCommoditiesSubscription = null;
        }
    }

    public loadInitialValues() {
        this.loadCommodities();
        this.loadManufacturerCountries(); // from lookup service
    }

    public monitorValueChanges() {
        this.formGroup.controls.savedCommodity.valueChanges
            .subscribe(
                (value: string) => {
                    const commodity = this.commodities.find(item => item.CommodityID === value);

                    if (!commodity) {
                        return;
                    }

                    this.formGroup.patchValue(
                        {
                            harmonizedCode: commodity.HarmonizedCode,
                            harmonizedCodeText: `${ commodity.CommodityDescription } - ${ commodity.HarmonizedCode }`,
                            description: commodity.CommodityDescription,
                            quantity: commodity.Quantity,
                            weight: commodity.Weight,

                            // [MV3-1333] Round the customs value to integer.
                            customsValue: Math.round(+commodity.CustomsValue),

                            manufacturerCountry: commodity.CountryOfManufacture,
                            isSaveCommodity: commodity.SaveInDB === 'Y',
                            commodityName: commodity.CommodityName,
                        },
                        {
                            emitEvent: false,
                        });

                    this.setQuantityUOM(commodity.HarmonizedCode);
                },
            );

        this.formGroup.controls.harmonizedCode.valueChanges
            .subscribe(
                (value) => {
                    if (!this.harmonizedCommodities) {
                        this.quantityUOM = '';
                        return;
                    }

                    const commodity = this.harmonizedCommodities.find(item => item.HarmonizedCode === value);

                    this.formGroup.patchValue({
                        description: commodity?.AESCommodityDescription,
                    });

                    this.setQuantityUOM(commodity?.HarmonizedCode);
                    this.formGroup.controls.savedCommodity.setValue('', { emitEvent: false });
                    this.formGroup.controls.commodityName.setValue('', { emitEvent: false });
                },
            );

        this.formGroup.controls.harmonizedCodeText.valueChanges
            .subscribe(
                (value) => {
                    if (value === null || typeof value === 'undefined') {
                        return;
                    }

                    this.filteredHarmonizedCommodities = this.allHarmonizedCommodities.filter(
                        (commodity) => {
                            const fullDescription = `${ commodity.CommodityDescription } - ${ commodity.HarmonizedCode }`;
                            return fullDescription.toLowerCase().startsWith(value.toLowerCase());
                        },
                    );

                    if (!this.filteredHarmonizedCommodities || !this.filteredHarmonizedCommodities.length) {
                        this.formGroup.controls.harmonizedCode.setValue('');
                    }
                },
            );

        this.formGroup.controls.isSaveCommodity.valueChanges
            .subscribe(
                (value) => {
                    this.isSaveToProfile = value;
                    this.validationService.clearFormControlValidators([this.formGroup.controls.commodityName]);

                    if (value) {
                        this.validationService.setFormControlValidators(
                            this.formGroup.controls.commodityName,
                            Validators.compose([Validators.required]),
                        );
                    }
                },
            );
    }

    public getCommodityDisplayText(harmonizedCode: string): string {
        if (!harmonizedCode || !this.filteredHarmonizedCommodities) {
            return '';
        }

        const targetCommodity = this.filteredHarmonizedCommodities.find(item => item.HarmonizedCode === harmonizedCode);
        if (!targetCommodity) {
            return '';
        }

        return `${ targetCommodity.CommodityDescription } - ${ targetCommodity.HarmonizedCode }` || '';
    }

    public onHarmonizedCommoditySelected(event) {
        this.formGroup.controls.harmonizedCode.setValue(event.option.value);
    }

    public restoreFormValues() {
        if (!this.commodity) {
            return;
        }

        this.formGroup.patchValue({
            commodityId: this.commodity._id,
            harmonizedCode: this.commodity.HarmonizedCode,
            description: this.commodity.CommodityDescription,
            quantity: this.commodity.Quantity,
            weight: this.commodity.WeightUnitExpression === 'Total' ?
                this.commodity.Weight :
                (+this.commodity.Weight / +this.commodity.Quantity),

            // TODO: restore the correct value.
            weightUnit: 'LBS',

            weightUnitExpression: this.commodity.WeightUnitExpression,
            customsValue: this.commodity.CustomsValueCurrencyExpression === 'Total' ?
                this.commodity.CustomsValue :
                (+this.commodity.CustomsValue / +this.commodity.Quantity),

            // TODO: restore the correct value.
            customsValueCurrency: 'USD',

            customsValueCurrencyExpression: this.commodity.CustomsValueCurrencyExpression,
            manufacturerCountry: this.commodity.CountryOfManufacture,
            exportLicenseNumber: this.commodity.ExportLicenseNo,
            isSaveCommodity: this.commodity.SaveInDB === 'Y',
            commodityName: this.commodity.CommodityName,
        });
    }

    public onFormSubmit(event, form) {
        event.preventDefault();

        const commodity = new Commodity();
        commodity._id = form.commodityId;
        commodity.CommodityDescription = form.description;
        commodity.CountryOfManufacture = form.manufacturerCountry;
        commodity.CustomsValueCurrencyExpression = form.customsValueCurrencyExpression;
        commodity.CustomsValue = form.customsValueCurrencyExpression === 'Total' || form.customsValueCurrencyExpression === 'Totale' || form.customsValueCurrencyExpression === 'Gesamt' ? form.customsValue : form.customsValue * form.quantity;
        commodity.ExportLicenseNo = form.exportLicenseNumber;
        commodity.HarmonizedCode = form.harmonizedCode;
        commodity.Quantity = form.quantity;
        commodity.Weight = form.weightUnitExpression === 'Total' || form.weightUnitExpression === 'Totale' || form.weightUnitExpression === 'Gesamt' ? form.weight : form.weight * form.quantity;
        commodity.WeightUnitExpression = form.weightUnitExpression;
        commodity.SaveInDB = form.isSaveCommodity ? 'Y' : 'N';
        commodity.CommodityName = form.commodityName;
        commodity.AESHarmonizedCode = form.harmonizedCode;
        commodity.UOM = this.quantityUOM;

        this.dialogRef.close(commodity);
    }

    /**
     * Gets the form errors.
     * This allows a parent form to get this child form errors.
     */
    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Commodity');
    }

    public resetForm(event) {
        event.preventDefault();

        this.formGroup.reset();

        this.formGroup.patchValue({
            savedCommodity: '',
            harmonizedCode: '',
            weightUnit: this.weightUnit,
            weightUnitExpression: '',
            customsValueCurrency: this.customsValueCurrency,
            customsValueCurrencyExpression: '',
            manufacturerCountry: '',
            exportLicenseNumber: 'C33',
        });
    }

    /**
     * Checks whether the form is valid.
     * This allows a parent form to get the child form validity.
     */
    public isFormValid() {
        return this.formGroup.valid;
    }

    //#endregion

    public roundWeight() {
        const weight = this.formGroup.controls.weight.value;
        this.formGroup.controls.weight.setValue(this.getRoundedWeight(+weight));
    }

    public isLangFrench(): boolean {
        return sessionStorage.getItem('lang') === 'FR';
    }

    private setQuantityUOM(harmonizedCode: string) {
        if (!this.harmonizedCommodities) {
            this.quantityUOM = '';
            return;
        }

        const commodity = this.harmonizedCommodities.find(item => item.HarmonizedCode === harmonizedCode);

        if (!commodity) {
            this.quantityUOM = '';
            return;
        }

        this.quantityUOM = commodity.AESUOM || '';
    }

    //#endregion

    private loadCommodities() {
        if (this.getCommoditiesSubscription) {
            this.getCommoditiesSubscription.unsubscribe();
            this.getCommoditiesSubscription = null;
        }

        this.isCommoditiesLoading = true;
        this.getCommoditiesSubscription = this.shipmentService.getCommodities()
                                              .pipe(
                                                  catchError((err) => {
                                                      this.handleGetCommoditiesFailure(err);
                                                      return of(null);
                                                  }),
                                                  takeUntil(this.ngDestroyed$),
                                                  finalize(() => {
                                                      this.isCommoditiesLoading = false;
                                                  }),
                                              )
                                              .subscribe(
                                                  commodities => this.handleGetCommoditiesSuccess(commodities),
                                              );
    }

    //#region GetCommodities handlers.
    private handleGetCommoditiesSuccess(commodities: Commodity[]) {
        this.commodities = commodities;
        // this.utilityService.delay(() => {
        //     this.isCommoditiesLoading = false;
        // });
    }

    private handleGetCommoditiesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting commodities',
            NotificationType.ERROR);
        // this.utilityService.delay(() => {
        //     this.isCommoditiesLoading = false;
        // });
    }

    private loadManufacturerCountries() {
        this.lookupService.getCountries()
            .subscribe(
                countries => this.handleGetLookupCountriesSuccess(countries),
                err => this.handleGetLookupCountriesFailure(err),
            );
    }

    //#region GetLookupCountries handlers
    private handleGetLookupCountriesSuccess(countries: any[]) {
        this.manufacturerCountries = _.cloneDeep(countries);

        if (!this.user.IsGuest) {
            return;
        }

        if (this.user.IsGuest) {
            return;
        }

        // REVERTING THIS LOGIC
        // MV3-1213: Filter commodity manufacturer countries for a guest user.
        // this.manufacturerCountries = this.manufacturerCountries.filter(
        //   (country) => {
        //     return this.guestCommodityManufacturerCountries.includes(country.CountryCode);
        //   },
        // );
    }

    private handleGetLookupCountriesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting lookup countries',
            NotificationType.ERROR);
    }

    private getRoundedWeight(weight: number): string {
        // if (this.userService.isUSCustomer(this.customer)) {
        //   return Math.ceil(weight).toString();
        // }

        return weight.toFixed(2).replace(/\.?0+$/, '');
    }
}

<div class="ship-from ups-form" [formGroup]="formGroup">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{title|translate}}</h5>
            <div class="form-section-actions" *ngIf="!user?.IsGuest">
                <div class="address-book-search">

                    <upsc-address-book-search class="form-section-action"
                                              [carrier]="carrier"
                                              [currentUserIsAdmin]="currentUserIsAdmin"
                                              (contactSelected)="onContactSelected($event)">
                    </upsc-address-book-search>

                    <!--                    <button mat-raised-button *ngIf = "isEUUser"-->
<!--                            type="button"-->
<!--                            class="alternative-button"-->
<!--                            [currentUserIsAdmin]="currentUserIsAdmin"-->
<!--                            (click)="openEditLocationDialog($event)">-->
<!--                            {{'AddressBook'|translate}}-->
<!--                    </button>-->
                </div>                
                <a href="#"
                   class="form-section-action ship-from-edit hide-me"
                   (click)="editShipFromLocation($event)">
                    <span>{{'Edit'|translate}}</span>
                </a>
            </div>
        </div>

        <div class="location-selector-wrapper">
            <div class="select-wrapper" class="location-selector" *ngIf="isShowAddressBook">
                <mat-form-field>
                    <mat-label>{{'selectLocation'|translate}}</mat-label>
                    <mat-select #locationSelector
                                formControlName="location">
                        <mat-option value="" disabled selected>{{'SelectALocation'|translate}}</mat-option>
                        <mat-option value="0">{{'ShipFromOneTime'|translate}}</mat-option>
                        <mat-option *ngFor="let location of locations" [value]="location.ContactId">
                            {{ location.NickName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <upsc-element-blocker *ngIf="isLocationsLoading"></upsc-element-blocker>
            </div>

            <div class="edit-location" *ngIf="!user?.IsGuest && !isLocationEditable">
                <a href="#" (click)="openEditLocationDialog($event)">
                    <span>{{'EditLocation'|translate}}</span>
                </a>
            </div>
        </div>

        <span class="location-id hide-me">{{ formGroup.controls.location.value }}</span>
        <upsc-ship-from-static
                #shipFromStatic
                *ngIf="!isLocationEditable"
                [user]="user"
                [location]="shipFromAddress"
                [shouldDisplayEUFields]="shouldDisplayEUFields"
                (countryCodeChanged)="onShipFromCountryCodeChanged($event)"
                (zipCodeChanged)="onShipFromZipCodeChanged($event)"
                (isValid)="setFormValidity($event)"
                (formValueChanged)="onFormValueChanged($event)"></upsc-ship-from-static>
        <upsc-ship-from-edit
                #shipFromEdit
                [class.hide-me]="!isLocationEditable"
                [user]="user"
                [customer]="customer"
                [location]="shipFromAddress"
                [carrier]="carrier"
                [shipFromCountryCode]="shipFromCountryCode"
                [shipFromCurrencyCode]="shipFromCurrencyCode"
                [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                [isRestoreShipment]="isRestoreShipment"
                [shouldDisplayEUFields]="shouldDisplayEUFields"
                (countryChanged)="onShipFromCountryChanged($event)"
                (countryCodeChanged)="onShipFromCountryCodeChanged($event)"
                (zipCodeChanged)="onShipFromZipCodeChanged($event)"
                (isValid)="setFormValidity($event)"
                (formValueChanged)="onFormValueChanged($event)"></upsc-ship-from-edit>
    </div>
</div>

<div class="ship-billing-details ups-form" [formGroup]="shipBillingForm">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{'BillingDetails'|translate}}</h5>
        </div>

        <mat-radio-group formControlName="billingDetails">
            <mat-radio-button value="01"
                             *ngIf="(config?.showUPSBillTo || !config?.showFedExBillTo) && !config?.showBillToAccountSelector">
                <span *ngIf="!isBillToNA">{{'BillTo'|translate}} {{ billToAccount || 'N/A' }}</span>
                <span *ngIf="isBillToNA">{{'BillToNA'|translate}}</span>
            </mat-radio-button>
            <mat-radio-button value="01" *ngIf="config?.showBillToAccountSelector">
                <div class="customer-bill-to-accounts">
                    <span>{{'BillTo'|translate}}</span>
                    <div class="select-wrapper" *ngIf="config?.showBillToAccountSelector">
                        <mat-form-field>
                            <mat-select formControlName="billToCustomerId">
                                <mat-option value="" disabled selected>{{'SelectAccountNumber'|translate}}</mat-option>
                                <mat-option *ngFor="let account of billToAccounts" [value]="account.BillToCustomerId">
                                    {{ account.BillToCustomerId }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="shipBillingForm.controls.billToCustomerId.errors?.required">
                                <span *ngIf="shipBillingForm.controls.billToCustomerId.errors?.required">
                                    {{'AccountNumberRequired'|translate}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-radio-button>
            <mat-radio-button value="01"
                             *ngIf="isFedEx() && config?.showFedExBillTo && !config?.showBillToAccountSelector">{{'BillToParcelProAccount'|translate}}
            </mat-radio-button>
            <mat-radio-button value="02" *ngIf="config?.showBillToThirdParty && !config?.showBillToAccountSelector">{{'BillToThirdParty'|translate}}
            </mat-radio-button>
        </mat-radio-group>
        <div class="bill-to-third-party" *ngIf="config?.showBillToThirdParty && isBillToThirdPartyVisible">
            <mat-form-field>
                <mat-label *ngIf="isEUUser && isUps">{{'UPSAccountNumber'|translate}}</mat-label>
                <mat-label *ngIf="!isEUUser">{{'AccountNumber'|translate}}</mat-label>
                <input matInput formControlName="accountNumber">
                <mat-error *ngIf="shipBillingForm.controls.accountNumber.errors?.required">
                        <span *ngIf="shipBillingForm.controls.accountNumber.errors?.required && isEUUser && isUps">
                            {{'UPSAccountNumberRequired'|translate}}
                        </span>
                        <span *ngIf="shipBillingForm.controls.accountNumber.errors?.required && (!isEUUser)">
                            {{'AccountNumberRequired'|translate}}
                        </span>
                </mat-error>
            </mat-form-field>
            <div class="select-wrapper" *ngIf="isUps">
                <mat-form-field>
                    <mat-label>{{'Country'|translate}}</mat-label>
                    <mat-select formControlName="country">
                        <mat-option value="" disabled selected>{{'SelectCountry'|translate}}</mat-option>
                        <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                            {{ country.CountryName }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="shipBillingForm.controls.country.errors?.required">
                        <span *ngIf="shipBillingForm.controls.country.errors?.required">
                            {{'CountryRequired'|translate}}
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field *ngIf="isUps">
                <mat-label>{{'Zipcode'|translate}}</mat-label>
                <input matInput formControlName="zipCode">
                <mat-error *ngIf="shipBillingForm.controls.zipCode.errors?.required">
                        <span *ngIf="shipBillingForm.controls.zipCode.errors?.required">
                            {{'ZipCodeRequired'|translate}}
                        </span>
                </mat-error>
            </mat-form-field>
        </div>

        @if (shipState.isInternational()) {
            <div class="bill-duties" *ngIf="config?.showBillDutiesTaxes">
                <div class="select-wrapper">
                    <mat-form-field>
                        <mat-label>{{ 'BillDutiesTaxesFees'|translate }}</mat-label>
                        <mat-select formControlName="billDuties">
                            <mat-option value="" disabled selected>{{ 'SelectBillDuties'|translate }}</mat-option>
                            <mat-option value="Recipient">{{ 'Recipient'|translate }}</mat-option>
                            <mat-option value="Third Party">{{ 'ThirdParty'|translate }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="shipBillingForm.controls.billDutiesCountry.errors?.required">
                        <span *ngIf="shipBillingForm.controls.billDutiesCountry.errors?.required">
                            {{ 'CountryRequired'|translate }}
                        </span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="bill-duties-third-party" *ngIf="isBillDutiesThirdPartyVisible">
                    <mat-form-field>
                        <mat-label *ngIf="isEUUser && isUps">{{ 'UPSAccountNumber'|translate }}</mat-label>
                        <mat-label *ngIf="!isEUUser">{{ 'AccountNumber'|translate }}</mat-label>
                        <input matInput formControlName="billDutiesAccountNumber">
                        <mat-error *ngIf="shipBillingForm.controls.billDutiesAccountNumber.errors?.required">
                        <span *ngIf="shipBillingForm.controls.billDutiesAccountNumber.errors?.required && isEUUser && isUps">
                            {{ 'UPSAccountNumberRequired'|translate }}
                        </span>
                            <span *ngIf="shipBillingForm.controls.billDutiesAccountNumber.errors?.required && (!isEUUser)">
                            {{ 'AccountNumberRequired'|translate }}
                        </span>
                        </mat-error>
                    </mat-form-field>
                    <div class="select-wrapper" *ngIf="isUps">
                        <mat-form-field>
                            <mat-label>{{ 'Country'|translate }}</mat-label>
                            <mat-select formControlName="billDutiesCountry">
                                <mat-option value="" disabled selected>{{ 'SelectCountry'|translate }}</mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                                    {{ country.CountryName }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="shipBillingForm.controls.billDutiesCountry.errors?.required">
                        <span *ngIf="shipBillingForm.controls.billDutiesCountry.errors?.required">
                            {{ 'CountryRequired'|translate }}
                        </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field *ngIf="isUps">
                        <mat-label>{{ 'Zipcode'|translate }}</mat-label>
                        <input matInput formControlName="billDutiesZipCode">
                        <mat-error *ngIf="shipBillingForm.controls.billDutiesZipCode.errors?.required">
                        <span *ngIf="shipBillingForm.controls.billDutiesZipCode.errors?.required">
                            {{ 'ZipCodeRequired'|translate }}
                        </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        }
    </div>
</div>

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Location } from 'app/shared/models/location/location.model';
import { FormService } from 'app/shared/services/form/form.service';
import { User } from 'app/shared/services/user/models/user.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IShipComponent } from '../../../models/ship-component.interface';
import { ShipConfig } from '../../../models/ship-config.model';
import { ShipConfigService } from '../../../services/ship-config.service';
import { ShipmentService } from '../../../services/shipment.service';

@Component({
    selector: 'upsc-return-ship-to-static',
    templateUrl: './return-ship-to-static.component.html',
    styleUrls: ['./return-ship-to-static.component.scss'],
})
export class ReturnShipToStaticComponent implements OnInit, OnChanges, IShipComponent, OnDestroy {
    @Input() user: User;
    @Input() location: Location;
    @Input() shouldDisplayEUFields = false;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() countryCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();

    public formGroup: UntypedFormGroup;
    public senderEmail: string;
    public config: ShipConfig;
    private ngUnsubscribe = new Subject();

    constructor(private formBuild: UntypedFormBuilder,
                private formService: FormService,
                private shipConfigService: ShipConfigService,
                private shipmentService: ShipmentService,
    ) {
        this.formGroup = this.formBuild.group({
            isNotifySender: [this.config ? this.config.isNotifySender : false],
            vat: [''],
            taxId: [''],
            eori: [''],
        });

        this.shipConfigService.configSubject
            .subscribe(
                (config) => {
                    if (!config) {
                        return;
                    }

                    this.config = config;

                    if (!this.formGroup) {
                        return;
                    }

                    this.formGroup.controls.isNotifySender.setValue(this.config.isNotifySender);
                },
            );
    }

    public ngOnInit() {
        this.monitorValueChanges();
        this.setFormValues();

        this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;

                if (!this.formGroup || !this.config) {
                    return;
                }
            },
        );

        this.shipmentService.shippingUpdateInformationChanged$
            .pipe(
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(
                (data) => {
                    if (!data) {
                        return;
                    }

                    this.formGroup.patchValue({
                        isNotifySender: data.shouldSendToSender,
                    });

                    this.shipmentService.saveShipToStatic(this.formGroup.value);
                },
            );
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onUserChanged(changes['user']);
        this.onLocationChanged(changes['location']);
    }

    public ngOnDestroy() {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    }

    public resetForm() {
        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            isNotifySender: this.config ? this.config.isNotifySender : false,
        });
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges
            .subscribe(
                (form) => {
                    this.isValid.emit(this.formGroup.valid);
                    this.shipmentService.saveShipToStatic(form);
                    this.formValueChanged.emit(form);
                });

        this.formGroup.controls.isNotifySender.valueChanges
            .subscribe(
                (value) => {
                    this.shipConfigService.Config.isNotifySender = value;
                },
            );
    }

    private setFormValues() {
        if (!this.shipmentService.Quote || !this.config) {
            return;
        }

        this.formGroup.patchValue({
            isNotifySender: this.config.isNotifySender,
            vat: this.shipmentService.Quote.ShipToVAT,
            taxId: this.shipmentService.Quote.ShipToTaxID,
            eori: this.shipmentService.Quote.ShipToEORI,
        });

        this.formService.markAllAsTouchedAndDirty(this.formGroup);
        this.isValid.emit(this.formGroup.valid);
    }

    private onUserChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.setSenderEmail();
    }

    private onLocationChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.formGroup?.patchValue({
            vat: '',
            taxId: '',
            eori: '',
        });
        
        this.saveShipToLocation(change.currentValue);
        this.countryCodeChanged.emit((<Location>(change.currentValue)).Country);
        this.zipCodeChanged.emit((<Location>(change.currentValue)).Zip);

        this.setSenderEmail();
    }

    private setSenderEmail() {
        if (!this.location || !this.user) {
            return;
        }

        // [Requested] Always use the login user's email as the sender email.
        this.senderEmail = this.user.Email; // this.location.Email || this.user.Email;
    }

    private saveShipToLocation(location: Location) {
        const data = {
            contactId: location.ContactId,
            firstName: location.FirstName,
            lastName: location.LastName,
            company: location.CompanyName,
            zipCode: location.Zip,
            city: location.City,
            state: location.State || location.ProvinceRegion,
            country: location.Country,
            address1: location.StreetAddress,
            address2: location.ApartmentSuite,
            address3: location.AdditionalAddressInformation,
            email: location.Email,
            phone: location.TelephoneNo,
            vat: this.formGroup?.get('vat')?.value,
            taxId: this.formGroup?.get('taxId')?.value,
            eori: this.formGroup?.get('eori')?.value,
        };

        this.shipmentService.saveShipTo(data);
    }
}

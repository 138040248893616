<div class="location-search-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{'SearchLocation'|translate}}</h2>
        <mat-dialog-actions>
            <!--<button mat-raised-button class="close-button-medium" mat-dialog-close>{{'Close'|translate}}</button>-->
        </mat-dialog-actions>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
        <div class="search-result" *ngIf="searchResult">
            <upsc-location-search-table [items]="searchResult"
                                        [isActionsAllowed]="isTableActionsAllowed"
                                        (rowActionClicked)="onRowActionClicked($event)"></upsc-location-search-table>
        </div>
    </mat-dialog-content>
</div>

<ng-template #form>
    <form [formGroup]="formGroup" class="ups-form" (ngSubmit)="onFormSubmit($event, formGroup.value)">
        <div class="form-body">
            <div class="search">
                <mat-form-field class="search-parameter hide-me">
                    <input matInput
                           readonly
                           type="text"
                           placeholder="{{'PhoneNumber'|translate}}"
                           formControlName="phoneNumber">
                </mat-form-field>
                <mat-form-field class="search-parameter">
                    <input matInput type="text" placeholder="{{'Zipcode'|translate}}" formControlName="zipCode">
                </mat-form-field>

                <div class="select-wrapper search-parameter hide-me">
                    <mat-form-field>
                        <mat-select placeholder="Within" formControlName="distance">
                            <mat-option value="" disabled selected>{{'SelectDistance'|translate}}</mat-option>
                            <mat-option value="5">5</mat-option>
                            <mat-option value="10">10</mat-option>
                            <mat-option value="25">25</mat-option>
                            <mat-option value="50">50</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span matSuffix>{{'Miles'|translate}}</span>
                </div>

                <div class="form-actions">
                    <button type="submit"
                            mat-raised-button
                            color="primary"
                            class="form-action"
                            [disabled]="!isFormValid()">
                        {{'Search'|translate}}
                    </button>
                    <upsc-element-blocker *ngIf="isLocationSearching"></upsc-element-blocker>
                </div>
            </div>
        </div>
    </form>
</ng-template>
<div class="return-ship-to-static" *ngIf="location" [formGroup]="formGroup">
    <ng-container *ngIf="shouldDisplayEUFields">
        <mat-form-field>
            <mat-label>{{ 'vat' | translate }}</mat-label>
            <input matInput
                   type="text"
                   maxlength="20"
                   formControlName="vat">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'taxId' | translate }}</mat-label>
            <input matInput
                   type="text"
                   maxlength="20"
                   formControlName="taxId">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'eori' | translate }}</mat-label>
            <input matInput
                   type="text"
                   maxlength="20"
                   formControlName="eori">
            <a href="{{ 'eoriInfoLink' | translate}}"
               target="_blank"
               matSuffix>
                <mat-icon
                        matTooltip="{{ 'eoriInfo' | translate }}">
                    help
                </mat-icon>
            </a>
        </mat-form-field>
    </ng-container>

    <div class="name">{{ location.FirstName }} {{ location.LastName }}</div>
    <div class="company">{{ location.CompanyName }}</div>
    <div class="address">
        <span>{{ location.StreetAddress }},</span>
        <span>{{ location.ApartmentSuite}}</span>
        <span *ngIf="isFRITUser">{{ location.AdditionalAddressInformation}}</span>
    </div>
    <div class="address">
        <span>{{ location.City }},</span>
        <span>{{ location.State.trim() || location.ProvinceRegion }}</span>
        <span>{{ location.Zip }}</span>
        <span>{{ location.Country }}</span>
    </div>
    <div class="phone">{{ location.TelephoneNo }}</div>
</div>

<div class="commodity-ship-info">
    <form [formGroup]="formGroup" class="ups-form">
        <div class="form-body">
            <mat-form-field>
                <mat-label>{{'PackageContent'|translate}}</mat-label>
                <input matInput
                       readonly
                       formControlName="packageContent">
            </mat-form-field>

            <div class="select-wrapper">
                <mat-form-field>
                    <mat-label>{{'ShipmentPurpose'|translate}}</mat-label>
                    <mat-select required
                                formControlName="shipmentPurpose">
                        <mat-option value="" disabled selected>{{'SelectShipmentPurpose'|translate}}</mat-option>
                        <mat-option *ngFor="let purpose of shipmentPurposes" [value]="purpose | translate">
                            {{ purpose | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf = "formGroup.controls.shipmentPurpose?.errors">
                        <span *ngIf = "formGroup.controls.shipmentPurpose?.errors?.required">{{ 'ShipmentPurposeRequired' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label>{{'TotalCustoms'|translate}}</mat-label>
                <input matInput
                       required
                       type="text"
                       pattern="[0-9]+"
                       onfocus="this.select();"
                       formControlName="totalCustomsValue">
                <span matSuffix>{{ customsValueCurrency }}</span>
                <mat-error *ngIf="formGroup.controls.totalCustomsValue?.errors">
                    <span *ngIf="formGroup.controls.totalCustomsValue?.errors?.required">{{'TotalCustomsValueRequired'|translate}}</span>
                    <span *ngIf="formGroup.controls.totalCustomsValue?.errors?.pattern">{{'Total Customs value can only be an integer'|translate}}</span>
                    <span *ngIf="formGroup.controls.totalCustomsValue?.errors?.totalCustomsValueMatched">Total customs values of {{ totalCustomsValue }} is expected.</span>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>

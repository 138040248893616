export class Commodity {
  _id?: string;
  AECommodityDescription?: string;
  AESHarmonizedCode?: string;
  CommodityDescription?: string;
  CommodityID?: string;
  CommodityLongDescription?: string;
  CommodityName?: string;
  CountryOfManufacture?: string;
  CustomsValue?: string;
  CustomsValueCurrencyExpression?: string;
  ExportLicenseNo?: string;
  HarmonizedCode?: string;
  Quantity?: string;
  SaveInDB?: string;
  ShipmentPurpose?: string;
  UOM?: string;
  UserID?: number;
  Weight?: string;
  WeightUnitExpression?: string;
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserTermsUseComponent } from 'app/account/user-settings/user-terms-use/user-terms-use.component';
import { StorageService } from 'app/core/services/storage/storage.service';
import { INotifyTemplate } from 'app/notify-templates/models/notify-template.interface';
import {
    NotifyTemplatePreviewDialogComponent,
} from 'app/notify-templates/notify-template-preview-dialog/notify-template-preview-dialog.component';
import { NotifyTemplateService } from 'app/notify-templates/services/notify-template.service';
import { Carriers } from 'app/shared/enum/general-enum';
import { ICountry } from 'app/shared/models/country.interface';
import { Customer } from 'app/shared/models/customer/customer.model';
import { Location } from 'app/shared/models/location/location.model';
import { NotificationType } from 'app/shared/models/notification-type';
import { PostalCode } from 'app/shared/models/postal-code.model';
import { DialogService } from 'app/shared/services/dialog/dialog.service';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { FormService } from 'app/shared/services/form/form.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { User } from 'app/shared/services/user/models/user.model';
import { UserService } from 'app/shared/services/user/user.service';
import { UtilityService } from 'app/shared/services/utility/utility.service';
import { ValidationService } from 'app/shared/services/validation/validation.service';
import { ZipCodeService } from 'app/shared/services/zip-code/zip-code.service';
import * as _ from 'lodash';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, takeUntil, takeWhile } from 'rxjs/operators';
import { IAddressValidation } from '../../../models/address-validation.interface';
import { ICheckRestrictedResponse } from '../../../models/CheckRestrictedResponse.interface';
import { IEmailNotificationTemplate } from '../../../models/email-notification-template.interface';
import { Package } from '../../../models/package.model';
import { IShipComponent } from '../../../models/ship-component.interface';
import { ShipConfig } from '../../../models/ship-config.model';
import { ShipConfigService } from '../../../services/ship-config.service';

import { ShipmentService } from '../../../services/shipment.service';

@Component({
    selector: 'upsc-return-ship-from-edit',
    templateUrl: './return-ship-from-edit.component.html',
    styleUrls: ['./return-ship-from-edit.component.scss'],
})
export class ReturnShipFromEditComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
    @Input() location: Location;
    @Input() carrier: Carriers;
    @Input() user: User;
    @Input() customer: Customer;
    @Input() shipFromCountryCode: string;
    @Input() shipFromCurrencyCode = 'USD';
    @Input() maxCoverageCurrencyCode = 'USD';
    @Input() restrictedCountryCodes: any;
    @Input() shouldDisplayEUFields = false;
    @Input() isFRITUser: boolean;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() countryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();
    @Output() countryCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() isResidentialAddressChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() adultSignatureRequested: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() residentialCheckChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() addressValidationChanged: EventEmitter<IAddressValidation> = new EventEmitter<IAddressValidation>();
    @Output() forceClearAdultSignatureRequiredChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public formGroup: UntypedFormGroup;
    public config: ShipConfig;
    public countries: ICountry[];
    public displayedCountries: ICountry[];
    public allCountries: ICountry[];
    public selectedCountry: ICountry;
    public isCountriesLoading = true;
    public isZipCodeValidating = false;
    public isTemplateLoading = false;
    public isParcelProNotificationEnabled = false;
    public emailTemplates: IEmailNotificationTemplate[] = [];
    public isFirstNameLastNameRequired = true;
    public isCompanyNameRequired = true;
    public isLoadingNotifyTemplate = false;
    public zipCodeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public shouldShowCreateNotifyTemplate = false;
    addressPayload: any;
    private NACountries = ['CA', 'MX', 'US'];
    private configSubscription: Subscription;
    private getShipFromCountriesSubscription: Subscription;
    private getAddressByZipCodeSubscription: Subscription;
    private getEmailNotificationTemplatesSubscription: Subscription;
    private checkDoNotShipSubscription: Subscription;
    private isDoNotShipChecking = false;
    private isDoNotShipValid = false;
    private checkRestrictedSubscription: Subscription;
    private isRestrictedChecking = false;
    private isRestricted = false;
    private validateAddressSubscription: Subscription;
    private isValidatingAddress = false;
    private isResidentialAddress = false;
    private isInvalidAddress = false;
    private isRestrictedAddress = false;
    private shouldResetZip = true;
    private lastCheckAddress: any;
    private getNotifyTemplateSubscription: Subscription;

    private term: any;
    private ngUnsubscribe = new Subject();
    
    
    constructor(private formBuild: UntypedFormBuilder,
                private notificationService: NotificationService,
                private utilityService: UtilityService,
                private errorHandlerService: ErrorHandlerService,
                private validationService: ValidationService,
                private dialogService: DialogService,
                private formService: FormService,
                private zipCodeService: ZipCodeService,
                private shipConfigService: ShipConfigService,
                private dialog: MatDialog,
                private userService: UserService,
                private notifyTemplateService: NotifyTemplateService,
                private storageService: StorageService,
                private shipmentService: ShipmentService,
    ) {

    }

    public ngOnDestroy() {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();

        this.utilityService.clearSubscriptions([
            this.configSubscription,
            this.checkDoNotShipSubscription,
            this.checkRestrictedSubscription,
            this.getShipFromCountriesSubscription,
            this.checkDoNotShipSubscription,
            this.getAddressByZipCodeSubscription,
            this.getEmailNotificationTemplatesSubscription,
            this.getNotifyTemplateSubscription,
        ]);
    }

    public ngOnInit() {
        this.formGroup = this.formBuild.group({
            contactId: [''],
            firstName: ['', Validators.compose([Validators.required, Validators.maxLength(15)])],
            lastName: ['', Validators.compose([Validators.required, Validators.maxLength(19)])],
            company: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
            zipCode: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
            city: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
            state: ['', Validators.compose([Validators.maxLength(35)])],
            country: [this.shipFromCountryCode, Validators.compose([Validators.required, Validators.maxLength(2)])],
            address1: ['', Validators.compose([Validators.required, Validators.maxLength(35)])],
            address2: ['', Validators.compose([Validators.maxLength(35)])],
            address3: ['', Validators.compose([Validators.maxLength(35)])],
            email: ['', Validators.compose([Validators.required,this.validationService.emailFormatValidator(), Validators.maxLength(100)])],
            emailShipmentNotification: [false],
            emailExceptionNotification: [false],
            emailDeliveryNotification: [false],
            emailParcelProNotification: [false],
            parcelProEmailTemplate: [''],
            parcelProEmailPackageContent: [''],
            parcelProEmailPhoto: [''],
            phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10)])],
            // notifyRecipient: [false],
            residentialCheck: [false],
            updateAddress: [false],
            addExpress: [false],

            vat: [''],
            taxId: [''],
            eori: [''],
        });

        this.monitorValueChanges();
        this.setFormValues();
        this.utilityService.clearSubscriptions([this.configSubscription]);
        this.configSubscription = this.shipConfigService.configSubject.subscribe(
            (config) => {
                if (!config) {
                    return;
                }

                this.config = config;

                // console.log(this.config.isSendCustomEmailToRecipient);
                // TODO: BUG: Find out why calling without a delay (even if it's 0ms) breaks the express contact selection!
                this.utilityService.delay(
                    () => {
                        this.setValuesFromConfig();
                    },
                    0);
            },
        );

        this.userService.getTermsSigned()
            .pipe(takeWhile(() => !this.term))
            .subscribe(
                (term) => {
                    this.term = term;
                },
                (err) => {
                    this.notificationService.notify(
                        this.errorHandlerService.getHttpErrorMessage(err),
                        'Error Loading Terms',
                        NotificationType.ERROR);
                });

        this.shipmentService.shippingUpdateInformationChanged$
            .pipe(
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(
                (data) => {
                    if (!data || !data?.recipients?.length) {
                        this.formGroup.patchValue({
                            emailShipmentNotification: false,
                        });

                        this.shipmentService.saveShipFrom(this.formGroup.value);
                        return;
                    }

                    const recipient = data.recipients[0];

                    this.formGroup.patchValue({
                        firstName: recipient.firstName,
                        lastName: recipient.lastName,
                        email: recipient.email,
                        emailShipmentNotification: true,
                    });

                    this.shipmentService.saveShipFrom(this.formGroup.value);
                },
            );
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.onLocationChanged(changes['location']);
        this.onCarrierChanged(changes['carrier']);
        this.onUserChanged(changes['user']);
        this.onShipFromCountryCodeChanged(changes['shipFromCountryCode']);
        //this.onRestrictedShipToCountryCodesChanged(changes['restrictedCountryCodes']); TODO update
        this.onShipFromCurrencyCodeChanged(changes['shipFromCurrencyCode']);
        this.onCustomerChanged(changes['customer']);
    }

    public isFedEx() {
        return +Carriers[this.carrier] === Carriers.Fedex;
    }

    public getFormErrors() {
        return this.validationService.getFormControlValidationErrors(this.formGroup.controls, 'Ship From');
    }

    public isFormValid() {
        if (!this.formGroup) {
            return true;
        }

        return this.formGroup.valid && this.isDoNotShipValid; // && this.isRestricted;
    }

    public shouldRequireStateProvince() {
        const country = this.formGroup.controls.country.value;
        if (!country) {
            return false;
        }

        return this.NACountries.includes(country);
    }

    public validateAddress() {
        if (!this.formGroup) {
            return;
        }

        const city = this.formGroup.controls.city.value;
        const country = this.formGroup.controls.country.value;
        const state = this.formGroup.controls.state.value;
        const streetAddress = this.formGroup.controls.address1.value;
        const apartmentSuite = this.formGroup.controls.address2.value;
        const additionalAddressInfo = this.formGroup.controls.address3.value;
        const zipCode = this.formGroup.controls.zipCode.value;

        const address = {
            City: city,
            Country: country,
            State: state,
            StreetAddress: streetAddress,
            ApartmentSuite: apartmentSuite,
            AdditionalAddressInfo: additionalAddressInfo,
            Zip: zipCode,
        };

        if (!this.utilityService.isObjectsDifferent(this.lastCheckAddress, address)) {
            return;
        }

        this.lastCheckAddress = address;
        if (this.utilityService.isEmptyOrNullOrUndefined([city, country, state, streetAddress, zipCode])) {
            return;
        }

        if (country !== 'US') {
            return;
        }

        if (this.validateAddressSubscription) {
            this.validateAddressSubscription.unsubscribe();
            this.validateAddressSubscription = null;
        }

        this.isValidatingAddress = true;
        this.addressPayload = {
            City: city,
            Country: country,
            State: state,
            StreetAddress: streetAddress,
            ApartmentSuite: apartmentSuite == null ? '' : apartmentSuite,
            Zip: zipCode,
        };
        this.validateAddressSubscription = this.shipmentService.validateAddress(this.addressPayload, this.carrier)
                                               .subscribe(
                                                   addressValidation => this.validateAddressSuccess(addressValidation),
                                                   err => this.validateAddressFailure(err),
                                               );
    }

    public resetForm() {
        if (!this.formGroup) {
            return;
        }

        this.formGroup.reset({
            country: this.shipFromCountryCode,
            emailShipmentNotification: false,
            emailExceptionNotification: false,
            emailDeliveryNotification: false,
            emailParcelProNotification: false,
            parcelProEmailTemplate: '',
            parcelProEmailPackageContent: '',
            parcelProEmailPhoto: '',
            residentialCheck: false,
            updateAddress: false,
            addExpress: false,
        });
    }

    public forceValidation() {
        this.formService.markAllAsTouchedAndDirty(this.formGroup, false);
    }

    public formatMoney(value: number): string {
        return this.utilityService.formatMoney(value, this.maxCoverageCurrencyCode, 'en-US', 0);
    }

    public trackCountry(index, country: ICountry) {
        return country.CountryCode;
    }

    public openTermsOfUseDialog(event) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '70%',
            data: {
                termsofUse: this.term.TermsUsage,
            },
        };

        let dialogRef: MatDialogRef<UserTermsUseComponent>;
        dialogRef = this.dialog.open(UserTermsUseComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    public previewTemplate(event, templateId: string) {
        if (event) {
            event.preventDefault();
        }

        if (!templateId) {
            return;
        }

        if (!this.emailTemplates) {
            this.isLoadingNotifyTemplate = true;
            this.utilityService.clearSubscriptions([this.getNotifyTemplateSubscription]);
            this.getNotifyTemplateSubscription = this.notifyTemplateService.getNotifyTemplate(templateId)
                                                     .subscribe(
                                                         template => this.handleGetNotifyTemplateSuccess(template),
                                                         err => this.handleGetNotifyTemplateFailure(err),
                                                     );

            return;
        }

        const targetTemplate = this.emailTemplates.find(item => item.TemplateId === templateId);
        this.showPreview(targetTemplate);
    }

    //#endregion

    // TODO update
    // private onRestrictedShipToCountryCodesChanged(change: SimpleChange) {
    //   if (!change || !change.currentValue) {
    //     return;
    //   }

    //   this.shouldResetZip = false;
    //   this.filterCountries();
    // }

    public fillCostEstimateValues(cachedCostEstimate: any) {
        this.formGroup.patchValue({
            country: cachedCostEstimate.ShipFromCountry,
            zipCode: cachedCostEstimate.PostalCode,
            residentialCheck: cachedCostEstimate.ShipToResidential,
        });

        this.formGroup.controls.country.markAsDirty();
        this.formGroup.controls.country.markAsTouched();

        this.formGroup.controls.zipCode.markAsDirty();
        this.formGroup.controls.zipCode.markAsTouched();

        this.formGroup.controls.residentialCheck.markAsDirty();
        this.formGroup.controls.residentialCheck.markAsTouched();
    }

    public restoreShipment(shipment: Package) {
        const ShipFrom = shipment.ShipFrom;
        this.formGroup.patchValue(
            {
                contactId: ShipFrom.ContactId,
                zipCode: ShipFrom.Zip,
                city: ShipFrom.City,
                state: ShipFrom.State || ShipFrom.ProvinceRegion,
                address1: ShipFrom.StreetAddress,
                address2: ShipFrom.ApartmentSuite,
                address3: ShipFrom.AdditionalAddressInformation,
                email: ShipFrom.Email,
                phone: ShipFrom.TelephoneNo,
                emailShipmentNotification: shipment.NotifyRecipient,
                emailExceptionNotification: shipment.NotifyExceptionRecipient,
                emailDeliveryNotification: shipment.NotifyDeliveryRecipient,
                // parcelProEmailPhoto: shipment.Photo,
                residentialCheck: shipment.ShipToResidential,
                updateAddress: shipment.UpdateAddressBook,
                addExpress: shipment.AddToExpress,
            },
            { emitEvent: false });

        this.formService.markAllAsTouchedAndDirty(this.formGroup);

        this.formGroup.patchValue({
            firstName: ShipFrom.FirstName,
            lastName: ShipFrom.LastName,
            company: ShipFrom.CompanyName,
            country: ShipFrom.Country,
            emailParcelProNotification: shipment.NotifyCustomShipmentRecipient,
            parcelProEmailTemplate: shipment.TemplateID,
            parcelProEmailPackageContent: shipment.PackageContent,
        });

        this.formGroup.controls.emailParcelProNotification.markAsTouched();
        this.formGroup.controls.emailParcelProNotification.markAsDirty();

        const formValue = this.formGroup.value;
        this.isValid.emit(this.formGroup.valid);
        this.shipmentService.saveShipFrom(formValue);
        this.formValueChanged.emit(formValue);
    }

    private setValuesFromConfig() {
        // [MV3-1958][MV3-1959] Disable setting Parcel Pro Notify value from config if the field is dirty.
        if (!this.formGroup.controls.emailParcelProNotification.dirty || !this.formGroup.controls.emailParcelProNotification.touched) {
            this.formGroup.controls.emailParcelProNotification.setValue(this.config.isSendCustomEmailToRecipient);
        }

        // [MV3-1959] Uncheck email notification checkboxes upon hidden.
        if (!this.config.showNotifyRecipient) {
            this.formGroup.patchValue({
                emailShipmentNotification: false,
                emailExceptionNotification: false,
                emailDeliveryNotification: false,
            });
        }
    }

    private onLocationChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.updateLocation(change.currentValue);
        this.validateAddress();
    }

    private onUserChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.shouldShowCreateNotifyTemplate = !this.userService.isDEGBUser(change.currentValue);
    }

    private onCustomerChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }
    }

    private onShipFromCountryCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        if (this.formGroup) {
            this.formGroup.controls.country.setValue(change.currentValue);
        }
    }

    private onShipFromCurrencyCodeChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.displayedCountries = _.cloneDeep(this.countries);
    }

    private updateLocation(location: Location) {
        if (!this.formGroup) {
            return;
        }

        this.shouldResetZip = false;
        this.formGroup.patchValue(
            {
                contactId: location.ContactId,
                firstName: location.FirstName,
                lastName: location.LastName,
                company: location.CompanyName,
                zipCode: location.Zip,
                city: location.City,
                state: location.State,
                country: location.Country,
                address1: location.StreetAddress,
                address2: location.ApartmentSuite,
                address3: location.AdditionalAddressInformation,
                email: location.Email,
                phone: location.TelephoneNo,

                vat: '',
                taxId: '',
                eori: '',
            },
            { onlySelf: true, emitEvent: true });

        this.shouldResetZip = false; // as the country change event will triggered and city,zip will be cleared
        this.selectedCountry = this.countries.find(country => country.CountryCode === location.Country);
        
        this.countryCodeChanged.emit(location.Country);
        this.zipCodeChanged.emit(location.Zip);
    }

    private onCarrierChanged(change: SimpleChange) {
        if (!change || !change.currentValue) {
            return;
        }

        this.carrier = this.utilityService.getEnumValue(Carriers, change.currentValue);
        this.loadCarrierCountries(this.carrier);
    }

    private loadCarrierCountries(carrier: Carriers) {
        if (!carrier) {
            return;
        }

        // const carrierCountries = this.storageService.get('carrier-countries');
        // if (carrierCountries) {
        //   this.handleGetShipFromCountriesSuccess(carrierCountries);
        //   return;
        // }

        if (this.getShipFromCountriesSubscription) {
            this.getShipFromCountriesSubscription.unsubscribe();
            this.getShipFromCountriesSubscription = null;
        }

        this.getShipFromCountriesSubscription
            = this.shipmentService.getShipToCountries(carrier)
                  .pipe(
                      catchError((err) => {
                          this.handleGetShipFromCountriesFailure(err);
                          return of(null);
                      }),
                  )
                  .subscribe(
                      countries => this.handleGetShipFromCountriesSuccess(countries),
                  );
    }

    //#region GetShipFromCountries handlers
    private handleGetShipFromCountriesSuccess(countries) {
        this.countries = _.sortBy(countries, ['CountryName', 'MaxCoverage']);
        this.allCountries = this.countries;
        // this.storageService.set('carrier-countries', this.countries);

        this.displayedCountries = _.cloneDeep(this.countries);

        this.utilityService.delay(() => {
            this.isCountriesLoading = false;
        });

        if (this.shipmentService.Quote) {
            this.selectedCountry = this.countries.find(country => country.CountryCode === this.shipmentService.Quote.ShipFrom.Country);

            if (this.formGroup) {
                this.formGroup.controls.country.setValue(this.shipmentService.Quote.ShipFrom.Country);
            }
        }

        this.filterCountries();
    }

    private handleGetShipFromCountriesFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed loading country list',
            NotificationType.ERROR);
        this.utilityService.delay(() => {
            this.isCountriesLoading = false;
        });
    }

    private filterCountries() {
        // If no restriction, allow all countries.
        if (!this.countries ||
            !this.restrictedCountryCodes ||
            (!this.restrictedCountryCodes.availableCountryCodes.length && !this.restrictedCountryCodes.forbiddenCountryCodes.length)) {
            return;
        }

        this.countries = this.allCountries.filter(
            (country) => {
                const isNoRestriction = !this.restrictedCountryCodes.availableCountryCodes ||
                    !this.restrictedCountryCodes.availableCountryCodes.length;
                if (!this.restrictedCountryCodes || isNoRestriction) {
                    return true;
                }

                return this.restrictedCountryCodes.availableCountryCodes.includes(country.CountryCode);
            },
        );

        this.countries = this.countries.filter(
            (country) => {
                const isNoRestriction = !this.restrictedCountryCodes.forbiddenCountryCodes ||
                    !this.restrictedCountryCodes.forbiddenCountryCodes.length;
                if (!this.restrictedCountryCodes || isNoRestriction) {
                    return true;
                }

                return !this.restrictedCountryCodes.forbiddenCountryCodes.includes(country.CountryCode);
            },
        );

        this.displayedCountries = _.cloneDeep(this.countries);
        if (this.countries && this.countries.length) {
            this.formGroup.patchValue({
                country: this.shipFromCountryCode || this.countries[0].CountryCode,
            });
        }
    }

    private setFormValues() {
        if (!this.shipmentService.Quote) {
            return;
        }

        this.formGroup.patchValue({
            contactId: this.shipmentService.Quote.ShipFrom.ContactId,
            firstName: this.shipmentService.Quote.ShipFrom.FirstName,
            lastName: this.shipmentService.Quote.ShipFrom.LastName,
            company: this.shipmentService.Quote.ShipFrom.CompanyName,
            zipCode: this.shipmentService.Quote.ShipFrom.Zip,
            city: this.shipmentService.Quote.ShipFrom.City,
            state: this.shipmentService.Quote.ShipFrom.State,
            country: this.shipmentService.Quote.ShipFrom.Country || this.shipFromCountryCode,
            address1: this.shipmentService.Quote.ShipFrom.StreetAddress,
            address2: this.shipmentService.Quote.ShipFrom.ApartmentSuite,
            address3: this.shipmentService.Quote.ShipFrom.AdditionalAddressInformation,
            email: this.shipmentService.Quote.ShipFrom.Email,
            emailShipmentNotification: this.shipmentService.Quote.NotifyRecipient,
            emailExceptionNotification: this.shipmentService.Quote.NotifyExceptionRecipient,
            emailDeliveryNotification: this.shipmentService.Quote.NotifyDeliveryRecipient,
            emailParcelProNotification: this.shipmentService.Quote.NotifyCustomShipmentRecipient,
            parcelProEmailTemplate: this.shipmentService.Quote.TemplateID,
            parcelProEmailPackageContent: this.shipmentService.Quote.PackageContent,
            // parcelProEmailPhoto: this.shipmentService.Quote.Photo,
            phone: this.shipmentService.Quote.ShipFrom.TelephoneNo,
            // notifyRecipient: this.shipmentService.Quote.NotifyRecipient,
            residentialCheck: this.shipmentService.Quote.ShipFrom.IsResidential || this.shipmentService.Quote.ShipToResidential,
            updateAddress: this.shipmentService.Quote.UpdateAddressBook,
            addExpress: this.shipmentService.Quote.AddToExpress,

            vat: this.shipmentService.Quote.ShipFromVAT,
            taxId: this.shipmentService.Quote.ShipFromTaxID,
            eori: this.shipmentService.Quote.ShipFromEORI,
        });

        this.formService.markAllAsTouchedAndDirty(this.formGroup);
        this.isValid.emit(this.formGroup.valid);
    }

    private monitorValueChanges() {
        this.formGroup.valueChanges.subscribe((form) => {
            this.isValid.emit(this.formGroup.valid);
            this.shipmentService.saveShipFrom(form);
            this.formValueChanged.emit(form);
        });

        this.formGroup.controls.firstName.valueChanges
            .subscribe(
                (value) => {
                    this.updateAlternateValidators();
                },
            );

        this.formGroup.controls.lastName.valueChanges
            .subscribe(
                (value) => {
                    this.updateAlternateValidators();
                },
            );

        this.formGroup.controls.company.valueChanges
            .subscribe(
                (value) => {
                    this.updateAlternateValidators();
                },
            );

        this.formGroup.controls.country.valueChanges
            .subscribe(
                (value) => {
                    if (this.shouldResetZip) {
                        this.formGroup.patchValue(
                            {
                                zipCode: '',
                                city: '',
                                state: '',
                                vat: '',
                                taxId: '',
                                eori: '',
                            },
                            { onlySelf: true });
                    }

                    this.zipCodeChanged.emit('');

                    this.shouldResetZip = true;

                    this.checkDoNotShip();
                    this.validateAddress();

                    this.validationService.clearFormControlValidators([
                        this.formGroup.controls.state,
                    ]);

                    if (this.NACountries.includes(value)) {
                        this.validationService.setFormControlValidators(
                            this.formGroup.controls.state,
                            Validators.compose([Validators.required, Validators.maxLength(35)]),
                        );
                    }

                    if (!this.allCountries) {
                        return;
                    }

                    this.selectedCountry = this.allCountries.find(country => country.CountryCode === value);

                    if (this.selectedCountry) {
                        if (this.selectedCountry.IsPostalCodeAware) {
                            this.formGroup.controls.zipCode.enable();
                            ///////////////////// true + "" ||  true + Null /////////////////////////
                            if (this.selectedCountry.IsPostalCodeAware == true && this.selectedCountry.PostalCodePattern == '' ||
                                this.selectedCountry.IsPostalCodeAware == true && this.selectedCountry.PostalCodePattern == 'NULL') {

                                this.validationService.setFormControlValidators(
                                    this.formGroup.controls.zipCode,
                                    Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(1)]),
                                );
                            }

                            /////////////////////GB user/////////////////////////
                            if (this.selectedCountry.CountryCode == 'GB') {

                                this.validationService.setFormControlValidators(
                                    this.formGroup.controls.zipCode,
                                    Validators.compose([Validators.required, Validators.maxLength(7), Validators.minLength(5)]),
                                );
                            }
                            if (this.selectedCountry.IsPostalCodeAware == true && this.selectedCountry.PostalCodePattern != 'NULL'
                                && this.selectedCountry.PostalCodePattern != '' && this.selectedCountry.CountryCode != 'GB') {

                                this.selectedCountry.PostalCodeDigits = this.selectedCountry.PostalCodePattern.length;
                                this.validationService.setFormControlValidators(
                                    this.formGroup.controls.zipCode,
                                    Validators.compose([Validators.required, Validators.maxLength(this.selectedCountry.PostalCodeDigits), Validators.minLength(this.selectedCountry.PostalCodeDigits)]),
                                );
                            }
                            //////////////////////////////////////////////

                            // this.validationService.setFormControlValidators(
                            //   this.formGroup.controls.zipCode,
                            //   Validators.compose([Validators.required, Validators.maxLength(this.selectedCountry.PostalCodeDigits),Validators.minLength(this.selectedCountry.PostalCodeDigits)]),
                            // );

                            this.countryChanged.emit(this.selectedCountry);
                        } else {
                            // this.formGroup.controls.zipCode.disable();
                            console.log('aware false=======');
                            this.validationService.setFormControlValidators(
                                this.formGroup.controls.zipCode,
                                Validators.compose([Validators.maxLength(10)]),
                            );

                            this.isRestricted = false;
                            this.isDoNotShipValid = true;
                        }
                        this.shipConfigService.setPostalCodeAware(this.selectedCountry);
                    }
                    // console.log(this.formGroup.controls.zipCode.validator({} as AbstractControl));
                    this.ifInternationalOrDomesticShipment();
                    this.countryCodeChanged.emit(value);
                },
            );

        this.formGroup.controls.zipCode.valueChanges
            .pipe(debounceTime(500),
                distinctUntilChanged())
            .subscribe(
                (value) => {
                    if (value?.length < 4) {
                        return;
                    }

                    this.checkDoNotShip();
                    this.checkRestricted();
                    if (!value || (value && !this.isZipCodeValid(value))) {
                        return;
                    }

                    this.zipCodeChanged.emit(value);

                    const addressLookupCountries = ['US', 'CA', 'PR', 'VI', 'GU'];
                    if (!this.selectedCountry || !addressLookupCountries.includes(this.selectedCountry.CountryCode)) {
                        return;
                    }

                    if (this.getAddressByZipCodeSubscription) {
                        this.getAddressByZipCodeSubscription.unsubscribe();
                        this.getAddressByZipCodeSubscription = null;
                    }

                    this.isZipCodeValidating = true;
                    this.getAddressByZipCodeSubscription = this.zipCodeService.getAddressByZipCode(value)
                                                               .subscribe(
                                                                   result => this.handleZipCodeValidationSuccess(result),
                                                                   err => this.handleZipCodeValidationFailure(err),
                                                               );
                },
            );

        this.formGroup.controls.emailParcelProNotification.valueChanges
            .subscribe(
                (value) => {
                    this.isParcelProNotificationEnabled = value;

                    this.utilityService.clearSubscriptions([this.getEmailNotificationTemplatesSubscription]);
                    this.isTemplateLoading = true;
                    this.getEmailNotificationTemplatesSubscription = this.notifyTemplateService.getNotifyTemplates()
                                                                         .subscribe(
                                                                             templates => this.handleGetEmailNotificationTemplateSuccess(templates),
                                                                             err => this.handleGetEmailNotificationTemplateFailure(err),
                                                                         );

                    this.validationService.clearFormControlValidators([
                        this.formGroup.controls.parcelProEmailTemplate,
                        this.formGroup.controls.parcelProEmailPackageContent,
                        this.formGroup.controls.parcelProEmailPhoto,
                    ]);

                    if (!value) {
                        return;
                    }

                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.parcelProEmailTemplate,
                        Validators.compose([Validators.required]),
                    );

                    this.validationService.setFormControlValidators(
                        this.formGroup.controls.parcelProEmailPackageContent,
                        Validators.compose([Validators.required]),
                    );
                },
            );

        this.formGroup.controls.residentialCheck.valueChanges
            .subscribe(
                (value) => {
                    this.shipConfigService.Config.isCheckResidential = value;
                    this.residentialCheckChanged.emit(value);
                },
            );

        this.formGroup.controls.updateAddress.valueChanges
            .subscribe(
                (value) => {
                    if (value) {
                        return;
                    }

                    this.formGroup.controls.addExpress.setValue(false);
                },
            );

        this.formGroup.controls.addExpress.valueChanges
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    this.formGroup.controls.updateAddress.setValue(true);
                },
            );
    }

    private ifInternationalOrDomesticShipment(): void {
        const firstName = this.formGroup.controls.firstName.value;
        const lastName = this.formGroup.controls.lastName.value;
        const companyName = this.formGroup.controls.company.value;

        let shipToCountry = this.shipmentService.Quote.ShipTo.Country;
        let shipFromCountry = this.shipmentService.Quote.ShipFrom.Country

        if (shipToCountry == shipFromCountry) { // Domestic
            this.isCompanyNameRequired = (!firstName && !lastName) || (!!companyName && (!firstName || !lastName));    
            this.isFirstNameLastNameRequired = !companyName || (!!firstName && !!lastName && !!companyName);
        }

        else { // International
            this.isCompanyNameRequired = true;
            this.isFirstNameLastNameRequired = true;
        }
    }

    private updateAlternateValidators() {
        // [MV3-1427] A user can enter either (First Name and Last Name) or Company Name.
        // Company Name is not required if both First Name and Last Name are present.
        // (MV3-6320) Only applies to Domestic Shipments. InternationalShipments require both
        this.ifInternationalOrDomesticShipment();

        if (this.isFirstNameLastNameRequired) {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.firstName,
                Validators.compose([Validators.required, Validators.maxLength(15)]),
                { emitEvent: false });
            this.validationService.setFormControlValidators(
                this.formGroup.controls.lastName,
                Validators.compose([Validators.required, Validators.maxLength(20)]),
                { emitEvent: false });
        } else {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.firstName,
                Validators.compose([Validators.maxLength(15)]),
                { emitEvent: false });

            this.validationService.setFormControlValidators(
                this.formGroup.controls.lastName,
                Validators.compose([Validators.maxLength(20)]),
                { emitEvent: false });
        }

        if (this.isCompanyNameRequired) {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.company,
                Validators.compose([Validators.required, Validators.maxLength(35)]),
                { emitEvent: false });
        } else {
            this.validationService.setFormControlValidators(
                this.formGroup.controls.company,
                Validators.compose([Validators.maxLength(35)]),
                { emitEvent: false });
        }
    }

    private checkDoNotShip() {
        if (!this.formGroup) {
            return;
        }

        const zipCode = this.formGroup.controls.zipCode.value;
        const countryCode = this.formGroup.controls.country.value;

        if (!this.carrier || !zipCode || !countryCode) {
            return;
        }

        if (this.checkDoNotShipSubscription) {
            this.checkDoNotShipSubscription.unsubscribe();
            this.checkDoNotShipSubscription = null;
        }

        this.isDoNotShipChecking = true;
        this.checkDoNotShipSubscription = this.shipmentService.checkDoNotShip(zipCode, this.carrier, countryCode)
                                              .subscribe(
                                                  isInDoNotShipList => this.handleCheckDoNotShipSuccess(isInDoNotShipList),
                                                  err => this.handleCheckDoNotShipFailure(err),
                                              );
    }

    private handleCheckDoNotShipSuccess(isInDoNotShipList: boolean) {
        if (isInDoNotShipList) {
            this.notificationService.notify(
                'Shipments are not allowed to this zip code.',
                'Do Not Ship',
                NotificationType.ERROR);
        }

        this.isDoNotShipValid = !isInDoNotShipList;
        this.isDoNotShipChecking = false;
    }

    private handleCheckDoNotShipFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed checking do-not-ship',
            NotificationType.ERROR);
        this.isDoNotShipChecking = false;
    }

    private checkRestricted() {
        if (!this.formGroup) {
            return;
        }

        const zipCode = this.formGroup.controls.zipCode.value;
        const insuredValue = this.shipmentService.Quote.InsuredValue;
        const serviceType = this.shipmentService.Quote.ServiceCode;

        if (!this.carrier || !zipCode || !insuredValue || !serviceType) {
            return;
        }

        if (this.shipmentService.Quote.ShipFrom.Country !== 'US') {
            return;
        }

        if (this.checkRestrictedSubscription) {
            this.checkRestrictedSubscription.unsubscribe();
            this.checkRestrictedSubscription = null;
        }

        this.isRestrictedChecking = true;
        this.checkRestrictedSubscription = this.shipmentService.checkRestricted(zipCode, this.carrier, insuredValue, serviceType)
                                               .subscribe(
                                                   result => this.handleCheckRestrictedSuccess(result),
                                                   err => this.handleCheckRestrictedFailure(err),
                                               );
    }

    private handleCheckRestrictedSuccess(result: ICheckRestrictedResponse) {
        if (result.IsRestricted) {
            const displayMessage = result.Messages.map(message => message.Message).join(',');
            this.notificationService.notify(
                displayMessage,
                'Restricted',
                NotificationType.ERROR);
        }

        this.isRestricted = result.IsRestricted;
        this.isRestrictedChecking = false;
    }

    private handleCheckRestrictedFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed checking restricted',
            NotificationType.ERROR);
        this.isRestrictedChecking = false;
    }

    private handleGetEmailNotificationTemplateSuccess(templates: INotifyTemplate[]) {
        this.emailTemplates = <any[]>_.sortBy(templates, ['TemplateName', 'TemplateId']);
        this.utilityService.delay(() => {
            this.isTemplateLoading = false;
        });
    }

    private handleGetEmailNotificationTemplateFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed getting email notification templates',
            NotificationType.ERROR,
        );
        this.utilityService.delay(() => {
            this.isTemplateLoading = false;
        });
    }

    /**
     * Client-side validation of a zip code.
     * @returns {boolean}
     */
    private isZipCodeValid(zipCode: string): boolean {
        if (!this.selectedCountry) {
            return false;
        }

        // TODO: set validation for zip code regexp. Look out for the postal prefix. The digit number doesn't include it.
        const zipCodeRegExp = new RegExp(`^[a-z0-9]\{${ this.selectedCountry.PostalCodeDigits }\}$`, 'i');

        return zipCodeRegExp.test(zipCode);
    }

    private handleZipCodeValidationSuccess(zipCode: PostalCode) {
        this.formGroup.patchValue({
            city: zipCode.City,
            state: zipCode.State,
            // country: zipCode.Country,
        });

        this.utilityService.delay(() => {
            this.isZipCodeValidating = false;
        });
    }

    private handleZipCodeValidationFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed validating a zip code',
            NotificationType.ERROR);
        this.utilityService.delay(() => {
            this.isZipCodeValidating = false;
        });
    }

    private validateAddressSuccess(addressValidation: IAddressValidation) {
        this.addressValidationChanged.emit(addressValidation);
        this.isValidatingAddress = false;

        // TODO: If the AddressType is 1 (residential), tell the user that "Check if this is residential address"
        // is going to be selected and ask him that if he wants the system to select "adult signature" for him.
        this.isResidentialAddress = addressValidation.AddressType === 1;
        this.isInvalidAddress = addressValidation.AddressType === 0;
        this.isRestrictedAddress = addressValidation.IsRestricted;

        this.isResidentialAddressChanged.emit(this.isResidentialAddress);

        this.handleAddressType(addressValidation.AddressType);
    }

    private handleAddressType(addressType: number) {
        const residentialCheckControl = this.formGroup.controls.residentialCheck;
        residentialCheckControl.setValue(false);

        switch (addressType) {
            // Invalid address
            case 0:
                this.forceClearAdultSignatureRequiredChanged.emit(true);
                this.dialogService.confirm(
                    'Address Not Recognized',
                    'You have entered an invalid address. Please select OK to correct or Cancel to proceed with conditions.',
                    '',
                    'OK',
                    'Cancel',
                ).subscribe(
                    (isToFix) => {
                        if (isToFix) {
                            return;
                        }

                        // [MV3-3060] Do not ask for Adult Signature Required confirmation if config.showAdultSignatureMessage is false.
                        if (this.shipConfigService.isUps(this.carrier) && this.config && !this.config.showAdultSignatureMessage) {
                            return;
                        }

                        this.dialogService.confirm(
                            'Adult Signature is required',
                            'As a precaution, please note that coverage may be compromised due to an incorrect or invalid address.<br>' +
                            'If this is a residential address, click OK to have the Signature option added to your shipment, ' +
                            'or click Cancel to Not add the Signature option.<br>' +
                            'Please see Parcel Pro\'s Terms and Conditions for more details and coverage requirements for residential deliveries.',
                            '',
                            'OK',
                            'Cancel',
                        ).subscribe(
                            (isAutoChecked) => {
                                if (isAutoChecked) {
                                    this.adultSignatureRequested.emit(true);
                                }
                            },
                        );
                    },
                );

                break;

            // Residential address
            case 1:
                this.forceClearAdultSignatureRequiredChanged.emit(true);
                residentialCheckControl.setValue(true);
                // residentialCheckControl.disable();

                // [MV3-3060] Do not ask for Adult Signature Required confirmation if config.showAdultSignatureMessage is false.
                if (this.shipConfigService.isUps(this.carrier) && this.config && !this.config.showAdultSignatureMessage) {
                    return;
                }

                this.dialogService.confirm(
                    'Adult Signature is required',
                    'For a residential address, an adult signature is required.<br>' +
                    'Would you like us to select this option for you?',
                ).subscribe(
                    (isAutoChecked) => {
                        if (isAutoChecked) {
                            this.adultSignatureRequested.emit(true);
                        }
                    },
                );

                break;
            default:
                break;
        }
    }

    private validateAddressFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed validating address',
            NotificationType.ERROR);
        this.isValidatingAddress = false;
    }

    private handleGetNotifyTemplateSuccess(template: INotifyTemplate) {
        this.showPreview(template);
        this.isLoadingNotifyTemplate = false;
    }

    private handleGetNotifyTemplateFailure(err) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed Getting Notify Template',
            NotificationType.ERROR);
        this.isLoadingNotifyTemplate = false;
    }

    private showPreview(template: INotifyTemplate) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            data: {
                template,
            },
        };

        const dialogRef = this.dialog.open(NotifyTemplatePreviewDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            (result) => {
                if (!result) {
                    return;
                }
            },
        );
    }
}

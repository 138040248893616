<div class="ship-from ups-form" [formGroup]="formGroup">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{ 'ShipFromAddress' | translate }}</h5>
            <div class="form-section-actions" *ngIf="!user?.IsGuest">
                <upsc-address-book-search [carrier]="carrier"
                                          [availableCountryCodes]="restrictedCountryCodes?.availableCountryCodes"
                                          [forbiddenCountryCodes]="restrictedCountryCodes?.forbiddenCountryCodes"
                                          (contactSelected)="onContactSelected($event)"></upsc-address-book-search>
            </div>
        </div>

        <div class="location-selector-wrapper">
            <div class="select-wrapper" class="location-selector">
                <mat-form-field>
                    <mat-label>{{'InitialTrackingNumber'|translate}}</mat-label>
                    <input matInput
                        formControlName="initialTrackingId"
                        #initialTrackingIdInput/>
                </mat-form-field>
            
                <div class="edit-location">
                    <a href="#" (click)="getShipmentDetails(initialTrackingIdInput.value, $event)">
                        <span>
                            {{ 'check' | translate }}
                        </span>
                    </a>
                </div>
            </div>
        </div>

        <div class="select-wrapper" *ngIf="isShowAddressBook">
            <mat-form-field>
                <mat-label>{{'ExpressContacts'|translate}}</mat-label>
                <mat-select #locationSelector
                            (focus)="onLocationFocused($event, locationSelector)"
                            formControlName="location">
                    <mat-option value="" selected>{{'SelectALocation'|translate}}</mat-option>
                    <mat-option *ngFor="let location of locations" [value]="location.ContactId">
                        {{ location.NickName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <upsc-element-blocker *ngIf="isLocationsLoading"></upsc-element-blocker>
        </div>

        <upsc-return-ship-from-static
                #shipFromStatic
                *ngIf="!isLocationEditable"
                [location]="shipFromAddress"
                [carrier]="carrier"
                [shipFromCountryCode]="shipFromCountryCode"
                [shouldDisplayEUFields]="shouldDisplayEUFields"
                (isValid)="setFormValidity($event)"></upsc-return-ship-from-static>
        <upsc-return-ship-from-edit
                #shipFromEdit
                *ngIf="isLocationEditable"
                [location]="shipFromAddress"
                [carrier]="carrier"
                [isFRITUser]="isFRITUser"
                [customer]="customer"
                [shipFromCountryCode]="shipFromCountryCode"
                [shipFromCurrencyCode]="shipFromCurrencyCode"
                [maxCoverageCurrencyCode]="maxCoverageCurrencyCode"
                [restrictedCountryCodes]="restrictedCountryCodes"
                [shouldDisplayEUFields]="shouldDisplayEUFields"
                (countryChanged)="onCountryChanged($event)"
                (countryCodeChanged)="onCountryCodeChanged($event)"
                (zipCodeChanged)="onZipCodeChanged($event)"
                (isResidentialAddressChanged)="onIsResidentialAddressChanged($event)"
                (adultSignatureRequested)="onAdultSignatureRequested($event)"
                (residentialCheckChanged)="onResidentialCheckChanged($event)"
                (isValid)="setFormValidity($event)"
                (addressValidationChanged)="onAddressValidationChanged($event)"
                (forceClearAdultSignatureRequiredChanged)="onForceClearAdultSignatureRequiredChanged($event)"
                (formValueChanged)="onFormValueChanged($event)"></upsc-return-ship-from-edit>
    </div>
</div>

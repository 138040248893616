import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppState } from '../../app.state';
import { ShipmentStateService } from '../services/shipment-state.service';

import { ShipmentService } from '../services/shipment.service';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { FormService } from '../../shared/services/form/form.service';
import { Carriers } from '../../shared/enum/general-enum';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { ICarrierKey } from '../../shared/models/carrier-key.interface';
import { Subscription } from 'rxjs';
import { LookupService } from '../../shared/services/lookup/lookup.service';
import { ShipConfigService } from '../services/ship-config.service';
import { ShipConfig } from '../models/ship-config.model';
import { User } from '../../shared/services/user/models/user.model';
import { IBillToAccount } from '../models/bill-to-account.interface';
import { IShipComponent } from '../models/ship-component.interface';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { Package } from '../models/package.model';
import { CarrierService } from '../../shared/services/carrier/carrier.service';
import { SharedService } from '../../core/services/shared.service';
import { UserService } from '../../shared/services/user/user.service';

@Component({
  selector: 'upsc-ship-billing-details',
  templateUrl: './ship-billing-details.component.html',
  styleUrls: ['./ship-billing-details.component.scss'],
})
export class ShipBillingDetailsComponent implements OnInit, OnChanges, OnDestroy, IShipComponent {
  @Input() carrier: Carriers;
  @Input() user: User;
  @Input() isDomestic = true;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();

  public shipBillingForm: UntypedFormGroup;
  public billToAccount: string;
  public isUps = false;
  public isBillToThirdPartyVisible = false;
  public isBillDutiesThirdPartyVisible = false;
  public countries: any[] = [
    {
      CountryCode: 'US',
      CountryName: 'United States',
    },
  ];
  public config: ShipConfig;
  public isBillToNA = false;
  public billToAccounts: IBillToAccount[];
  public getCustomerBillToAccountsSubscription: Subscription;
  public isBillToAccountsLoading = false;
  public isEUUser:boolean;
  private isDefaultValuesApplied = false;

  private getCarrierKeySubscription: Subscription;
  private getLookupCountriesSubscription: Subscription;

  public constructor(private formBuild: UntypedFormBuilder,
                     private validationService: ValidationService,
                     private notificationService: NotificationService,
                     private lookupService: LookupService,
                     private formService: FormService,
                     private errorHandlerService: ErrorHandlerService,
                     private shipConfigService: ShipConfigService,
                     private carrierService: CarrierService,
                     private shipmentService: ShipmentService,
                     private userService: UserService,
                     private sharedService: SharedService,
                     private readonly appState: AppState,
                     public readonly shipState: ShipmentStateService,
  ) {
    this.isValid.emit(true);
    this.loadInitialValues();
    this.GetUserCountryCode();
  }

  // TODO: Add Customer Object for Bill To Account
  public ngOnInit() {
    this.shipBillingForm = this.formBuild.group({
      billingDetails: ['01', Validators.compose([Validators.required])],
      accountNumber: [''],
      country: ['US'],
      zipCode: [''],
      billToCustomerId: [''],
      billDuties: ['Recipient'],
      billDutiesAccountNumber: [''],
      billDutiesCountry: ['US'],
      billDutiesZipCode: [''],
      // billToAccount: [''],
      // billToThirdPartyCountry: [''],
      // billToThirdPartyPostalCode: [''],
    });

    this.monitorValueChanges();
    this.setFormValues();

    this.shipConfigService.configSubject.subscribe(
      (config) => {
        if (!config) {
          return;
        }

        this.config = config;

        if (this.config && this.config.isAPIDriven && !this.isDefaultValuesApplied) {
          this.shipBillingForm.patchValue({
            accountNumber: this.config.DefaultBillToThirdPartyAccountNo,
            country: this.config.DefaultBillToThirdPartyCountry,
            zipCode: this.config.DefaultBillToThirdPartyZipCode,
            billingDetails: this.config.isBillToThirdParty ? '02' : '01',
          });

          this.isDefaultValuesApplied = true;
        }
      },
    );

    this.loadBillToAccounts();
  }

  public resetForm() {
    if (!this.shipBillingForm) {
      return;
    }

    this.shipBillingForm.reset({
      billingDetails: '01',
      accountNumber: '',
      country: 'US',
      zipCode: '',
      billToCustomerId: '',
      billDuties: 'Recipient',
      billDutiesAccountNumber: '',
      billDutiesCountry: 'US',
      billDutiesZipCode: '',
    });
  }

  public forceValidation() {
    this.formService.markAllAsTouchedAndDirty(this.shipBillingForm, false);
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.onCarrierChanged(changes['carrier']);
    this.onUserChanged(changes['user']);
  }

  public ngOnDestroy() {
    this.clearGetCarrierKeySubscription();
  }

  private onCarrierChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.clearGetCarrierKeySubscription();
    this.getCarrierKeySubscription = this.carrierService.getCarrierKey(change.currentValue)
      .subscribe(
        carrierKeys => this.handleGetCarrierKeySuccess(carrierKeys),
        err => this.handleGetCarrierKeyFailure(err),
      );

    this.isUps = this.shipConfigService.isUps(this.carrier);
  }

  private onUserChanged(change: SimpleChange) {
    if (!change || !change.currentValue) {
      return;
    }

    this.isBillToNA = !change.currentValue.ShowRates;
    if (this.user.IsCustomBillTo) {
      this.shipBillingForm.controls.billToCustomerId.setValue(this.user.CustomerId);
    }
  }

  private loadInitialValues() {
    // TODO: enable this line when the lookup-countries API is fixed.
    this.loadLookupCountries();
  }

  private loadLookupCountries() {
    if (this.getLookupCountriesSubscription) {
      this.getLookupCountriesSubscription.unsubscribe();
      this.getCarrierKeySubscription = null;
    }

    this.getLookupCountriesSubscription = this.lookupService.getCountries()
      .subscribe(
        countries => this.handleGetLookupCountriesSuccess(countries),
        err => this.handleGetLookupCountriesFailure(err),
      );
  }

  private loadBillToAccounts() {
    if (this.billToAccounts) {
      return;
    }

    if (this.getCustomerBillToAccountsSubscription) {
      this.getCustomerBillToAccountsSubscription.unsubscribe();
      this.getCustomerBillToAccountsSubscription = null;
    }

    this.isBillToAccountsLoading = true;
    this.getCustomerBillToAccountsSubscription = this.shipmentService.getCustomerBillToAccounts()
      .subscribe(
        accounts => this.handleGetCustomerBillToAccountsSuccess(accounts),
        err => this.handleGetCustomerBillToAccountsFailure(err),
      );
  }

  //#region GetLookupCountries handlers
  private handleGetLookupCountriesSuccess(countries) {
    this.countries = countries;
  }

  private handleGetLookupCountriesFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed getting lookup countries',
      NotificationType.ERROR);
  }

  //#endregion

  //#region getCustomerBillToAccounts handlers
  private handleGetCustomerBillToAccountsSuccess(accounts: IBillToAccount[]) {
    this.billToAccounts = accounts;
    this.isBillToAccountsLoading = false;
  }

  private handleGetCustomerBillToAccountsFailure(err) {
    console.error(err);
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed getting customer bill-to accounts',
      NotificationType.ERROR);
    this.isBillToAccountsLoading = false;
  }

  //#endregion

  //#region GetCarrierKey handlers
  private handleGetCarrierKeySuccess(carrierKeys: ICarrierKey[]) {
    this.billToAccount = 'N/A';
    let accountNumber;
    let isShowRates = false;

    if (carrierKeys && carrierKeys.length && carrierKeys[0]) {
      accountNumber = carrierKeys[0].AccountNumber;
      isShowRates = carrierKeys[0].ShowRates;
      this.billToAccount = accountNumber;

      if (this.shipBillingForm && this.shipBillingForm.controls.billingDetails.value !== '02') {
        this.shipBillingForm.patchValue({
          accountNumber: this.billToAccount,
        });
      }
    }

    this.shipConfigService.setShowFedExBillTo(this.carrier, accountNumber, isShowRates);
  }

  private handleGetCarrierKeyFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed getting carrier key',
      NotificationType.ERROR);
  }

  //#endregion

  private monitorValueChanges() {
    this.shipBillingForm.valueChanges
      .subscribe(
        (form) => {
          this.isValid.emit(this.shipBillingForm.valid);

          form.accountNumber = form.billingDetails !== '02' ? this.billToAccount : (form.accountNumber || 'N/A');
          form.isBillToThirdParty = form.billingDetails === '02';

          this.shipmentService.saveShipBillingDetails(form);
          this.formValueChanged.emit(form);
        });

    this.shipBillingForm.controls.billingDetails.valueChanges
      .subscribe(
        (value) => {
          this.isBillToThirdPartyVisible = value === '02';

          this.validationService.clearFormControlValidators([
            this.shipBillingForm.controls.accountNumber,
            this.shipBillingForm.controls.country,
            this.shipBillingForm.controls.zipCode,
          ]);

          if (this.isBillToThirdPartyVisible) {
            this.validationService.setFormControlValidators(
              this.shipBillingForm.controls.accountNumber,
              Validators.compose([Validators.required]),
            );

            if (this.isUps) {
              this.validationService.setFormControlValidators(
                this.shipBillingForm.controls.country,
                Validators.compose([Validators.required]),
              );

              this.validationService.setFormControlValidators(
                this.shipBillingForm.controls.zipCode,
                Validators.compose([Validators.required]),
              );
            }
          }
        },
      );

    this.shipBillingForm.controls.billDuties.valueChanges
      .subscribe(
        (value) => {
          this.isBillDutiesThirdPartyVisible = value === 'Third Party';

          this.validationService.clearFormControlValidators([
            this.shipBillingForm.controls.billDutiesAccountNumber,
            this.shipBillingForm.controls.billDutiesCountry,
            this.shipBillingForm.controls.billDutiesZipCode,
          ]);

          if (this.isBillDutiesThirdPartyVisible) {
            this.validationService.setFormControlValidators(
              this.shipBillingForm.controls.billDutiesAccountNumber,
              Validators.compose([Validators.required]),
            );

            if (this.isUps) {
              this.validationService.setFormControlValidators(
                this.shipBillingForm.controls.billDutiesCountry,
                Validators.compose([Validators.required]),
              );

              this.validationService.setFormControlValidators(
                this.shipBillingForm.controls.billDutiesZipCode,
                Validators.compose([Validators.required]),
              );
            }
          }
        },
      );
  }

  private setFormValues() {
    if (!this.shipmentService.Quote) {
      return;
    }

    this.shipBillingForm.patchValue({
      billingDetails: '01',
    });

    this.formService.markAllAsTouchedAndDirty(this.shipBillingForm);
    this.isValid.emit(this.shipBillingForm.valid);
  }

  public getFormErrors() {
    return this.validationService.getFormControlValidationErrors(this.shipBillingForm.controls, 'Billing Details');
  }

  public isFormValid() {
    return this.shipBillingForm.valid;
  }

  private clearGetCarrierKeySubscription() {
    if (!this.getCarrierKeySubscription) {
      return;
    }

    this.getCarrierKeySubscription.unsubscribe();
    this.getCarrierKeySubscription = null;
  }

  public isFedEx() {
    return this.shipConfigService.isFedEx(this.carrier);
  }

  public restoreShipment(shipment: Package) {
    this.shipBillingForm.patchValue({
      billingDetails: shipment.IsBillToThirdParty ? '02' : '01',
      accountNumber: shipment.BillToAccount || 'N/A',
      country: shipment.BillToThirdPartyCountry || 'US',
      zipCode: shipment.BillToThirdPartyPostalCode,
      billToCustomerId: shipment.BillToCustomerId,
      billDuties: shipment.BillDutiesTo || 'Recipient',
      billDutiesAccountNumber: shipment.BillDutiesToAccount,
      billDutiesCountry: shipment.BillDutiesToThirdPartyCountry || 'US',
      billDutiesZipCode: shipment.BillDutiesToThirdPartyPostalCode,
    });

    this.shipBillingForm.markAsDirty();
    this.shipBillingForm.markAsTouched();
  }

  private GetUserCountryCode()
  {
    const user = this.appState.user$();
    this.isEUUser = this.userService.isEUUser(user?.CountryCode);
  }
}

<div class="ship-electronic-export ups-form">
    <div class="form-body">
        <div class="form-section-header">
            <h5 class="form-section-title">{{'ExportInformation'|translate}}</h5>
        </div>

        <form [formGroup]="formGroup" class="ups-form">
            <div class="form-body">
                <div *ngIf="config?.showEEI" class="eei-section">
                    <p *ngIf="!config?.requireEEI && !config?.showB13 && !config?.showNoB13Required && !showFilingOptionsByCountry"
                       class="eei-message">
                        {{'NoEEISEDRequired'|translate}}
                    </p>
                    <p *ngIf="(config?.requireEEI && !config?.showB13 && !config?.showNoB13Required) || showFilingOptionsByCountry"
                       class="eei-message">
                        {{'ExportInformationShipperExportDeclaration'|translate}}
                    </p>
                    <div *ngIf="config?.showB13" class="b13">
                        <p class="eei-message">{{ 'B13ARequired' | translate }}</p>
                        <p>{{ 'B13Message' | translate }}</p>
                        <p>
                            <a href="https://www.cbsa-asfc.gc.ca/publications/forms-formulaires/b13a.pdf"
                               target="_blank">{{
                                'B13FormDownload' | translate }}</a>
                        </p>
                    </div>
                    <div *ngIf="!config?.showB13 && config?.showNoB13Required" class="b13">
                        <p class="eei-message">{{ 'NoB13ARequired' | translate }}</p>
                    </div>

                    <div *ngIf="config?.requireEEI || config?.showB13 || showFilingOptionsByCountry" class="eei">
                        <div class="select-wrapper">
                            <mat-form-field>
                                <mat-label>{{'EEISEDFilingOption'|translate}}</mat-label>
                                <mat-select formControlName="eeiFilingOption"
                                            required>
                                    <mat-option disabled selected value="">{{'SelectFilingOption'|translate}}
                                    </mat-option>
                                    <mat-option *ngFor="let filingOption of filteredEeiFilingOptions"
                                                [value]="filingOption.value">
                                        {{ filingOption.message }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formGroup.controls.eeiFilingOption?.errors">
                                <span *ngIf="formGroup.controls.eeiFilingOption?.errors?.required || formGroup.controls.eeiFilingOption?.errors?.pattern">
                                    {{'DestinationControlStatementRequired'|translate}}
                                </span>
                                </mat-error>

                            </mat-form-field>
                        </div>

                        <div *ngIf="config?.showPowerOfAttorney && !config?.showB13" class="aes-agreement">
                            <p>
                                {{ 'aesAgreementPleaseRead' | translate }}
                                <a (click)="false" href="#">{{ 'aesPrintAgreement' | translate }}</a>
                            </p>
                            <p [innerHTML]="powerOfAttorneyContent" class="power-of-attorney-content"></p>
                            <mat-checkbox formControlName="isPowerOfAttorneyAgreed">{{ 'aesAgreementCheckbox' |
                                translate
                                }}
                            </mat-checkbox>
                        </div>

                        <div *ngIf="hasPostDeparture" class="post-departure">
                            <div class="select-wrapper">
                                <mat-form-field>
                                    <mat-label>{{'AESCitationType'|translate}}</mat-label>
                                    <mat-select formControlName="aesCitationType">
                                        <mat-option disabled selected value="">{{'SelectCitationType'|translate}}
                                        </mat-option>
                                        <mat-option *ngFor="let citationType of aesCitationTypes"
                                                    [value]="citationType.value">
                                            {{ citationType.message }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="formGroup.controls.aesCitationType?.errors?.required">
                                    <span *ngIf="formGroup.controls.aesCitationType?.errors?.required">
                                       {{'AESCitationTypeRequired'|translate}}
                                    </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <mat-form-field>
                                <mat-label>{{'AESCitation'|translate}}</mat-label>
                                <input formControlName="aesCitation"
                                       matInput>
                                <mat-error *ngIf="formGroup.controls.aesCitation?.errors?.required">
                                    <span *ngIf="formGroup.controls.aesCitation?.errors?.required">{{'AESCitationRequired'|translate}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="select-wrapper">
                            <mat-form-field>
                                <mat-label>{{'DestinationControlStatement'|translate}}</mat-label>
                                <mat-select formControlName="destinationControlStatement"
                                            required>
                                    <mat-option *ngFor="let controlStatement of destinationControlStatements"
                                                [value]="controlStatement.value">
                                        {{ controlStatement.message }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formGroup.controls.destinationControlStatement?.errors?.required">
                                <span *ngIf="formGroup.controls.destinationControlStatement?.errors?.required">
                                    {{'DestinationControlStatementRequired'|translate}}
                                </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="config?.showMRN" class="mrn">
                    <mat-form-field>
                        <mat-label>{{'EnterMovementReferenceNumber'|translate}}</mat-label>
                        <input class="mrn-input"
                               formControlName="mrn"
                               matInput
                               maxlength="18">
                        <img [ngClass]="lang"
                             alt="Help"
                             class="help-icon"
                             matTooltip="{{ mrnTooltipKey | translate }}"
                             matTooltipPosition="above"
                             src="../../../assets/icons/help.svg">
                        <!-- <mat-icon matSuffix matTooltip="{{ mrnTooltipKey | translate }}" matTooltipPosition="above">
                            help_outline
                        </mat-icon> -->
                        <mat-error
                                *ngIf="formGroup.controls.mrn?.errors?.required || formGroup.controls.mrn?.errors?.mrnFormat">
                            <span *ngIf="formGroup.controls.mrn?.errors?.required">{{'MRNRequired'|translate}}</span>
                            <span *ngIf="formGroup.controls.mrn?.errors?.mrnFormat">
                            {{'MRNAlphaNumericCharacters'|translate}}
                        </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>

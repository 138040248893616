import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'upsc-fedex-location-learn-more-dialog',
    templateUrl: './fedex-location-learn-more-dialog.component.html',
    styleUrls: ['./fedex-location-learn-more-dialog.component.scss'],
})
export class FedExLocationLearnMoreDialogComponent {
    @HostBinding('class') public hostClass = 'fedex-location-learn-more-dialog';

    constructor(
        public dialogRef: MatDialogRef<FedExLocationLearnMoreDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }
}

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Carriers } from '../../shared/enum/general-enum';
import { RendererService } from '../../shared/services/utility/renderer.service';
import {
    AccessPointsLearnMoreDialogService,
} from '../access-points-dialog/access-point-learn-more/access-points-learn-more-dialog.service';
import {
    FedexLocationLearnMoreDialogService,
} from '../fedex-location-dialog/fedex-location-learn-more/fedex-location-learn-more-dialog.service';
import { PickupLocationsDialogComponent } from '../pickup-locations-dialog/pickup-locations-dialog.component';
import { PickupLocationConfig } from './config/pickup-location-config';
import { IPickupLocationRequest } from './models/pickup-location-request.interface';
import { IPickupLocation } from './models/pickup-location.interface';
import { PickupLocationStateService } from './pickup-location-state.service';

@Component({
    selector: 'upsc-pickup-locations',
    templateUrl: './pickup-locations.component.html',
    styleUrls: ['./pickup-locations.component.scss'],
})
export class PickupLocationsComponent implements OnInit, AfterViewInit {
    @Input() public carrier: Carriers;
    @Input() public request: IPickupLocationRequest;
    @Input() public isReadonly = false;
    @Input() public pickupLocationId: string;
    @Input() public insuredValue: number;
    @Output() public locationSelected = new EventEmitter<IPickupLocation>();

    @HostBinding('class') public hostClass = 'pickup-locations';

    @ViewChild('locationsEl') public locationsEl: ElementRef;

    public locations: IPickupLocation[];
    public pickupLocationControl = new FormControl<IPickupLocation>(null);
    public isSearching = false;

    protected readonly Carriers = Carriers;

    public constructor(private accessPointsLearnMoreDialogService: AccessPointsLearnMoreDialogService,
                       private fedexLocationLearnMoreDialogService: FedexLocationLearnMoreDialogService,
                       private readonly pickupLocationStateService: PickupLocationStateService,
                       public readonly pickupLocationsDialogComponent: PickupLocationsDialogComponent,
                       private readonly rendererService: RendererService,
    ) {
    }

    public get carrierString(): string {
        return Carriers[this.carrier]?.toString().toLowerCase();
    }

    public get origin(): string {
        return [
            this.request?.streetAddress,
            this.request?.apartmentSuite,
            this.request?.city,
            this.request?.state,
            this.request?.zip,
            this.request?.country,
        ].filter(Boolean).join(',+');
    }

    public getMaxContentHeight(): number {
        let dialogHeight = this.pickupLocationsDialogComponent.dialogHeight;
        const minDialogHeight = document.body.clientHeight * .8;
        if (dialogHeight < minDialogHeight) {
            dialogHeight = minDialogHeight;
        }

        return dialogHeight - (325 + this.pickupLocationsDialogComponent.dialogFooterHeight);
    }

    public ngOnInit(): void {
        this.pickupLocationControl.valueChanges.subscribe(
            (location) => {
                this.pickupLocationStateService.selectedPickupLocation$.set(location);
                this.locationSelected.emit(location);
            },
        );

        if (this.isReadonly) {
            this.pickupLocationControl.disable();
        }
    }

    public ngAfterViewInit(): void {
        const maxHeight = this.getMaxContentHeight();
        const marginBottom = this.pickupLocationsDialogComponent.dialogFooterHeight;

        const styles = {
            'max-height': `${ maxHeight }px`,
            'margin-bottom': `${ marginBottom }px`,
        };

        this.rendererService.setElementStyles(this.locationsEl, styles);
    }

    public openLearnMoreDialog(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        switch (this.carrier) {
            case Carriers.Ups:
                this.accessPointsLearnMoreDialogService.open();
                break;
            case Carriers.Fedex:
                this.fedexLocationLearnMoreDialogService.open();
                break;
            default:
                break;
        }
    }

    public onSearchResultsReady(locations: IPickupLocation[]) {
        this.isSearching = false;

        locations.forEach(location => {
            if (!location.businessName) {
                location.isEnabled = true;
                return;
            }

            const normalizedStoreName = location.businessName.toUpperCase().replace(/ /g, '_');
            const store = Object.keys(PickupLocationConfig.storeMaxCoverage)
                                .find(key => normalizedStoreName.startsWith(key));

            location.isEnabled = this.isReadonly || !store || PickupLocationConfig.storeMaxCoverage[store] >= this.insuredValue;
        });

        this.locations = locations;

        const preselectedPickupLocation = this.locations.find(location => location.locationId === this.pickupLocationId);
        this.pickupLocationControl.setValue(preselectedPickupLocation);
    }

    public onSearchParametersChanged(request: IPickupLocationRequest) {
        this.request = request;
        this.isSearching = true;
    }
}

import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Carriers } from '../../shared/enum/general-enum';
import { GetShippingUpdatesDialogService } from '../get-shipping-updates-dialog/get-shipping-updates-dialog.service';
import {
    IShippingUpdateInformation,
} from '../get-shipping-updates-dialog/models/shipping-update-information.interface';
import { IAccessPointOriginConfig } from '../models/access-point-origin-config.interface';
import { IAccessPoint } from '../models/access-point.interface';
import { ShipmentService } from '../services/shipment.service';
import {
    AccessPointsLearnMoreDialogComponent,
} from './access-point-learn-more/access-points-learn-more-dialog.component';

@Component({
    selector: 'upsc-access-points-dialog',
    templateUrl: './access-points-dialog.component.html',
    styleUrls: ['./access-points-dialog.component.scss'],
})
export class AccessPointsDialogComponent implements OnInit {
    @HostBinding('class') public hostClass = 'access-points-dialog';

    public formGroup: UntypedFormGroup;
    public isSearching = false;
    public readonly originConfig: IAccessPointOriginConfig;
    public accessPoints: IAccessPoint[];

    constructor(
        public dialogRef: MatDialogRef<AccessPointsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly shipmentService: ShipmentService,
        private dialog: MatDialog,
        private getShippingUpdatesDialogService: GetShippingUpdatesDialogService,
        private snackBar: MatSnackBar,
    ) {
        this.originConfig = this.data?.originConfig;

        this.formGroup = this.formBuilder.group({
            searchRangeMiles: [10],
            recordCount: [10],
            selectedAccessPointId: [null, [Validators.required]],
        });

        this.formGroup?.get('searchRangeMiles')?.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
            )
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    if (this.originConfig) {
                        this.originConfig.Miles = +value;
                        this.searchAccessPoints();
                    }
                },
            );

        this.formGroup?.get('recordCount')?.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
            )
            .subscribe(
                (value) => {
                    if (!value) {
                        return;
                    }

                    if (this.originConfig) {
                        this.originConfig.NoOfRecords = +value;
                        this.searchAccessPoints();
                    }
                },
            );
    }

    public ngOnInit() {
        this.formGroup?.patchValue({
            searchRangeMiles: +(this.originConfig?.Miles || 10),
            recordCount: +(this.originConfig?.NoOfRecords || 10),
            selectedAccessPointId: this.data?.accessPointId,
        });
    }

    public setAccessPoint(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const shippingUpdateInformation: IShippingUpdateInformation = {
            carrier: Carriers.Ups,
            shouldSendToSender: true,
            sender: {
                firstName: this.shipmentService.Quote.ShipFrom.FirstName || '',
                lastName: this.shipmentService.Quote.ShipFrom.LastName || '',
                email: this.shipmentService.Quote.ShipFrom.Email || '',
            },
            recipients: [
                {
                    firstName: this.shipmentService.Quote.ShipTo.FirstName || '',
                    lastName: this.shipmentService.Quote.ShipTo.LastName || '',
                    email: this.shipmentService.Quote.ShipTo.Email || '',
                },
            ],
        };

        // [DD-349] Only close dialog if a user has confirmed the email notifications.
        // If it's not confirmed, reset the pickup location selection and remain on this dialog.
        this.getShippingUpdatesDialogService.open(shippingUpdateInformation).afterClosed().subscribe(
            (shippingUpdateInformation) => {
                if (!shippingUpdateInformation) {
                    this.formGroup.patchValue({
                        selectedAccessPointId: null,
                    });

                    this.snackBar.open(
                        'Email notification is required when using a pickup location.',
                        null,
                        { duration: 3000 },
                    );

                    return;
                }

                this.shipmentService.shippingUpdateInformationChanged$.next(shippingUpdateInformation);

                const selectedAccessPointId = this.formGroup.get('selectedAccessPointId').value;
                const selectedAccessPoint = this.accessPoints?.find(ap => ap.UPSAccessPointID === selectedAccessPointId);

                this.dialogRef.close({
                    accessPoint: selectedAccessPoint,
                    searchRange: this.formGroup?.get('searchRangeMiles').value,
                    maxItems: this.formGroup?.get('recordCount').value,
                });
            },
        );
    }

    public showLearnMoreDialog(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '34.625rem',
            panelClass: 'mobile-fullscreen-dialog',
            data: {},
        };

        this.dialog.open(AccessPointsLearnMoreDialogComponent, dialogConfig);
    }

    private searchAccessPoints(): void {
        this.isSearching = true;
        this.shipmentService.getAccessPoints(this.data?.carrierCode, this.originConfig)
            .pipe(
                finalize(() => {
                    this.isSearching = false;
                }),
            ).subscribe(
            (res) => {
                this.accessPoints = res;
            },
        );
    }
}
